<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <template v-slot:activator="{ on }">
      <span v-on="items.length != 0 ? on : null" class="mr-3">
        <primary-button :disabled="items.length == 0">Schedule</primary-button>
      </span>
    </template>
    <dialog-card
      title="Schedule Letters"
      :loading="loading"
      @close="dialog = false"
      @submit="submit"
    >
      <select-field
        :items="faxSchedules"
        item-text="schedule_name"
        label="Assign a schedule to the selected letters"
        v-model="schedule"
      ></select-field>
    </dialog-card>
  </v-dialog>
</template>

<script>
import DialogCard from "../ui/DialogCard.vue";
import PrimaryButton from "../ui/buttons/PrimaryButton.vue";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/assets/utils/firebase.utils";
import SelectField from "../ui/form/SelectField.vue";
export default {
  components: { DialogCard, PrimaryButton, SelectField },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      schedule: "",
      loading: false,
    };
  },
  watch: {
    dialog(val) {
      if (val) this.$emit("close");
    },
  },
  inject: ["faxSchedules"],
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        let doc = {
          ids: vm.items.map((i) => i.id),
          scheduleId: this.schedule,
        };
        let updateFaxSchedule = httpsCallable(functions, "updateFaxSchedule");
        let { status, message } = (await updateFaxSchedule(doc)).data;
        if (!status) {
          vm.notifyError(message);
        }

        vm.dialog = false;
        vm.loading = false;
        vm.$emit("fetchFaxes");
      } catch (error) {
        vm.loading = false;
        vm.notifyError(error.message);
      }
    },
  },
};
</script>

<style></style>
