<template>
  <layout title="My Team Members">
    <add-member slot="add" v-if="hasEdit('team-members')" />
    <static-table-view :list="members" :loading="loading" :headers="headers">
      <template v-slot:item.updated_by="{ value }">
        {{ getUpdatedBy(value) }}</template
      >
      <template v-slot:item.updated_date="{ item }">
        {{ item.updated_at }}</template
      >
      <template v-slot:item.role="{ item }">
        {{ getRoleName(item.role_id) }}</template
      >
      <template v-slot:item.action="{ item }">
        <div class="d-flex">
          <add-member :item="item" v-if="hasEdit('team-members')" />
          <delete-icon-button
            v-if="hasDelete('team-members')"
            @click="remove(item)"
          ></delete-icon-button>
        </div>
      </template>
    </static-table-view>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import StaticTableView from "@/components/ui/StaticTableView.vue";
import { mapGetters } from "vuex";
import DeleteIconButton from "@/components/ui/buttons/DeleteIconButton.vue";
import { httpsCallable } from "firebase/functions";
import AddMember from "@/components/mycompany/AddMember.vue";
import { functions } from "@/assets/utils/firebase.utils";
export default {
  components: { Layout, StaticTableView, DeleteIconButton, AddMember },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["mapUser", "members"]),
    headers() {
      return [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Role", value: "role", width: "100px" },
        { text: "Update On", value: "updated_date", width: "200px" },
        { text: "Update By", value: "updated_by", width: "200px" },
        { text: "Action", value: "action", width: "100px" },
      ];
    },
  },
  methods: {
    async remove({ id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure you want to delete this user?",
        onConfirm: async () => {
          try {
            let deleteUser = httpsCallable(functions, "manageUser");
            await deleteUser({
              data: {
                id,
              },
              action: "delete",
            });
          } catch (error) {
            this.handleError(error);
          }
        },
      });
    },
  },
};
</script>

<style></style>
