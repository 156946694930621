<template>
  <div>
    <div class="text-center mt-10" v-if="loading">
      <v-progress-circular
        color="primary"
        indeterminate
        class="mb-0"
      ></v-progress-circular>
    </div>
    <template v-else>
      <div class="d-flex text-h4 py-6 align-center" v-if="isTop">
        <slot name="title">
          {{ title }}
        </slot>
        <v-spacer></v-spacer>
        <slot name="add"></slot>
      </div>
      <slot></slot>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    title: {},
    isTop: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
