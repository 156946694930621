<template>
  <v-btn
    v-bind="$attrs"
    color="primary"
    height="40px"
    text
    @click="$emit('click')"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<style></style>
