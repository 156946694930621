<template>
  <v-data-table
    :headers="headersList"
    :items="list"
    class="dynamic-table"
    :loading="loading"
    @input="$emit('input', selected)"
    v-model="selected"
    v-bind="$attrs"
    :search="search"
    :footer-props="{
      'items-per-page-options': [10, 20, 50, 100, -1],
      'show-first-last-page': true,
    }"
    :items-per-page="itemsPerPage"
  >
    <template v-slot:top="{ pagination, options, updateOptions }" v-if="isTop">
      <div class="d-flex">
        <slot name="left">
          <div class="flex-1">
            <search-field
              :placeholder="searchPlaceholder"
              v-model="search"
              clearable
              v-if="showSearch"
            ></search-field>
          </div>
        </slot>
        <v-data-footer
          :items-per-page-options="[10, 20, 50, 100, -1]"
          :pagination="pagination"
          :options="options"
          show-first-last-page
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
        />
      </div>
    </template>

    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
      ><slot :name="slot" v-bind="scope" />
    </template>
  </v-data-table>
</template>
<script>
import { isLocal } from "@/assets/utils/firebase.utils";
import SearchField from "./form/SearchField.vue";
export default {
  components: { SearchField },
  inheritAttrs: false,
  props: {
    isTop: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    searchPlaceholder: {
      type: String,
      default: "Search",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: "",
      selected: [],
      itemsPerPage: isLocal ? 10 : 50,
    };
  },
  computed: {
    headersList() {
      return [...this.headers].map((item) => {
        return item;
      });
    },
  },
  watch: {
    value(val) {
      this.selected = val || [];
    },
  },
};
</script>
