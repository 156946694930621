<template>
  <layout>
    <v-row>
      <v-col cols="12" md="6">
        <div class="text-h4 pb-4 text-center">
          {{ `Import / Audit (${currentClient.name})` }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" class="d-flex align-center flex-column">
        <div style="width: auto" class="d-flex justify-center">
          <div>
            <v-tabs hide-slider class="tab-grouped rounded-lg" v-model="tab" f>
              <v-tab class="text-none">Import Credit Report</v-tab>
              <v-tab class="text-none"> Simple Audit (Credit Analysis) </v-tab>
            </v-tabs>
          </div>
        </div>
        <template v-if="tab == 0">
          <v-skeleton-loader
            v-if="loading"
            style="width: 400px"
            type="article, actions"
          ></v-skeleton-loader>
          <template v-else>
            <div class="title font-weight-meidum mt-6">Credit Report</div>
            <div class="text-caption">
              {{
                items.length == 0
                  ? "No credit reports have been imported yet"
                  : `Last imported ${lastReportDate}`
              }}
            </div>
            <img
              src="@/assets/images/credit-import.png"
              class="mt-10"
              width="85px"
            />
            <credit-report-import :logs="items" @onImport="onImport" />
          </template>
        </template>
      </v-col>
      <v-col cols="12" md="6" v-if="tab == 0">
        <div class="title font-weight-meidum">Import Logs</div>
        <div class="text-caption">Report providers: SmartCredit / Enfortra</div>
        <static-table-view
          :headers="headers"
          :items="items"
          :loading="loading"
          :list="items"
        >
          <template v-slot:item.report_type="{ value }">
            {{ value || "SmartCredit" }}
          </template>
          <template v-slot:item.action="{ item }">
            <div class="d-flex justify-end" v-if="item.is_log != true">
              <v-btn
                @click="$refs.summary.init(item)"
                class="mr-2"
                text
                color="primary"
                v-if="item.previous_report"
                >Summary</v-btn
              >
              <v-btn
                color="primary"
                v-if="item.report_pdf"
                text
                @click="openReport(item)"
                >View Report</v-btn
              >
            </div>
          </template>
        </static-table-view>
      </v-col>
    </v-row>
    <credit-report-summary ref="summary" :customer="currentClient" />
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import CreditReportImport from "@/components/clients/creditReport/CreditReportImport.vue";
import StaticTableView from "@/components/ui/StaticTableView.vue";
import { getHeaders } from "@/assets/utils/common.utils";
import {
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  colCreditReportLogs,
  colCreditReports,
  db,
} from "@/assets/utils/firebase.utils";
import { mapDocs } from "@/assets/utils/doc.utils";
import moment from "moment-timezone";
import CreditReportSummary from "@/components/clients/creditReport/CreditReportSummary.vue";
export default {
  components: {
    Layout,
    CreditReportImport,
    StaticTableView,
    CreditReportSummary,
  },
  data() {
    return {
      tab: 0,
      items: [],
      loading: false,
      unsubscribeReports: null,
    };
  },
  computed: {
    headers() {
      let doc = {
        created_at: "Date",
        created_by: "Imported By",
        report_type: "Report Type",
        action: "Action",
      };
      return getHeaders(doc);
    },
    lastReportDate() {
      return moment(this.items[0].created_at).fromNow();
    },
  },
  methods: {
    onImport(result) {
      if (result.previous_report) {
        this.$refs.summary.init(result);
      }
    },

    async fetchReports() {
      this.loading = true;
      let reportQuery = query(
        colCreditReports,
        orderBy("created_at", "desc"),
        where("customer", "==", this.$route.params.id)
      );
      this.items = [];
      let logQuery = query(
        colCreditReportLogs,
        where("customer", "==", this.$route.params.id)
      );
      this.unsubscribeReports = onSnapshot(
        reportQuery,
        async (snap) => {
          let logs = mapDocs((await getDocs(logQuery)).docs).map((i) => ({
            ...i,
            is_log: true,
          }));
          let reports = mapDocs(snap.docs);
          this.items = [...logs, ...reports].sort(
            (a, b) => moment(b.created_at).unix() - moment(a.created_at).unix()
          );
          this.loading = false;
        },
        (error) => {
          this.handleError(error);
        }
      );
    },
    openReport(item) {
      window.open(item.report_pdf, "_blank");
    },
    convertToJSON(data) {
      const lines = data
        .split("\n")
        .map((line) => line.split("arrow_downward").join("").trim());
      const headers = [lines[0], lines[1]];
      console.log(headers);
      const jsonData = [];

      if (
        headers.indexOf("Date Saved As Pending") != -1 &&
        headers.indexOf("Team Member") != -1
      ) {
        for (let i = 2; i < lines.length; i += 2) {
          let created_at = moment(lines[i].trim(), "MMM DD YYYY hh:mm A");
          let created_by = lines[i + 1]?.trim();
          if (created_at.isValid() && created_by) {
            const entry = {
              created_at: created_at.format("YYYY-MM-DDTHH:mm:ss"),
              customer: this.$route.params.id,
              created_by,
              report_pdf: "",
            };
            jsonData.push(entry);
          }
        }
      }

      return jsonData;
    },
    async handlePaste(event) {
      if (this.loading) return;
      const pastedData = (event.clipboardData || window.clipboardData).getData(
        "text"
      );
      let list = this.convertToJSON(pastedData);
      console.log(list);
      try {
        this.loading = true;
        list = list.filter((i) => {
          let item = this.items.find(
            (r) =>
              moment(r.created_at).format("YYYY-MM-DD") ==
              moment(i.created_at).format("YYYY-MM-DD")
          );
          return item == null;
        });
        let batch = writeBatch(db);
        list.forEach((i) => {
          let reportId = doc(colCreditReportLogs).id;
          batch.set(doc(colCreditReportLogs, reportId), i);
        });
        await batch.commit();
        if (this.unsubscribeReports) this.unsubscribeReports();
        this.fetchReports();
      } catch (error) {
        this.handleError(error);
      }
    },
  },

  beforeDestroy() {
    if (this.unsubscribeReports) this.unsubscribeReports();
    if (this.hasEdit("customers")) {
      window.removeEventListener("paste", this.handlePaste);
    }
  },
  mounted() {
    if (this.hasEdit("customers")) {
      window.addEventListener("paste", this.handlePaste);
    }
    this.fetchReports();
  },
};
</script>

<style></style>
