<template>
  <layout title="Fax Recipients">
    <add-fax-recipient
      @onUpdate="init()"
      slot="add"
      v-if="hasEdit('fax-recipients')"
    />
    <static-table-view :list="list" :loading="loading" :headers="headers">
      <template v-slot:item.color="{ value }">
        <span class="status-dot" :style="{ background: value }"></span>
      </template>
      <template v-slot:item.updated_date="{ item }">
        {{ item.updated_at }}</template
      >

      <template v-slot:item.action="{ item }">
        <div class="d-flex">
          <add-fax-recipient
            @onUpdate="init()"
            :item="item"
            v-if="hasEdit('fax-recipients')"
          />
          <delete-icon-button
            v-if="hasDelete('fax-recipients')"
            @click="remove(item)"
          />
        </div>
      </template>
      <template v-slot:item.updated_by="{ value }">
        {{ getUpdatedBy(value) }}
      </template>
    </static-table-view>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import StaticTableView from "@/components/ui/StaticTableView.vue";
import { colFaxRecipients } from "@/assets/utils/firebase.utils";
import { deleteDoc, doc, getDocs } from "firebase/firestore";
import DeleteIconButton from "@/components/ui/buttons/DeleteIconButton.vue";
import { mapDocs } from "@/assets/utils/doc.utils";
import AddFaxRecipient from "@/components/mycompany/AddFaxRecipient.vue";
export default {
  components: {
    Layout,
    StaticTableView,
    DeleteIconButton,
    AddFaxRecipient,
  },
  data() {
    return {
      list: [],
      loading: false,
    };
  },
  computed: {
    headers() {
      return [
        { text: "Recipient", value: "recipient" },
        { text: "Update On", value: "updated_date", width: "200px" },
        { text: "Update By", value: "updated_by", width: "200px" },
        { text: "Action", value: "action", width: "100px" },
      ];
    },
  },
  methods: {
    async remove({ id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure you want to delete this client status?",
        onConfirm: () => {
          // eslint-disable-next-line no-async-promise-executor
          return new Promise(async (resolve, reject) => {
            try {
              await deleteDoc(doc(colFaxRecipients, id));
              vm.init();
              resolve();
            } catch (error) {
              reject(error);
            }
          });
        },
      });
    },
    async init() {
      const vm = this;
      try {
        vm.list = [];
        vm.loading = true;
        vm.list = mapDocs((await getDocs(colFaxRecipients)).docs);
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.status-dot {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: inline-block;
}
</style>
