<template>
  <v-container class="d-flex align-center justify-center fluid fill-height">
    <v-card width="380" flat outlined class="pa-2 mt-10">
      <v-card-title class="headline font-weight-bold">
        Forgot Password?
      </v-card-title>
      <form-wrapper @submit="submit">
        <v-card-text class="pt-0">
          <input-field
            v-model="email"
            rules="email"
            type="email"
            label="Enter Your Email"
          >
          </input-field>
        </v-card-text>
        <v-card-actions class="pa-3 pt-0 flex-column">
          <primary-button type="submit" class="mb-3" block :loading="loading">
            Submit
          </primary-button>
          <p class="mb-0">
            Already have an account?
            <router-link to="/login">Login</router-link>
          </p>
        </v-card-actions>
      </form-wrapper>
    </v-card>
  </v-container>
</template>

<script>
import FormWrapper from "../../components/ui/form/FormWrapper.vue";
import InputField from "../../components/ui/form/InputField.vue";
import PrimaryButton from "../../components/ui/buttons/PrimaryButton.vue";
import { functions } from "@/assets/utils/firebase.utils.js";
import { httpsCallable } from "firebase/functions";

export default {
  components: { InputField, FormWrapper, PrimaryButton },
  data() {
    return {
      email: "",
      loading: false,
    };
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        let resetPassword = httpsCallable(functions, "resetPassword");
        let body = { email: vm.email };
        let { status, message } = (await resetPassword(body)).data;
        if (!status) {
          vm.notifyError(message);
        } else {
          vm.email = "";
          vm.$router.replace("/login");
          vm.notifySuccess("Your access details have been sent to your email.");
        }
        vm.loading = false;
      } catch (error) {
        vm.loading = false;
        vm.notifyError(error.message);
      }
      // sendPasswordResetEmail(auth, vm.email)
      //   .then(() => {
      //     vm.loading = false;
      //     vm.email = "";
      //     vm.$router.push("/login");
      //     vm.notifyError("We have sent a password reset link to your E-mail");
      //   })
      //   .catch(({ message, code }) => {
      //     vm.loading = false;
      //     if (code == "auth/user-not-found") message = "User not found.";
      //     vm.notifyError(message);
      //   });
    },
  },
};
</script>
<style></style>
