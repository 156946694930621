<template>
  <v-dialog width="400px" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" v-if="hasEdit('send-faxes')" color="primary">
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </template>
    <dialog-card
      title="Schedule Settings"
      :loading="isLoading"
      @submit="submit"
      @close="dialog = false"
    >
      <radio-group
        :items="items"
        :row="false"
        label="Schedule Action"
        v-model="schedule_action"
      ></radio-group>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { getDate } from "@/assets/utils/common.utils";
import DialogCard from "../ui/DialogCard.vue";
import RadioGroup from "../ui/form/RadioGroup.vue";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { colSettings } from "@/assets/utils/firebase.utils";
export default {
  components: {
    DialogCard,
    RadioGroup,
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      schedule_action: "send-fax",
      items: [
        {
          name: "Send Fax",
          value: "send-fax",
        },
        {
          name: "Manual Print Draft",
          value: "manual-print-draft",
        },
      ],
      faxSettings: {},
    };
  },
  watch: {
    dialog() {
      if (this.faxSettings.schedule_action) {
        this.schedule_action = this.faxSettings.schedule_action;
      }
    },
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.isLoading = true;
        await updateDoc(doc(colSettings, "fax"), {
          schedule_action: vm.schedule_action,
          updated_at: getDate(),
          updated_by: vm.userEmail,
        });
        vm.isLoading = false;
        vm.dialog = false;
      } catch (error) {
        vm.isLoading = false;
        vm.handleError(error);
      }
    },
    async init() {
      try {
        this.isLoading = true;
        this.faxSettings = (await getDoc(doc(colSettings, "fax"))).data() || {};
        this.isLoading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
