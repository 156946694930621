<template>
  <component :is="isPage ? 'layout' : 'div'" title="Running Totals">
    <div style="width: 120px">
      <select-field
        label="Year"
        v-model="year"
        :disabled="loading"
        @input="onYearChange"
        :items="years"
        hide-details
      ></select-field>
    </div>
    <please-wait v-if="loading"></please-wait>
    <v-row v-else>
      <v-col class="blue--text d-flex justify-center flex-column">
        <div>
          Total PPD = <b>{{ formatPrice(stats.totalPPD) }}</b>
        </div>
        <div class="mt-2">
          AVG PPD = <b>{{ formatPrice(stats.avgPPD) }}</b>
        </div>
        <div class="mt-2">
          AVG PPD Enrolled/mo = <b> {{ avgPPDEnr }}</b>
        </div>
      </v-col>
      <v-col class="blue--text d-flex justify-center flex-column">
        <div>
          Total MR = <b>{{ formatPrice(stats.totalMR) }}</b>
        </div>
        <div class="mt-2">
          AVG MR = <b>{{ formatPrice(stats.avgMR) }} </b>
        </div>
        <div class="mt-2">
          AVG MR Enrolled/mo = <b>{{ avgMREnr }}</b>
        </div>
      </v-col>
      <v-col class="blue--text d-flex justify-center flex-column">
        <div>
          AVG MR Length of Stay = <b>{{ avgMRLength }} months</b>
        </div>
        <div class="mt-2">
          Active MR Clients = <b>{{ activeMRClient }}</b>
        </div>
        <div class="mt-2">
          Total Commission = <b>{{ formatPrice(stats.commission) }}</b>
        </div>
      </v-col>
    </v-row>
  </component>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import SelectField from "@/components/ui/form/SelectField.vue";
import {
  count,
  getAggregateFromServer,
  getCountFromServer,
  query,
  sum,
  where,
} from "firebase/firestore";
import { colClients, colTransactions } from "@/assets/utils/firebase.utils";
import moment from "moment-timezone";
import { endDate, startDate } from "@/assets/utils/common.utils";
import PleaseWait from "@/components/ui/PleaseWait.vue";
export default {
  props: {
    isPage: {
      type: Boolean,
      default: true,
    },
  },
  components: { Layout, SelectField, PleaseWait },
  data() {
    return {
      year: new Date().getFullYear(),
      loading: false,
      report: {},
      activeMRClient: 0,
      stats: {
        totalPPD: 0,
        totalMR: 0,
        commission: 0,
        avgMR: 0,
        avgPPD: 0,
      },
      totals: {},
      totalMRClient: 0,
      avgPPDEnr: 0,
      avgMREnr: 0,
      totalMRTransactions: 0,
    };
  },
  computed: {
    years() {
      let range = new Date().getFullYear() + 1;
      let years = [];
      for (let year = 2020; year < range; year++) {
        years.push(year);
      }
      years = years.reverse();
      years.push("All Time");
      return years;
    },

    avgMRLength() {
      let total = this.totalMRTransactions || 0;
      if (!total || !this.totalMRClient) return 0;
      return (total / this.totalMRClient).toFixed(2);
    },
    averageMR() {
      return 0;
    },
  },
  methods: {
    async onYearChange(isInit) {
      const vm = this;
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          vm.loading = true;
          vm.avgMREnr = 0;
          vm.avgPPDEnr = 0;
          let start = "2020-01-01";
          let year = this.year;
          let end = moment().format("YYYY-MM-DD");
          if (year != "All Time") start = `${year}-01-01`;
          if (year != "All Time" && year != moment().format("YYYY")) {
            end = `${year}-12-01`;
          }
          start = startDate(start);
          end = endDate(end);
          let months = moment(end).diff(start, "months", false) + 1;
          let mrClientQuery = query(
            colClients,
            where("first_mr", ">=", start),
            where("first_mr", "<=", end)
          );
          let ppdClientQuery = query(
            colClients,
            where("first_ppd", ">=", start),
            where("first_ppd", "<=", end)
          );
          let totalCommissionQuery = query(
            colTransactions,
            where("created_at", ">=", start),
            where("created_at", "<=", end)
          );
          let totalPPDQuery = query(
            colTransactions,
            where("created_at", ">=", start),
            where("created_at", "<=", end),
            where("client_type", "==", "PPD")
          );
          let totalMRQuery = query(
            colTransactions,
            where("created_at", ">=", start),
            where("created_at", "<=", end),
            where("client_type", "==", "MR")
          );
          let mrClients = (await getCountFromServer(mrClientQuery)).data()
            .count;
          let ppdClients = (await getCountFromServer(ppdClientQuery)).data()
            .count;
          let totalCommission = (
            await getAggregateFromServer(totalCommissionQuery, {
              total: sum("commission"),
            })
          ).data().total;

          let ppdStats = (
            await getAggregateFromServer(totalPPDQuery, {
              count: count(),
              total: sum("amount"),
            })
          ).data();

          let mrStats = (
            await getAggregateFromServer(totalMRQuery, {
              count: count(),
              total: sum("amount"),
            })
          ).data();
          vm.stats = {
            totalPPD: ppdStats.total,
            totalMR: mrStats.total,
            commission: totalCommission,
            avgMR: mrStats.count
              ? (mrStats.total / mrStats.count).toFixed(2)
              : 0,
            avgPPD: ppdStats.count
              ? (ppdStats.total / ppdStats.count).toFixed(2)
              : 0,
          };
          vm.avgMREnr = (mrClients / months).toFixed(1);
          vm.avgPPDEnr = (ppdClients / months).toFixed(1);
          vm.loading = false;
          resolve();
        } catch (error) {
          console.log(error);
          if (isInit) {
            reject(error);
          } else {
            vm.handleError(error);
          }
        }
      });
    },
    async init() {
      const vm = this;
      try {
        vm.loading = true;
        let mrQuery = query(
          colClients,
          where("client_type", "==", "MR"),
          where("running_totals.all.total_mr_transaction", ">", 0)
        );
        let mrTransQuery = query(colClients, where("client_type", "==", "MR"));
        let avgMRQuery = query(
          colClients,
          where("customer_type", "in", ["client", "ready for processing"]),
          where("client_type", "==", "MR")
        );

        vm.activeMRClient = (await getCountFromServer(avgMRQuery)).data().count;
        vm.totalMRClient = (await getCountFromServer(mrQuery)).data().count;

        let mrTransactions = (
          await getAggregateFromServer(mrTransQuery, {
            mr: sum("running_totals.all.total_mr_transaction"),
            ppd: sum("running_totals.all.total_ppd_transaction"),
          })
        ).data();
        vm.totalMRTransactions = mrTransactions.mr + mrTransactions.ppd;
        await vm.onYearChange(true);
      } catch (error) {
        console.log(error);
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
