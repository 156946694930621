export const frequencyUnits = ["Days", "Weeks", "Months"];
export const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const faxStatusList = [
  "DRAFT",
  "SENDING",
  "SUCCESS",
  "COMPLETED",
  "MAILED",
  "UPLOADED TU",
  "UPLOADED EFX",
  "FAILED",
  "SENT TO 1 OF 2 RECIPIENTS",
  "SENT TO 0 OF 2 RECIPIENTS",
];
export const faxNumberStatusColors = {
  NEW: "orange",
  INPROGRESS: "orange",
  COMPLETE: "success",
  ERROR: "red",
  CANCELED: "red",
  MAILED: "blue",
  "UPLOADED TU": "blue",
  "UPLOADED EFX": "blue",
};
export const faxStatusColors = {
  DRAFT: "grey--text",
  SENDING: "black--text",
  COMPLETED: "green--text",
  SUCCESS: "green--text",
  FAILED: "red--text",
  MAILED: "blue--text",
  "UPLOADED TU": "blue--text",
  "UPLOADED EFX": "blue--text",
  "SENT TO 1 OF 2 RECIPIENTS": "orange--text",
  "SENT TO 0 OF 2 RECIPIENTS": "orange--text",
};
