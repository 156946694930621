import Vue from "vue";
import Vuex from "vuex";
import userStore from "./modules/user.store";
import affiliateStore from "./modules/affiliate.store";
import clientStore from "./modules/client.store";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: userStore,
    affiliateStore,
    clientStore,
  },
});
