<template>
  <layout title="Roles & Permissions">
    <add-button slot="add" to="add-role" v-if="hasEdit('roles-permissions')"
      >Add New Role</add-button
    >
    <static-table-view :list="list" :loading="loading" :headers="headers">
      <template v-slot:item.action="{ item }">
        <div class="d-flex align-center">
          <v-btn
            text
            v-if="hasEdit('roles-permissions')"
            color="primary"
            :to="{ name: 'update-role', params: { id: item.id } }"
            >Manage Permissions</v-btn
          >
          <delete-icon-button
            v-if="hasDelete('roles-permissions')"
            @click="remove(item)"
          ></delete-icon-button>
        </div>
      </template>
    </static-table-view>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import StaticTableView from "@/components/ui/StaticTableView.vue";
import AddButton from "@/components/ui/buttons/AddButton.vue";
import {
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { colRoles, colUsers } from "@/assets/utils/firebase.utils";
import { mapDocs } from "@/assets/utils/doc.utils";
import DeleteIconButton from "@/components/ui/buttons/DeleteIconButton.vue";
export default {
  components: { Layout, StaticTableView, AddButton, DeleteIconButton },
  data() {
    return {
      list: [],
      loading: false,
    };
  },
  computed: {
    headers() {
      return [
        { text: "Name", value: "name" },
        { text: "Action", value: "action", width: "100px" },
      ];
    },
  },
  methods: {
    async remove({ id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure you want to Delete this Role?",
        onConfirm: () => {
          // eslint-disable-next-line no-async-promise-executor
          return new Promise(async (resolve, reject) => {
            try {
              let q = query(colUsers, where("role_id", "==", id), limit(1));
              let docs = (await getDocs(q)).docs;
              if (docs.length != 0) {
                reject({
                  message:
                    "Role can't be removed, since it is attached to the users",
                });
                return;
              }
              await deleteDoc(doc(colRoles, id));
              vm.notifySuccess("Role has been deleted successfully.");
              vm.init();
              resolve();
            } catch (error) {
              reject(error);
            }
          });
        },
      });
    },
    async init() {
      const vm = this;
      try {
        vm.list = [];
        vm.loading = true;
        let q = query(colRoles, orderBy("name", "asc"));
        vm.list = mapDocs((await getDocs(q)).docs);
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
