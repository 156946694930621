<template>
  <layout title="Fax Templates">
    <add-button slot="add" to="add-fax-template" v-if="hasEdit('fax-templates')"
      >Add New</add-button
    >
    <static-table-view
      :show-search="true"
      :list="list"
      search-placeholder="Search by Template Name"
      :loading="loading"
      :headers="headers"
    >
      <template v-slot:item.updated_date="{ item }">
        {{ item.updated_at }}</template
      >

      <template v-slot:item.action="{ item }">
        <div class="d-flex align-center">
          <edit-icon-button
            v-if="hasEdit('fax-templates')"
            :to="{ name: 'update-fax-template', params: { id: item.id } }"
          />
          <delete-icon-button
            v-if="hasDelete('fax-templates')"
            @click="remove(item)"
          />
        </div>
      </template>
      <template v-slot:item.updated_by="{ value }">
        {{ getUpdatedBy(value) }}
      </template>
    </static-table-view>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import StaticTableView from "@/components/ui/StaticTableView.vue";
import AddButton from "@/components/ui/buttons/AddButton.vue";
import { deleteDoc, doc, getDocs, orderBy, query } from "firebase/firestore";
import { colFaxTemplates } from "@/assets/utils/firebase.utils";
import { mapDocs } from "@/assets/utils/doc.utils";
import DeleteIconButton from "@/components/ui/buttons/DeleteIconButton.vue";
import EditIconButton from "@/components/ui/buttons/EditIconButton.vue";
export default {
  components: {
    Layout,
    StaticTableView,
    AddButton,
    DeleteIconButton,
    EditIconButton,
  },
  data() {
    return {
      list: [],
      loading: false,
    };
  },
  computed: {
    headers() {
      return [
        { text: "Template Name", value: "template_name" },
        { text: "Update On", value: "updated_date", width: "200px" },
        { text: "Update By", value: "updated_by", width: "200px" },
        { text: "Action", value: "action", width: "100px" },
      ];
    },
  },
  methods: {
    async remove({ id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure you want to delete this fax template?",
        onConfirm: () => {
          // eslint-disable-next-line no-async-promise-executor
          return new Promise(async (resolve, reject) => {
            try {
              await deleteDoc(doc(colFaxTemplates, id));
              vm.init();
              resolve();
            } catch (error) {
              reject(error);
            }
          });
        },
      });
    },
    async init() {
      const vm = this;
      try {
        vm.list = [];
        vm.loading = true;
        let q = query(colFaxTemplates, orderBy("template_name", "asc"));
        vm.list = mapDocs((await getDocs(q)).docs);
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
