import { mapDocs, sortAlpha } from "@/assets/utils/doc.utils";
import { colAffiliates } from "@/assets/utils/firebase.utils";
import { onSnapshot } from "firebase/firestore";

let unsubscribeAffilates = null;
export default {
    state: {
        _affiliates: []
    },
    getters: {
        affiliates: state => sortAlpha(state._affiliates),
        getAffiliateName(state) {
            return (id) => {
                let affiliate = state._affiliates.find(i => i.id == id);
                return affiliate ? affiliate.name : '';
            }
        }
    },
    mutations: {
    },
    actions: {
        resetAffiliate({ state }) {
            state._affiliates = [];
            if (unsubscribeAffilates) unsubscribeAffilates()
        },
        listenAffiliates({ state }) {
            unsubscribeAffilates = onSnapshot(colAffiliates, (snap) => {
                state._affiliates = mapDocs(snap.docs).map(i => {
                    let commission = i.commission || 0;
                    let commission_owed = i.commission_owed || 0;
                    return {
                        ...i,
                        total: parseFloat(commission) + parseFloat(commission_owed),
                        name: `${i.first_name} ${i.last_name}`
                    }
                })
            })
        },
    }
};
