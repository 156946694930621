<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">Rename</v-list-item>
    </template>
    <dialog-card
      title="Rename"
      :loading="loading"
      @close="
        dialog = false;
        $emit('close');
      "
      @submit="submit"
    >
      <input-field
        rules="required"
        label="Title"
        v-model="fax_title"
      ></input-field>
    </dialog-card>
  </v-dialog>
</template>

<script>
import rulesConstants from "@/assets/constants/rules.constants";
import DialogCard from "../ui/DialogCard.vue";
import { getDate, getSearchArray } from "@/assets/utils/common.utils";
import InputField from "../ui/form/InputField.vue";
import { doc, updateDoc } from "firebase/firestore";
import { auth, colFaxes } from "@/assets/utils/firebase.utils";
export default {
  components: { DialogCard, InputField },
  props: {
    item: Object,
  },
  data() {
    return {
      dialog: false,
      rulesConstants,
      fax_title: "",
      loading: false,
    };
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        console.log(this.item.id);
        await updateDoc(doc(colFaxes, this.item.id), {
          fax_title: this.fax_title,
          _search: getSearchArray(this.fax_title),
          updated_at: getDate(),
          updated_by: auth.currentUser.email,
        });
        vm.dialog = false;
        vm.loading = false;
        vm.$emit("onNameChange");
      } catch (error) {
        vm.loading = false;
        vm.notifyError(error.message);
      }
    },
  },
  mounted() {
    this.fax_title = this.item.fax_title || "";
  },
};
</script>

<style></style>
