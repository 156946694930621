<template>
  <v-dialog v-model="dialog" persistent max-width="400px">
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <edit-icon-button v-if="isUpdate"></edit-icon-button>
        <add-button v-else>Add New</add-button>
      </span>
    </template>
    <dialog-card
      :loading="loading"
      :title="title"
      @close="dialog = false"
      @submit="submit()"
    >
      <v-row>
        <v-col cols="12" class="pb-0">
          <phone-field
            label="Recipient"
            v-model="recipient"
            rules="required|phone"
          ></phone-field>
        </v-col>
      </v-row>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { getDate } from "@/assets/utils/common.utils";
import AddButton from "../ui/buttons/AddButton.vue";
import EditIconButton from "../ui/buttons/EditIconButton.vue";
import DialogCard from "../ui/DialogCard.vue";
import {
  addDoc,
  doc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { colFaxRecipients } from "@/assets/utils/firebase.utils";
import PhoneField from "../ui/form/PhoneField.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      recipient: "",
    };
  },
  components: {
    AddButton,
    EditIconButton,
    DialogCard,
    PhoneField,
  },
  watch: {
    dialog(val) {
      if (val) {
        this.recipient = this.item.recipient || "";
      }
    },
  },
  computed: {
    isUpdate() {
      return this.item.id != undefined;
    },
    title() {
      return this.isUpdate ? "Edit Fax Recipient" : "Add Fax Recipient";
    },
    recipientId() {
      return this.item.id;
    },
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        let { recipient, recipientId } = vm;
        vm.loading = true;
        let status = {
          updated_by: vm.userEmail,
          recipient,
          updated_at: getDate(),
        };
        let q = query(
          colFaxRecipients,
          where("recipient", "==", recipient),
          limit(1)
        );
        let snap = (await getDocs(q)).docs;
        if (snap.length != 0 && recipientId != snap[0].id) {
          vm.loading = false;
          vm.notifyError("This fax recipient already exists.");
          return;
        }
        if (recipientId) {
          await updateDoc(doc(colFaxRecipients, recipientId), status);
        } else {
          await addDoc(colFaxRecipients, {
            ...status,
            created_at: getDate(),
          });
        }
        vm.recipient = "";
        vm.$emit("onUpdate");
        vm.loading = false;
        vm.dialog = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
};
</script>

<style></style>
