<template>
  <ValidationProvider :rules="rules" v-slot="{ errors }" mode="passive">
    <div>
      <div class="subtitle-1">{{ label }}</div>
      <v-radio-group
        v-model="input"
        :row="row"
        class="mt-0"
        :dense="dense"
        outlined
        @change="$emit('input', input)"
        v-bind="$attrs"
        :error-messages="errors"
      >
        <v-radio
          v-for="(item, i) in items"
          :key="i"
          :value="item.value"
          :label="item.name"
        >
        </v-radio>
        <template v-slot:append>
          <v-tooltip bottom v-if="help">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="" x-small>
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ help }}</span>
          </v-tooltip>
        </template>
      </v-radio-group>
    </div>
  </ValidationProvider>
</template>

<script>
import { getRules, inputDense } from "../../../assets/utils/common.utils.js";
export default {
  props: {
    value: {
      type: [String, Array],
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    row: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: inputDense,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: String,
      default: "",
    },
    help: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    customRules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      input: "",
    };
  },
  computed: {
    getRules() {
      let { customRules, rules } = this;
      if (customRules.length > 0) return customRules;
      return getRules(rules);
    },
  },
  watch: {
    value(val) {
      this.input = val;
    },
  },
  mounted() {
    this.input = this.value;
  },
};
</script>

<style></style>
