export default [
  {
    title: "Clients",
    name: "customers",
    roles: ["view", "edit", "delete", "ppd", "mr", "mr_ppd", "charge"],
  },
  {
    title: "Affiliates",
    name: "affiliates",
    roles: ["view", "edit", "delete", "payout"],
  },
  {
    title: "Transaction History",
    name: "transaction-log",
    roles: ["view", "edit", "delete_mpm", "ppd", "mr", "mr_ppd"],
  },
  {
    title: "Company profile",
    name: "my-company-profile",
    roles: ["view", "edit"],
  },
  {
    title: "Running Totals",
    name: "running-totals",
    roles: ["view"],
  },
  {
    title: "My Team Members",
    name: "team-members",
    roles: ["view", "edit", "delete"],
  },
  {
    title: "Roles & Permissions",
    name: "roles-permissions",
    roles: ["view", "edit", "delete"],
  },
  {
    title: "Client Status Types",
    name: "client-status-types",
    roles: ["view", "edit", "delete"],
  },
  {
    title: "Payment Methods",
    name: "payment-methods",
    roles: ["view", "edit", "delete"],
  },
  {
    title: "Fax Schedules",
    name: "fax-schedules",
    roles: ["view", "edit", "delete"],
  },
  {
    title: "Fax Templates",
    name: "fax-templates",
    roles: ["view", "edit", "delete"],
  },
  {
    title: "Fax Recipients",
    name: "fax-recipients",
    roles: ["view", "edit", "delete"],
  },
  {
    title: "Automated Notifications",
    name: "automated-notifications",
    roles: ["view", "edit", "delete"],
  },
  {
    title: "Credit Audit",
    name: "credit-audit",
    roles: ["view", "edit", "delete"],
  },
  {
    title: "Dispute Letters",
    name: "send-faxes",
    roles: ["view", "edit", "delete"],
  },
  {
    title: "Schedule",
    name: "schedule",
    roles: ["view", "edit", "delete"],
  },
  {
    title: "Client Agreements",
    name: "client-agreements",
    roles: ["view"],
  },
];
