<template>
  <div>
    <v-card flat class="mt-10">
      <v-data-table
        :headers="headers"
        class="credit-report-list"
        :items="items"
        @click:row="onRowClick"
        :loading="loading"
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:header.equifax="{}">
          <equifax-img :width="80" />
        </template>
        <template v-slot:header.experian="{}">
          <experian-img :width="80" />
        </template>
        <template v-slot:header.transunion="{}">
          <transunion-img :width="80" />
        </template>
        <template v-slot:body.prepend>
          <tr>
            <td class="py-5 font-weight-medium">Credit Scores</td>
            <td class="py-5"></td>
            <td class="py-5 font-weight-medium text-center">
              {{ report.credit_scores.equifax }}
            </td>
            <td class="py-5 font-weight-medium text-center">
              {{ report.credit_scores.experian }}
            </td>
            <td class="py-5 font-weight-medium text-center">
              {{ report.credit_scores.transunion }}
            </td>
            <td class="py-5" v-if="hasEdit('customers')"></td>
          </tr>
        </template>

        <template v-slot:item.account="{ value }">
          <span v-html="value"></span>
        </template>
        <template v-slot:item.equifax="{ value }">
          <credit-report-status :status="value"></credit-report-status>
        </template>
        <template v-slot:item.transunion="{ value }">
          <credit-report-status :status="value"></credit-report-status>
        </template>
        <template v-slot:item.experian="{ value }">
          <credit-report-status :status="value"></credit-report-status>
        </template>
        <template v-slot:item.action="{ index }">
          <v-menu
            offset-y
            v-model="menus[index]"
            :close-on-click="true"
            :close-on-content-click="true"
          >
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list class="pa-0" dense>
              <credit-report-item-edit
                :report="report"
                :reportAccounts="items"
                @onReportUpdate="$emit('onReportUpdate')"
                @close="menus[index] = false"
                :report-index="index"
              />
              <template v-if="hasDelete('customers')">
                <v-divider></v-divider>
                <v-list-item @click="removeReportItem(index)"
                  >Delete</v-list-item
                >
              </template>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <credit-report-item-edit
      :is-preview="true"
      ref="reportView"
      :report="report"
    />
  </div>
</template>

<script>
import { formatDateOnly, getDate } from "@/assets/utils/common.utils";
import { doc, updateDoc } from "firebase/firestore";
import { colCreditReports } from "@/assets/utils/firebase.utils";
import ExperianImg from "../ExperianImg.vue";
import EquifaxImg from "../EquifaxImg.vue";
import TransunionImg from "../TransunionImg.vue";
import CreditReportStatus from "./CreditReportStatus.vue";
import CreditReportItemEdit from "./CreditReportItemEdit.vue";
export default {
  components: {
    EquifaxImg,
    TransunionImg,
    CreditReportStatus,
    ExperianImg,
    CreditReportItemEdit,
  },
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menus: {},
    };
  },
  computed: {
    items() {
      let { getAccountNumber } = this;
      let list = [...this.report.accounts]
        .map((i) => {
          let equifax = i.list.find((a) => a.bureau == "Equifax") || {};
          let transunion = i.list.find((a) => a.bureau == "TransUnion") || {};
          let experian = i.list.find((a) => a.bureau == "Experian") || {};
          let unspecified = i.list.filter((i) => i.status == "Unspecified");
          let account = `
        Equifax: ${getAccountNumber(equifax, i.type)}<br/>
        Experian: ${getAccountNumber(experian, i.type)}<br/>
        Transunion: ${getAccountNumber(transunion, i.type)}<br/>
        `;
          return {
            ...i,
            account,
            equifax: equifax.status || "",
            experian: experian.status || "",
            transunion: transunion.status || "",
            unspecified: unspecified.length,
          };
        })
        .sort((a, b) => b.unspecified - a.unspecified);
      let inquiryItems = list.filter((i) => i.type == "inquiry");
      let nonInquiryItems = list.filter((i) => i.type != "inquiry");
      return [...inquiryItems, ...nonInquiryItems];
    },
    headers() {
      let doc = {
        name: "Creditor/Furnisher",
        account: "Account #",
        equifax: "equifax",
        experian: "experian",
        transunion: "transunion",
        action: "",
      };
      if (!this.hasEdit("customers")) {
        delete doc.action;
      }
      let leftAlgin = ["name", "account"];
      return Object.keys(doc).map((value) => ({
        value,
        cellClass: "py-3",
        text: doc[value],
        align: leftAlgin.indexOf(value) == -1 ? "center" : "left",
        width: value == "action" ? "50px" : "",
      }));
    },
  },
  methods: {
    removeReportItem(index) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to remove this?",
        onConfirm: async () => {
          try {
            let accounts = [...vm.items];
            accounts.splice(index, 1);
            await updateDoc(doc(colCreditReports, vm.report.id), {
              accounts,
              updated_at: getDate(),
            });
            vm.$emit("onReportUpdate");
          } catch (error) {
            vm.notifyError(error.message);
          }
        },
      });
    },
    getAccountNumber({ account_number, date_filed }, type) {
      if (type == "inquiry") {
        if (date_filed) return formatDateOnly(date_filed);
        return "";
      }
      return account_number || "";
    },
    onRowClick(item) {
      this.$refs.reportView.initPreview(item);
    },
  },
};
</script>

<style lang="scss">
.credit-report-list {
  tr td {
    line-height: 25px;
    border-right: 0px !important;
    &:last-child {
    }
    &:nth-child(5) {
      border-right: 0px !important;
    }
  }
}
</style>
