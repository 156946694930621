<template>
  <layout title="My Company Profile" :loading="isLoading">
    <form-wrapper @submit="submit">
      <v-row class="form-row mt-1">
        <v-col cols="12" md="4">
          <input-field
            label="Company Name"
            v-model="form.company_name"
            rules="required"
          />
        </v-col>
        <v-col cols="12" md="4">
          <input-field label="Website" v-model="form.website" rules="" />
        </v-col>
        <v-col cols="12" md="4">
          <autocomplete
            label="Time Zone"
            :items="timezones"
            v-model="form.time_zone"
            rules="required"
          />
        </v-col>
        <v-col cols="12" md="4">
          <address-field
            label="Mailing Address"
            rules="required"
            @onAddress="getAddressData"
            v-model="form.mailing_address"
          ></address-field>
        </v-col>
        <v-col cols="12" md="4">
          <input-field label="City" v-model="form.city" rules="required" />
        </v-col>

        <v-col cols="12" md="4">
          <v-row class="my-0" style="max-width: 462px">
            <v-col cols="12" md="6" class="py-0 pr-0">
              <autocomplete
                label="State"
                :items="states"
                v-model="form.state"
                rules="required"
              />
            </v-col>
            <v-col cols="12" md="6" class="py-0 pl-4 pr-0">
              <input-field
                label="Zip Code"
                v-model="form.zip_code"
                rules="required|zip"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4">
          <input-field label="Country" readonly v-model="form.country" />
        </v-col>
        <v-col cols="12" md="4">
          <phone-field
            label="Phone"
            rules="required|phone"
            v-model="form.phone"
          />
        </v-col>
        <v-col cols="12" md="4">
          <phone-field label="Fax" v-model="form.fax" rules="phone" />
        </v-col>
      </v-row>
      <p class="body-medium py-4">
        Automated notifications are sent from the account holder’s name and
        email address. Or you may designate a different name (or a a company
        name) and email below for all notification sent.
      </p>
      <v-row class="form-row">
        <v-col cols="12" md="4">
          <input-field
            label="Sender Name"
            class="mt-6 mt-xl-0"
            v-model="form.sender_name"
            rules=""
          />
        </v-col>
        <v-col cols="12" md="4">
          <input-field
            label="Sender Email"
            class="mt-6 mt-xl-0"
            v-model="form.sender_email"
            rules="email"
          />
        </v-col>
        <v-col cols="12" md="4">
          <input-field
            label="Name/Company that your client invoices should be payable to"
            v-model="form.invoice_name"
            rules=""
          />
        </v-col>
      </v-row>
      <secondary-button
        v-if="hasEdit('my-company-profile')"
        :loading="loading"
        type="submit"
        >Submit</secondary-button
      >
    </form-wrapper>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import FormWrapper from "@/components/ui/form/FormWrapper.vue";
import InputField from "@/components/ui/form/InputField.vue";
import SecondaryButton from "@/components/ui/buttons/SecondaryButton.vue";
import Autocomplete from "@/components/ui/form/Autocomplete.vue";
import PhoneField from "@/components/ui/form/PhoneField.vue";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { colSettings } from "@/assets/utils/firebase.utils";
import { getDate, initForm } from "@/assets/utils/common.utils";
import timezones from "@/assets/constants/timezones";
import states from "@/assets/constants/states.names.js";
import rulesConstants from "@/assets/constants/rules.constants";
import AddressField from "@/components/ui/form/AddressField.vue";

const profileRef = doc(colSettings, "company-profile");

export default {
  components: {
    Layout,
    FormWrapper,
    InputField,
    SecondaryButton,
    Autocomplete,
    PhoneField,
    AddressField,
  },
  data() {
    return {
      isLoading: true,
      rules: [rulesConstants.required],
      form: {
        company_name: "",
        website: "",
        time_zone: "",
        mailing_address: "",
        city: "",
        state: "",
        zip_code: "",
        country: "",
        phone: "",
        fax: "",
        sender_name: "",
        sender_email: "",
        invoice_name: "",
      },
      states,
      timezones,
      loading: false,
    };
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        await updateDoc(profileRef, {
          ...vm.form,
          updated_at: getDate(),
          updated_by: vm.userEmail,
        });
        vm.loading = false;
        vm.notifySuccess("Company Profile has been saved successfully.");
      } catch (error) {
        vm.handleError(error);
      }
    },
    getAddressData(evt) {
      console.log(evt);
      setTimeout(() => {
        let address = `${evt.street_number || ""} ${evt.route || ""}`;
        this.form.mailing_address = address.replace(/\s+/g, " ").trim();
      }, 500);
      this.form.zip_code = evt.postal_code || "";
      let state = this.states.find(
        (i) => i.value == evt.administrative_area_level_1
      );
      this.form.city = evt.locality || "";
      if (state) this.form.state = evt.administrative_area_level_1 || "";
    },
    async init() {
      const vm = this;
      try {
        vm.isLoading = true;
        let doc = (await getDoc(profileRef)).data() || {};
        initForm(vm.form, doc);
        vm.isLoading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
