<template>
  <v-dialog v-model="dialog" max-width="450px">
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <add-button>Add Dispute Letter</add-button>
      </span>
    </template>
    <dialog-card
      @close="dialog = false"
      @submit="submit()"
      title="Select Client"
    >
      <client-autocomplete rules="required" v-model="client" />
    </dialog-card>
  </v-dialog>
</template>

<script>
import ClientAutocomplete from "../clients/ClientAutocomplete.vue";
import AddButton from "../ui/buttons/AddButton.vue";
import DialogCard from "../ui/DialogCard.vue";
export default {
  components: {
    AddButton,
    DialogCard,
    ClientAutocomplete,
  },
  data() {
    return {
      dialog: false,
      client: "",
    };
  },
  methods: {
    submit() {
      this.$router.push(`/send-letters/${this.client}`);
      this.client = "";
      this.dialog = false;
    },
  },
};
</script>

<style></style>
