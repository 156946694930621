<template>
  <v-dialog v-model="dialog" persistent max-width="400px">
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <edit-icon-button v-if="isUpdate"></edit-icon-button>
        <add-button v-else>Add New Status</add-button>
      </span>
    </template>
    <dialog-card
      :loading="loading"
      :title="title"
      @close="dialog = false"
      @submit="submit()"
    >
      <v-row>
        <v-col cols="12" class="pb-0">
          <input-field
            label="Name"
            v-model="form.name"
            rules="required"
          ></input-field>
        </v-col>
        <v-col cols="12" class="pb-0">
          <color-picker
            label="Color"
            v-model="form.color"
            rules="required"
          ></color-picker>
        </v-col>
      </v-row>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { getDate, initForm } from "@/assets/utils/common.utils";
import AddButton from "../ui/buttons/AddButton.vue";
import EditIconButton from "../ui/buttons/EditIconButton.vue";
import DialogCard from "../ui/DialogCard.vue";
import InputField from "../ui/form/InputField.vue";
import ColorPicker from "../ui/form/ColorPicker.vue";
import {
  addDoc,
  doc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { colClientStatusTypes } from "@/assets/utils/firebase.utils";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      form: {
        name: "",
        color: "#22FF2B",
      },
    };
  },
  components: {
    AddButton,
    EditIconButton,
    DialogCard,
    InputField,
    ColorPicker,
  },
  watch: {
    dialog(val) {
      if (val) {
        initForm(this.form, this.item);
      }
    },
  },
  computed: {
    isUpdate() {
      return this.item.id != undefined;
    },
    title() {
      return this.isUpdate ? "Edit Status" : "Add Status";
    },
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        let name_lw = vm.form.name.trim().toLowerCase();
        console.log(vm.form);
        let status = {
          ...vm.form,
          name_lw,
          updated_by: vm.userEmail,
          updated_at: getDate(),
        };
        let q = query(
          colClientStatusTypes,
          where("name_lw", "==", name_lw),
          limit(1)
        );
        let snap = (await getDocs(q)).docs;
        let id = vm.item.id;
        if (snap.length != 0 && id != snap[0].id) {
          vm.loading = false;
          vm.notifyError(
            "Another status already exists with the same name. Please enter a unique Status name."
          );
          return;
        }
        if (id) {
          await updateDoc(doc(colClientStatusTypes, id), status);
          vm.notifySuccess("Status has been updated successfully.");
        } else {
          await addDoc(colClientStatusTypes, {
            ...status,
            created_at: getDate(),
          });
          vm.notifySuccess("Status has been created successfully.");
        }
        vm.form = { name: "", color: "#22FF2B" };
        vm.$emit("onUpdate");
        vm.loading = false;
        vm.dialog = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
};
</script>

<style></style>
