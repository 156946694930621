<template>
  <layout :title="title" :loading="isLoading">
    <form-wrapper @submit="submit()">
      <v-row class="form-row mt-1">
        <v-col cols="12" md="4">
          <input-field
            label="First Name"
            rules="required"
            v-model="form.first_name"
          />
        </v-col>
        <v-col cols="12" md="4">
          <input-field
            label="Middle Name"
            rules=""
            v-model="form.middle_name"
          />
        </v-col>
        <v-col cols="12" md="4">
          <input-field
            label="Last Name"
            rules="required"
            v-model="form.last_name"
          />
        </v-col>
        <v-col cols="12" md="4">
          <input-field
            label="Preferred Name"
            rules=""
            v-model="form.preferred_name"
          />
        </v-col>
        <v-col cols="12" md="4">
          <input-field
            label="Suffix ( Jr ,  Sr , etc. )"
            rules=""
            v-model="form.suffix"
          />
        </v-col>
        <v-col cols="12" md="4">
          <phone-field label="Phone (M)" rules="phone" v-model="form.mobile" />
        </v-col>
        <v-col cols="12" md="4">
          <input-field
            label="Email"
            rules="required|email"
            v-model="form.email"
          />
        </v-col>
        <v-col cols="12" md="4">
          <input-field
            v-mask="'###-##-####'"
            label="SSN"
            v-model="form.ss_number"
          />
        </v-col>
        <v-col cols="12" md="4">
          <date-field
            label="Date of Birth"
            rules=""
            v-model="form.birth_date"
          />
        </v-col>
        <v-col cols="12" md="4">
          <address-field
            label="Mailing Address"
            rules="required"
            @onAddress="onAddress"
            v-model="form.street"
          ></address-field>
        </v-col>
        <v-col cols="12" md="4">
          <input-field label="City" rules="" v-model="form.city" />
        </v-col>
        <v-col cols="12" md="4" class="d-flex">
          <autocomplete
            :items="states"
            label="State"
            class="mr-2"
            rules=""
            v-model="form.state"
          />
          <input-field
            label="Zip Code"
            class="ml-2"
            rules="zip"
            v-model="form.postal_code"
          />
        </v-col>
        <template v-if="isShowMore">
          <v-col cols="12" md="4">
            <phone-field
              label="Phone (Alternate)"
              rules="phone"
              v-model="form.home_phone"
            />
          </v-col>
          <v-col cols="12" md="4">
            <phone-field
              label="Phone (Work)"
              rules="phone"
              v-model="form.work_phone"
            />
          </v-col>
          <v-col cols="12" md="4">
            <phone-field label="Fax" rules="phone" v-model="form.fax" />
          </v-col>
        </template>
      </v-row>
      <a @click="isShowMore = !isShowMore">{{
        isShowMore ? "Show Less" : "Show More"
      }}</a>
      <v-divider class="mt-4"></v-divider>
      <v-row class="form-row mt-4">
        <v-col cols="12" md="4">
          <client-status-field
            label="Status"
            rules="required"
            v-model="form.customer_type"
          />
        </v-col>
        <v-col cols="12" md="4">
          <select-field
            label="Client Type"
            :items="clientTypes"
            v-model="form.client_type"
          />
        </v-col>
        <v-col cols="12" md="4"> </v-col>
        <v-col cols="12" md="4">
          <autocomplete
            label="Referred By 1"
            clearable
            :items="affiliates"
            @input="onAffiliateChange('affiliate_id')"
            v-model="form.affiliate_id"
          />
        </v-col>
        <v-col cols="12" md="4">
          <autocomplete
            label="Referred By 2 (optional)"
            :items="affiliates"
            v-if="form.affiliate_id"
            v-model="form.affiliate_id_2"
            @input="onAffiliateChange('affiliate_id_2')"
            clearable
          />
        </v-col>
        <v-col cols="12" md="4">
          <autocomplete
            label="Referred By 3 (optional)"
            :items="affiliates"
            @input="onAffiliateChange('affiliate_id_3')"
            v-if="form.affiliate_id && form.affiliate_id_2"
            v-model="form.affiliate_id_3"
            clearable
          />
        </v-col>
        <v-col cols="12" md="4">
          <autocomplete label="Agreement" disabled v-model="form.agreement" />
          <a class="text-decoration-underline">View Agreement</a>
        </v-col>
      </v-row>
      <div class="d-flex">
        <v-spacer></v-spacer>
        <close-button :disabled="loading" @click="back()">Cancel</close-button>
        <secondary-button class="ml-3" :loading="loading" type="submit"
          >Submit</secondary-button
        >
      </div>
    </form-wrapper>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import InputField from "@/components/ui/form/InputField.vue";
import PhoneField from "@/components/ui/form/PhoneField.vue";
import SelectField from "@/components/ui/form/SelectField.vue";
import ClientStatusField from "@/components/clients/ClientStatusField.vue";
import DateField from "@/components/ui/form/DateField.vue";
import AddressField from "@/components/ui/form/AddressField.vue";
import Autocomplete from "@/components/ui/form/Autocomplete.vue";
import states from "@/assets/constants/states.names";
import FormWrapper from "@/components/ui/form/FormWrapper.vue";
import CloseButton from "@/components/ui/buttons/CloseButton.vue";
import SecondaryButton from "@/components/ui/buttons/SecondaryButton.vue";
import { getDate, initForm } from "@/assets/utils/common.utils";
import {
  addDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { colClients } from "@/assets/utils/firebase.utils";
import { toTerms } from "@/assets/utils/v2.utils";
export default {
  components: {
    Layout,
    InputField,
    PhoneField,
    SelectField,
    ClientStatusField,
    DateField,
    AddressField,
    Autocomplete,
    FormWrapper,
    CloseButton,
    SecondaryButton,
  },
  data() {
    return {
      states,
      loading: false,
      isLoading: false,
      isShowMore: false,
      form: {
        no_email: false,
        first_name: "",
        middle_name: "",
        last_name: "",
        preferred_name: "",
        suffix: "",
        mobile: "",
        email: "",
        ss_number: "",
        birth_date: "",
        street: "",
        city: "",
        state: "",
        postal_code: "",
        home_phone: "",
        work_phone: "",
        fax: "",
        customer_type: "client",
        affiliate_id: "",
        affiliate_id_2: "",
        affiliate_id_3: "",
        client_type: "",
      },
    };
  },
  computed: {
    isUpdate() {
      return this.$route.name == "update-client";
    },
    title() {
      return this.isUpdate ? "Edit Profile" : "Add Lead/Client";
    },
  },
  methods: {
    onAffiliateChange(key) {
      const vm = this;
      if (!vm.form[key]) {
        if (key == "affiliate_id") {
          vm.form.affiliate_id_2 = "";
          vm.form.affiliate_id_3 = "";
        }
        if (key == "affiliate_id_2") {
          vm.form.affiliate_id_3 = "";
        }
      }
    },
    back() {
      if (this.isUpdate) {
        let id = this.$route.params.id;
        this.$router.replace({ name: "client-profile", params: { id } });
      } else {
        this.$router.replace({ name: "clients" });
      }
    },
    onAddress(evt) {
      setTimeout(() => {
        let address = `${evt.street_number || ""} ${evt.route || ""}`;
        this.form.street = address.replace(/\s+/g, " ").trim();
      }, 500);
      this.form.postal_code = evt.postal_code || "";
      let state = this.states.find(
        (i) => i.value == evt.administrative_area_level_1
      );
      this.form.city = evt.locality || "";
      if (state) this.form.state = evt.administrative_area_level_1 || "";
    },
    async init() {
      const vm = this;
      if (!vm.isUpdate) return;
      try {
        vm.isLoading = true;
        let clientId = this.$route.params.id;
        let client = (await getDoc(doc(colClients, clientId))).data();
        if (client == null) {
          vm.notifyError("Clinet not found");
          vm.$router.replace({ name: "clients" });
          return;
        }
        initForm(vm.form, client);
        if (vm.form.birth_date == "Invalid date") {
          vm.form.birth_date = "";
        }
        vm.isLoading = false;
      } catch (error) {
        vm.handleError(error.message);
      }
    },
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        let clientId = this.$route.params.id;
        let email = vm.form.email.trim().toLowerCase();
        let name = `${vm.form.first_name} ${vm.form.last_name}`;
        let terms = [name, vm.form.email];
        if (vm.form.mobile) terms.push(vm.form.mobile);
        if (vm.form.home_phone) terms.push(vm.form.home_phone);
        if (vm.form.work_phone) terms.push(vm.form.work_phone);

        let formDoc = {
          ...vm.form,
          email,
          terms: toTerms(terms),
          updated_at: getDate(),
          affiliate_name: vm.getAffiliateName(vm.form.affiliate_id),
          updated_by: vm.userEmail,
        };
        let q = query(colClients, where("email", "==", email), limit(1));
        let snap = (await getDocs(q)).docs;
        if (snap.length != 0 && clientId != snap[0].id) {
          vm.loading = false;
          vm.notifyError(
            "Another client exists with the same Email. Please enter a unique Email."
          );
          return;
        }
        if (vm.isUpdate) {
          let ref = doc(colClients, clientId);
          await updateDoc(ref, formDoc);
          vm.notifySuccess("Client updated successfully.");
        } else {
          await addDoc(colClients, { ...formDoc, created_at: getDate() });
          vm.notifySuccess("Client added successfully.");
        }
        vm.loading = false;
        vm.back();
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
