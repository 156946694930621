<template>
  <div class="d-flex align-center justify-center mt-5" v-if="loading">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
</template>

<script>
import { httpsCallable } from "firebase/functions";
import { functions } from "@/assets/utils/firebase.utils";
export default {
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    async editResend() {
      const vm = this;
      try {
        let id = vm.$route.params.id;
        let print = Boolean(vm.$route.query.print);
        let editResend = httpsCallable(functions, "editResendEfaxAction");
        let { status, message, faxId, fax_pdf_url } = (
          await editResend({ id, print })
        ).data;
        vm.loading = false;
        if (!status) {
          vm.notifyError(message);
          vm.$router.replace("/dispute-letters");
        } else {
          if (fax_pdf_url && print == true) {
            window.open(fax_pdf_url, "_blank");
            vm.$router.replace("/dispute-letters");
          } else {
            vm.$router.replace(`/edit-send-letter/${faxId}`);
          }
        }
      } catch (error) {
        vm.$router.back();
        vm.notifyError(error.message);
      }
    },
  },
  mounted() {
    this.editResend();
  },
};
</script>

<style></style>
