<template>
  <div class="my-5">
    <div class="d-flex">
      <v-btn
        color="success"
        large
        depressed
        :disabled="
          isReportTableLoading ||
          !hasEdit('customers') ||
          (loading && reportType != 'enfortra')
        "
        :loading="loading && reportType == 'enfortra'"
        class="mr-3 text-none"
        @click="importEnfortraReport(false)"
      >
        {{ enfortraLogs.length ? "Reimport" : "Import" }} with Enfortra
      </v-btn>
      <v-btn
        color="success"
        large
        depressed
        :disabled="
          isReportTableLoading ||
          !hasEdit('customers') ||
          (loading && reportType != 'smartcredit')
        "
        :loading="loading && reportType == 'smartcredit'"
        class="text-none"
        @click="importReport()"
      >
        {{ smartcreditLogs.length ? "Reimport" : "Import" }} with SmartCredit
      </v-btn>
    </div>
    <v-dialog persistent v-model="dialog" max-width="400px">
      <v-card>
        <v-list>
          <v-list-item v-for="(item, i) in list" :key="i">
            <v-list-item-avatar>
              <v-progress-circular
                indeterminate
                v-if="currentStatus == i"
                color="primary"
                size="25"
              ></v-progress-circular>
              <v-icon v-if="currentStatus > i" color="green">
                mdi-checkbox-marked-circle-outline</v-icon
              >
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ item }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="verificationDialog"
      max-width="1000px"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <iframe
            :src="url"
            ref="iframe"
            style="width: 100%; height: calc(100vh - 100px); border: 0px"
            @change="onLoad"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { httpsCallable } from "firebase/functions";
import { functions } from "@/assets/utils/firebase.utils";
export default {
  props: {
    logs: {
      type: Array,
      default: () => [],
    },
    isReportTableLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      currentStatus: 0,
      loading: false,
      reportType: "",
      verificationDialog: false,
      url: "",
      list: [
        "Connecting to Smart Credit",
        "Importing Report",
        "Processing Report",
        "Completed",
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    id() {
      return this.$route.params.id;
    },
    smartcreditLogs() {
      return this.logs.filter((i) => i.report_type != "Enfortra");
    },
    enfortraLogs() {
      return this.logs.filter((i) => i.report_type == "Enfortra");
    },
  },
  watch: {
    user(val) {
      this.currentStatus = val.reportStatus || 0;
    },
  },
  methods: {
    reimportReport(report) {
      const vm = this;
      this.$confirm.show({
        message:
          "There is no new report available. Do you want to import the same report again?",
        onConfirm: async () => {
          try {
            let reImport = httpsCallable(functions, "reimportCreditReport", {
              timeout: 9 * 60 * 1000,
            });
            let { result, status, message } = (
              await reImport({ ...report, customer: vm.id })
            ).data;
            if (status) {
              vm.$emit("onImport", result);
            } else {
              vm.notifyError(message);
            }
          } catch (error) {
            vm.notifyError(error.message);
          }
        },
      });
    },
    async importEnfortraReport(force) {
      const vm = this;
      if (
        !vm.currentClient.enfortra_username ||
        !vm.currentClient.enfortra_password
      ) {
        return vm.notifyError(
          "Please enter the customer’s Email and Password for Enfortra."
        );
      }
      try {
        vm.reportType = "enfortra";
        vm.loading = true;
        vm.currentStatus = 0;
        let importCreditReport = httpsCallable(
          functions,
          "importEnfortraCreditReport",
          {
            timeout: 9 * 60 * 1000,
          }
        );
        let { result, status, message } = (
          await importCreditReport({ id: vm.id, force: force == true })
        ).data;
        vm.loading = false;
        if (status) {
          if (result.action == "verify-kba") {
            vm.verificationDialog = true;
            vm.url = result.redirectURL;
          } else {
            vm.$emit("onImport", result);
          }
        } else {
          if (message == "exists") {
            vm.reimportReport(result);
          } else {
            vm.notifyError(message);
          }
        }
      } catch (error) {
        vm.dialog = false;
        vm.loading = false;
        vm.notifyError(error.message);
      }
    },
    async importReport(force) {
      const vm = this;
      if (!vm.currentClient.sc_username || !vm.currentClient.sc_password) {
        return vm.notifyError(
          "Please enter the customer’s Email and Password for Smart Credit."
        );
      }
      try {
        vm.reportType = "smartcredit";
        vm.loading = true;
        vm.dialog = true;
        vm.currentStatus = 0;
        let importCreditReport = httpsCallable(
          functions,
          "importCreditReport",
          {
            timeout: 9 * 60 * 1000,
          }
        );
        let { result, status, message } = (
          await importCreditReport({ id: vm.id, force: force == true })
        ).data;
        vm.loading = false;
        vm.dialog = false;
        if (status) {
          vm.$emit("onImport", result);
        } else {
          if (message == "exists") {
            vm.reimportReport(result);
          } else {
            vm.notifyError(message);
          }
        }
      } catch (error) {
        vm.dialog = false;
        vm.loading = false;
        vm.notifyError(error.message);
      }
    },
    onLoad(e) {
      console.log(e.target.src);
    },
  },
  mounted() {
    const vm = this;
    window.addEventListener("message", (event) => {
      // Ensure the message is coming from the iframe
      if (vm.$refs.iframe && event.source === vm.$refs.iframe.contentWindow) {
        vm.verificationDialog = false;
        vm.url = "";
        vm.importEnfortraReport();
      }
    });
  },
};
</script>

<style></style>
