import {
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { chunk } from "lodash";
import { transformCustomer } from "./doc.utils";
import statesNames from "@/assets/constants/states.names.js";
import {
  colAffiliates,
  colClients,
  colCommissions,
  colSettings,
  colTransactions,
  db,
} from "./firebase.utils";
import moment from "moment-timezone";

export const migrateClients = (affiliates) => {
  getDocs(colClients).then(async (snap) => {
    for (let items of chunk(snap.docs, 500)) {
      let batch = writeBatch(db);
      for (let item of items) {
        let cus = item.data();
        let affiliate = affiliates.find((i) => i.id == cus.affiliate_id);
        let terms = [`${cus.first_name} ${cus.last_name}`, cus.email];
        if (cus.mobile) terms.push(cus.mobile);
        if (cus.home_phone) terms.push(cus.home_phone);
        if (cus.work_phone) terms.push(cus.work_phone);
        let updateDoc = {
          terms: toTerms(terms),
          affiliate_name: affiliate ? affiliate.name : "",
          created_at: cus.updated_at,
        };
        let state = statesNames.find((i) => i.name == cus.state);
        if (state) updateDoc.state = state.value;

        batch.update(item.ref, updateDoc);
      }
      console.log("commiting....");
      await batch.commit();
    }
    console.log("Completed...");
  });
};
export const migrateTransactions = async (affiliateList) => {
  let transactions = (await getDocs(colTransactions)).docs;
  console.log("Transaction fetched successfully...", transactions.length);
  for (let items of chunk(transactions, 500)) {
    let batch = writeBatch(db);
    for (let item of items) {
      let { affiliate_id } = item.data();
      let affiliates = [];
      if (affiliate_id) affiliates.push(affiliate_id);
      let commissions = {};
      let affiliate = affiliateList.find((i) => i.id == affiliate_id);
      let commissionDoc = { transaction_id: item.id };
      if (affiliate) {
        commissions[affiliate_id] = (affiliate.commission_rate || 0) * 100;
        commissionDoc.commission_rate = (affiliate.commission_rate || 0) * 100;
      }
      batch.update(item.ref, {
        affiliates: affiliates,
        commissions,
      });
      let id = doc(colCommissions).id;
      let excludedKey = ["terms", "affiliates", "commissions"];
      Object.keys(item.data()).forEach((key) => {
        if (excludedKey.indexOf(key) == -1) {
          commissionDoc[key] = item.data()[key];
        }
      });
      batch.set(doc(colCommissions, id), commissionDoc);
    }
    await batch.commit();
    console.log("Transaction updated...");
  }
  console.log("Completed");
};
export const migrateRunningTotal = async () => {
  try {
    let commissions = (await getDocs(query(colCommissions))).docs.map((i) =>
      i.data()
    );
    let transactions = (await getDocs(query(colTransactions))).docs.map((i) =>
      i.data()
    );
    let updateDoc = {};
    let range = new Date().getFullYear() + 1;
    for (let year = 2020; year < range; year++) {
      let list = transactions.filter(
        (i) => moment(i.created_at).format("YYYY") == year
      );
      let commission = commissions
        .filter((i) => moment(i.created_at).format("YYYY") == year)
        .reduce((a, i) => a + parseFloat(i.commission || 0), 0);
      let total = list.reduce((a, i) => a + parseFloat(i.amount || 0), 0);
      let ppd_transactions = list.filter((i) => i.client_type == "PPD");
      let ppd = ppd_transactions.reduce(
        (a, i) => a + parseFloat(i.amount || 0),
        0
      );
      let mr_transactions = list.filter((i) => i.client_type == "MR");
      let mr = mr_transactions.reduce(
        (a, i) => a + parseFloat(i.amount || 0),
        0
      );
      updateDoc[year] = {
        mr: parseFloat(mr.toFixed(2)),
        ppd: parseFloat(ppd.toFixed(2)),
        total: parseFloat(total.toFixed(2)),
        commission: parseFloat(commission.toFixed(2)),
        ppd_transactions: ppd_transactions.length,
        mr_transactions: mr_transactions.length,
      };
    }
    await setDoc(doc(colSettings, "running-totals"), updateDoc);
  } catch (error) {
    console.log(error.message);
  }
};
export const migrateClientRunningTotals = async () => {
  let clients = (await getDocs(query(colClients, orderBy("running_totals"))))
    .docs;
  let mr_client_transactions = 0;
  for (let items of chunk(clients, 500)) {
    let batch = writeBatch(db);
    for (let client of items) {
      let total_mr = 0,
        total_mr_transaction = 0,
        total_ppd = 0,
        total_ppd_transaction = 0;
      let totals = client.data().running_totals || {};
      Object.keys(totals).forEach((key) => {
        let report = totals[key];
        total_mr += parseFloat(report.total_mr);
        total_mr_transaction += report.total_mr_transaction;
        total_ppd += parseFloat(report.total_ppd);
        total_ppd_transaction += report.total_ppd_transaction;
      });
      totals["all"] = {
        total_mr: parseFloat(total_mr.toFixed(2)),
        total_mr_transaction,
        total_ppd: parseFloat(total_ppd.toFixed(2)),
        total_ppd_transaction,
      };
      batch.update(client.ref, {
        running_totals: totals,
      });
      if (client.data().client_type == "MR") {
        mr_client_transactions =
          mr_client_transactions + total_ppd_transaction + total_mr_transaction;
      }
    }
    console.log("Running");
    await batch.commit();
  }
  updateDoc(doc(colSettings, "running-totals"), {
    mr_client_transactions,
  });
  console.log("Completed");
};
export const migrateClientTransactions = async () => {
  let transactions = (await getDocs(colTransactions)).docs;
  console.log("Transaction fetched successfully...");
  getDocs(colClients).then(async (snap) => {
    console.log("Client fetched successfully...");
    let i = 0;
    for (let client of snap.docs) {
      console.log(`Update transaction ${i}`, snap.size);
      i++;
      let cus = transformCustomer(client.data());
      let batch = writeBatch(db);
      transactions
        .filter((i) => i.data().customer_id == client.id)
        .forEach((i) => {
          let terms = [`${cus.name}`, cus.email];
          batch.update(i.ref, {
            name: cus.name,
            terms: toTerms(terms),
            email: cus.email,
          });
        });
      await batch.commit();
    }
  });
};
export const migrateAffiliates = async (affiliates) => {
  for (let item of affiliates) {
    let q = query(colCommissions, where("affiliate_id", "==", item.id));
    let history = (await getDocs(q)).docs.map((i) => i.data());
    let total = history.reduce((a, i) => parseFloat(i.amount) + a, 0);
    let commission = history
      .filter((i) => i.payout == true)
      .reduce((a, i) => parseFloat(i.commission) + a, 0);
    let commission_owed = history
      .filter((i) => i.payout == false)
      .reduce((a, i) => parseFloat(i.commission) + a, 0);
    await updateDoc(doc(colAffiliates, item.id), {
      transaction_amount: parseFloat(total.toFixed(2)),
      commission_owed,
      commission,
    });
    console.log(item.name);
  }
  console.log("Completed....");
};

export const toTerms = (fields) => {
  var array = [];
  for (let c of fields) {
    for (let i = 1; i < c.length + 1; i++) {
      array.push(c.toString().substring(0, i).toLowerCase());
    }
  }
  return array;
};
