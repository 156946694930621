<template>
  <v-btn
    depressed
    small
    dark
    :class="statusStyle(item.status)"
    width="80px"
    @click="updateStatus()"
  >
    {{ item.status == "Running" ? "Pause" : "Run" }}
  </v-btn>
</template>
<script>
import { doc, updateDoc } from "firebase/firestore";
import { colFaxSchedules } from "@/assets/utils/firebase.utils";
import { getDate } from "@/assets/utils/common.utils";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    statusStyle(value) {
      if (value != "Running") return "running";
      return "paused";
    },

    updateStatus() {
      const vm = this;
      let { id } = vm.item;
      let message = "Are you sure, Do you want to pause this schedule?";
      let isPaused = vm.item.status == "Paused";
      if (isPaused) message = "Are you sure, Do you want to run this schedule?";
      vm.$confirm.show({
        message,
        onConfirm: () => {
          // eslint-disable-next-line no-async-promise-executor
          return new Promise(async (resolve, reject) => {
            try {
              let status = isPaused ? "Running" : "Paused";
              let ref = doc(colFaxSchedules, id);
              await updateDoc(ref, {
                status,
                updated_at: getDate(),
                updated_by: vm.userEmail,
                status_updated_at: getDate(),
              });
              vm.$emit("onUpdate");
              resolve();
            } catch (error) {
              reject(error);
            }
          });
        },
      });
    },
  },
};
</script>

<style>
</style>