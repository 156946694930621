import { documentId, getDocs, query, where } from "firebase/firestore";
import { chunk } from "lodash";
import { formatDate } from "./common.utils";
import { colClients } from "./firebase.utils";

export const mapDocs = (docs) => {
  return docs.map((i) => {
    let doc = { ...i.data(), id: i.id };
    doc.updated_date = doc.updated_at;
    doc.created_date = doc.created_at;
    doc.updated_at = formatDate(doc.updated_at);
    doc.created_at = formatDate(doc.created_at);
    doc.value = doc.id;
    return doc;
  });
};
export const fetchTransactionClients = (list) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      let clientIds = [];
      list
        .filter((i) => i.customer_id)
        .forEach((i) => {
          if (clientIds.indexOf(i.customer_id) == -1) {
            clientIds.push(i.customer_id);
          }
        });
      let clients = await Promise.all(
        chunk(clientIds, 10).map(async (chunkIds) => {
          let q = query(colClients, where(documentId(), "in", chunkIds));
          const accounts = await getDocs(q);
          return accounts.docs.filter((doc) => doc.exists);
        })
      );
      clients = clients.flat(1);
      list = list.map((i) => {
        let client = clients.find((c) => {
          return c.id == i.customer_id;
        });
        if (client) {
          client = transformCustomer(client.data());
          i.client_name = client.name;
          i.client_email = client.email;
        }
        return i;
      });
      resolve(list);
    } catch (error) {
      reject(error);
    }
  });
};
export const transformCustomer = (doc) => {
  return {
    ...doc,
    name: `${doc.first_name} ${doc.last_name}`,
  };
};
export const transformDisputeLetters = (
  doc,
  templates = [],
  schedules = []
) => {
  let schedule = schedules.find((i) => i.id == doc.schedule);
  let template = templates.find((i) => i.id == doc.template);
  if (doc.status == "DRAFT") doc.created_at = "";
  return {
    ...doc,
    template: template ? template.template_name : "",
    schedule_name: schedule ? schedule.schedule_name : "",
  };
};

export const sortAlpha = (docs, key = "name") => {
  return docs.sort((a, b) => a[key].localeCompare(b[key]));
};
