import { transformCustomer } from "@/assets/utils/doc.utils";
import { colClients, colDocuments } from "@/assets/utils/firebase.utils";
import { doc, onSnapshot, query } from "firebase/firestore";

let unsubscribeClient = null;
let unsubscribeDocuments = null;
export default {
  state: {
    _client: {},
    _documents: [],
  },
  getters: {
    currentClient: (state) => state._client,
    documents: (state) => state._documents,
  },
  mutations: {
    setClient(state, value) {
      state._client = value;
    },
  },
  actions: {
    resetClient({ state }) {
      state._client = {};
      if (unsubscribeClient) unsubscribeClient();
    },
    listenDocuments({ state }) {
      return new Promise((resolve, reject) => {
        if (unsubscribeDocuments == null) {
          unsubscribeDocuments = onSnapshot(
            query(colDocuments),
            (snap) => {
              let documents = snap.docs.map((i) => ({
                ...i.data(),
                id: i.id,
              }));
              state._documents = documents.sort(
                (a, b) => a.position - b.position
              );
              resolve();
            },
            (error) => {
              reject(error);
            }
          );
        } else {
          resolve();
        }
      });
    },
    listenClient({ commit }, id) {
      return new Promise((resolve, reject) => {
        unsubscribeClient = onSnapshot(doc(colClients, id), (snap) => {
          if (snap.data()) {
            let client = transformCustomer({ ...snap.data(), id });
            commit("setClient", client);
            resolve(client);
          } else {
            reject({ message: "Client not found." });
          }
        });
      });
    },
  },
};
