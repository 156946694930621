<template>
  <v-dialog v-model="dialog" max-width="500px" v-if="canPayout">
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <v-btn icon color="secondary">
          <v-icon>mdi-check-circle</v-icon>
        </v-btn>
      </span>
    </template>
    <dialog-card
      title="Confirm"
      @close="dialog = false"
      @submit="submit"
      :loading="loading"
    >
      <p class="black--text body-medium mb-5">
        Commission Owed amount {{ formatPrice(item.commission_owed) }} will be
        marked as paid to Credit Two Point Zero.
      </p>
      <select-field
        label="Payment Method"
        :items="paymentMethods"
        v-model="payment_method"
        rules="required"
      ></select-field>
      <b class="black--text body-medium">Are you Sure you want to proceed?</b>
      <div class="d-flex" slot="footer">
        <v-spacer></v-spacer>
        <close-button @click="dialog = false" :disabled="loading"
          >No</close-button
        >
        <secondary-button type="submit" :loading="loading" :disabled="loading"
          >Yes</secondary-button
        >
      </div>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { functions } from "@/assets/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
import CloseButton from "../ui/buttons/CloseButton.vue";
import SecondaryButton from "../ui/buttons/SecondaryButton.vue";
import DialogCard from "../ui/DialogCard.vue";
import SelectField from "../ui/form/SelectField.vue";
export default {
  components: { CloseButton, SecondaryButton, DialogCard, SelectField },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      payment_method: "",
    };
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        let payoutAction = httpsCallable(functions, "payoutAction");
        let data = {
          payment_method: vm.payment_method,
          affiliate_id: vm.item.id,
          type: "affiliate-payout",
        };
        await payoutAction(data);
        vm.dialog = false;
        vm.payment_method = "";
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
};
</script>

<style></style>
