<template>
  <div>
    <v-row class="mt-3">
      <v-col cols="3">
        <date-field v-model="form.from" label="From Date" />
      </v-col>
      <v-col cols="3">
        <date-field v-model="form.to" label="To Date" />
      </v-col>
      <v-col cols="3">
        <select-field
          label="Payout Status"
          clearable
          v-model="form.payout"
          placeholder="All"
          :items="payoutStatus"
        ></select-field>
      </v-col>
      <v-col cols="3" class="pt-10 d-flex">
        <v-spacer></v-spacer>
        <close-button width="100px" @click="resetFilter()">Reset</close-button>
        <primary-button width="100px" @click="fetchTransactions()"
          >Apply</primary-button
        >
      </v-col>
    </v-row>
    <static-table-view :list="list" :loading="loading" :headers="headers">
      <template v-slot:item.startWeek="{ item }"> {{ item.weekName }}</template>
      <template v-slot:item.payable="{ value, item }">
        <affiliate-weekly-commissions-breakdown
          type="earned"
          @onUpdate="fetchTransactions()"
          :transaction="item"
          :affiliate="affiliate"
          :value="value"
        />
      </template>
      <template v-slot:item.paid="{ value, item }">
        <affiliate-weekly-commissions-breakdown
          type="paid"
          :affiliate="affiliate"
          :transaction="item"
          :value="value"
        />
      </template>
      <template v-slot:item.owed="{ value, item }">
        <affiliate-weekly-commissions-breakdown
          type="owed"
          :item="affiliate"
          :transaction="item"
          :value="value"
        />
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex">
          <affiliate-weekly-commissions-view
            @onPayout="fetchTransactions()"
            :item="item"
          />
          <affiliate-weekly-payout
            v-if="item.owed > 0"
            @onPayout="fetchTransactions()"
            :selected="item.history"
          />
        </div>
      </template>
    </static-table-view>
  </div>
</template>

<script>
import { getHeaders, getStartEndDate } from "@/assets/utils/common.utils";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
import DateField from "@/components/ui/form/DateField.vue";
import { getDocs, orderBy, query, where } from "firebase/firestore";
import { colCommissions, isLocal } from "@/assets/utils/firebase.utils";
import { fetchTransactionClients, mapDocs } from "@/assets/utils/doc.utils";
import CloseButton from "@/components/ui/buttons/CloseButton.vue";
import SelectField from "../ui/form/SelectField.vue";
import { payoutStatus } from "@/assets/constants/client.constants";
import moment from "moment-timezone";
import StaticTableView from "../ui/StaticTableView.vue";
import AffiliateWeeklyPayout from "./AffiliateWeeklyPayout.vue";
import AffiliateWeeklyCommissionsBreakdown from "./AffiliateWeeklyCommissionsBreakdown.vue";
import AffiliateWeeklyCommissionsView from "./AffiliateWeeklyCommissionsView.vue";
const defaultMonth = isLocal ? 12 : 3;
export default {
  props: {
    affiliate: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    DateField,
    PrimaryButton,
    CloseButton,
    SelectField,
    StaticTableView,
    AffiliateWeeklyPayout,
    AffiliateWeeklyCommissionsBreakdown,
    AffiliateWeeklyCommissionsView,
  },
  data() {
    return {
      dialog: false,
      payoutStatus,
      isLoading: true,
      loading: false,
      list: [],
      form: {
        from: moment().subtract(defaultMonth, "months").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        payout: "",
      },
    };
  },
  computed: {
    affiliateItem() {
      return this.item;
    },
    affiliateId() {
      return this.$route.params.id;
    },
    headers() {
      let doc = {
        startWeek: "Week Start - Week End",
        payable: "Total Comission Payable",
        paid: "Total Commission Paid",
        owed: "Total Commission Owed",
        payout: "Payout Status",
        actions: "Actions",
      };
      return getHeaders(doc);
    },
  },
  methods: {
    resetFilter() {
      this.form = {
        from: moment().subtract(defaultMonth, "months").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        payout: "",
      };
      this.fetchTransactions(this.options);
    },
    query() {
      let queryList = [];
      let { start, end } = getStartEndDate(this.form);
      queryList.push(orderBy("created_at", "desc"));
      if (start && end) {
        queryList.push(where("created_at", ">=", start));
        queryList.push(where("created_at", "<=", end));
      }
      queryList.push(where("affiliate_id", "==", this.affiliateId));
      console.log(this.form.payout);
      if (this.form.payout) {
        queryList.push(where("payout", "==", this.form.payout == "Paid"));
      }
      return query(colCommissions, ...queryList);
    },
    async fetchTransactions(options) {
      const vm = this;
      try {
        vm.loading = true;
        vm.options = options;
        vm.selected = [];
        vm.list = [];
        let docs = (await getDocs(vm.query())).docs;
        vm.firstVisible = docs[0];
        vm.lastVisible = docs[docs.length - 1];
        let transactions = mapDocs(docs);
        transactions = await fetchTransactionClients(transactions);
        let start = moment(vm.form.from);
        let end = moment(vm.form.to);
        let weeks = end.diff(start, "weeks");
        let list = [];
        for (let i = 0; i < weeks; i++) {
          let startWeek = moment(start).add(i, "isoWeek").startOf("week");
          let endWeek = moment(start).add(i, "isoWeek").endOf("week");
          let history = transactions.filter((i) => {
            let date = moment(i.created_date);
            return date.isAfter(startWeek) && date.isBefore(endWeek);
          });
          let payable = history.reduce((a, item) => {
            return parseFloat(item.commission) + a;
          }, 0);
          let total = history.reduce((a, item) => {
            return Number(item.amount) + a;
          }, 0);
          let paid = history
            .filter((i) => i.payout == true)
            .reduce((a, item) => {
              return parseFloat(item.commission) + a;
            }, 0);
          let owed = history
            .filter((i) => i.payout == false)
            .reduce((a, item) => {
              return parseFloat(item.commission) + a;
            }, 0);
          if (total > 0) {
            list.push({
              weekName: `${startWeek.format("MMM DD YYYY")} - ${endWeek.format(
                "MMM DD YYYY"
              )}`,
              payable,
              name: `${this.affiliate.first_name} ${this.affiliate.last_name}`,
              paid,
              history,
              total,
              owed,
              payout: owed == 0 ? "Paid" : "Unpaid",
              startWeek: startWeek.format(),
            });
          }
        }
        vm.list = list;
        vm.loading = false;
      } catch (error) {
        console.log(error);
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchTransactions();
  },
};
</script>

<style></style>
