import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { headerTabs, myCompanyTabs } from "../constants/nav.constants";
import { auth, colRoles, colUsers } from "./firebase.utils";
import store from "@/store";

export let isAuthLogin = (to, from, next) => {
  onAuthStateChanged(auth, (user) => {
    if (!user) return next();
    store.commit("setUID", user.uid);
    next("/");
  });
};
export const isAuth = (_to, _from, next) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      store.commit("setUID", user.uid);
      if (!_to.meta) {
        next();
      } else {
        let { way, permission } = _to.meta;
        getUser(user.uid)
          .then((data) => {
            store.commit("setUser", data);
            if (hasPermission(way, permission, data)) {
              next();
            } else {
              let nextName = getNext(data, "view", _to);
              if (nextName) {
                next({ name: nextName });
              } else {
                auth.signOut();
                next("/login");
              }
            }
          })
          .catch((error) => {
            auth.signOut();
            alert(error.message);
            next("/login");
          });
      }
    } else {
      next("/login");
    }
  });
};

const getNext = (user, way, _to) => {
  let isCompany = _to.matched.find((i) => i.name == "my-company") != null;
  let tabs = isCompany ? myCompanyTabs : headerTabs;
  let next = tabs.find((i) => {
    return hasPermission(i.permission, way, user);
  });
  return next ? next.path : "";
};
const getUser = (uid) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      let user = (await getDoc(doc(colUsers, uid))).data();
      let role = (await getDoc(doc(colRoles, user.role_id))).data();
      user.permissions = role.permissions;
      store.commit("setPermission", role.permissions);
      resolve(user);
    } catch (error) {
      console.log("Error while fetching the user details", error);
      reject(error);
    }
  });
};
const hasPermission = (area, type, user) => {
  let permissions = user.permissions || {};
  let permission = permissions[area] || {};
  return permission[type] == true;
};
