<template>
  <div>
    <client-status-field
      v-if="isUpdate"
      hide-details
      v-model="status"
      @click.stop=""
      @input="submit()"
      :loading="isLoading"
      style="max-width: 150px"
    ></client-status-field>
    <a v-else @click.stop="" @click="openUpdate()">
      <client-status :type="status"></client-status>
    </a>
  </div>
</template>

<script>
import { getDate } from "@/assets/utils/common.utils";
import ClientStatus from "./ClientStatus.vue";
import { doc, updateDoc } from "firebase/firestore";
import { colClients } from "@/assets/utils/firebase.utils";
import ClientStatusField from "./ClientStatusField.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    statusUpdateId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      status: "",
      isUpdate: false,
    };
  },
  watch: {
    statusUpdateId(val) {
      if (val != this.item.id) {
        this.isUpdate = false;
      }
    },
  },
  components: {
    ClientStatus,
    ClientStatusField,
  },
  methods: {
    openUpdate() {
      if (this.hasEdit("customers")) {
        this.isUpdate = true;
        this.$emit("onUpdateClick", this.item.id);
      }
    },
    async submit() {
      const vm = this;
      try {
        vm.isLoading = true;
        await updateDoc(doc(colClients, vm.item.id), {
          updated_at: getDate(),
          customer_type: vm.status,
          updated_by: vm.userEmail,
        });
        vm.$emit("onUpdate");
        vm.isLoading = false;
        vm.isUpdate = false;
        vm.dialog = false;
        vm.notifySuccess("Client status has been changed successfully.");
      } catch (error) {
        vm.isLoading = false;
        vm.handleError(error);
      }
    },
  },
  beforeMount() {
    this.status = this.item.customer_type || "";
    window.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) {
        if (!this.isLoading) {
          this.isUpdate = false;
        }
      }
    });
  },
};
</script>
