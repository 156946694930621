<template>
  <div>
    <img
      :src="account.logo"
      :class="account.class"
      width="200px"
      style="object-fit: contain"
      height="65px"
    />
    <div v-if="!isEdit" class="px-5">
      <div class="d-flex align-center" style="width: 100%">
        <v-icon>mdi-account-circle</v-icon>
        <div class="px-2 text-truncate flex-grow-1">
          {{ form.username }}
        </div>
        <v-btn icon x-small v-if="form.username" @click="copyEmail()">
          <v-icon>mdi-content-copy</v-icon></v-btn
        >
      </div>
      <div class="d-flex align-center mt-3">
        <v-icon>mdi-lock</v-icon>
        <div class="px-2 text-truncate flex-grow-1">
          {{ form.password }}
        </div>
        <v-btn icon x-small v-if="form.password" @click="copyPassword()">
          <v-icon>mdi-content-copy</v-icon></v-btn
        >
      </div>
      <v-btn
        outlined
        v-if="hasEdit('customers')"
        color="green"
        small
        @click="isEdit = true"
        class="mt-3"
        >Edit</v-btn
      >
      <v-btn class="mt-3 ml-3" @click="openLink()" color="primary" small text
        >Go to Website</v-btn
      >
    </div>
    <div class="px-5" v-else>
      <form-wrapper @submit="submit()">
        <input-field
          prepend-inner-icon="mdi-account-circle"
          v-model.trim="form.username"
          rules="required"
        />
        <input-field
          prepend-inner-icon="mdi-lock"
          v-model="form.password"
          rules="required"
        />
        <div>
          <v-btn :loading="loading" outlined color="green" type="submit" small
            >Save</v-btn
          >
          <v-btn
            outlined
            :disabled="loading"
            color="red"
            small
            @click="close()"
            class="ml-3"
            >Cancel</v-btn
          >
        </div>
      </form-wrapper>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/ui/form/InputField.vue";
import FormWrapper from "@/components/ui/form/FormWrapper.vue";
import { doc, updateDoc } from "firebase/firestore";
import { colClients } from "@/assets/utils/firebase.utils";
export default {
  components: { InputField, FormWrapper },
  props: {
    type: String,
    logo: String,
    account: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isEdit: false,
      loading: false,
      form: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    close() {
      this.init();
      this.isEdit = false;
    },
    init() {
      this.form.username =
        this.currentClient[`${this.account.prefix}_username`] || "";
      this.form.password =
        this.currentClient[`${this.account.prefix}_password`] || "";
    },
    async submit() {
      try {
        this.loading = true;
        await updateDoc(doc(colClients, this.$route.params.id), {
          [`${this.account.prefix}_username`]: this.form.username,
          [`${this.account.prefix}_password`]: this.form.password,
        });
        this.isEdit = false;
        this.loading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
    openLink() {
      window.open(this.account.link, "_blank");
    },
    copyEmail() {
      navigator.clipboard.writeText(this.form.username);
      this.notifySuccess("Email Copied");
    },
    copyPassword() {
      navigator.clipboard.writeText(this.form.password);
      this.notifySuccess("Password Copied");
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
