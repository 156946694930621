<template>
  <layout :isTop="false">
    <div class="text-h4 pt-6">
      {{ `Credit Report (${currentClient.name})` }}
    </div>
    <v-skeleton-loader
      type="table-thead, table-tbody"
      v-if="isLoading"
    ></v-skeleton-loader>
    <template v-if="!isLoading && report.date">
      <div class="subtitle-1 font-weight-medium d-flex align-center mb-3">
        Dispute Items as of {{ report.date }}
        <v-spacer></v-spacer>
        <credit-report-item-edit
          type="add"
          v-if="hasEdit('customers')"
          :report="report"
          :report-accounts="report.accounts"
        />
      </div>
      <div class="d-flex">
        <div>
          <v-tabs
            class="tab-grouped rounded-lg px-0"
            :show-arrows="false"
            v-model="tab"
            hide-slider
          >
            <v-tab>
              <v-icon left>mdi-view-list-outline</v-icon>
              List View
            </v-tab>
            <v-tab>
              <v-icon left>mdi-chart-box-outline</v-icon>
              Report View
            </v-tab>
          </v-tabs>
        </div>
      </div>
      <template v-if="report.id">
        <credit-report-list-view
          v-show="tab == 0"
          @onReportUpdate="fetchReport"
          :report="report"
          :loading="loading"
        ></credit-report-list-view>
        <credit-report-report-view
          :loading="loading"
          v-show="tab == 1"
          @onReportUpdate="fetchReport"
          :report="report"
        ></credit-report-report-view>
      </template>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import { auth, colCreditReports } from "@/assets/utils/firebase.utils";
import {
  addDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  formatDateOnly,
  getDate,
  getDateOnly,
  getHeaders,
} from "@/assets/utils/common.utils";
import CreditReportListView from "@/components/clients/creditReport/CreditReportListView.vue";
import CreditReportReportView from "@/components/clients/creditReport/CreditReportReportView.vue";
import CreditReportItemEdit from "@/components/clients/creditReport/CreditReportItemEdit.vue";
export default {
  components: {
    Layout,
    CreditReportListView,
    CreditReportReportView,
    CreditReportItemEdit,
  },
  data() {
    return {
      date: "",
      tab: 0,
      isLoading: false,
      loading: false,
      report: {},
    };
  },
  computed: {
    items() {
      return [
        {
          bureau: "Credit Scores",
          ...this.report.credit_scores,
        },
      ];
    },
    headers() {
      let doc = {
        name: "Creditor/Furnisher",
        account: "Account #",
        equifax: "equifax",
        experian: "experian",
        transunion: "transunion",
        action: "",
      };
      return getHeaders(doc);
    },
  },
  methods: {
    convertToJSON(data) {
      const lines = data
        .split("\n")
        .map((i) => i.replace(/(\r\n|\n|\r)/gm, ""))
        .filter((line) => line.trim() !== "arrow_drop_down");
      const headers = [...lines].splice(0, 6);
      const requiredHeaders = [
        "Creditor/Furnisher",
        "Account #",
        "Dispute Items",
        "Equifax",
        "Experian",
        "Transunion",
      ];
      let accounts = [];
      if (JSON.stringify(headers) == JSON.stringify(requiredHeaders)) {
        for (let i = 6; i < lines.length; ) {
          let name = lines[i];
          let isEquifax = lines[i + 1]?.startsWith("Equifax:");
          let isExperian = lines[i + 2]?.startsWith("Experian:");
          let isTransunion = lines[i + 3]?.startsWith("Transunion:");
          if (isEquifax && isExperian && isTransunion) {
            let equifaxNumber = lines[i + 1]?.split("Equifax:")[1]?.trim();
            let experianNumber = lines[i + 2]?.split("Experian:")[1]?.trim();
            let transunionNumber = lines[i + 3]
              ?.split("Transunion:")[1]
              ?.trim();
            i = i + 5;
            let equifax = this.getAccountStatus(lines, i);
            i = equifax.i;
            let equifaxStatus = equifax.status;
            let experian = this.getAccountStatus(lines, i);
            let experianStatus = experian.status;
            i = experian.i;
            let transunion = this.getAccountStatus(lines, i);
            let transunionStatus = transunion.status;
            i = transunion.i;
            let type = "tradeline";
            if (
              equifaxStatus == "Unspecified" ||
              experianStatus == "Unspecified" ||
              transunionStatus == "Unspecified"
            ) {
              type = "inquiry";
            }
            accounts.push({
              name,
              type,
              list: [
                {
                  account_name: name,
                  account_number: equifaxNumber,
                  bureau: "Equifax",
                  status: equifaxStatus,
                },
                {
                  account_name: name,
                  account_number: experianNumber,
                  bureau: "Experian",
                  status: experianStatus,
                },
                {
                  account_name: name,
                  account_number: transunionNumber,
                  bureau: "TransUnion",
                  status: transunionStatus,
                },
              ],
            });
          } else {
            i++;
          }
        }
      }
      return accounts;
    },
    getAccountStatus(lines, i) {
      let statusList = [
        "Negative",
        "In Dispute",
        "Positive",
        "Unspecified",
        "Deleted",
        "Updated",
      ];

      let status = "";
      if (lines[i]?.trim() == "") {
        i = i + 1;
      } else {
        status = lines[i + 1];
        if (statusList.indexOf(status) == -1) status = "";
        i = i + 2;
      }
      return { status, i };
    },
    async handlePaste(event) {
      if (this.loading) return;
      const pastedData = (event.clipboardData || window.clipboardData).getData(
        "text"
      );
      let list = this.convertToJSON(pastedData);
      try {
        if (this.report.id) {
          list = list.filter((i) => {
            let item = this.report.accounts.find((item) => {
              return JSON.stringify(i) == JSON.stringify(this.getAccount(item));
            });
            return item == null;
          });
        }
        if (list.length) {
          this.isLoading = true;
          if (this.report.id) {
            await updateDoc(doc(colCreditReports, this.report.id), {
              accounts: [...this.report.accounts, ...list],
            });
          } else {
            let report = {
              created_at: getDate(),
              created_by: auth.currentUser.email,
              current_address: {},
              credit_scores: {},
              customer: this.$route.params.id,
              date: getDateOnly(),
              previous_address: {},
              updated_at: getDate(),
              accounts: list,
            };
            await addDoc(colCreditReports, report);
          }

          this.fetchReport();
        }
      } catch (error) {
        this.handleError(error);
      }
    },
    getAccount(item) {
      let accounts = [];
      let equifax = item.list.find((i) => i.bureau == "Equifax");
      let experian = item.list.find((i) => i.bureau == "Experian");
      let transunion = item.list.find((i) => i.bureau == "TransUnion");
      if (equifax) {
        accounts.push({
          account_name: item.name,
          account_number: equifax.account_number,
          bureau: "Equifax",
          status: equifax.status,
        });
      }
      if (experian) {
        accounts.push({
          account_name: item.name,
          account_number: experian.account_number,
          bureau: "Experian",
          status: experian.status,
        });
      }
      if (transunion) {
        accounts.push({
          account_name: item.name,
          account_number: transunion.account_number,
          bureau: "TransUnion",
          status: transunion.status,
        });
      }
      return {
        name: item.name,
        type: item.type,
        list: accounts,
      };
    },
    async fetchReport() {
      const vm = this;
      try {
        vm.isLoading = true;
        let id = vm.$route.params.id;
        let reportQuery = query(
          colCreditReports,
          orderBy("created_at", "desc"),
          where("customer", "==", id),
          limit(1)
        );
        let reports = (await getDocs(reportQuery)).docs;
        if (reports.length > 0) {
          vm.report = {
            ...reports[0].data(),
            id: reports[0].id,
            date: formatDateOnly(reports[0].data().date),
          };
          console.log(vm.report.id);
        }
        vm.isLoading = false;
      } catch (error) {
        vm.isLoading = false;
        vm.notifyError(error.message);
      }
    },
  },
  beforeDestroy() {
    if (this.hasEdit("customers")) {
      window.removeEventListener("paste", this.handlePaste);
    }
  },

  mounted() {
    if (this.hasEdit("customers")) {
      window.addEventListener("paste", this.handlePaste);
    }
    this.fetchReport();
  },
};
</script>

<style></style>
