<template>
  <v-tooltip v-bind="$attrs" top>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot> </slot>
      </span>
    </template>
    <span>{{ message }}</span>
  </v-tooltip>
</template>
  
  <script>
export default {
  inheritAttrs: false,
  props: {
    message: {},
    top: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
  
  <style>
</style>