<template>
  <img
    src="@/assets/images/equifax.png"
    :width="width"
    v-bind="$attrs"
    style="object-fit: contain"
  />
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    width: {
      type: Number,
      default: 100,
    },
  },
};
</script>

<style></style>
