<template>
  <v-dialog v-model="dialog" max-width="1000px" scrollable>
    <template v-slot:activator="{ on }">
      <template v-if="!isPreview">
        <v-list-item v-if="type == 'list'" v-on="on">Edit</v-list-item>
        <span v-on="on" v-else-if="type == 'add'">
          <add-button>Add New</add-button>
        </span>
        <span v-on="on" v-else>
          <edit-icon-button small></edit-icon-button>
        </span>
      </template>
    </template>
    <dialog-card
      :title="title"
      @close="dialog = false"
      :loading="loading"
      @submit="submit()"
      :is-submit="!isPreview"
    >
      <div class="py-3" v-if="isInit">
        <v-row v-for="(item, i) in formItems" :key="'item-' + i">
          <v-col cols="3" class="font-weight-bold black--text py-0 pt-2">
            {{ item.label }}
          </v-col>
          <v-col cols="9" class="py-0 pb-2">
            <component
              :rules="isInquiry ? '' : item.rules"
              hide-details
              v-model="form[item.key]"
              :disabled="item.disabled"
              :items="item.items"
              :is="item.type"
            />
          </v-col>
        </v-row>
        <v-row class="pb-5">
          <v-col cols="3" class="py-0"></v-col>
          <v-col cols="9" class="py-0">
            <v-row>
              <v-col>
                <v-checkbox
                  hide-details
                  :disabled="isPreview"
                  v-model="account_visible.equifax"
                  @change="onAccountChange"
                >
                  <template v-slot:label>
                    <equifax-img />
                  </template>
                </v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  hide-details
                  :disabled="isPreview"
                  v-model="account_visible.experian"
                  @change="onAccountChange"
                >
                  <template v-slot:label>
                    <experian-img />
                  </template>
                </v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox
                  hide-details
                  :disabled="isPreview"
                  v-model="account_visible.transunion"
                  @change="onAccountChange"
                >
                  <template v-slot:label>
                    <transunion-img />
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-for="(item, i) in accountItems" :key="i">
          <v-col cols="3" class="font-weight-bold black--text py-0 py-5">
            {{ item.label }}
          </v-col>
          <v-col cols="9" class="py-0">
            <v-row>
              <v-col
                v-for="acc of ['equifax', 'experian', 'transunion']"
                :key="acc"
                class="pb-5"
              >
                <component
                  :rules="isInquiry ? '' : item.rules"
                  v-model="account[acc][item.key]"
                  :items="item.items"
                  :disabled="item.disabled"
                  hide-details
                  v-if="account_visible[acc] == true"
                  :is="item.type"
                ></component>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </dialog-card>
  </v-dialog>
</template>

<script>
import CloseButton from "@/components/ui/buttons/CloseButton.vue";
import EditIconButton from "@/components/ui/buttons/EditIconButton.vue";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
import DateField from "@/components/ui/form/DateField.vue";
import RadioGroup from "@/components/ui/form/RadioGroup.vue";
import InputField from "@/components/ui/form/InputField.vue";
import SelectField from "@/components/ui/form/SelectField.vue";
import TextareaField from "@/components/ui/form/TextareaField.vue";
import FormWrapper from "@/components/ui/form/FormWrapper.vue";
import TransunionImg from "../TransunionImg.vue";
import ExperianImg from "../ExperianImg.vue";
import EquifaxImg from "../EquifaxImg.vue";
import AddButton from "@/components/ui/buttons/AddButton.vue";
import {
  CreditReportReasons,
  CreditReportStatus,
} from "@/assets/constants/credit.report.constants.js";
import { doc, updateDoc } from "firebase/firestore";
import { colCreditReports } from "@/assets/utils/firebase.utils";
import { formatDateOnly, getDate } from "@/assets/utils/common.utils";
import DialogCard from "@/components/ui/DialogCard.vue";

export default {
  props: {
    isPreview: Boolean,

    type: {
      type: String,
      default: "list",
    },
    reportIndex: {
      type: Number,
      default: -1,
    },
    report: {
      type: Object,
      default: () => ({}),
    },
    reportAccounts: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    SelectField,
    InputField,
    CloseButton,
    PrimaryButton,
    FormWrapper,
    DateField,
    TransunionImg,
    ExperianImg,
    EquifaxImg,
    EditIconButton,
    RadioGroup,
    TextareaField,
    AddButton,
    DialogCard,
  },
  data() {
    return {
      loading: false,
      form: {
        date: "",
        type: "",
        name: "",
        reason: "-",
        instruction: "",
      },
      account_visible: {
        equifax: false,
        experian: false,
        transunion: false,
      },
      isInit: false,
      account: {
        equifax: {},
        experian: {},
        transunion: {},
      },
      dialog: false,
    };
  },
  computed: {
    isAdd() {
      return this.type == "add";
    },
    title() {
      if (this.isPreview) return "Dispute Item";
      return this.isAdd ? "Add Dispute Item" : "Edit Dispute Item";
    },
    formItems() {
      let doc = {
        type: "Account Type",
        date: "Date",
        name: "Creditor/Furnisher:",
        reason: "Reason",
        instruction: "Instruction",
      };
      let type = {
        type: "radio-group",
        reason: "select-field",
        date: "date-field",
        instruction: "textarea-field",
      };
      let items = {
        reason: CreditReportReasons,
        type: [
          {
            name: "Inquiry",
            value: "inquiry",
          },
          {
            name: "Account",
            value: "tradeline",
          },
        ],
      };
      let disabled = ["date", "type"];
      let rulesKey = ["name"];
      if (this.isAdd) rulesKey.push("type");
      return Object.keys(doc).map((key) => ({
        key,
        label: doc[key],
        rules: rulesKey.indexOf(key) != -1 ? "required" : "",
        type: type[key] || "input-field",
        items: items[key] || [],
        disabled:
          this.isPreview || (disabled.indexOf(key) != -1 && !this.isAdd),
      }));
    },
    accountItems() {
      let doc = {
        status: "Status",
        account_name: "Account Name",
        date_opened: "Date Opened",
        high_balance: "High Balance",
        credit_limit: "Credit Limit",
        account_number: "Account #",
        past_due_amount: "Past Due Amount",
        account_type: "Account Type",
        balance_owned: "Blance Owed",
        date_reported: "Date Reported",
        last_activity: "Last Activity",
        amount: "Amount",
        plaintiff: "Plaintiff",
        ecoa: "ECOA",
        date_filed: "Date Filed",
        account_status: "Account Status",
        payment_amount: "Payment Amount",
        terms_months: "Term Length",
        payment_status: "Payment Status",
        creditor_remarks: "Creditor Remarks",
        account_rating: "Account Rating",
        account_description: "Account Description",
        dispute_status: "Dispute Status",
        creditor_type: "Creditor Type",
        last_verfied: "Last Verified",
        closed_date: "Cloesd Date",
        payment_frequency: "Payment Frequency",
        late_payment_status: "Late Payment Status",
        address: "Address",
        interal_note: "Internal Note",
      };
      let type = {
        status: "select-field",
        date_opened: "date-field",
        date_reported: "date-field",
        last_activity: "date-field",
        date_filed: "date-field",
        last_verfied: "date-field",
        closed_date: "date-field",
        address: "textarea-field",
        interal_note: "textarea-field",
      };
      let requiredFields = ["account_name", "account_number", "status"];
      if (this.form.type == "inquiry") {
        requiredFields = ["account_name", "date_filed", "status"];
      }
      let items = {
        status: CreditReportStatus,
      };

      return Object.keys(doc).map((key) => ({
        key,
        label: doc[key],
        items: items[key] || [],
        rules: requiredFields.indexOf(key) != -1 ? "required" : "",
        type: type[key] || "input-field",
        disabled: this.isPreview,
      }));
    },
    isInquiry() {
      let account = this.reportAccounts[this.reportIndex];
      return account && account.type == "inquiry";
    },
  },
  watch: {
    dialog(val) {
      if (!this.isPreview) {
        this.init();
      }
      if (!val) this.$emit("close");
    },
  },
  methods: {
    initPreview(item) {
      const vm = this;
      vm.isInit = false;
      if (item) {
        Object.keys(vm.form).forEach((key) => {
          if (item[key] != undefined) vm.form[key] = item[key];
        });
        let equifax = item.list.find((a) => a.bureau == "Equifax") || {};
        let transunion = item.list.find((a) => a.bureau == "TransUnion") || {};
        let experian = item.list.find((a) => a.bureau == "Experian") || {};
        let doc = { equifax, transunion, experian };
        if (item.account_visible == undefined) {
          vm.account_visible = {
            equifax: Object.keys(equifax).length != 0,
            experian: Object.keys(experian).length != 0,
            transunion: Object.keys(transunion).length != 0,
          };
        } else {
          vm.account_visible = item.account_visible;
        }
        Object.keys(vm.account).forEach((key) => {
          let account = doc[key];
          vm.accountItems.forEach((i) => {
            vm.account[key][i.key] = account[i.key] || "";
          });
        });
        vm.isInit = true;
        vm.form.date = formatDateOnly(vm.report.date);
      }
      vm.dialog = true;
    },
    onAccountChange() {
      Object.keys(this.account_visible).forEach((key) => {
        if (this.account_visible[key] != true) {
          this.account[key] = {};
        }
      });
    },
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        let { id } = vm.report;
        // let report = (await credit_reports.doc(id).get()).data();
        let accounts = vm.reportAccounts;
        let oldItem = accounts[vm.reportIndex];
        if (vm.isAdd) oldItem = { list: [] };
        let item = {
          ...oldItem,
          ...vm.form,
          account_visible: vm.account_visible,
        };
        let equifax = item.list.findIndex((a) => a.bureau == "Equifax");
        let transunion = item.list.findIndex((a) => a.bureau == "TransUnion");
        let experian = item.list.findIndex((a) => a.bureau == "Experian");
        let list = [];
        if (equifax != -1 && vm.account_visible.equifax == true) {
          list.push({ ...item.list[equifax], ...vm.account.equifax });
        } else if (vm.account_visible.equifax) {
          list.push({ ...vm.account.equifax, bureau: "Equifax" });
        }
        if (experian != -1 && vm.account_visible.experian == true) {
          list.push({
            ...item.list[experian],
            ...vm.account.experian,
          });
        } else if (vm.account_visible.experian) {
          list.push({ ...vm.account.experian, bureau: "Experian" });
        }
        if (transunion != -1 && vm.account_visible.transunion == true) {
          list.push({
            ...item.list[transunion],
            ...vm.account.transunion,
          });
        } else if (vm.account_visible.transunion) {
          list.push({ ...vm.account.transunion, bureau: "TransUnion" });
        }
        if (vm.isAdd) {
          accounts.push({ ...item, list, account_id: new Date().getTime() });
        } else {
          accounts[vm.reportIndex] = { ...item, list };
        }
        await updateDoc(doc(colCreditReports, id), {
          updated_at: getDate(),
          accounts,
        });
        vm.resetForm();
        vm.loading = false;
        vm.dialog = false;
        vm.$emit("onReportUpdate");
      } catch (error) {
        vm.loading = false;
        vm.notifyError(error.message);
      }
    },
    init() {
      const vm = this;
      if (vm.type != "add") {
        vm.isInit = false;
        let item = vm.reportAccounts[vm.reportIndex];
        if (item) {
          Object.keys(vm.form).forEach((key) => {
            if (item[key] != undefined) vm.form[key] = item[key];
          });
          let equifax = item.list.find((a) => a.bureau == "Equifax") || {};
          let transunion =
            item.list.find((a) => a.bureau == "TransUnion") || {};
          let experian = item.list.find((a) => a.bureau == "Experian") || {};
          let doc = { equifax, transunion, experian };
          if (item.account_visible == undefined) {
            vm.account_visible = {
              equifax: Object.keys(equifax).length != 0,
              experian: Object.keys(experian).length != 0,
              transunion: Object.keys(transunion).length != 0,
            };
          } else {
            vm.account_visible = item.account_visible;
          }
          Object.keys(vm.account).forEach((key) => {
            let account = doc[key];
            vm.accountItems.forEach((i) => {
              vm.account[key][i.key] = account[i.key] || "";
            });
          });
          vm.isInit = true;
          vm.form.date = formatDateOnly(vm.report.date);
        }
      } else {
        vm.isInit = true;
      }
    },
    resetForm() {
      this.form = {
        date: "",
        type: "",
        name: "",
        reason: "-",
        instruction: "",
      };
      (this.account_visible = {
        equifax: false,
        experian: false,
        transunion: false,
      }),
        (this.account = {
          equifax: {},
          experian: {},
          transunion: {},
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
