<template>
  <v-btn v-bind="$attrs" height="40px" icon @click="$emit('click')">
    <v-icon>mdi-pencil</v-icon>
  </v-btn>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<style></style>
