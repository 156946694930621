<template>
  <ValidationProvider :rules="rules" v-slot="{ errors }" mode="passive">
    <v-textarea
      v-model="input"
      :dense="dense"
      v-bind="$attrs"
      :label="inlineLabel"
      :rows="rows"
      outlined
      :error-messages="errors"
      @input="$emit('input', input)"
    />
  </ValidationProvider>
</template>

<script>
import { getRules, inputDense } from "../../../assets/utils/common.utils.js";
export default {
  inheritAttrs: false,
  props: {
    inlineLabel: String,
    rows: {
      type: Number,
      default: 3,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    dense: {
      type: Boolean,
      default: inputDense,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: String,
      default: "",
    },
    customRules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      input: "",
    };
  },
  computed: {
    getRules() {
      let { customRules, rules } = this;
      if (customRules.length > 0) return customRules;
      return getRules(rules);
    },
  },
  watch: {
    value(val) {
      this.input = val;
    },
  },
  mounted() {
    this.input = this.value;
  },
};
</script>
