<template>
  <v-dialog max-width="450px" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-title>Rename</v-list-item-title>
      </v-list-item>
    </template>
    <dialog-card
      title="Rename File"
      @close="dialog = false"
      @submit="submit()"
      :loading="isLoading"
    >
      <input-field rules="required" v-model="name" />
    </dialog-card>
  </v-dialog>
</template>

<script>
import { getDate } from "@/assets/utils/common.utils";
import { colClients } from "@/assets/utils/firebase.utils";
import DialogCard from "@/components/ui/DialogCard.vue";
import InputField from "@/components/ui/form/InputField.vue";
import { doc, updateDoc } from "firebase/firestore";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { DialogCard, InputField },
  data() {
    return {
      name: "",
      dialog: false,
      isLoading: false,
    };
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$emit("close");
      }
    },
  },
  methods: {
    async submit() {
      try {
        this.isLoading = true;
        let file_uploads = this.currentClient.file_uploads;
        let index = file_uploads.findIndex((i) => i.fileId == this.item.fileId);
        if (index != -1) {
          file_uploads[index].name = this.name;
          await updateDoc(doc(colClients, this.$route.params.id), {
            file_uploads,
            updated_by: this.userEmail,
            updated_at: getDate(),
          });
        }
        this.isLoading = false;
        this.dialog = false;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
  mounted() {
    this.name = this.item.name;
  },
};
</script>

<style></style>
