<template>
  <v-dialog v-model="dialog" max-width="1000px" persistent scrollable>
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on" class="text-none" color="primary" height="40px"
        >Bulk Edit Tasks</v-btn
      >
    </template>
    <dialog-card title="Bulk Edit Tasks" @close="dialog = false">
      <form-wrapper @submit="reset()">
        <div class="d-flex mt-1">
          <select-field
            v-model="form.filter"
            inlineLabel="Filter"
            style="flex: 0 0 285px"
            hide-details
            rules="required"
            @input="resetFilter()"
            :items="filters"
          />
          <div class="ml-3" style="flex: 0 0 285px">
            <select-field
              inlineLabel="Status"
              rules="requiredBoolean"
              v-model="form.value"
              :items="statusList"
              v-if="form.filter == 'is_completed'"
              hide-details
            />
            <select-field
              rules="required"
              :items="eventTypes"
              v-if="form.filter == 'event_type'"
              v-model="form.value"
              inlineLabel="Task Type"
              hide-details
            />
            <client-autocomplete
              rules="required"
              v-if="form.filter == 'client'"
              v-model="form.value"
              inlineLabel="Client"
            />
            <select-field
              v-if="form.filter == 'member'"
              rules="required"
              v-model="form.value"
              :items="users"
              inlineLabel="Team Member"
              hide-details
            />
          </div>
          <primary-button type="submit" class="ml-3">Search</primary-button>
        </div>
      </form-wrapper>
      <v-card tile outlined class="mt-3">
        <v-data-table
          :loading="loading"
          :options.sync="options"
          show-select
          v-model="selected"
          :server-items-length="total"
          :items-per-page="20"
          fixed-header
          :height="height"
          :must-sort="true"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, 500],
            'show-first-last-page': false,
          }"
          :headers="headers"
          :items="items"
        >
          <template v-slot:item.status="{ item }">
            {{ item.is_completed ? "Completed" : "Incompleted" }}
          </template>
        </v-data-table>
      </v-card>

      <div slot="footer">
        <div class="d-flex justify-end">
          <close-button @click="dialog = false" />
          <div style="flex: 0 0 250px">
            <select-field
              inlineLabel="Apply Bulk Action"
              hide-details
              v-model="action"
              :items="actions"
            />
          </div>
          <v-btn
            height="40px"
            color="success"
            depressed
            class="ml-3"
            @click="applyAction()"
            >Apply Bulk Action</v-btn
          >
        </div>
      </div>
    </dialog-card>
  </v-dialog>
</template>

<script>
import {
  doc,
  getCountFromServer,
  getDocs,
  orderBy,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import ClientAutocomplete from "../clients/ClientAutocomplete.vue";
import PrimaryButton from "../ui/buttons/PrimaryButton.vue";
import DialogCard from "../ui/DialogCard.vue";
import FormWrapper from "../ui/form/FormWrapper.vue";
import SelectField from "../ui/form/SelectField.vue";
import { mapDocs } from "@/assets/utils/doc.utils";
import moment from "moment-timezone";
import { colTeamSchedules, db } from "@/assets/utils/firebase.utils";
import CloseButton from "../ui/buttons/CloseButton.vue";
import { getDate, getTaskCustomers } from "@/assets/utils/common.utils";
export default {
  components: {
    DialogCard,
    SelectField,
    ClientAutocomplete,
    PrimaryButton,
    FormWrapper,
    CloseButton,
  },
  data() {
    return {
      dialog: false,
      items: [],
      total: 50,
      options: {},
      loading: false,
      form: {
        filter: "is_completed",
        value: false,
      },
      selected: [],
      customers: [],
      action: "",
    };
  },
  inject: {
    users: {
      from: "users",
      default: () => [],
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchEvents();
      },
      deep: true,
    },
  },
  computed: {
    height() {
      return window.innerHeight - 360;
    },
    statusList() {
      return ["Incompleted", "Completed"].map((name, index) => ({
        name,
        value: index == 1,
      }));
    },
    actions() {
      return ["Delete", "Mark As Completed", "Mark As Incompleted"].map(
        (name, value) => ({ name, value })
      );
    },
    eventTypes() {
      return [
        "Billing",
        "Send Invoice",
        "Follow Up",
        "Appointment",
        "Other",
      ].map((name) => {
        let value = name;
        return { name, value };
      });
    },
    filters() {
      let data = {
        is_completed: "Completed/Incompleted",
        event_type: "Task Type",
        client: "Client",
        member: "Assigned to team members",
      };
      return Object.keys(data).map((value) => ({ value, name: data[value] }));
    },
    headers() {
      let headers = {
        status: "Status",
        event_type: "Task Type",
        subject: "Subject",
        due_date: "Due Date",
        client_name: "Client",
        member_name: "Team Member",
      };
      return Object.keys(headers).map((value) => ({
        value,
        sortable: false,
        text: headers[value],
      }));
    },
    querys() {
      let queryList = [];
      queryList.push(orderBy("from_date", "desc"));

      queryList.push(where(this.form.filter, "==", this.form.value));
      return {
        countQuery: query(colTeamSchedules, ...queryList),
        pageQuery: query(
          colTeamSchedules,
          ...[...queryList, ...this.getPaginationQuery(this.options)]
        ),
      };
    },
  },
  methods: {
    resetFilter() {
      this.items = [];
      this.selected = [];
      this.action = "";
      this.form.value = "";
    },
    reset() {
      this.options = { ...this.options, page: 1 };
    },
    async applyAction() {
      if (this.action === "") {
        return this.notifyError("Please select action for checked tasks");
      }
      if (this.selected.length == 0) {
        return this.notifyError("Please check tasks to apply bulk action");
      }
      try {
        this.loading = true;
        let batch = writeBatch(db);
        for (let item of this.selected) {
          let ref = doc(colTeamSchedules, item.id);
          if (this.action == 0) {
            batch.delete(ref);
          } else {
            batch.update(ref, {
              is_completed: this.action == 1,
              updated_by: this.userEmail,
              updated_at: getDate(),
            });
          }
        }
        await batch.commit();
        this.$store.dispatch("fetchUserTaskCount");
        this.action = "";
        this.reset();
      } catch (error) {
        this.handleError(error);
      }
    },
    async fetchEvents() {
      try {
        if (!this.form.filter || this.form.value === "") return;
        this.loading = true;
        this.items = [];
        this.selected = [];
        let { countQuery, pageQuery } = this.querys;
        if (this.options.page == 1) {
          this.total = (await getCountFromServer(countQuery)).data().count;
        }
        let docs = (await getDocs(pageQuery)).docs;
        this.firstVisible = docs[0];
        this.lastVisible = docs[docs.length - 1];
        this.currentPage = this.options.page;
        this.customers = await getTaskCustomers(docs, this.customers);

        this.items = mapDocs(docs).map((i) => {
          i.due_date = moment(`${i.from_date}T${i.from_time}`).format(
            "MM/DD/YYYY hh:mm A"
          );
          let member = this.users.find((u) => u.id == i.member);
          if (member) i.member_name = member.name;
          let customer = this.customers.find((c) => c.id == i.client);
          if (customer && i.client) {
            i.client_name = `${customer.first_name} ${customer.last_name}`;
          }
          return i;
        });
        this.loading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>

<style></style>
