<template>
  <v-data-table
    :headers="headersList"
    :items="list"
    @input="$emit('input', selected)"
    v-model="selected"
    class="dynamic-table"
    :loading="loading"
    :must-sort="true"
    v-bind="$attrs"
    :options.sync="options"
    :footer-props="{
      'items-per-page-options': [10, 20, 50, 100, 500],
      'show-first-last-page': true,
    }"
    :items-per-page="itemsPerPage"
  >
    <template v-slot:top="{ pagination, options, updateOptions }">
      <div class="d-flex algin-center">
        <slot name="left"></slot>
        <v-spacer></v-spacer>
        <v-data-footer
          :items-per-page-options="[10, 20, 50, 100, 500]"
          :pagination="pagination"
          :options="options"
          show-first-last-page
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
        />
      </div>
    </template>
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
      ><slot :name="slot" v-bind="scope" />
    </template>
  </v-data-table>
</template>
<script>
import { isLocal } from "@/assets/utils/firebase.utils";
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: {},
      // itemsPerPage: isLocal ? 10 : 50,
      itemsPerPage: 50,
      selected: [],
    };
  },
  watch: {
    options: {
      handler() {
        this.$emit("onPageChange", this.options);
      },
      deep: true,
    },
  },
  computed: {
    headersList() {
      return [...this.headers].map((item) => {
        return item;
      });
    },
  },
};
</script>
