<template>
  <v-menu v-model="menu" offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <div class="d-flex align-center">
        <div class="subtitle-1 mr-3 font-weight-medium">Schedule Status</div>
        <v-btn depressed height="40px" color="primary" v-if="isPaused"
          >Paused</v-btn
        >
        <v-btn depressed height="40px" color="success" v-else>Running</v-btn>
        <v-btn
          icon
          v-on="on"
          height="40px"
          width="40px"
          outlined
          class="ml-3"
          v-if="hasEdit('customers')"
          color="primary"
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </div>
    </template>
    <v-card flat width="500px">
      <form-wrapper @submit="updateScheduleConfig()">
        <div class="pa-4">
          <v-btn @click="updateScheduleStatus()" color="primary" depressed>
            {{ isPaused ? "Run" : "Pause" }}
          </v-btn>
          <v-row class="ma-0 mt-3">
            <v-col cols="12">
              <v-checkbox
                class="mt-0 pt-0"
                dense
                v-model="form.is_autoturn_on"
                label="Automatic Turn On"
              />
              <v-row class="ml-4" v-if="form.is_autoturn_on">
                <v-col cols="6">
                  <date-field
                    label="Date"
                    v-model="form.autoturn_on.date"
                    rules="required"
                  />
                </v-col>
                <v-col cols="6">
                  <label class="body-small">Time</label>
                  <v-row class="pt-1">
                    <v-col cols="6" class="pr-0">
                      <select-field
                        v-model="form.autoturn_on.hour"
                        :items="hours"
                        rules="required"
                      />
                    </v-col>
                    <v-col cols="6">
                      <select-field
                        v-model="form.autoturn_on.am"
                        :items="['AM', 'PM']"
                        rules="required"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-checkbox
                class="mt-0 pt-0"
                dense
                v-model="form.is_autoturn_off"
                label="Automatic Turn Off"
              />
              <v-row class="ml-4" v-if="form.is_autoturn_off">
                <v-col cols="6">
                  <date-field
                    label="Date"
                    v-model="form.autoturn_off.date"
                    rules="required"
                  />
                </v-col>
                <v-col cols="6">
                  <label class="body-small">Time</label>
                  <v-row class="pt-1">
                    <v-col cols="6" class="pr-0">
                      <select-field
                        v-model="form.autoturn_off.hour"
                        :items="hours"
                        rules="required"
                      />
                    </v-col>
                    <v-col cols="6">
                      <select-field
                        v-model="form.autoturn_off.am"
                        :items="['AM', 'PM']"
                        rules="required"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="d-flex">
            <v-spacer></v-spacer>
            <close-button :disabled="loading" @click="menu = false"
              >Close</close-button
            >
            <v-btn
              :loading="loading"
              color="success"
              class="ml-3"
              depressed
              type="submit"
              height="40px"
            >
              Save
            </v-btn>
          </div>
        </div>
      </form-wrapper>
    </v-card>
  </v-menu>
</template>

<script>
import { doc, updateDoc } from "firebase/firestore";
import CloseButton from "../ui/buttons/CloseButton.vue";
import DateField from "../ui/form/DateField.vue";
import SelectField from "../ui/form/SelectField.vue";
import { colClients } from "@/assets/utils/firebase.utils";
import { getDate, initForm } from "@/assets/utils/common.utils";
import FormWrapper from "../ui/form/FormWrapper.vue";
export default {
  components: { SelectField, DateField, CloseButton, FormWrapper },
  data() {
    return {
      menu: false,
      loading: false,
      form: {
        is_autoturn_off: false,
        autoturn_off: {
          hour: "",
          am: "AM",
        },
        is_autoturn_on: false,
        autoturn_on: {
          hour: "",
          am: "AM",
        },
      },
    };
  },
  computed: {
    hours() {
      let list = [];
      for (let i = 1; i < 13; i++) {
        if (i < 10) {
          list.push(`0${i}`);
        } else {
          list.push(i.toString());
        }
      }
      return list;
    },
    isPaused() {
      return this.currentClient.fax_schedule_status == "Paused";
    },
  },
  methods: {
    updateScheduleStatus() {
      const vm = this;
      let message = "Are you sure, Do you want to pause this schedule?";
      if (vm.isPaused)
        message = "Are you sure, Do you want to run this schedule?";
      let fax_schedule_status = vm.isPaused ? "Running" : "Paused";
      vm.$confirm.show({
        message,
        onConfirm: async () => {
          try {
            let id = vm.$route.params.id;
            await updateDoc(doc(colClients, id), {
              fax_schedule_status,
              updated_at: getDate(),
              updated_by: this.userEmail,
              fax_status_updated_at: getDate(),
            });
          } catch (error) {
            this.handleError(error);
          }
        },
      });
    },
    async updateScheduleConfig() {
      try {
        this.loading = true;
        let id = this.$route.params.id;
        await updateDoc(doc(colClients, id), {
          fax_schedule_config: this.form,
          updated_at: getDate(),
          updated_by: this.userEmail,
        });
        this.loading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
  mounted() {
    initForm(this.form, this.currentClient.fax_schedule_config || {});
  },
};
</script>

<style></style>
