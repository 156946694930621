<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <v-form
      ref="form"
      v-model="isValid"
      @submit.prevent="handleSubmit(submit)"
      :lazy-validation="false"
    >
      <slot></slot>
      <div class="d-flex">
        <v-spacer></v-spacer>
        <slot name="footer"></slot>
      </div>
    </v-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from "vee-validate";
export default {
  components: {
    ValidationObserver,
  },
  data() {
    return {
      isValid: false,
    };
  },
  methods: {
    reset() {
      const vm = this;
      vm.$refs.form.reset();
    },
    validate() {
      const vm = this;
      vm.$refs.form.validate();
      return vm.$refs.form.validate();
    },
    submit() {
      this.$emit("submit");
    },
  },
};
</script>

<style></style>
