export const CreditReportReasons = [
  "Sample Reason",
  "The following personal information is incorrect",
  "The following account is not mine",
  "The status is incorrect for the following account",
  "The following information is outdated. I would like it removed from my credit history report.",
  "The following inquiry is more than two years old and I would like it removed",
  "The inquiry was not authorized",
  "The following accounts were closed by me and should state that.",
  "The following account was a Bankruptcy/Charge-off. Balance should be $0",
  "Mistaken Identity",
  "Identity Theft",
  "Other information I would like changed",
  "This is a duplicate account",
  "The wrong account is being reported",
  "This is the wrong creditor for this item",
  "Validate account",
  "-",
  "Fraudulent Charges",
  "Over ten years since reported- This should be removed from public records",
  "Family member has the same name as me",
  "They were not the creditor at this time",
  "This is not the original creditor",
];
export const CreditReportStatus = [
  "Negative",
  "In Dispute",
  "Positive",
  "Unspecified",
  "Deleted",
  "Updated",
];
