<template>
  <v-dialog v-model="dialog" max-width="450px" persistent>
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <add-button class="ml-3"> Add Manual Letter</add-button>
      </div>
    </template>
    <dialog-card
      @close="dialog = false"
      title="Add Manual Letter"
      :loading="loading"
      @submit="submit()"
    >
      <select-field
        :items="faxStatus"
        v-model="form.status"
        label="Status"
        rules="required"
      />
      <input-field v-model="form.name" label="Name of File" rules="required" />
      <date-field
        v-model="form.date"
        rules="required"
        label="Date Uploaded/ Date Updated"
      />
    </dialog-card>
  </v-dialog>
</template>

<script>
import { getDate, getSearchArray } from "@/assets/utils/common.utils";
import AddButton from "../ui/buttons/AddButton.vue";
import DialogCard from "../ui/DialogCard.vue";
import DateField from "../ui/form/DateField.vue";
import InputField from "../ui/form/InputField.vue";
import SelectField from "../ui/form/SelectField.vue";
import { addDoc } from "firebase/firestore";
import { colFaxes } from "@/assets/utils/firebase.utils";
let defaultForm = {
  status: "",
  name: "",
  date: "",
};
export default {
  components: { DialogCard, AddButton, SelectField, InputField, DateField },
  data() {
    return {
      dialog: false,
      loading: false,
      form: { ...defaultForm },
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.form = { ...defaultForm };
      }
    },
  },
  computed: {
    faxStatus() {
      return ["UPLOADED TU", "UPLOADED EFX"];
    },
  },
  methods: {
    async submit() {
      try {
        this.loading = true;
        let data = {
          fax_title: this.form.name,
          status: this.form.status,
          created_at: getDate(this.form.date),
          updated_at: getDate(this.form.date),
          customer_name: this.currentClient.name,
          _search: getSearchArray(this.form.name),
          customer: this.$route.params.id,
          sent_at: getDate(),
          is_manual_fax: true,
          schedule: null,
        };
        await addDoc(colFaxes, data);
        this.$emit("add");
        this.dialog = false;
        this.loading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>

<style></style>
