<template>
  <layout :loading="isLoading" :isTop="false">
    <v-row class="mt-3">
      <v-col cols="12" lg="6">
        <div class="d-flex mb-3 align-xl-center flex-column flex-xl-row">
          <div class="text-h4">{{ client.name }}</div>
          <v-divider vertical class="mx-3"></v-divider>
          <div>Referred By : {{ clientAffilate }}</div>
        </div>
        <update-client-status class="mb-3" :item="client" />
        <div class="d-flex mb-3 align-center">
          <div class="d-flex" v-if="client.email">
            <v-icon class="mr-1">mdi-email</v-icon>
            {{ client.email }}
          </div>
          <div class="d-flex ml-3" v-if="client.mobile">
            <v-icon class="mr-1">mdi-phone</v-icon>
            {{ client.mobile }}
          </div>
        </div>
        <div class="d-flex mb-3 black--text">
          {{ address }}
          <span v-if="address" class="mx-1">|</span>
          <template v-if="formatDateOnly(client.birth_date) != 'Invalid date'">
            <tooltip :message="`DOB: ${formatDateOnly(client.birth_date)}`">
              <span @click="copyDOB(true)" class="cursor-pointer">
                DOB:&nbsp;</span
              >
            </tooltip>
            <tooltip :message="formatDateOnly(client.birth_date)">
              <a class="black--text" @click="copyDOB(false)">
                {{ formatDateOnly(client.birth_date) }}</a
              >
            </tooltip>
          </template>
          <span v-if="client.ss_number">
            <span class="mx-1"> | </span>
            <tooltip :message="`SSN: ${client.ss_number}`">
              <span @click="copyClipboard(true)" class="cursor-pointer">
                SSN:&nbsp;
              </span>
            </tooltip>
            <tooltip :message="client.ss_number">
              <a class="black--text" @click="copyClipboard(false)">
                XXX-XX-{{ ssn }}</a
              >
            </tooltip>
          </span>
        </div>
        <router-link
          class="text-decoration-none"
          v-if="hasEdit('customers')"
          :to="{ name: 'update-client', params: { id: clientId } }"
          >View/Edit Profile</router-link
        >
        <span v-if="agreement" class="mx-3">|</span>
        <client-agreement-preview-dialog
          v-if="agreement"
          :agreement="agreement"
        />
      </v-col>
      <v-col cols="12" lg="6">
        <v-slide-group
          class="pa-4 account-group"
          active-class="success"
          show-arrows
        >
          <draggable
            v-model="accounts"
            style="display: flex"
            draggable=".account-item"
            @change="onPositionUpdate"
          >
            <v-slide-item
              v-for="account in accounts"
              :key="account.prefix"
              style="width: 250px"
            >
              <client-report-account
                :class="hasEdit('customers') ? 'account-item' : ''"
                :type="account.prefix"
                :account="account"
                :logo="account.logo"
              />
            </v-slide-item>
          </draggable>
        </v-slide-group>
      </v-col>
    </v-row>
    <v-divider class="mt-5"></v-divider>
    <v-row class="my-0">
      <v-col cols="12" lg="6" class="pb-10">
        <client-profile-scores />
        <client-profile-progress ref="progress" />
        <div class="d-flex mb-5" v-if="canChargeClient">
          <charge-client-dialog />
          <client-recurring-schedule />
          <client-profile-payment-methods />
        </div>
        <v-divider class="my-3" />
        <client-profile-dispute-status />
      </v-col>
      <v-col
        cols="12"
        lg="6"
        class="pb-10"
        style="border-left: 1px solid #0000001f"
      >
        <client-team-tasks />
        <v-divider class="mb-3 mt-1" />
        <div class="title mb-3">Internal Memo</div>
        <div>
          <div
            v-if="!isMemoEdit"
            @click="editMemo()"
            class="pa-3 indigo lighten-5 rounded-lg"
            :class="hasEdit('customers') ? 'cursor-pointer' : ''"
            v-html="memoHTML"
          ></div>
          <template v-else>
            <textarea-field v-model="memo" :rows="10" />
            <div class="d-flex">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                class="mr-3"
                @click="isMemoEdit = false"
                outlined
                :disabled="isMemoLoading"
                >Cancel</v-btn
              >
              <v-btn
                color="success"
                depressed
                @click="updateMemo()"
                :loading="isMemoLoading"
                >Save Memo</v-btn
              >
            </div>
          </template>
        </div>
        <v-divider class="my-3" />
        <client-profile-documents
          @onFileUpdate="$refs.progress.fetchProgress()"
        />
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import UpdateClientStatus from "@/components/clients/UpdateClientStatus.vue";
import Tooltip from "@/components/ui/Tooltip.vue";
import ClientProfileScores from "@/components/clients/clientProfile/ClientProfileScores.vue";
import ClientProfileProgress from "@/components/clients/clientProfile/ClientProfileProgress.vue";
import ClientProfileDocuments from "@/components/clients/clientProfile/ClientProfileDocuments.vue";
import ClientReportAccount from "./ClientReportAccount.vue";
import ClientProfileDisputeStatus from "@/components/clients/clientProfile/ClientProfileDisputeStatus.vue";
import TextareaField from "@/components/ui/form/TextareaField.vue";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { colClientAgreements, colClients } from "@/assets/utils/firebase.utils";
import { eventBus, getDate } from "@/assets/utils/common.utils";
import ClientTeamTasks from "@/components/clients/clientProfile/ClientTeamTasks.vue";
import ChargeClientDialog from "@/components/clients/clientProfile/ChargeClientDialog.vue";
import ClientRecurringSchedule from "@/components/clients/clientProfile/ClientRecurringSchedule.vue";
import draggable from "vuedraggable";
import ClientAgreementPreviewDialog from "@/components/clients/ClientAgreementPreviewDialog.vue";
import ClientProfilePaymentMethods from "@/components/clients/clientProfile/ClientProfilePaymentMethods.vue";

export default {
  components: {
    Layout,
    UpdateClientStatus,
    Tooltip,
    ClientProfileScores,
    ClientProfileProgress,
    ClientProfileDocuments,
    ClientReportAccount,
    ClientProfileDisputeStatus,
    TextareaField,
    ClientTeamTasks,
    ChargeClientDialog,
    ClientRecurringSchedule,
    draggable,
    ClientAgreementPreviewDialog,
    ClientProfilePaymentMethods,
  },
  data() {
    return {
      isLoading: false,
      isMemoEdit: false,
      isMemoLoading: false,
      memo: "",
      agreement: null,
      accountOrder: [],
      accounts: [
        {
          prefix: "sc",
          logo: require("@/assets/images/smartcredit.svg"),
          link: "https://www.smartcredit.com/login/",
        },
        {
          prefix: "enfortra",
          logo: require("@/assets/images/enfortra.png"),
          link: "https://enfortra.com",
        },
        {
          prefix: "equipfax",
          logo: require("@/assets/images/equifax.png"),
          class: "px-5 pt-4",
          link: "https://my.equifax.com/membercenter/#/login",
        },
        {
          prefix: "experian",
          logo: require("@/assets/images/experian.svg"),
          class: "py-1",
          link: "https://www.experian.com/help/login.html",
        },
        {
          prefix: "transunion",
          logo: require("@/assets/images/transunion.png"),
          class: "py-1",
          link: "https://service.transunion.com/dss/login.page?PLACE_CTA=TransUnion:DSS:Login",
        },
        {
          prefix: "creditkarma",
          class: "px-2",
          logo: require("@/assets/images/creditkarma.svg"),
          link: "https://www.creditkarma.com/auth/logon",
        },
      ],
    };
  },
  computed: {
    memoHTML() {
      let memo = this.currentClient.memo || "-";
      return memo.split("\n").join("<br/>");
    },
    client() {
      return { ...this.currentClient };
    },
    clientId() {
      return this.$route.params.id;
    },
    ssn() {
      let ssn = this.client.ss_number || "";
      return ssn.toString().substr(ssn.toString().length - 4);
    },
    address() {
      let address = [];
      let { street, state, postal_code, city } = this.client;
      if (street) address.push(street);
      if (city) address.push(city);
      if (state) address.push(state);
      if (postal_code) address.push(postal_code);
      return address.toString().split(",").join(", ");
    },
    clientAffilate() {
      let { affiliate_id, affiliate_id_2, affiliate_id_3 } = this.client;
      let list = [];
      if (affiliate_id) list.push(this.getAffiliateName(affiliate_id));
      if (affiliate_id_2) list.push(this.getAffiliateName(affiliate_id_2));
      if (affiliate_id_3) list.push(this.getAffiliateName(affiliate_id_3));
      return list.toString().split(",").join(", ");
    },
  },
  methods: {
    onPositionUpdate() {
      let account_orders = this.accounts.map((i) => i.prefix);
      updateDoc(doc(colClients, this.$route.params.id), {
        account_orders,
      });
    },
    copyClipboard(withSSNText) {
      let ssn = this.client.ss_number;
      if (withSSNText) {
        ssn = `SSN: ${ssn}`;
      }
      navigator.clipboard.writeText(ssn);
      this.notifySuccess("SSN Copied");
    },
    copyDOB(withDOBText) {
      let dob = this.formatDateOnly(this.client.birth_date);
      if (withDOBText) {
        dob = `DOB: ${dob}`;
      }
      navigator.clipboard.writeText(dob);
      this.notifySuccess("DOB Copied");
    },
    editMemo() {
      if (this.hasEdit("customers")) {
        this.isMemoEdit = true;
        this.memo = this.currentClient.memo || "";
      }
    },
    async updateMemo() {
      try {
        this.isMemoLoading = true;
        await updateDoc(doc(colClients, this.$route.params.id), {
          memo: this.memo,
          updated_by: this.userEmail,
          updated_at: getDate(),
        });
        this.isMemoEdit = false;
        this.isMemoLoading = false;
      } catch (error) {
        this.isMemoLoading = false;
        this.handleError(error);
      }
    },
    initAccountOrder() {
      let orders = this.currentClient.account_orders || [];
      let orderFound = orders
        .map((i) => {
          let item = this.accounts.find((a) => a.prefix == i);
          return item;
        })
        .filter((i) => i != null);
      let orderNotFound = this.accounts.filter((i) => {
        return orders.find((o) => o == i.prefix) == null;
      });
      this.accounts = [...orderFound, ...orderNotFound];
    },
    async fetchAgreement() {
      try {
        this.isLoading = true;
        let agreementRef = doc(colClientAgreements, this.$route.params.id);
        this.agreement = (await getDoc(agreementRef)).data();
        if (this.agreement) {
          this.agreement.client = this.currentClient;
        }
        this.isLoading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
  beforeDestroy() {
    eventBus.$off("refreshProfile");
  },
  beforeMount() {
    this.initAccountOrder();
    this.fetchAgreement();

    eventBus.$on("refreshProfile", () => {
      this.initAccountOrder();
      this.fetchAgreement();
    });
  },
};
</script>

<style lang="scss">
.account-group {
  .v-slide-group__next,
  .v-slide-group__prev {
    min-width: unset !important;
  }
}
</style>
