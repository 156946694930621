<template>
  <v-btn v-bind="$attrs" height="40px" text color="red" @click="$emit('click')">
    <slot>Close</slot>
  </v-btn>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<style></style>
