<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <edit-icon-button v-if="isUpdate"></edit-icon-button>
        <add-button v-else>Add New</add-button>
      </span>
    </template>
    <dialog-card
      :title="title"
      :loading="loading"
      @close="dialog = false"
      @submit="submit()"
    >
      <v-row>
        <v-col md="6" cols="12" class="pb-0">
          <input-field
            label="First Name"
            v-model="form.first_name"
            rules="required"
          ></input-field>
        </v-col>
        <v-col md="6" cols="12" class="pb-0">
          <input-field
            label="Last Name"
            v-model="form.last_name"
            rules="required"
          ></input-field>
        </v-col>
        <v-col md="6" cols="12" class="pb-0">
          <input-field
            label="Email"
            v-model.trim="form.email"
            rules="required|email"
          ></input-field>
        </v-col>
        <v-col md="6" cols="12" class="pb-0">
          <input-field
            label="Password"
            v-model="form.password"
            rules="required"
            :loading="isPasswordLoading"
          ></input-field>
        </v-col>
        <v-col md="6" cols="12" class="pb-0">
          <select-field
            label="Role"
            :items="roles"
            v-model="form.role_id"
            rules="required"
          ></select-field>
        </v-col>
      </v-row>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { initForm } from "@/assets/utils/common.utils";
import AddButton from "../ui/buttons/AddButton.vue";
import EditIconButton from "../ui/buttons/EditIconButton.vue";
import DialogCard from "../ui/DialogCard.vue";
import InputField from "../ui/form/InputField.vue";
import SelectField from "../ui/form/SelectField.vue";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/assets/utils/firebase.utils";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isPasswordLoading: false,
      form: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        role_id: "",
      },
    };
  },
  components: {
    AddButton,
    EditIconButton,
    DialogCard,
    InputField,
    SelectField,
  },
  watch: {
    dialog() {
      initForm(this.form, this.item);
      this.form.password = "";
      if (this.isUpdate && this.item.password) {
        this.fetchPassword();
      }
    },
  },
  computed: {
    isUpdate() {
      return this.item.id != undefined;
    },
    title() {
      return this.isUpdate ? "Edit Team Member" : "Add New Team Member";
    },
  },
  methods: {
    async fetchPassword() {
      try {
        this.isPasswordLoading = true;
        let request = {
          action: "password",
          data: { id: this.item.id },
        };
        let getPassword = httpsCallable(functions, "manageUser");
        let password = (await getPassword(request)).data;
        this.form.password = password;
        this.isPasswordLoading = false;
      } catch (error) {
        this.isPasswordLoading = false;
        this.handleError(error);
      }
    },
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        let data = { ...vm.form };
        if (vm.isUpdate) {
          data.id = vm.item.id;
        }
        let request = {
          action: vm.isUpdate ? "update" : "create",
          data,
        };
        await httpsCallable(functions, "manageUser")(request);
        if (vm.isUpdate) {
          vm.notifySuccess("Team member has been updated successfully.");
        } else {
          vm.notifySuccess("Team member has been created successfully.");
        }
        vm.dialog = false;
        vm.form = {
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          role_id: "",
        };
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
};
</script>

<style></style>
