<template>
  <div>
    <v-dialog v-model="dialog" max-width="550px">
      <v-card class="pa-4">
        <div class="d-flex align-center">
          <v-icon>mdi-calendar</v-icon>
          <div class="title ml-3">Event Details</div>
          <v-spacer></v-spacer>
          <edit-icon-button v-if="hasEdit('schedule')" @click="openEdit()" />
          <delete-icon-button
            v-if="hasDelete('schedule')"
            @click="deleteEvent()"
          />
        </div>
        <div class="px-9 py-5 body-2" v-if="event">
          <div class="mb-3">
            {{ event.subject }}
          </div>
          <div class="mb-3">
            {{ eventDate }}
          </div>
          <div>
            {{ eventTime }}
          </div>
        </div>
      </v-card>
    </v-dialog>
    <add-schedule-event
      @onEventUpsert="$emit('onEventUpsert')"
      :is-edit="true"
      ref="event"
      :show-button="false"
      :item="event"
      :type="event?.type"
    />
  </div>
</template>

<script>
import moment from "moment-timezone";
import DeleteIconButton from "../ui/buttons/DeleteIconButton.vue";
import AddScheduleEvent from "./AddScheduleEvent.vue";
import EditIconButton from "../ui/buttons/EditIconButton.vue";
import scheduleMixin from "@/mixins/schedule.mixin";
export default {
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [scheduleMixin],
  components: {
    DeleteIconButton,
    AddScheduleEvent,
    EditIconButton,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    eventDate() {
      let { from_date, to_date } = this.event;
      let from = moment(from_date).format("ddd,MMM DD,YYYY");
      let to = moment(to_date).format("ddd,MMM DD,YYYY");
      if (from_date == to_date) {
        return `${from}`;
      }
      return `${from} - ${to}`;
    },
    eventTime() {
      if (this.event.is_all_date) return "12 AM";
      let from = moment(this.event.from_time, "HH:mm").format("hh:mm A");
      let to = moment(this.event.to_time, "HH:mm").format("hh:mm A");
      return `${from} - ${to}`;
    },
  },
  methods: {
    openEdit() {
      this.dialog = false;
      this.$refs.event.dialog = true;
    },
    open() {
      this.dialog = true;
    },
    deleteEvent() {
      this.removeTask(this.event).then(() => {
        this.$emit("onDelete");
        this.dialog = false;
      });
    },
  },
};
</script>

<style></style>
