<template>
  <div class="d-flex" style="height: calc(100vh - 132px)">
    <div style="width: 300px" class="bg pt-9 pl-10 pr-4 overflow-y-auto">
      <p class="body-small--bold grey--text">Company Settings</p>
      <v-list dense nav class="transparent pl-0 mycompany-nav">
        <v-list-item
          v-for="item in items"
          :key="item.title"
          @click="$router.push({ name: item.path })"
          :class="activeClass(item)"
          :disabled="isDisabled(item)"
          link
        >
          <v-list-item-icon class="mr-3">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
    <div class="flex-1 px-6 py-6 overflow-y-auto">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { myCompanyTabs } from "@/assets/constants/nav.constants";
export default {
  computed: {
    items() {
      return myCompanyTabs;
    },
  },
  methods: {
    isDisabled({ permission }) {
      return !this.hasPermission(permission, "view");
    },
    activeClass({ path, child }) {
      let routes = [path, ...(child || [])];
      return routes.indexOf(this.$route.name) != -1 ? "white" : "";
    },
  },
};
</script>

<style lang="scss">
.mycompany-nav {
  .v-list-item__icon {
    padding-right: 0px !important;
  }
}
</style>
