import { colTeamSchedules, db } from "@/assets/utils/firebase.utils";
import { collection, doc, getDocs, writeBatch } from "firebase/firestore";

export default {
  methods: {
    removeTask({ subject, id }) {
      return new Promise((resolve, reject) => {
        this.$confirm.show({
          message: `Are you sure, Do you want to remove ${subject}?`,
          onConfirm: async () => {
            try {
              let snap = await getDocs(
                collection(colTeamSchedules, id, "history")
              );
              let batch = writeBatch(db);
              snap.docs.forEach((i) => {
                batch.delete(i.ref);
              });
              batch.delete(doc(colTeamSchedules, id));
              await batch.commit();
              this.$store.dispatch("fetchUserTaskCount");
              resolve();
            } catch (error) {
              reject(error);
              this.handleError(error);
            }
          },
        });
      });
    },
  },
};
