<template>
  <layout :title="`My Saved Letters (${currentClient.name})`" ref="paste">
    <static-table-view :list="items" :loading="loading" :headers="headers">
      <template v-slot:item.created_at="{ value }">
        {{ formatDate(value) }}
      </template>
      <template v-slot:item.action="{ item }" v-if="false">
        <div class="d-flex justify-end">
          <v-btn
            outlined
            width="100px"
            v-if="item.is_saved"
            :disabled="loading"
            @click="uploadFile(item)"
          >
            {{ item.report_pdf ? "Replace" : "Upload" }}
          </v-btn>
          <v-btn
            class="ml-3"
            text
            color="primary"
            v-if="item.report_pdf"
            @click="openUrl(item)"
            >Preview</v-btn
          >
        </div>
      </template>
    </static-table-view>
    <input
      type="file"
      class="d-none"
      ref="file"
      @change="onFileChange"
      accept=".pdf,.html"
    />
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import StaticTableView from "@/components/ui/StaticTableView.vue";
import { getHeaders } from "@/assets/utils/common.utils";
import {
  doc,
  getDocs,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { colSavedLetters, db, storage } from "@/assets/utils/firebase.utils";
import moment from "moment-timezone";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
export default {
  components: { Layout, StaticTableView },
  data() {
    return {
      items: [],
      loading: false,
      reportItem: {},
    };
  },
  computed: {
    headers() {
      let doc = {
        letter_name: "Letter Name",
        created_at: "Created Date",
        action: "Action",
      };
      return getHeaders(doc).map((i) => ({
        ...i,
        width: i.value == "action" ? "100px" : "",
      }));
    },
  },
  methods: {
    uploadFile(item) {
      this.reportItem = item;
      this.$refs.file.click();
    },
    openUrl(item) {
      window.open(item.report_pdf, "_blank");
    },
    async fetchReports() {
      try {
        this.loading = true;
        let listQuery = query(
          colSavedLetters,
          where("customer", "==", this.$route.params.id)
        );
        let list = (await getDocs(listQuery)).docs.map((i) => ({
          ...i.data(),
          id: i.id,
        }));
        this.items = list.sort(
          (a, b) => moment(b.created_at).unix() - moment(a.created_at).unix()
        );
        this.loading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
    async onFileChange(e) {
      let file = e.target.files[0];
      if (file) {
        let contentTypes = ["text/html", "application/pdf"];
        if (contentTypes.indexOf(file.type) == -1) {
          this.notifyError("File type not supported.");
          return;
        }
        try {
          this.loading = true;
          const parts = file.name.split(".");
          let ext = parts[parts.length - 1];
          let storageRef = ref(
            storage,
            `customers/${this.reportItem.customer}/saved-reports/${this.reportItem.id}.${ext}`
          );
          await uploadBytes(storageRef, file);
          let report_pdf = await getDownloadURL(storageRef);
          await updateDoc(doc(colSavedLetters, this.reportItem.id), {
            report_pdf,
          });
          this.fetchReports();
        } catch (error) {
          this.handleError(error);
        }
      }
      this.$refs.file.value = null;
    },

    convertToJSON(data) {
      const lines = data
        .split("\n")
        .filter(
          (line) => line.trim() !== "arrow_drop_down" && line.trim() !== ""
        );
      const headers = lines[0].split("\t");
      console.log(headers);
      const jsonData = [];
      let filterHeaders = headers.filter((i) => {
        return ["letter name", "created date"].indexOf(
          (i || "").trim().toLowerCase()
        );
      });
      if (filterHeaders.length == 2) {
        for (let i = 1; i < lines.length; i++) {
          let split = lines[i].split("\t");
          if (split.length == 3) {
            let letter_name = lines[i].split("\t")[0].trim();
            let created_at = moment(
              lines[i].split("\t")[1].trim(),
              "MM/DD/YYYY"
            );
            if (created_at.isValid()) {
              const entry = {
                created_at: created_at.format("YYYY-MM-DDTHH:mm:ss"),
                customer: this.$route.params.id,
                letter_name,
                report_pdf: "",
              };
              console.log(entry);
              jsonData.push(entry);
            }
          }
        }
      }

      return jsonData;
    },
    async handlePaste(event) {
      if (this.loading) return;
      const pastedData = (event.clipboardData || window.clipboardData).getData(
        "text"
      );
      let list = this.convertToJSON(pastedData);
      try {
        this.loading = true;
        list = list.filter((i) => {
          let item = this.items.find(
            (r) =>
              moment(r.created_at).format("YYYY-MM-DD") ==
                moment(i.created_at).format("YYYY-MM-DD") &&
              r.letter_name == i.letter_name
          );
          return item == null;
        });
        let batch = writeBatch(db);
        list.forEach((i) => {
          let reportId = doc(colSavedLetters).id;
          batch.set(doc(colSavedLetters, reportId), i);
        });
        await batch.commit();
        this.fetchReports();
      } catch (error) {
        this.handleError(error);
      }
    },
  },
  beforeDestroy() {
    if (this.hasEdit("customers")) {
      window.removeEventListener("paste", this.handlePaste);
    }
  },

  mounted() {
    if (this.hasEdit("customers")) {
      window.addEventListener("paste", this.handlePaste);
    }
    this.fetchReports();
  },
};
</script>

<style></style>
