import { getDate } from "@/assets/utils/common.utils";
import {
  colAffiliates,
  colCommissions,
  colTransactions,
  db,
} from "@/assets/utils/firebase.utils";
import { doc, getDoc, writeBatch } from "firebase/firestore";

export default {
  methods: {
    async applyCommission() {
      const vm = this;
      if (vm.selected.length == 0) {
        vm.notifyError("Please select at least one transaction.");
        return;
      }
      if (vm.commission_rate < 0 || vm.commission_rate > 100) {
        vm.notifyError("Commission rate must be between 0 to 100");
        return;
      }
      try {
        vm.isLoading = true;
        let affiliate = await (
          await getDoc(doc(colAffiliates, vm.affiliate.id))
        ).data();
        let commission_paid = affiliate.commission || 0;
        let commission_owed = affiliate.commission_owed || 0;

        let batch = writeBatch(db);
        for (let item of vm.selected) {
          let { amount, payout } = item;
          let commission = parseFloat(item.commission || 0);
          let new_commission = parseFloat(amount) * (vm.commission_rate / 100);
          console.log(commission, new_commission);

          if (payout) {
            commission_paid =
              parseFloat(commission_paid) - parseFloat(commission);
            commission_paid =
              parseFloat(commission_paid) + parseFloat(new_commission);
          } else {
            commission_owed =
              parseFloat(commission_owed) - parseFloat(commission);
            commission_owed =
              parseFloat(commission_owed) + parseFloat(new_commission);
          }
          batch.update(doc(colCommissions, item.id), {
            updated_at: getDate(),
            updated_by: vm.userEmail,
            commission: new_commission,
            commission_rate: vm.commission_rate,
          });
          batch.update(doc(colTransactions, item.transaction_id), {
            [`commissions.${vm.affiliate.id}`]: vm.commission_rate,
          });
        }
        batch.update(doc(colAffiliates, vm.affiliate.id), {
          commission: parseFloat(commission_paid.toFixed(2)),
          commission_owed: parseFloat(commission_owed.toFixed(2)),
          updated_at: getDate(),
          updated_by: vm.userEmail,
        });
        await batch.commit();
        this.commission_rate = (affiliate.commission_rate || 0) * 100;
        this.afterApplyCommission();
        vm.isLoading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
};
