<template>
  <div>
    <v-card class="mt-10" v-for="(item, i) in items" flat :key="i">
      <v-data-table
        :headers="headers"
        hide-default-header
        :items-per-page="-1"
        :loading="loading"
        :items="getItems({ ...item, index: i })"
        class="credit-report-view mb-10"
        hide-default-footer
      >
        <template v-slot:header>
          <thead>
            <tr class="grey lighten-3">
              <th width="20%" class="text-uppercase">Creditor/Furnisher</th>
              <th width="20%" class="text-uppercase"></th>
              <th width="20%">
                <equifax-img />
              </th>
              <th width="20%">
                <experian-img />
              </th>
              <th width="20%">
                <transunion-img />
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:item.name="{ value }">
          <div class="pt-3">
            {{ value }}
            <br />
            <div class="d-flex" v-if="value">
              <credit-report-item-edit
                :report="report"
                v-if="hasEdit('customers')"
                :report-accounts="items"
                @onReportUpdate="$emit('onReportUpdate')"
                :report-index="i"
                type="view"
              />
              <delete-icon-button
                @click="removeReportItem(i)"
                small
                v-if="hasDelete('customers')"
              />
            </div>
          </div>
        </template>

        <template v-slot:item.account="{ value, index }">
          <div class="text-right">
            {{ value }}
            <br />
            <a
              text
              class="primary--text"
              @click="showMore(i)"
              v-if="isMore(index, i)"
            >
              More
            </a>
            <a
              text
              class="primary--text"
              @click="showLess(i)"
              v-if="isLess(item, index, i)"
            >
              Less
            </a>
          </div>
        </template>
        <template v-slot:item.equifax="{ value }">
          <credit-report-status
            class="my-2"
            :align="'align-start'"
            :status="value"
          ></credit-report-status>
        </template>
        <template v-slot:item.transunion="{ value }">
          <credit-report-status
            class="my-2"
            :align="'align-start'"
            :status="value"
          ></credit-report-status>
        </template>
        <template v-slot:item.experian="item">
          <credit-report-status
            class="my-2"
            :align="'align-start'"
            :status="item.value"
          ></credit-report-status>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  formatDateOnly,
  formatPrice,
  getDate,
} from "@/assets/utils/common.utils";
import { doc, updateDoc } from "firebase/firestore";
import { colCreditReports } from "@/assets/utils/firebase.utils";
import CreditReportStatus from "./CreditReportStatus.vue";
import DeleteIconButton from "@/components/ui/buttons/DeleteIconButton.vue";
import EquifaxImg from "../EquifaxImg.vue";
import ExperianImg from "../ExperianImg.vue";
import TransunionImg from "../TransunionImg.vue";
import CreditReportItemEdit from "./CreditReportItemEdit.vue";
export default {
  components: {
    CreditReportStatus,
    DeleteIconButton,
    EquifaxImg,
    ExperianImg,
    TransunionImg,
    CreditReportItemEdit,
  },
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: [],
    };
  },
  computed: {
    headers() {
      let doc = {
        name: "Creditor/Furnisher",
        account: "Account #",
        equifax: "equifax",
        experian: "experian",
        transunion: "transunion",
      };
      return Object.keys(doc).map((value) => ({
        value,
        text: doc[value],
        divider: true,
      }));
    },
    items() {
      let list = [...this.report.accounts]
        .map((i) => {
          let unspecified = i.list.filter((i) => i.status == "Unspecified");
          return {
            ...i,
            unspecified: unspecified.length,
          };
        })
        .sort((a, b) => b.unspecified - a.unspecified);
      let inquiryItems = list.filter((i) => i.type == "inquiry");
      let nonInquiryItems = list.filter((i) => i.type != "inquiry");
      return [...inquiryItems, ...nonInquiryItems];
    },
  },
  methods: {
    showMore(row) {
      this.expanded.push(row);
    },
    showLess(row) {
      this.expanded = [...this.expanded].filter((i) => i != row);
    },
    isLess(item, index, row) {
      let list = this.getItems({ ...item, index: row });
      return this.expanded.indexOf(row) != -1 && list.length - 2 == index;
    },
    isMore(index, row) {
      return this.expanded.indexOf(row) == -1 && index == 4;
    },
    getItems({ list, name, index }) {
      let accountDetails = {
        account_name: "Account Name",
        date_opened: "Date Opened",
        high_balance: "High Balance",
        credit_limit: "Credit Limit",
        account_number: "Account #",
        balance_owned: "Balance Owed",
        closed_date: "Cloesd Date",
        account_rating: "Account Rating",
        account_description: "Account Description",
        dispute_status: "Dispute Status",
        creditor_type: "Creditor Type",
        account_status: "Account Status",
        payment_status: "Payment Status",
        creditor_remarks: "Creditor Remarks",
        payment_amount: "Payment Amount",
        last_payment: "Last Payment",
        past_due_amount: "Past Due Amount",
        account_type: "Account Type",
        last_verfied: "Last Verified",
        late_payment_status: "Late Payment Status",
        amount: "Amount",
        last_activity: "Last Activity",
        date_reported: "Date Reported",
        date: "Date Reported",
        interal_note: "Internal Note",
        date_filed: "Date Filed",
        plaintiff: "Plaintiff",
        ecoa: "ECOA",
        // terms_months: "Term Length",
        // payment_frequency: "Payment Frequency",
        status: "Status",
      };
      let equifax = list.find((a) => a.bureau == "Equifax") || {};
      let transunion = list.find((a) => a.bureau == "TransUnion") || {};
      let experian = list.find((a) => a.bureau == "Experian") || {};
      let items = Object.keys(accountDetails).map((k) => {
        let equifaxVal = this.formatValues(equifax[k] || "", k);
        let transunionVal = this.formatValues(transunion[k] || "", k);
        let experianVal = this.formatValues(experian[k] || "", k);
        return {
          equifax: equifaxVal,
          transunion: transunionVal,
          experian: experianVal,
          account: accountDetails[k],
        };
      });
      if (items.length > 0) {
        items[0].name = name;
      }
      if (this.expanded.indexOf(index) == -1) {
        let statusItem = items[items.length - 1];
        items = items.splice(0, 5);
        items.push(statusItem);
      }
      return items;
    },
    formatValues(val, k) {
      let dateFields = [
        "date",
        "date_filed",
        "date_reported",
        "last_activity",
        "last_payment",
        "date_opened",
      ];
      let amountFields = [
        "amount",
        "payment_amount",
        "balance_owned",
        "high_balance",
        "credit_limit",
        "past_due_amount",
      ];

      if (dateFields.indexOf(k) != -1) {
        if (val) val = formatDateOnly(val);
      }
      if (amountFields.indexOf(k) != -1) {
        if (val) val = formatPrice(val);
      }
      return val;
    },
    removeReportItem(index) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure, Do you want to remove this?",
        onConfirm: async () => {
          try {
            let accounts = [...vm.items];
            accounts.splice(index, 1);

            await updateDoc(doc(colCreditReports, vm.report.id), {
              accounts,
              updated_at: getDate(),
            });
            vm.$emit("onReportUpdate");
          } catch (error) {
            vm.notifyError(error.message);
          }
        },
      });
    },
  },
};
</script>

<style lang="scss">
.credit-report-view {
  tr th {
    &:first-child {
      border-right: 0px !important;
    }
    &:last-child {
      border-right: 0px !important;
    }
  }
  tbody tr:nth-child(even) {
    background: #f5f5f5;
  }
  tr td {
    border-bottom: 0px !important;
    &:last-child {
      border-right: 0px !important;
    }
    &:first-child {
      border-right: 0px !important;
      background: white;
    }
    &:nth-child(2) {
      background: white;
      border-bottom: 0px !important;
    }
    &:nth-child(5) {
      border-right: 0px !important;
    }
    .align-start i {
      margin-left: 13px;
    }
  }
}
</style>
