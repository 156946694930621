<template>
  <div>
    <v-row>
      <v-col cols="4" class="d-flex align-end">
        <input-field
          hide-details
          class="flex-1"
          clearable
          @input="onSearch"
          v-model="form.search"
          :label="isAdvance ? 'Search by name or email' : ''"
          placeholder="Search by name or email"
        />
        <primary-button v-if="!isAdvance" class="ml-4" @click="onSearch()"
          >Search</primary-button
        >
      </v-col>
      <template v-if="isAdvance">
        <v-col cols="2">
          <date-field hide-details v-model="form.from" label="Added From" />
        </v-col>
        <v-col cols="2">
          <date-field hide-details v-model="form.to" label="Added To" />
        </v-col>
        <v-col cols="4"> </v-col>
        <v-col cols="3">
          <autocomplete
            v-model="form.affiliate_id"
            placeholder="All"
            clearable
            :items="affiliates"
            hide-details
            label="Affiliate Name"
          />
        </v-col>
        <v-col cols="3">
          <select-field
            v-model="form.payment_method"
            placeholder="All"
            clearable
            :items="clientPaymentMethods"
            hide-details
            label="Payment Method"
          />
        </v-col>
        <v-col cols="6" class="d-flex align-end mb-3">
          <select-field
            hide-details
            placeholder="All"
            label="Client Type"
            clearable
            v-model="form.client_type"
            :items="filterredTypes"
            style="max-width: 120px"
            class="mr-4"
          />
          <primary-button @click="applyFilter()">Search</primary-button>
          <text-button @click="isAdvance = false">Basic Search</text-button>
          <text-button @click="resetFilter()"> Reset Filter</text-button>
        </v-col>
      </template>
    </v-row>
    <v-btn
      text
      v-if="!isAdvance"
      @click="isAdvance = true"
      class="primary--text px-0 mt-2"
      >Advanced Search</v-btn
    >
  </div>
</template>

<script>
import { clientTypes } from "@/assets/constants/client.constants";
import PrimaryButton from "../ui/buttons/PrimaryButton.vue";
import TextButton from "../ui/buttons/TextButton.vue";
import Autocomplete from "../ui/form/Autocomplete.vue";
import DateField from "../ui/form/DateField.vue";
import InputField from "../ui/form/InputField.vue";
import SelectField from "../ui/form/SelectField.vue";
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isAdvance: false,
      clientTypes,
      form: {},
    };
  },
  components: {
    InputField,
    SelectField,
    DateField,
    PrimaryButton,
    TextButton,
    Autocomplete,
  },
  computed: {
    filterredTypes() {
      let per = {
        MR: "mr",
        PPD: "ppd",
        Both: "mr_ppd",
      };
      return this.clientTypes.filter((i) =>
        this.hasPermission("transaction-log", per[i.value])
      );
    },

    clientPaymentMethods() {
      let paymentMethods = this.$store.getters.clientPaymentMethods;
      return [
        ...paymentMethods,
        {
          name: "Credit Card",
          value: "Credit Card",
        },
      ];
    },
  },
  methods: {
    resetFilter() {
      this.form = {};
      this.$emit("onFilterApply", {});
    },
    onSearch() {
      const vm = this;
      if (vm.isAdvance == true) return;
      if (vm.form.search != null && vm.form.search.length < 3) return;
      this.$emit("onFilterApply", { search: vm.form.search });
      this.updateFilter({
        key: "transactions",
        val: { search: vm.form.search },
      });
    },
    applyFilter() {
      let form = { ...this.form };
      this.$emit("onFilterApply", form);
      this.updateFilter({ key: "transactions", val: form });
    },
  },
  beforeMount() {
    this.form = this.filters.transactions || {};
  },
};
</script>

<style></style>
