<template>
  <v-dialog
    v-model="dialog"
    :max-width="step == 1 ? '450px' : '1000px'"
    persistent
    scrollable
  >
    <dialog-card title="Import Clients from CSV File" @close="closeDialog()">
      <template v-if="step == 1">
        <p>Choose A CSV File to Import your clients</p>
        <div class="d-flex flex-column align-center" v-if="!file">
          <v-icon size="75px" color="grey"> mdi-file-plus </v-icon>
          <v-btn
            outlined
            color="secondary"
            class="my-3"
            @click="$refs.file.click()"
            >Choose File</v-btn
          >
        </div>
        <input
          ref="file"
          @change="onFileChange"
          type="file"
          accept=".csv"
          class="d-none"
        />
        <div class="d-flex align-center" v-if="file">
          <v-icon>mdi-file-document</v-icon>
          <div class="flex-1 ml-3">{{ file.name }}</div>
          <delete-icon-button @click="file = null"></delete-icon-button>
        </div>
        <v-divider v-if="file" class="mb-4"></v-divider>
        <div slot="footer">
          <template v-if="file">
            <close-button @click="closeDialog()" />
            <secondary-button class="ml-4" @click="step = 2"
              >Next</secondary-button
            >
          </template>
        </div>
      </template>
      <template v-else>
        <v-alert outlined type="info" icon="mdi-message-reply-text" prominent>
          Scroll left to right and map the fields you would like to import.
          Next, choose the records to import and click "Import Clients." Records
          highlighted in yellow cannot be imported because they contain a
          duplicate Email ID.
        </v-alert>
        <v-radio-group v-model="status" row>
          <template slot="label">
            <b class="black--text">Import as:</b>
          </template>
          <v-radio label="Import All as Lead" value="lead" />
          <v-radio label="Import All as Active Client" value="client" />
          <v-radio label="Import All as Inactive Client" value="inactive" />
        </v-radio-group>
        <v-card outlined>
          <v-data-table
            hide-default-footer
            class="import-table"
            item-key="id"
            :item-class="getItemClass"
            v-model="selected"
            :items-per-page="-1"
            :headers="headers"
            :loading="previewLoading"
            show-select
            :items="items"
            hide-default-header
          >
            <template v-slot:header="{ props: { headers } }">
              <thead>
                <tr class="">
                  <th v-for="(item, i) in headers" :key="i">
                    <v-simple-checkbox
                      @input="onSelectChange"
                      v-if="item.value == 'data-table-select'"
                      v-model="select"
                    ></v-simple-checkbox>
                    <v-select
                      v-else
                      :label="item.text"
                      outlined
                      :items="headerItems"
                      item-text="text"
                      @change="onHeaderChange(item.value)"
                      clearable
                      item-value="value"
                      v-model="form[item.value]"
                      dense
                      hide-details
                      style="width: 200px"
                    ></v-select>
                  </th>
                </tr>
              </thead>
            </template>
          </v-data-table>
        </v-card>

        <div slot="footer">
          <close-button @click="closeDialog()"></close-button>
          <secondary-button class="ml-4" :loading="loading" @click="submit()"
            >Import Clients</secondary-button
          >
        </div>
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
import {
  getDate,
  getHeaders,
  validateEmail,
} from "@/assets/utils/common.utils";
import CloseButton from "../ui/buttons/CloseButton.vue";
import DeleteIconButton from "../ui/buttons/DeleteIconButton.vue";
import SecondaryButton from "../ui/buttons/SecondaryButton.vue";
import DialogCard from "../ui/DialogCard.vue";
import { csvJSON } from "@/assets/utils/csv.utils";
import { chunk } from "lodash";
import { colClients, db } from "@/assets/utils/firebase.utils";
import { doc, getDocs, query, where, writeBatch } from "firebase/firestore";
import { toTerms } from "@/assets/utils/v2.utils";
export default {
  components: {
    DialogCard,
    DeleteIconButton,
    CloseButton,
    SecondaryButton,
  },
  data() {
    return {
      dialog: false,
      file: null,
      status: "",
      select: false,
      selected: [],
      form: {},
      previewLoading: false,
      step: 1,
      json: [],
      loading: false,
      items: [],
    };
  },
  computed: {
    headerItems() {
      if (!this.json[0]) return [];
      return Object.keys(this.json[0]).map((value) => {
        return { value, text: value };
      });
    },
    getItems() {
      return this.json
        .map((i) => {
          let doc = {};
          Object.keys(this.form).forEach((key) => {
            if (i[this.form[key]] != undefined) {
              doc[key] = i[this.form[key]];
            }
          });
          return doc;
        })
        .filter((i) => Object.keys(i).length != 0);
    },
    headers() {
      let doc = {
        first_name: "First Name",
        middle_name: "Middle Name",
        last_name: "Last Name",
        suffix: "Suffix",
        preferred_name: "Preferred name",
        email: "Email",
        mobile: "Phone (M)",
        birth_date: "Date of Birth",
        ss_number: "SSN",
        street: "Address",
        city: "City",
        state: "State",
        postal_code: "Zip Code",
      };
      return getHeaders(doc);
    },
  },
  methods: {
    onSelectChange() {
      if (this.select) {
        this.selected = this.items.filter((i) => i.isSelectable);
      } else {
        this.selected = [];
      }
    },
    getItemClass(item) {
      console.log(item.isSelectable);
      return item.isSelectable == true ? "" : "yellow";
    },
    async onHeaderChange(key) {
      const vm = this;
      let emails = [];
      let clients = vm.items
        .filter((i) => i.isSelectable == false)
        .map((i) => i.email);
      vm.selected = [];
      vm.items = [];
      let items = vm.getItems
        .filter((i) => {
          if (vm.form.email) {
            let isExists = emails.indexOf(i.email) == -1;
            if (!validateEmail(i.email)) return false;
            if (!isExists) return false;
            emails.push(i.email);
            return true;
          }
          return false;
        })
        .map((i) => {
          if (i.email) i.email.toString().trim().toLowerCase();
          return i;
        });
      emails = items.map((i) => i.email);
      try {
        if (key == "email") {
          vm.previewLoading = true;
          clients = await Promise.all(
            chunk(emails, 10).map(async (chunkIds) => {
              let q = query(colClients, where("email", "in", chunkIds));
              const accounts = await getDocs(q);
              return accounts.docs
                .filter((doc) => doc.exists)
                .map((i) => i.data().email);
            })
          );
          clients = clients.flat(1);
          vm.previewLoading = false;
        }
        console.log(clients);
        vm.items = items.map((i, index) => ({
          ...i,
          id: index,
          isSelectable: clients.indexOf(i.email) == -1,
        }));
      } catch (error) {
        vm.previewLoading = false;
        vm.handleError(error);
      }
    },
    onFileChange(e) {
      const vm = this;
      vm.json = [];
      vm.file = e.target.files[0];
      vm.form = {};
      if (vm.file) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const text = e.target.result;
          vm.json = csvJSON(text);
          if (vm.json.length == 0) {
            vm.file = null;
            vm.notifyError("Record not found in the csv");
          }
          let client = vm.json[0];
          let keys = Object.keys(client || {}).map((i) =>
            i.toString().toLowerCase()
          );
          vm.headers.forEach((h) => {
            let key = h.text.toLowerCase();
            if (keys.indexOf(key) != -1) {
              vm.form[h.value] = key;
            }
          });
          if (vm.form["email"] != undefined) {
            vm.onHeaderChange("email");
          }
          vm.step = 2;
        };
        reader.readAsText(vm.file);
      }
    },
    async submit() {
      const vm = this;
      let { first_name, last_name, email } = vm.form;
      if (!first_name || !last_name || !email) {
        vm.handleError("Please Select First Name, Last Name, Email.");
        return;
      }
      if (vm.selected.length == 0) {
        vm.handleError(
          "You have not selected any records. Please use the checkboxes."
        );
        return;
      }
      if (!vm.status) {
        vm.handleError("Please select status");
        return;
      }
      try {
        vm.loading = true;

        let batch = writeBatch(db);
        vm.selected.forEach((i) => {
          let id = doc(colClients).id;
          let terms = [`${i.first_name || ""} ${i.last_name || ""}`, i.email];
          let createDoc = {
            ...i,
            created_at: getDate(),
            terms: toTerms(terms),
            updated_at: getDate(),
            updated_by: vm.userEmail,
            customer_type: vm.status,
          };
          batch.set(doc(colClients, id), createDoc);
        });
        await batch.commit();
        vm.notifySuccess("Clients are imported Successfully.");
        vm.selected = [];
        vm.step = 1;
        vm.file = null;
        vm.json = [];
        vm.items = [];
        vm.dialog = false;
        vm.loading = false;
        vm.$emit("onUpdate");
      } catch (error) {
        vm.handleError(error);
      }
    },
    closeDialog() {
      const vm = this;
      vm.selected = [];
      vm.step = 1;
      vm.file = null;
      vm.json = [];
      vm.items = [];
      vm.dialog = false;
    },
  },
};
</script>

<style lang="scss">
.import-table {
  thead {
    background: #eaf0fc !important;
  }
  th {
    background: #eaf0fc !important;
    white-space: nowrap;
    font-weight: normal !important;
  }
}
</style>
