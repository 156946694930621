<template>
  <v-app>
    <app-header></app-header>
    <v-main>
      <v-container :class="isFluid ? 'py-0' : ''">
        <router-view></router-view>
      </v-container>
      <confirm-alert></confirm-alert>
      <loader />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import AppHeader from "./components/ui/AppHeader.vue";
import Loader from "./components/ui/loader/Loader.vue";
export default {
  components: { AppHeader, Loader },
  computed: {
    isFluid() {
      let routes = ["my-company", "client-dashboard"];
      let isMatched =
        this.$route.matched.find((i) => routes.indexOf(i.name) != -1) != null;
      return routes.indexOf(this.$route.name) != -1 || isMatched;
    },
  },
  methods: {
    ...mapActions(["listenAuth"]),
  },
  mounted() {
    this.listenAuth();
  },
};
</script>

<style lang="scss">
@import "./assets/scss/abstracts/_variables.scss";
@import "./assets/scss/base/_reset.scss";
@import "./assets/scss/base/_color.scss";
@import "./assets/scss/abstracts/mixins.scss";
@import "./assets/scss/base/_typography.scss";
@import "./assets/scss/components/_input.scss";
@import "./assets/scss/components/_tabs.scss";
@import "./assets/scss/components/_button.scss";
@import "./assets/scss/components/_alert.scss";
@import "./assets/scss/components/_table.scss";
</style>
