<template>
  <v-dialog v-model="dialog" max-width="1000px" scrollable persistent>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text color="primary" v-if="hasEdit('customers')">
        Add/edit Scores
      </v-btn>
    </template>
    <dialog-card
      :title="`Manage Scores - ${tab == 0 ? 'Vantage' : 'Fico'}`"
      :loading="loading"
      @close="dialog = false"
      :isSubmit="false"
    >
      <form-wrapper @submit="saveScore()">
        <div class="d-flex mb-3">
          <v-spacer></v-spacer>
          <add-button @click="addScore()" :disabled="loading"
            >Add Score</add-button
          >
        </div>
        <v-data-table
          hide-default-footer
          :items="sortedList"
          :items-per-page="-1"
          :headers="headerList"
        >
          <template v-slot:header.credit_scores.equifax="{}">
            <EquifaxImg :width="80" class="mt-2" />
          </template>
          <template v-slot:header.credit_scores.experian="{}">
            <ExperianImg :width="80" class="mt-2" />
          </template>
          <template v-slot:header.credit_scores.transunion="{}">
            <TransunionImg :width="80" class="mt-2" />
          </template>
          <template v-slot:item.created_at="{ value, index }">
            <date-field
              v-if="index == editIndex"
              hide-details
              rules="required"
              v-model="form.created_at"
            />
            <template v-else>
              {{ formatDateOnly(value) }}
            </template>
          </template>

          <template v-slot:item.credit_scores.equifax="{ value, index }">
            <input-field
              v-if="index == editIndex"
              hide-details
              rules="required"
              type="number"
              v-model="form.equifax"
            />
            <template v-else>
              {{ value }}
            </template>
          </template>
          <template v-slot:item.credit_scores.experian="{ value, index }">
            <input-field
              v-if="index == editIndex"
              hide-details
              type="number"
              rules="required"
              v-model="form.experian"
            />
            <template v-else>
              {{ value }}
            </template>
          </template>
          <template v-slot:item.credit_scores.transunion="{ value, index }">
            <input-field
              type="number"
              v-if="index == editIndex"
              hide-details
              rules="required"
              v-model="form.transunion"
            />
            <template v-else>
              {{ value }}
            </template>
          </template>

          <template v-slot:item.actions="{ index, item }">
            <div v-if="index == editIndex" class="d-flex">
              <v-btn outlined color="success" type="submit" :loading="loading"
                >Save</v-btn
              >
              <v-btn color="red" @click="close()" text class="ml-3"
                >Close</v-btn
              >
            </div>
            <template v-else>
              <v-menu
                v-if="hasEdit('customers') || hasDelete('customers')"
                :disabled="loading"
              >
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon x-small>
                    <v-icon> mdi-chevron-down </v-icon>
                  </v-btn>
                </template>
                <v-list class="my-0 py-0" dense small>
                  <v-list-item
                    v-if="hasEdit('customers')"
                    @click="editItem(index, item)"
                  >
                    <v-list-item-title>Edit Score</v-list-item-title>
                  </v-list-item>
                  <v-divider v-if="hasEdit('customers')"></v-divider>
                  <v-list-item
                    v-if="hasDelete('customers')"
                    @click="deleteScore(item)"
                  >
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </template>
        </v-data-table>
      </form-wrapper>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { getDate, initForm } from "@/assets/utils/common.utils";
import AddButton from "../../ui/buttons/AddButton.vue";
import DialogCard from "../../ui/DialogCard.vue";
import FormWrapper from "../../ui/form/FormWrapper.vue";
import EquifaxImg from "../EquifaxImg.vue";
import ExperianImg from "../ExperianImg.vue";
import TransunionImg from "../TransunionImg.vue";
import DateField from "../../ui/form/DateField.vue";
import InputField from "../../ui/form/InputField.vue";
import { addDoc, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { colCreditScores } from "@/assets/utils/firebase.utils";
import moment from "moment-timezone";
export default {
  components: {
    DialogCard,
    FormWrapper,
    TransunionImg,
    ExperianImg,
    EquifaxImg,
    AddButton,
    DateField,
    InputField,
  },
  data() {
    return {
      dialog: false,
      list: [],
      editIndex: null,
      loading: false,
      form: {
        created_at: "",
        equifax: "",
        experian: "",
        transunion: "",
      },
    };
  },
  inject: ["headers", "creditScores", "tab", "fetchScores"],
  computed: {
    sortedList() {
      let list = [...this.list].sort((a, b) => {
        return moment(b.created_at).unix() - moment(a.created_at).unix();
      });
      return list;
    },
    headerList() {
      return [
        ...this.headers,
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.list = JSON.parse(JSON.stringify(this.creditScores));
      }
    },
  },
  methods: {
    close() {
      let item = this.list[this.editIndex];
      if (!item.id) {
        this.list.splice(this.editIndex, 1);
      }
      this.editIndex = null;
    },
    editItem(index, item) {
      this.editIndex = index;
      initForm(this.form, {
        ...item,
        ...item.credit_scores,
      });
    },
    addScore() {
      let item = {
        created_at: getDate(),
        equifax: "",
        experian: "",
        transunion: "",
      };
      this.form = item;
      this.list.unshift(item);
      this.editIndex = 0;
    },
    async deleteScore(item) {
      this.$confirm.show({
        message: "Are you sure, Do you want to remove this?",
        onConfirm: async () => {
          await deleteDoc(doc(colCreditScores, item.id));
          await this.fetchScores();
          this.list = JSON.parse(JSON.stringify(this.creditScores));
        },
      });
    },
    async saveScore() {
      try {
        let item = this.list[this.editIndex];
        let credit_scores = { ...this.form };
        delete credit_scores.created_at;
        let data = {
          created_at: this.form.created_at,
          credit_scores,
          updated_at: getDate(),
          updated_by: this.userEmail,
        };
        this.loading = true;
        if (item.id) {
          await updateDoc(doc(colCreditScores, item.id), data);
        } else {
          data.type = this.tab == 0 ? "vantage" : "fico";
          data.customer = this.$route.params.id;
          await addDoc(colCreditScores, data);
        }
        await this.fetchScores();
        this.list = JSON.parse(JSON.stringify(this.creditScores));

        this.editIndex = null;
        this.loading = false;
      } catch (error) {
        console.error("Manage client score", error.message);
        this.handleError(error);
      }
    },
  },
};
</script>

<style></style>
