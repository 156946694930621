<template>
  <layout :title="`Transaction History ( ${client.name} )`">
    <client-transaction-filter v-model="form" />
    <static-table-view
      :headers="headers"
      ref="table"
      :list="list"
      :loading="loading"
    >
      <template v-slot:item.amount="{ value }">
        {{ formatPrice(value) }}
      </template>
      <template v-slot:item.created_date="{ item }">
        {{ item.created_at }}
      </template>
      <template v-slot:item.affiliate_id="{ value, item }">
        <div class="d-flex">
          {{ getAffiliateName(value) }}
          <client-other-affiliates v-if="value" :item="item" />
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <edit-transaction
          @onUpdate="fetchTransactions(options)"
          :item="item"
        ></edit-transaction>
        <delete-icon-button
          @click="removeTransaction(item)"
          v-if="isRemovable(item)"
        ></delete-icon-button>
      </template>
    </static-table-view>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import { getHeaders, getStartEndDate } from "@/assets/utils/common.utils";
import { colTransactions } from "@/assets/utils/firebase.utils";
import {
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import ClientOtherAffiliates from "@/components/clients/ClientOtherAffiliates.vue";
import DeleteIconButton from "@/components/ui/buttons/DeleteIconButton.vue";
import EditTransaction from "@/components/transactions/EditTransaction.vue";
import ClientTransactionFilter from "@/components/clients/ClientTransactionFilter.vue";
import StaticTableView from "@/components/ui/StaticTableView.vue";
import { mapDocs } from "@/assets/utils/doc.utils";
import moment from "moment-timezone";
export default {
  components: {
    Layout,
    ClientOtherAffiliates,
    DeleteIconButton,
    EditTransaction,
    ClientTransactionFilter,
    StaticTableView,
  },
  data() {
    return {
      form: {},
      transactions: [],
      loading: false,
    };
  },
  computed: {
    client() {
      return { ...this.currentClient };
    },
    list() {
      let list = [...this.transactions];
      list = list.map((i) => {
        i.affiliate = this.getAffiliateName(i.affiliate_id);
        i.name = this.client.name;
        i.email = this.client.email;
        return i;
      });
      let { start, end } = getStartEndDate(this.form);
      let { affiliate_id, payment_method, client_type } = this.form;
      if (start && end) {
        list = list.filter((i) => {
          let date = moment(i.created_at);
          return date.isAfter(moment(start)) && date.isBefore(moment(end));
        });
      }
      if (affiliate_id)
        list = list.filter((i) => i.affiliate_id == affiliate_id);
      if (payment_method)
        list = list.filter((i) => i.payment_method == payment_method);
      if (client_type) list = list.filter((i) => i.client_type == client_type);
      return list;
    },
    headers() {
      let doc = {
        created_date: "Date",
        reference_no: "Reference Number",
        amount: "Amount",
        client_type: "Transaction Type",
        type: "Type",
        payment_method: "Payment Method",
        affiliate_id: "Affiliate Name",
        actions: "Actions",
      };
      return getHeaders(doc);
    },
  },
  watch: {
    form() {
      this.$refs.table.search = this.form.search;
    },
  },
  methods: {
    removeTransaction({ id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure you want to delete this transaction?",
        onConfirm: () => {
          // eslint-disable-next-line no-async-promise-executor
          return new Promise(async (resolve, reject) => {
            try {
              await deleteDoc(doc(colTransactions, id));
              vm.notifySuccess("Transaction has been deleted successfully.");
              vm.fetchTransactions(vm.options);
              resolve();
            } catch (error) {
              reject(error);
            }
          });
        },
      });
    },
    isRemovable({ payment_type }) {
      return payment_type == "MPM";
    },
    query() {
      let queryList = [];
      queryList.push(orderBy("created_at", "desc"));
      queryList.push(where("customer_id", "==", this.client.id));
      return query(colTransactions, ...queryList);
    },
    async fetchTransactions() {
      const vm = this;
      try {
        vm.loading = true;
        vm.transactions = [];
        vm.transactions = mapDocs((await getDocs(vm.query())).docs).map((i) => {
          return {
            ...i,
            type: i.type?.startsWith("CC:Sale") ? "Sale" : "Refund",
          };
        });
        vm.loading = false;
      } catch (error) {
        console.log(error);
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchTransactions();
  },
};
</script>
<style></style>
