<template>
  <v-container class="d-flex align-center justify-center fluid fill-height">
    <v-card width="380" outlined flat class="pa-2 mt-10">
      <v-card-title class="headline font-weight-bold"> Login </v-card-title>
      <form-wrapper ref="form" @submit="submit">
        <v-card-text class="pt-0">
          <input-field
            v-model="email"
            @keydown.enter="onEnter()"
            rules="email"
            type="email"
            label="Email"
          >
          </input-field>
          <input-field
            v-model="password"
            rules="required"
            @keydown.enter="onEnter()"
            :type="show ? 'text' : 'password'"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @append="show = !show"
            label="Password"
          >
          </input-field>
          <div class="d-flex flex-row-reverse">
            <router-link to="/forgot-password"> Forgot Password? </router-link>
            <!-- <v-btn text small color="primary" @click="$router.push('/forgot')">
            </v-btn> -->
          </div>
        </v-card-text>
        <v-card-actions class="pa-3">
          <primary-button ref="button" type="submit" :loading="loading" block
            >LOGIN</primary-button
          >
        </v-card-actions>
      </form-wrapper>
    </v-card>
  </v-container>
</template>

<script>
import FormWrapper from "../../components/ui/form/FormWrapper.vue";
import InputField from "../../components/ui/form/InputField.vue";
import PrimaryButton from "../../components/ui/buttons/PrimaryButton.vue";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "@/assets/utils/firebase.utils.js";
export default {
  components: { InputField, FormWrapper, PrimaryButton },
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      show: false,
    };
  },
  methods: {
    submit() {
      const vm = this;
      if (vm.loading) return;
      vm.loading = true;
      signInWithEmailAndPassword(auth, vm.email, vm.password)
        .then((user) => {
          vm.$store.commit("setUID", user.user.uid);
          vm.email = "";
          vm.password = "";
          vm.loading = false;
        })
        .catch((error) => {
          let { code, message } = error;
          let msg = message;
          switch (code) {
            case "auth/wrong-password":
              msg =
                "Invalid Email or Password. Please enter valid Email and Password.";
              break;
            case "auth/user-not-found":
              msg =
                "Invalid Email or Password. Please enter valid Email and Password.";
              break;
            case "auth/user-disabled":
              msg =
                "Your account has been disabled. Please contact the Administrator.";
              break;
          }
          vm.loading = false;
          vm.notifyError(msg);
        });
    },
    onEnter() {
      this.$refs.button.$el.click();
    },
  },
};
</script>
<style></style>
