<template>
  <v-menu v-model="menu">
    <template v-slot:activator="{ on }">
      <v-btn class="ml-1" v-on="on" icon x-small>
        <v-icon>
          {{ menu ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
      </v-btn>
    </template>
    <v-list class="my-0 py-0" dense small>
      <dispute-letter-rename
        @onNameChange="
          $emit('fetchFaxes');
          menu = false;
        "
        @close="menu = false"
        v-if="hasPermission('send-faxes', 'edit')"
        :item="item"
      />
      <v-list-item
        @click="openDraft(item)"
        v-if="isDraft(item) && hasPermission('send-faxes', 'edit')"
        >Continue Editing
      </v-list-item>
      <v-list-item
        @click="editResend(true, 'print')"
        v-if="isPrint(item) && isSendFaxAdmin"
        >Print
      </v-list-item>
      <v-list-item
        @click="editResend(true, 'TU')"
        v-if="isPrint(item) && isSendFaxAdmin && item.status == 'DRAFT'"
        >Upload TU
      </v-list-item>
      <v-list-item
        @click="editResend(true, 'EFX')"
        v-if="isPrint(item) && isSendFaxAdmin && item.status == 'DRAFT'"
        >Upload EFX
      </v-list-item>
      <v-list-item
        @click="editResend(false)"
        v-if="
          !isDraft(item) &&
          !isSending(item) &&
          hasPermission('send-faxes', 'edit')
        "
        >Edit & Resend
      </v-list-item>
      <v-list-item @click="openDl(item)" v-if="isDL(item) && !isSendFaxAdmin"
        >Preview DL SSN</v-list-item
      >
      <v-list-item @click="downloadPDF(true)" v-if="isPreview(item)"
        >Preview</v-list-item
      >
      <v-list-item @click="downloadPDF(false)" v-if="isPreview(item)"
        >Download PDF</v-list-item
      >
      <v-list-item
        v-if="hasPermission('send-faxes', 'delete')"
        @click="deleteFax()"
        >Delete</v-list-item
      >
    </v-list>
  </v-menu>
</template>

<script>
import axios from "axios";
import DisputeLetterRename from "./DisputeLetterRename.vue";
import { httpsCallable } from "firebase/functions";
import { colFaxes, functions } from "@/assets/utils/firebase.utils";
import { deleteDoc, doc } from "firebase/firestore";
import { isDLFile } from "@/assets/utils/common.utils";
export default {
  components: { DisputeLetterRename },
  props: {
    item: Object,
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    isDraft({ status }) {
      return status == "DRAFT";
    },
    isPrint({ status, last_step, template }) {
      if (status == "DRAFT" && last_step == 2 && template) return true;
      return status != "DRAFT" && this.hasEdit("send-faxes");
    },
    isDL({ files }) {
      let dl = files.find((i) => isDLFile(i));
      return dl != null;
    },
    openDl({ files }) {
      let dl = files.find((i) => isDLFile(i));
      if (dl) {
        window.open(dl.url, "_blank");
      }
    },
    isPreview({ status }) {
      return ["DRAFT", "SENDING"].indexOf(status) == -1 && this.isSendFaxAdmin;
    },
    isSending({ status }) {
      return status == "SENDING";
    },
    openDraft({ id }) {
      this.$router.push(`/edit-send-letter/${id}`);
    },
    async downloadPDF(isPreview) {
      const vm = this;
      try {
        vm.$emit("showLoader");
        let url = vm.item.fax_pdf_url;
        if (url) {
          if (isPreview) {
            window.open(url, "_blank");
            vm.$emit("hideLoader");
          } else {
            let res = (await axios.get(url, { responseType: "blob" })).data;
            const fileName = `${vm.item.fax_title}.pdf`;
            var blobUrl = window.URL.createObjectURL(res);
            var a = document.createElement("a");
            a.href = blobUrl;
            a.download = fileName;
            a.click();
            a.remove();
            setTimeout(() => window.URL.revokeObjectURL(blobUrl), 100);
            vm.$emit("hideLoader");
          }
          return;
        }
        let downloadPDF = httpsCallable(functions, "downloadEfaxPdfAction");
        let { status, message, image } = (await downloadPDF({ id: vm.item.id }))
          .data;
        vm.$emit("hideLoader");

        if (!status) {
          vm.notifyError(message);
        } else {
          if (isPreview) {
            let blob = vm.base64toBlob(image);
            var fileURL = URL.createObjectURL(blob);
            window.open(fileURL);
          } else {
            const linkSource = `data:application/pdf;base64,${image}`;
            const downloadLink = document.createElement("a");
            const fileName = `${vm.item.fax_title}.pdf`;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
          }
        }
      } catch (error) {
        vm.$emit("hideLoader");
        vm.notifyError(error.message);
      }
    },
    base64toBlob(base64Data) {
      var sliceSize = 1024;
      var byteCharacters = atob(base64Data);
      var bytesLength = byteCharacters.length;
      var slicesCount = Math.ceil(bytesLength / sliceSize);
      var byteArrays = new Array(slicesCount);

      for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: "application/pdf;base64" });
    },
    async editResend(is_print, type) {
      const vm = this;
      if (is_print && vm.item.status == "DRAFT") {
        vm.printDraft(type);
        return;
      }
      vm.$router.push({
        name: "copy-dispute-letter",
        params: { id: vm.item.id },
        query: {
          print: is_print,
        },
      });
    },
    async printDraft(type) {
      const vm = this;
      try {
        vm.$emit("showLoader");
        let sendFax = httpsCallable(functions, "sendEfaxAction");
        let reqDoc = { id: vm.item.id, is_print: true, type };
        let { status, message, pdf_url } = (await sendFax(reqDoc)).data;
        if (!status) {
          vm.notifyError(message);
        } else {
          window.open(pdf_url, "_blank");
        }
        vm.$emit("hideLoader");
        vm.$emit("fetchFaxes");
      } catch (error) {
        vm.$emit("hideLoader");
        vm.handleError(error);
      }
    },
    async deleteFax() {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure you want to Delete this letter?",
        onConfirm: async (dialogVM) => {
          try {
            await deleteDoc(doc(colFaxes, vm.item.id));
            vm.$emit("fetchFaxes");
            dialogVM.loading = false;
            dialogVM.dialog = false;
          } catch (error) {
            dialogVM.loading = false;
            dialogVM.dialog = false;
            vm.notifyError(error.message);
          }
        },
      });
    },
  },
};
</script>

<style></style>
