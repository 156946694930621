import { documentId, getDocs, query, where } from "firebase/firestore";
import rulesConstants from "../constants/rules.constants";
import { colClients } from "./firebase.utils";
const moment = require("moment-timezone");
import _ from "lodash";
import { mapDocs } from "./doc.utils";
import Vue from "vue";

export const inputDense = true;

export const formatDate = (date) => {
  if (!date) return "";
  return moment(date).format("MM/DD/YYYY hh:mm A");
};
export const startDate = (date) => {
  return moment(date).startOf("day").format("YYYY-MM-DDTHH:mm:ss");
};
export const endDate = (date) => {
  return moment(date).endOf("day").format("YYYY-MM-DDTHH:mm:ss");
};
export const formatDateOnly = (date) => {
  return moment(date).format("MM/DD/YYYY");
};
export const getDate = (date) => {
  if (!date) date = new Date();
  return moment(date).format("YYYY-MM-DDTHH:mm:ss");
};
export const getDateOnly = () => {
  return moment().format("YYYY-MM-DD");
};
export const getRawDate = (date) => {
  return moment(date);
};
export const getStartEndDate = ({ from, to }) => {
  let start,
    end = "";
  if (from && to) {
    start = startDate(from);
    end = endDate(to);
  } else if (from) {
    start = startDate(from);
    end = endDate(from);
  } else if (to) {
    start = startDate(to);
    end = endDate(to);
  }
  return { start, end };
};
export const getSearchArray = (txt) => {
  const c = txt.toLowerCase();
  var array = [];
  for (let i = 3; i < c.length + 1; i++) {
    array.push(c.substring(0, i));
  }
  return array;
};
export function getRules(rules) {
  if (!rules) return [];
  rules = rules.split("|");
  let list = [];
  rules.forEach((rule) => {
    if (rulesConstants[rule]) list.push(rulesConstants[rule]);
  });
  return list;
}
export function formatPrice(n) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(n);
}
export function formatMiles(n) {
  if (!n) return "0";
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function getDoc(form, doc) {
  form = form || {};
  Object.keys(form).forEach((key) => {
    if (form[key] != undefined) doc[key] = form[key];
  });
  return doc;
}
export function getSnapDoc(snap) {
  return { ...(snap.data() || {}), id: snap.id };
}
export function initForm(form, doc) {
  doc = doc || {};
  Object.keys(form).forEach((key) => {
    if (doc[key] != undefined) {
      form[key] = doc[key];
    }
  });
}
export function formatUserLetter(message) {
  let div = document.createElement("div");
  div.innerHTML = message;
  let list = div.querySelectorAll("p");
  if (list.length == 1) {
    let p = div.querySelector("p");
    if (p) {
      p = p.innerHTML;
      p = p.split('data-id="account_numbers"')[1];
      p = `<label data-id="account_numbers"${p}`;
      return p;
    }
  } else {
    let html = "";
    let account;

    for (let p of list) {
      p = p.innerHTML;
      if (!account) {
        account = p.split('data-id="account_numbers"')[1];
      }
      if (account) {
        html += p;
      }
    }
    return html;
  }
  return "";
}
export const dlFileIDs = ["2XIxhOwu8cCIXPfctqyD", "ZBmLwL4RizuzbhSUYoeh"];
export function isDLFile({ name, fileId }) {
  if (!name) return false;
  name = name.toLowerCase();
  let dlDocument = dlFileIDs.find((i) =>
    (fileId || "").toString().startsWith(`${i}.`)
  );
  if (dlDocument != null) return true;
  return name.indexOf("dl") != -1 && name.indexOf("ssn") != -1;
}

export const sortUnspecified = (list) => {
  let unspecifiedList = list
    .filter((item) => item.status == "Unspecified")
    .map((i) => {
      i.account_number = i.date_filed || "";
      if (i.account_number) i.account_number = formatDateOnly(i.account_number);
      return i;
    });
  let otherList = list
    .filter((item) => item.status != "Unspecified")
    .map((item) => {
      return item;
    });
  list = [...unspecifiedList, ...otherList];
  return list;
};

export const getHeaders = (doc) => {
  return Object.keys(doc).map((value) => ({ value, text: doc[value] }));
};

export const validateEmail = (email) => {
  const emailRe = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  return emailRe.test(email);
};

export function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
export function getRandom(length) {
  return Math.floor(
    Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1)
  );
}
export function sleep(sec) {
  return new Promise((r) => {
    setTimeout(() => {
      r();
    }, sec || 1000);
  });
}

export function formatFax({ message, fields }) {
  fields = fields || {};
  fields.ss_number = getSSN(fields);
  if (fields.birth_date)
    fields.birth_date = moment(fields.birth_date).format("MM/DD/YYYY");
  let doc = document.createElement("div");
  doc.innerHTML = message;
  const list = doc.querySelectorAll("label");
  list.forEach((item) => {
    let id = item.getAttribute("data-id");
    if (id) id = id.toString().trim();
    let val = "";
    if (fields[id] != undefined) {
      val = fields[id];
    }
    item.innerHTML = val;
    item.setAttribute("contenteditable", true);
    item.classList.remove("tag-item");
  });
  return doc.innerHTML;
}
export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}
const getSSN = (fields) => {
  let ssn = "";
  if (isValidSSN(fields.ss_number)) {
    return fields.ss_number;
  }
  if (!fields.memo) return "";
  try {
    let memo = fields.memo.toLowerCase();
    ssn = memo.split("ssn ");
    if (memo.indexOf("ssn: ") != -1) {
      ssn = memo.split("ssn: ");
    }
    ssn = ssn[ssn.length - 1].split(" ")[0];
    return isValidSSN(ssn) ? ssn : "";
  } catch (error) {
    ssn = "";
  }
  return ssn;
};

function isValidSSN(ssn) {
  const ssnPattern = /^\d{3}-\d{2}-\d{4}$/;
  return ssnPattern.test(ssn);
}

export const readHTML = (file) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsText(file);
    reader.onload = function (e) {
      resolve(e.target.result);
    };
    reader.onerror = function (e) {
      console.error("Error reading file:", e);
      reject(e);
    };
  });
};

export const findMimeType = (name) => {
  const parts = name.split(".");
  let ext = parts[parts.length - 1];
  let json = require("../constants/mime-db.json");
  let type = Object.keys(json).find((key) => {
    return json[key].extensions.indexOf(ext) != -1;
  });
  return type;
};

export const getTaskCustomers = (docs, customers) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      let ids = docs.map((i) => i.data().client).filter((i) => i);
      ids = Array.from(new Set(ids)).filter(
        (id) => customers.find((c) => c.id == id) == null
      );
      let requests = _.chunk(ids, 10).map((items) =>
        getDocs(query(colClients, where(documentId(), "in", items)))
      );
      let results = await Promise.all(requests);
      results.forEach((i) => {
        customers = customers.concat(mapDocs(i.docs));
      });
      resolve(customers);
    } catch (error) {
      reject(error);
    }
  });
};
export function fetchClientByIds(ids) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      let requests = _.chunk(ids, 10).map((items) => {
        let listQuery = query(colClients, where(documentId(), "in", items));
        return getDocs(listQuery);
      });
      let responses = await Promise.all(requests);
      let list = [];
      responses.forEach((i) => {
        let docs = i.docs.filter((i) => i.data() != null);
        list = list.concat(mapDocs(docs));
      });
      list = ids
        .map((id) => {
          let item = list.find((i) => i.id == id);
          return item;
        })
        .filter((i) => i != null);
      resolve(list);
    } catch (error) {
      reject(error);
    }
  });
}

export const eventBus = new Vue();
