<template>
  <layout :title="`Referral Payout ( ${currentClient.name} )`">
    <v-tabs class="tab-outlined" hide-slider v-model="tab">
      <v-tab class="text-none" v-if="clientAffilate.length > 1"
        >All Affiliates</v-tab
      >
      <v-tab class="text-none" v-if="client.affiliate_id">{{
        getAffiliateName(client.affiliate_id)
      }}</v-tab>
      <v-tab class="text-none" v-if="client.affiliate_id_2">{{
        getAffiliateName(client.affiliate_id_2)
      }}</v-tab>
      <v-tab class="text-none" v-if="client.affiliate_id_3">{{
        getAffiliateName(client.affiliate_id_3)
      }}</v-tab>
    </v-tabs>
    <v-divider></v-divider>

    <div class="d-flex my-3">
      <div style="width: 300px" v-if="tab == 0 && clientAffilate.length > 1">
        Affiliate(s) <br />
        <b>{{ clientAffilate.toString().split(",").join(", ") }}</b>
      </div>
      <div style="width: 300px">
        Total Commission Amount <br />
        <b>{{ formatPrice(total) }}</b>
      </div>
      <div style="width: 300px">
        Total Unpaid <br />
        <b>{{ formatPrice(unpaid) }}</b>
      </div>
    </div>
    <div class="d-flex my-3">
      <div style="width: 300px">
        Total Transaction Amount <br />
        <b>{{ formatPrice(totalTransaction) }}</b>
      </div>
      <div style="width: 300px">
        Total Paid <br />
        <b>{{ formatPrice(paid) }}</b>
      </div>
    </div>
    <static-table-view
      :list="list"
      :loading="loading"
      :headers="headers"
      :show-select="hasEdit('affiliates')"
      v-model="selected"
    >
      <div class="d-flex flex-1" slot="left">
        <client-payout
          :selected="selected"
          :disabled="selected.length == 0"
          @onPayout="init()"
        />
      </div>
      <template v-slot:item.affiliate_id="{ value }">
        {{ getAffiliateName(value) }}</template
      >
      <template v-slot:item.amount="{ value }">
        {{ formatPrice(value || 0) }}</template
      >
      <template v-slot:item.commission="{ value }">
        {{ formatPrice(value || 0) }}</template
      >
      <template v-slot:item.commission_rate="{ value }"> {{ value }}%</template>
      <template v-slot:item.paid_at="{ value }">
        {{ formatDate(value) }}</template
      >
    </static-table-view>
  </layout>
</template>

<script>
import { getHeaders } from "@/assets/utils/common.utils";
import { colCommissions } from "@/assets/utils/firebase.utils";
import Layout from "@/components/ui/Layout.vue";
import StaticTableView from "@/components/ui/StaticTableView.vue";
import { getDocs, orderBy, query, where } from "firebase/firestore";
import { mapDocs } from "@/assets/utils/doc.utils";
import ClientPayout from "@/components/clients/ClientPayout.vue";
export default {
  components: { Layout, StaticTableView, ClientPayout },
  data() {
    return {
      tab: 0,
      transactions: [],
      loading: false,
      selected: [],
    };
  },
  computed: {
    clientAffilate() {
      let { affiliate_id, affiliate_id_2, affiliate_id_3 } = this.client;
      let list = [];
      if (affiliate_id) list.push(this.getAffiliateName(affiliate_id));
      if (affiliate_id_2) list.push(this.getAffiliateName(affiliate_id_2));
      if (affiliate_id_3) list.push(this.getAffiliateName(affiliate_id_3));
      return list;
    },
    client() {
      return { ...this.currentClient };
    },
    headers() {
      let doc = {
        created_at: "Date",
        reference_no: "Reference No",
        amount: "Transaction Amount",
        commission: "Comission Amount",
        commission_rate: "Commission %",
        affiliate_id: "Affiliate",
        paid_at: "Payout Date",
        payout_payment_method: "Payment Method",
      };
      return getHeaders(doc);
    },
    list() {
      let list = this.transactions;
      switch (this.tab) {
        case 1:
          return list.filter((i) => i.affiliate_id == this.client.affiliate_id);
        case 2:
          return list.filter(
            (i) => i.affiliate_id == this.client.affiliate_id_2
          );
        case 3:
          return list.filter(
            (i) => i.affiliate_id == this.client.affiliate_id_3
          );
        default:
          return list;
      }
    },
    total() {
      return this.list.reduce((a, i) => a + parseFloat(i.commission), 0);
    },
    totalTransaction() {
      return this.list.reduce((a, i) => a + parseFloat(i.amount), 0);
    },
    paid() {
      return this.list
        .filter((i) => i.payout == true)
        .reduce((a, i) => a + parseFloat(i.commission), 0);
    },
    unpaid() {
      return this.list
        .filter((i) => i.payout == false)
        .reduce((a, i) => a + parseFloat(i.commission), 0);
    },
  },
  methods: {
    async init() {
      const vm = this;
      try {
        vm.loading = true;
        vm.selected = [];
        let q = query(
          colCommissions,
          orderBy("created_at", "desc"),
          where("customer_id", "==", vm.client.id)
        );
        vm.transactions = mapDocs((await getDocs(q)).docs).map((i) => {
          return { ...i, isSelectable: i.payout == false };
        });

        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
