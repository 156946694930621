import { isAuth } from "@/assets/utils/auth.utils";
import Affiliates from "@/pages/affiliates/Affiliates.vue";
import AddAffiliate from "@/pages/affiliates/AddAffiliate.vue";
import AffiliateTransactionHistory from "@/pages/affiliates/AffiliateTransactionHistory.vue";

export default [
  {
    name: "affiliates",
    path: "/affiliates",
    component: Affiliates,
    beforeEnter: isAuth,
    meta: {
      permission: "view",
      way: "affiliates",
    },
  },
  {
    name: "add-affiliate",
    path: "/add-affiliate",
    component: AddAffiliate,
    beforeEnter: isAuth,
    meta: {
      permission: "edit",
      way: "affiliates",
    },
  },
  {
    name: "update-affiliate",
    path: "/update-affiliate/:id",
    component: AddAffiliate,
    beforeEnter: isAuth,
    meta: {
      permission: "edit",
      way: "affiliates",
    },
  },
  {
    name: "affiliate-transaction",
    path: "/affiliate-transaction/:id",
    component: AffiliateTransactionHistory,
    beforeEnter: isAuth,
    meta: {
      permission: "view",
      way: "affiliates",
    },
  },
];
