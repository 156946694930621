<template>
  <v-menu v-model="menu" offset-y max-width="180px">
    <template v-slot:activator="{ on }">
      <div v-on="on" class="color-picker-btn">
        <div class="color-col" :style="getStyle(input)"></div>
      </div>
    </template>
    <v-card>
      <v-row class="ma-0">
        <v-col v-for="(item, i) in colors" :key="i" cols="3" class="pa-2">
          <div
            class="color-col"
            :style="getStyle(item)"
            @click="selectColor(item)"
          ></div>
        </v-col>
        <v-col cols="6">
          <v-btn
            small
            outlined
            block
            color="green"
            @click="selectColor(defaultColor)"
            >none</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      menu: false,
      defaultColor: "#336abc",
      input: "#336abc",
      colors: [
        "#786e6e",
        "#b0484e",
        "#bd4a7b",
        "#8f499a",
        "#6c5aa8",
        "#51599e",
        "#6182c8",
        "#4891a8",
        "#569289",
        "#9a9a5c",
        "#879953",
        "#a69646",
        "#c9a629",
        "#cb863d",
        "#bd785b",
        "#957170",
        "#7b657d",
        "#50637b",
        "#7383a5",
        "#59817b",
        "#757561",
        "#8f7b5f",
      ],
    };
  },
  methods: {
    getStyle(input) {
      return {
        backgroundColor: input,
      };
    },
    selectColor(val) {
      this.input = val;
      this.$emit("input", val);
    },
  },
  mounted() {
    if (this.value) {
      this.input = this.value;
    }
  },
};
</script>

<style lang="scss">
.color-picker-btn {
  height: 40px;
  padding: 4px;
  width: 40px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 5px;
}
.color-col {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
}
</style>
