<template>
  <layout :title="`My Saved Reports (${currentClient.name})`" ref="paste">
    <v-card
      v-if="hasEdit('customers')"
      flat
      class="pa-5 mb-3"
      :class="isDragged ? 'green lighten-3' : 'grey lighten-3'"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        multiple
        name="fields[assetsFieldHandle][]"
        id="assetsFieldHandle"
        class="w-px h-px opacity-0 overflow-hidden absolute d-none"
        @change="onFileChange"
        ref="file"
      />

      <label for="assetsFieldHandle" class="block cursor-pointer">
        <div class="d-flex align-center flex-column">
          <div style="width: 60px; height: 60px" class="text-center">
            <v-progress-circular
              indeterminate
              color="grey"
              size="40"
              v-if="isUploadLoading"
            ></v-progress-circular>

            <v-icon v-else size="60px"> mdi-cloud-upload </v-icon>
          </div>
          <div class="text-center">Drag & Drop or Browse</div>
        </div>
      </label>
    </v-card>
    <static-table-view :list="items" :loading="loading" :headers="headers">
      <template v-slot:item.report_type="{ value }">
        {{ value || "SmartCredit" }}
      </template>
      <template v-slot:item.created_at="{ value }">
        {{ formatDate(value) }}
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-end">
          <v-btn class="ml-3" text color="primary" @click="openUrl(item)"
            >Preview</v-btn
          >
        </div>
      </template>
    </static-table-view>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import StaticTableView from "@/components/ui/StaticTableView.vue";
import { getHeaders, readHTML } from "@/assets/utils/common.utils";
import { doc, getDocs, query, setDoc, where } from "firebase/firestore";
import {
  colCreditReports,
  colSavedCreditReports,
  storage,
} from "@/assets/utils/firebase.utils";
import moment from "moment-timezone";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
export default {
  components: { Layout, StaticTableView },
  data() {
    return {
      items: [],
      loading: true,
      isUploadLoading: false,
      reportItem: {},
      isDragged: false,
    };
  },
  computed: {
    headers() {
      let doc = {
        created_at: "Added Date",
        report_type: "Provider name",
        action: "Action",
      };
      return getHeaders(doc).map((i) => ({
        ...i,
        width: i.value == "action" ? "100px" : "",
      }));
    },
  },
  methods: {
    uploadFile(item) {
      this.reportItem = item;
      this.$refs.file.click();
    },
    openUrl(item) {
      window.open(item.report_pdf, "_blank");
    },
    dragover(event) {
      event.preventDefault();
      if (this.isUploadLoading) return;
      this.isDragged = true;
    },
    dragleave() {
      if (this.isUploadLoading) return;
      this.isDragged = false;
    },
    drop(event) {
      event.preventDefault();
      if (this.isUploadLoading) return;
      this.$refs.file.files = event.dataTransfer.files;
      this.onFileChange();

      this.isDragged = false;
    },
    async fetchReports() {
      try {
        this.loading = true;
        let reportQuery = query(
          colCreditReports,
          where("customer", "==", this.$route.params.id)
        );
        let savedReportQuery = query(
          colSavedCreditReports,
          where("customer", "==", this.$route.params.id)
        );
        let reports = (await getDocs(reportQuery)).docs.map((i) => ({
          ...i.data(),
          id: i.id,
        }));
        let savedReports = (await getDocs(savedReportQuery)).docs.map((i) => ({
          ...i.data(),
          id: i.id,
          is_saved: true,
        }));
        reports = reports.filter(
          (i) => Object.keys(i.credit_scores || {}).length == 3
        );
        this.items = [...reports, ...savedReports].sort(
          (a, b) => moment(b.created_at).unix() - moment(a.created_at).unix()
        );
        this.loading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
    async onFileChange() {
      let files = this.$refs.file.files;
      let list = Array.from(files);
      list = list.filter((i) => {
        if (i.name.indexOf("_") == -1) return false;
        let date = i.name.split("_")[1].split(".")[0].split("(")[0].trim();
        return date && date.length == 6 && moment(date, "MMDDYY").isValid();
      });
      let filterFileList = {};
      for (let item of list) {
        if (item.type == "text/html") {
          let html = await readHTML(item);
          let dom = document.createElement("div");
          dom.innerHTML = html;
          let td = dom.querySelector("#reportTop td:nth-child(2)");
          if (td && td.textContent) {
            let dateString = item.name
              .split("_")[1]
              .split(".")[0]
              .split("(")[0]
              .trim();
            console.log(`${td.textContent.trim()} ${dateString}`);
            filterFileList[`${td.textContent.trim()} ${dateString}`] = item;
          }
        }
      }
      let reports = [];
      for (let key of Object.keys(filterFileList)) {
        let file = filterFileList[key];
        let item = this.items.find((i) => i.reference_id == key);
        if (item == null) {
          reports.push({ file, reference_id: key });
        }
      }
      try {
        this.isUploadLoading = true;
        let requests = reports.map(({ file, reference_id }) => {
          let dateString = file.name
            .split("_")[1]
            .split(".")[0]
            .split("(")[0]
            .trim();

          let created_at = moment(dateString, "MMDDYY").format(
            "YYYY-MM-DDTHH:mm:ss"
          );
          return this.uploadReport({
            file,
            created_at,
            reference_id,
          });
        });
        await Promise.all(requests);
        this.isUploadLoading = false;
        this.fetchReports();
      } catch (error) {
        this.isUploadLoading = false;
        this.fetchReports();
        this.handleError(error);
      }
      this.$refs.file.value = null;
    },
    uploadReport({ file, created_at, reference_id }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const parts = file.name.split(".");
          let ext = parts[parts.length - 1];
          let customerId = this.$route.params.id;
          let reportId = doc(colSavedCreditReports).id;
          let storageRef = ref(
            storage,
            `customers/${customerId}/saved-reports/${reportId}.${ext}`
          );
          await uploadBytes(storageRef, file);
          let report_pdf = await getDownloadURL(storageRef);
          await setDoc(doc(colSavedCreditReports, reportId), {
            report_pdf,
            created_at,
            customer: customerId,
            report_type: "SmartCredit",
            reference_id,
          });
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
  },

  mounted() {
    this.fetchReports();
  },
};
</script>

<style></style>
