/* eslint-disable no-useless-escape */
const emailRegex =
  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
const discoverRegex =
  /^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])\d{12}$/;
export default {
  required: (v) => !!v || "Field is required",
  requiredBoolean: (v) => v !== "" || "Field is required",
  select: (v) => v.length > 0 || "Field is required",
  email: (v) => !v || emailRegex.test(v) || "E-mail must be valid",
  phone: (v) => {
    return !v || v.length == 14 || "Please enter a valid US Phone number";
  },
  restrictDiscover: (v) => {
    return !discoverRegex.test(v) || "Card type can't be Discover";
  },
  cc: (v) => {
    return !v || v.length == 19 || "Please enter a valid card number";
  },
  ssn: (v) => {
    return !v || v.length == 11 || "Please enter a valid SSN number";
  },
  zip: (v) => !v || v.length == 5 || "Please enter a valid zip code",
  cvv: (value) => {
    const regex = /^[0-9]{3,4}$/;
    return regex.test(value) || "Please enter a valid security code";
  },
  commission_rate: (v) =>
    (v <= 100 && v >= 0) || "Percentage should be between 0 to 100",
  commission: (v) => v < 1 || "Commission rate must be less then 100%",
};
