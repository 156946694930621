<template>
  <v-btn
    @click.stop
    v-bind="$attrs"
    height="40px"
    icon
    :color="color"
    @click="$emit('click')"
  >
    <v-icon>mdi-delete</v-icon>
  </v-btn>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    color: {
      type: String,
      default: "red",
    },
  },
};
</script>

<style></style>
