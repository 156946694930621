<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-mask="dateMask"
        v-model="formattedDate"
        label="Date"
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable
      @input="updateDate"
    ></v-date-picker>
  </v-menu>
</template>
<script>
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
export default {
  data() {
    return {
      dateMask: [
        /\d/,
        /\d/,
        "/",
        /\d/,
        /\d/,
        "/",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        createAutoCorrectedDatePipe("mm/dd/yyyy"),
      ],
      menu: false,
      date: null,
      formattedDate: "",
    };
  },
  watch: {
    date(val) {
      this.formattedDate = this.formatDate(val);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    updateDate(val) {
      this.date = val;
      this.menu = false;
    },
  },
};
</script>

<style></style>
