<template>
  <v-menu v-model="menu" v-if="hasEdit('customers') || hasDelete('customers')">
    <template v-slot:activator="{ on }">
      <v-btn class="ml-1" v-on="on" icon x-small>
        <v-icon>
          {{ menu ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
      </v-btn>
    </template>
    <v-list class="my-0 py-0" dense small>
      <v-list-item
        :to="{ name: 'update-client', params: { id: item.id } }"
        v-if="hasEdit('customers')"
      >
        <v-list-item-title>Edit</v-list-item-title>
      </v-list-item>
      <v-divider v-if="hasEdit('customers')"></v-divider>
      <delete-client
        @onUpdate="$emit('onUpdate')"
        v-if="hasDelete('customers')"
        :item="item"
      ></delete-client>
    </v-list>
  </v-menu>
</template>

<script>
import DeleteClient from "./DeleteClient.vue";
export default {
  components: { DeleteClient },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      menu: false,
    };
  },
};
</script>

<style>
</style>