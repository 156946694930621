<template>
  <input-field
    v-model="input"
    full-width
    hide-details
    clearable
    :placeholder="placeholder"
    @clear="$emit('clear')"
    @enter="$emit('onEnter')"
    @input="$emit('input', input)"
    prepend-inner-icon="mdi-magnify"
    class="flex-1 search"
  ></input-field>
</template>

<script>
import InputField from "./InputField.vue";
export default {
  components: { InputField },
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Search",
    },
  },
  data() {
    return {
      input: "",
    };
  },
  watch: {
    value(val) {
      this.input = val;
    },
  },
  mounted() {
    this.input = this.value;
  },
};
</script>

<style></style>
