<template>
  <ValidationProvider :rules="rules" v-slot="{ errors }" mode="passive">
    <div>
      <label class="body-small" v-if="label">{{ label }}</label>
      <v-select
        v-model="input"
        :items="items"
        :dense="dense"
        item-value="value"
        :label="inlineLabel"
        :class="label ? 'mt-1' : ''"
        :item-text="itemText"
        outlined
        append-icon="mdi-chevron-down"
        @input="$emit('input', input)"
        @blur="$emit('blur')"
        v-bind="$attrs"
        :error-messages="errors"
      >
        <template v-slot:selection="{ item, index }" v-if="isMultiple">
          <template v-if="isObject(item)">
            <template v-if="index === 0">{{ item.name }}</template>
            <span v-if="index === 1" class="grey--text caption">
              (+{{ value.length - 1 }} others)
            </span>
          </template>
          <template v-else>
            <template v-if="index === 0">{{ item }}</template>
            <span v-if="index === 1" class="grey--text caption">
              (+{{ value.length - 1 }} others)
            </span>
          </template>
        </template>
        <div slot="append" class="d-flex">
          <v-icon> mdi-chevron-down</v-icon>
          <v-tooltip bottom v-if="help">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="" x-small>
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ help }}</span>
          </v-tooltip>
        </div>
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
          ><slot :name="slot" v-bind="scope" />
        </template>
      </v-select>
    </div>
  </ValidationProvider>
</template>

<script>
import { getRules, inputDense } from "../../../assets/utils/common.utils.js";
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    itemText: {
      type: String,
      default: "name",
    },
    value: {
      type: [String, Array, Number, Boolean],
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: inputDense,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: String,
      default: "",
    },
    customRules: {
      type: Array,
      default: () => [],
    },
    inlineLabel: String,
    help: String,
  },
  data() {
    return {
      input: "",
    };
  },
  computed: {
    getRules() {
      let { customRules, rules } = this;
      if (customRules.length > 0) return customRules;
      return getRules(rules);
    },
    isMultiple() {
      return this.input instanceof Array;
    },
  },
  watch: {
    value(val) {
      this.input = val;
    },
  },
  methods: {
    isObject(item) {
      return item instanceof Object;
    },
  },
  mounted() {
    this.input = this.value;
  },
};
</script>

<style></style>
