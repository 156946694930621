<template>
  <v-dialog v-model="dialog" max-width="1000px" scrollable>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" height="40px" width="40px" color="primary">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text class="pa-0">
        <vue-html2pdf
          :show-layout="true"
          :float-layout="false"
          :enable-download="true"
          :preview-modal="false"
          :pdf-quality="2"
          :manual-pagination="true"
          pdf-format="a4"
          @hasDownloaded="loading = false"
          pdf-orientation="landscape"
          :filename="filename"
          pdf-content-width="100%"
          ref="html2Pdf"
        >
          <section slot="pdf-content">
            <table class="client-table--print">
              <tbody>
                <tr>
                  <th v-for="(item, i) in formattedHeaders" :key="i">
                    {{ item.text }}
                  </th>
                </tr>
                <tr v-for="item in items" :key="item.id">
                  <td v-for="(header, i) in formattedHeaders" :key="i">
                    <client-status
                      v-if="header.value == 'customer_type'"
                      :type="item.customer_type"
                    />
                    <span v-else-if="header.value == 'affiliate_id'">
                      {{ getAffiliateName(item[header.value]) }}</span
                    >

                    <template v-else-if="header.value == 'contract_status'">
                      <v-icon
                        :style="{
                          color: item[header.value] ? '#4CAF50' : '#F44336',
                        }"
                      >
                        {{
                          item[header.value]
                            ? "mdi-check-circle"
                            : "mdi-close-circle"
                        }}
                      </v-icon>
                    </template>
                    <span v-else>
                      {{ item[header.value] }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </vue-html2pdf>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <close-button class="mr-4" @click="dialog = false"></close-button>
        <secondary-button
          @click="generateReport()"
          :loading="loading"
          width="100px"
          >Print</secondary-button
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getDate } from "@/assets/utils/common.utils";
import VueHtml2pdf from "vue-html2pdf";
import CloseButton from "../ui/buttons/CloseButton.vue";
import SecondaryButton from "../ui/buttons/SecondaryButton.vue";
import ClientStatus from "./ClientStatus.vue";
export default {
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    VueHtml2pdf,
    SecondaryButton,
    CloseButton,
    ClientStatus,
  },
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  computed: {
    filename() {
      return `Clients ${getDate()}`;
    },
    formattedHeaders() {
      return this.headers.filter((i) => {
        return i.value != "actions";
      });
    },
  },
  methods: {
    generateReport() {
      this.loading = true;
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style lang="scss">
.client-table--print {
  border-collapse: collapse !important;
  width: 100% !important;
  page-break-after: auto;
  th {
    background: #eaf0fc !important;
    color: black !important;
    text-align: left !important;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  th,
  td {
    page-break-inside: avoid;
    page-break-after: auto;
    border-bottom: 1px solid lightgrey !important;
    padding: 8px 16px !important;
    border-collapse: collapse;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem !important;
    letter-spacing: 0.0071428571em;
    font-family: "Inter", sans-serif;
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>
