<template>
  <div>
    <div class="text-center mt-10" v-if="isLoading">
      <v-progress-circular
        color="primary"
        indeterminate
        class="mb-0"
      ></v-progress-circular>
    </div>
    <v-container v-else style="padding-bottom: 200px">
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      clientId: this.$route.params.id,
    };
  },
  beforeDestroy() {
    this.$store.dispatch("resetClient");
  },
  beforeMount() {
    this.$store.dispatch("resetClient");
  },
  mounted() {
    this.$store
      .dispatch("listenClient", this.clientId)
      .then((snap) => {
        let per = {
          MR: "mr",
          PPD: "ppd",
          Both: "mr_ppd",
        };
        let type = per[snap.client_type];
        let permission = this.user.permissions.customers;
        if (type && permission[type] != true) {
          this.notifyError("Permission denied");
          this.$router.replace("/");
        } else {
          this.isLoading = false;
        }
      })
      .catch((error) => {
        this.isLoading = false;
        this.$router.replace("/");
        this.handleError(error);
      });
  },
};
</script>

<style></style>
