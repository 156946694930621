<template>
  <v-dialog v-model="dialog" width="820px" persistent scrollable>
    <vue-html2pdf
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div :class="isPrint ? 'v-application' : ''">
          <v-card ref="print" style="padding: 16px">
            <div class="d-flex">
              <div class="title">
                Re-import Summary: Results Since Your Last Import
              </div>
              <v-spacer></v-spacer>
              <v-btn v-if="!isPrint" icon color="red" @click="dialog = false">
                <v-icon>mdi-close</v-icon></v-btn
              >
            </div>
            <div class="subtitle-1">Client Name: {{ customerName }}</div>
            <please-wait class="py-5" v-if="loading"></please-wait>
            <template v-else>
              <v-card-text class="pa-0">
                <v-card outlined class="mt-3">
                  <div
                    class="d-flex pa-2 px-4 align-center primary white--text"
                  >
                    <div class="title">Your Credit Scores</div>
                    <v-spacer></v-spacer>
                    ({{ date }})
                  </div>
                  <div class="d-flex py-4">
                    <div
                      class="d-flex justify-center align-center flex-column flex-grow-1"
                    >
                      <equifax-img></equifax-img>
                      <JSCharting
                        style="height: 250px"
                        :options="getChartOptions('equifax')"
                      ></JSCharting>
                      <div
                        class="d-flex align-center"
                        v-html="getDifferent('equifax')"
                      ></div>
                    </div>
                    <div
                      cols="4"
                      class="d-flex justify-center align-center flex-column flex-grow-1"
                    >
                      <transunion-img></transunion-img>
                      <JSCharting
                        style="height: 250px"
                        :options="getChartOptions('transunion')"
                      ></JSCharting>
                      <div
                        class="d-flex align-center"
                        v-html="getDifferent('transunion')"
                      ></div>
                    </div>
                    <div
                      cols="4"
                      class="d-flex justify-center align-center flex-column flex-grow-1"
                    >
                      <experian-img></experian-img>
                      <JSCharting
                        style="height: 250px"
                        :options="getChartOptions('experian')"
                      ></JSCharting>
                      <div
                        class="d-flex align-center"
                        v-html="getDifferent('experian')"
                      ></div>
                    </div>
                  </div>
                </v-card>
                <v-card outlined class="mt-3" v-if="report.created_at">
                  <div
                    class="d-flex pa-2 px-4 align-center primary white--text"
                  >
                    <div class="title">Credit Score Factor Updates</div>
                    <v-spacer></v-spacer>
                    ({{ date }})
                  </div>
                  <div class="d-flex py-4">
                    <div
                      class="d-flex justify-center align-center flex-column flex-grow-1"
                    >
                      <div
                        class="text-h1 font-weight-medium"
                        :class="deletes == 0 ? 'grey--text' : 'success--text'"
                      >
                        {{ deletes }}
                      </div>
                      <h3 class="font-weight-medium">Deleted</h3>
                    </div>
                    <div
                      cols="3"
                      class="d-flex justify-center align-center flex-column flex-grow-1"
                    >
                      <div class="text-h1 font-weight-medium grey--text">
                        {{ updatedToPositive }}
                      </div>
                      <h3 class="font-weight-medium">Updated to Positive</h3>
                    </div>
                    <div
                      cols="3"
                      class="d-flex justify-center align-center flex-column flex-grow-1"
                    >
                      <div
                        class="text-h1 font-weight-medium"
                        :class="
                          updatedToNegative == 0 ? 'grey--text' : 'red--text'
                        "
                      >
                        {{ updatedToNegative }}
                      </div>
                      <h3 class="font-weight-medium">Updated to Negative</h3>
                    </div>
                    <div
                      cols="3"
                      class="d-flex justify-center align-center flex-column flex-grow-1"
                    >
                      <div class="text-h1 font-weight-medium grey--text">
                        {{ added }}
                      </div>
                      <h3 class="font-weight-medium">Added</h3>
                    </div>
                  </div>
                </v-card>
                <v-card
                  class="pa-4 my-3 subtitle-1 text-center"
                  outlined
                  v-if="!isChanged"
                >
                  There have been no change in the accounts since the last
                  import on ({{ date }})
                </v-card>
                <v-card outlined class="mt-3" v-else>
                  <div
                    class="d-flex pa-2 px-4 align-center primary white--text"
                  >
                    <div class="title">Summary of Changes</div>
                    <v-spacer></v-spacer>
                    ({{ date }})
                  </div>
                  <div class="pa-4">
                    <template v-for="(item, i) in report.accounts">
                      <v-card
                        outlined
                        :key="i"
                        class="mb-4"
                        v-if="getItems(item).length != 0"
                      >
                        <div class="subtitle-1 pa-2">
                          <v-chip
                            label
                            color="primary"
                            small
                            v-if="isNewData(item)"
                            >New Item</v-chip
                          >
                          {{ item.name }}
                        </div>
                        <v-divider></v-divider>
                        <v-data-table
                          :headers="headers"
                          hide-default-header
                          :items="getItems(item)"
                          class="affiliate-table"
                          hide-default-footer
                        >
                          <template v-slot:header>
                            <thead>
                              <tr class="grey lighten-3">
                                <th width="25%" class="text-uppercase">
                                  Bureau
                                </th>
                                <th width="25%">
                                  <equifax-img />
                                </th>
                                <th width="25%">
                                  <transunion-img />
                                </th>
                                <th width="25%">
                                  <experian-img />
                                </th>
                              </tr>
                            </thead>
                          </template>
                          <template v-slot:item.equifax="{ value }">
                            <credit-report-status
                              :status="value"
                            ></credit-report-status>
                          </template>
                          <template v-slot:item.transunion="{ value }">
                            <credit-report-status
                              :status="value"
                            ></credit-report-status>
                          </template>
                          <template v-slot:item.experian="{ value }">
                            <credit-report-status
                              :status="value"
                            ></credit-report-status>
                          </template>
                        </v-data-table>
                      </v-card>
                    </template>
                  </div>
                </v-card>
              </v-card-text>

              <v-card-actions v-if="!isPrint">
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  large
                  color="primary"
                  class="mr-3"
                  @click="printDiv()"
                  >Download As PDF</v-btn
                >
                <v-btn
                  color="success"
                  class="px-3"
                  large
                  depressed
                  :to="{ name: 'credit-report' }"
                  >Go to Credit Report</v-btn
                >
              </v-card-actions>
            </template>
          </v-card>
        </div>
      </section>
    </vue-html2pdf>
  </v-dialog>
</template>

<script>
import JSCharting from "jscharting-vue";
import VueHtml2pdf from "vue-html2pdf";
import { formatDateOnly } from "@/assets/utils/common.utils";
import { doc, getDoc } from "firebase/firestore";
import { colCreditReports } from "@/assets/utils/firebase.utils";
import PleaseWait from "@/components/ui/PleaseWait.vue";
import EquifaxImg from "../EquifaxImg.vue";
import TransunionImg from "../TransunionImg.vue";
import ExperianImg from "../ExperianImg.vue";
import CreditReportStatus from "./CreditReportStatus.vue";
export default {
  props: {
    customer: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    JSCharting,
    VueHtml2pdf,
    PleaseWait,
    EquifaxImg,
    TransunionImg,
    ExperianImg,
    CreditReportStatus,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      date: "",
      isLoading: false,
      isPrint: false,
      previous_report: {},
      report: {
        credit_reports: {},
      },
    };
  },
  computed: {
    customerName() {
      if (!this.customer.first_name) return "";
      return `${this.customer.first_name} ${this.customer.last_name}`;
    },

    isChanged() {
      return (
        this.deletes != 0 ||
        this.added != 0 ||
        this.updatedToNegative != 0 ||
        this.updatedToPositive != 0
      );
    },
    deletes() {
      if (!this.report.accounts) return 0;
      let count = 0;
      this.report.accounts.forEach((item) => {
        item.list.forEach((i) => {
          if (i.status == "Deleted") count = count + 1;
        });
      });
      return count;
    },
    updatedToNegative() {
      if (!this.report.accounts) return 0;
      let count = 0;
      let currentAccounts = [];
      let previousAccounts = [];
      this.report.accounts.forEach(
        (i) => (currentAccounts = [...currentAccounts, ...i.list])
      );
      this.previous_report.accounts.forEach(
        (i) => (previousAccounts = [...previousAccounts, ...i.list])
      );
      previousAccounts.forEach((p) => {
        let current = currentAccounts.find(
          (a) => a.account_number == p.account_number && p.bureau == a.bureau
        );
        if (current && p.status != "Negative" && current.status == "Negative") {
          count += 1;
        }
      });
      return count;
    },
    updatedToPositive() {
      if (!this.report.accounts) return 0;
      let count = 0;
      let currentAccounts = [];
      let previousAccounts = [];
      this.report.accounts.forEach(
        (i) => (currentAccounts = [...currentAccounts, ...i.list])
      );
      this.previous_report.accounts.forEach(
        (i) => (previousAccounts = [...previousAccounts, ...i.list])
      );
      previousAccounts.forEach((p) => {
        let current = currentAccounts.find(
          (a) => a.account_number == p.account_number && p.bureau == a.bureau
        );
        if (current && p.status != "Positive" && current.status == "Positive") {
          count += 1;
        }
      });
      return count;
    },
    added() {
      if (!this.report.accounts) return 0;
      let currentAccounts = [];
      let previousAccounts = [];
      this.report.accounts.forEach(
        (i) => (currentAccounts = [...currentAccounts, ...i.list])
      );
      this.previous_report.accounts.forEach(
        (i) => (previousAccounts = [...previousAccounts, ...i.list])
      );
      let length = currentAccounts.length - previousAccounts.length;
      return length < 0 ? 0 : length;
    },
    headers() {
      let doc = {
        bureau: "Bureau",
        equifax: "equifax",
        transunion: "transunion",
        experian: "experian",
      };
      return Object.keys(doc).map((value) => ({
        value,
        text: doc[value],
        divider: true,
      }));
    },
  },
  methods: {
    gotoReport() {
      this.$emit("gotoReport");
      this.dialog = false;
    },
    async printDiv() {
      this.isPrint = true;
      await this.$refs.html2Pdf.generatePdf();
      this.isPrint = false;
    },
    getItems(item) {
      let previousReport = this.previous_report;
      let index = null;
      item.list.forEach((account) => {
        if (index == null || index == -1) {
          // index = previousReport.accounts.findIndex((currentItem) => {
          //   return (
          //     currentItem.list.find(
          //       (a) =>
          //         a.account_number == account.account_number &&
          //         account.bureau == a.bureau
          //     ) != null && currentItem.name == item.name
          //   );
          // });

          if (item.type == "tradeline") {
            index = previousReport.accounts.findIndex((currentItem) => {
              return (
                currentItem.list.find(
                  (a) =>
                    a.account_number == account.account_number &&
                    a.account_name == account.account_name &&
                    account.bureau == a.bureau
                ) != null && currentItem.type == "tradeline"
              );
            });
          } else {
            index = previousReport.accounts.findIndex((currentItem) => {
              return (
                currentItem.list.find(
                  (a) =>
                    a.account_name == account.account_name &&
                    account.bureau == a.bureau
                ) != null && currentItem.type != "tradeline"
              );
            });
          }
        }
      });
      let previousItem = { list: [] };
      if (index != -1 && index != null) {
        previousItem = previousReport.accounts[index];
      }
      let equifax = item.list.find((a) => a.bureau == "Equifax") || {};
      let transunion = item.list.find((a) => a.bureau == "TransUnion") || {};
      let experian = item.list.find((a) => a.bureau == "Experian") || {};
      let previous_equifax =
        previousItem.list.find((a) => a.bureau == "Equifax") || {};
      let previous_transunion =
        previousItem.list.find((a) => a.bureau == "TransUnion") || {};
      let previous_experian =
        previousItem.list.find((a) => a.bureau == "Experian") || {};
      if (
        // previous_equifax.account_number != undefined &&
        previous_equifax.status == equifax.status
      ) {
        previous_equifax = {};
        equifax = {};
      }
      if (
        // previous_transunion.account_number != undefined &&
        previous_transunion.status == transunion.status
      ) {
        previous_transunion = {};
        transunion = {};
      }
      if (
        // previous_experian.account_number != undefined &&
        previous_experian.status == experian.status
      ) {
        previous_experian = {};
        experian = {};
      }

      let account = {
        bureau: "Account #",
        equifax: equifax.account_number || "",
        transunion: transunion.account_number || "",
        experian: experian.account_number || "",
      };
      let previousAccount = {
        bureau: "Status on Previous Report",
        equifax: previous_equifax.status || "",
        transunion: previous_transunion.status || "",
        experian: previous_experian.status || "",
      };
      let newAccount = {
        bureau: "Status on New Report",
        equifax: equifax.status || "",
        transunion: transunion.status || "",
        experian: experian.status || "",
      };
      if (
        Object.keys(equifax).length == 0 &&
        Object.keys(transunion).length == 0 &&
        Object.keys(experian).length == 0
      ) {
        return [];
      }
      return [account, previousAccount, newAccount];
    },
    getDifferent(key) {
      if (this.previous_report.credit_scores == undefined) return 0;
      let previous = this.previous_report.credit_scores[key] || 0;
      let current = this.report.credit_scores[key] || 0;
      let val = Number(current) - Number(previous);
      let style = val >= 0 ? "success--text" : "red--text";
      let icon = val >= 0 ? "mdi-arrow-up" : "mdi-arrow-down";
      return `<span class="${style}">${val}</span><i class='v-icon notranslate mdi ${icon} theme--light ${style}' style="font-size:20px" ></i>`;
    },
    isNewData(item) {
      let isNewItem = false;
      let currentAccounts = item.list;
      let previousAccounts = [];
      this.previous_report.accounts.forEach(
        (i) => (previousAccounts = [...previousAccounts, ...i.list])
      );
      currentAccounts.forEach((p) => {
        let account = previousAccounts.find(
          (a) => a.account_number == p.account_number && p.bureau == a.bureau
        );
        if (account == null) isNewItem = true;
      });
      return isNewItem;
    },
    async init(item) {
      const vm = this;
      vm.dialog = true;
      try {
        vm.loading = true;
        let previousReport = (
          await getDoc(doc(colCreditReports, item.previous_report))
        ).data();
        vm.loading = false;
        vm.previous_report = previousReport;
        vm.report = item;
        vm.dialog = true;
        vm.date = formatDateOnly(vm.report.date);
      } catch (error) {
        vm.loading = false;
        vm.notifyError(error.message);
      }
    },
    getChartOptions(type) {
      if (!this.report.created_at) return {};
      let credit = this.report.credit_scores[type] || 0;
      let creditText = "Excellent";
      if (credit <= 780) creditText = "Good";
      if (credit <= 719) creditText = "Fair";
      if (credit <= 657) creditText = "Poor";
      if (credit <= 600) creditText = "Very Poor";

      return {
        debug: true,
        width: 250,
        type: "gauge ",
        legend_visible: false,
        chartArea_boxVisible: false,
        xAxis: {
          /*Used to position marker on top of axis line.*/
          scale: { range: [0, 1], invert: true },
        },
        palette: {
          pointValue: "%yValue",
          ranges: [
            { value: 350, color: "#FF5353" },
            { value: 600, color: "#FFD221" },
            { value: 700, color: "#77E6B4" },
            { value: [800, 850], color: "#21D683" },
          ],
        },
        yAxis: {
          defaultTick: { padding: 13, enabled: false },
          customTicks: [600, 700, 800],
          line: {
            width: 8,
            breaks_gap: 0.03,
            color: "smartPalette",
          },
          scale: { range: [350, 850] },
        },
        defaultSeries: {
          opacity: 1,
          shape: {
            label: {
              align: "center",
              verticalAlign: "middle",
            },
          },
        },
        series: [
          {
            type: "marker",
            name: "Score",
            shape_label: {
              text: `${credit}<br/> <span style='fontSize: 18'>${creditText}</span>`,
              style: { fontSize: 35 },
            },
            defaultPoint: {
              tooltip: "%yValue",
              marker: {
                outline: {
                  width: 10,
                  color: "currentColor",
                },
                type: "circle",
                visible: true,
                size: 15,
              },
            },
            points: [[1, Number(credit)]],
          },
        ],
      };
    },
  },
};
</script>

<style></style>
