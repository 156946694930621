<template>
  <div>
    <div class="title mb-3">Scores</div>
    <div class="px-5">
      <v-tabs class="tab-outlined" hide-slider v-model="tab">
        <v-tab>
          <img
            src="@/assets/images/vantage-score.png"
            width="120px"
            style="object-fit: contain"
          />
        </v-tab>
        <v-tab>
          <img
            src="@/assets/images/fico.png"
            width="60px"
            style="object-fit: contain"
          />
        </v-tab>
      </v-tabs>
    </div>
    <v-row>
      <v-col cols="12" xl="8">
        <v-card outlined style="overflow: hidden">
          <v-data-table
            fixed-header
            height="235px"
            class="scores-table"
            :headers="headers"
            :loading="loading"
            hide-default-footer
            :items="items"
            :items-per-page="-1"
          >
            <template v-slot:header.credit_scores.equifax="{}">
              <EquifaxImg :width="60" class="mt-2" />
            </template>
            <template v-slot:header.credit_scores.experian="{}">
              <ExperianImg :width="60" class="mt-2" />
            </template>
            <template v-slot:header.credit_scores.transunion="{}">
              <TransunionImg :width="60" class="mt-2" />
            </template>
            <template v-slot:item.created_at="{ value }">
              {{ formatDateOnly(value) }}
            </template>
            <template v-slot:footer>
              <table style="width: 100%" class="scores-table--footer">
                <tbody>
                  <tr>
                    <td class="pa-2 subtitle-2 w-40">Score Increase</td>
                    <td
                      class="pa-2 w-20"
                      v-for="key in ['equifax', 'experian', 'transunion']"
                      :key="key"
                    >
                      <div
                        v-if="items.length"
                        :class="
                          getDiff(key) >= 0 ? 'success--text' : 'red--text'
                        "
                        class="d-flex align-center"
                      >
                        <template v-if="getDiff(key) >= 0">+</template
                        >{{ getDiff(key) }}
                        <v-icon v-if="getDiff(key) >= 0" color="success"
                          >mdi-arrow-up-bold</v-icon
                        >
                        <v-icon v-else color="error"
                          >mdi-arrow-down-bold</v-icon
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </v-data-table>
        </v-card>
        <div class="d-flex">
          <v-spacer></v-spacer>
          <manage-client-scores />
        </div>
      </v-col>
      <v-col cols="12" xl="4">
        <v-card flat style="background: #f2f2f2">
          <apexchart
            type="bar"
            height="268"
            :options="chartOptions"
            :series="series"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EquifaxImg from "@/components/clients/EquifaxImg.vue";
import ExperianImg from "@/components/clients/ExperianImg.vue";
import TransunionImg from "@/components/clients/TransunionImg.vue";
import { formatDateOnly, getHeaders } from "@/assets/utils/common.utils";
import { getDocs, orderBy, query, where } from "firebase/firestore";
import {
  colCreditReports,
  colCreditScores,
} from "@/assets/utils/firebase.utils";
import moment from "moment-timezone";
import _ from "lodash";
import ManageClientScores from "./ManageClientScores.vue";
import { computed } from "vue";
export default {
  components: {
    EquifaxImg,
    ExperianImg,
    TransunionImg,
    ManageClientScores,
  },
  data() {
    return { smartcredit: [], loading: false, tab: 0, commonScores: [] };
  },
  provide() {
    return {
      items: computed(() => this.items),
      headers: computed(() => this.headers),
      tab: computed(() => this.tab),
      creditScores: computed(() => this.creditScores),
      fetchScores: this.fetchScores,
    };
  },
  computed: {
    creditScores() {
      let type = this.tab == 0 ? "vantage" : "fico";
      let list = this.commonScores.filter((i) => {
        return i.type == type;
      });
      return list;
    },
    series() {
      let data = this.items.map((item) => {
        let val = ["equifax", "experian", "transunion"].reduce((t, key) => {
          let v = _.get(item, `credit_scores.${key}`, 0);
          t = parseInt(t) + parseInt(v);
          return t;
        }, 0);
        return parseInt(val / 3);
      });
      return [
        {
          name: "Score",
          data,
        },
      ];
    },

    chartOptions() {
      let categories = this.items.map((i) => formatDateOnly(i.created_at));
      return {
        chart: {
          toolbar: {
            show: false,
          },
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: categories,
          type: "datetime",
          // position: "top",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
          },
          min: 0,
          max: 800,
        },
        colors: ["#00A650"],
      };
    },
    headers() {
      let doc = {
        created_at: "Date",
        "credit_scores.equifax": "",
        "credit_scores.experian": "",
        "credit_scores.transunion": "",
      };
      return getHeaders(doc).map((i) => ({
        ...i,
        class: `subtitle-2 font-weight-medium ${
          i.value == "date" ? "w-40" : "w-20"
        }`,
      }));
    },
    items() {
      let list = [];
      if (this.tab == 0) list = list.concat(this.smartcredit);

      list = [...list, ...this.creditScores].sort((a, b) => {
        return moment(b.created_at).unix() - moment(a.created_at).unix();
      });
      return list;
    },
  },
  methods: {
    getDiff(key) {
      if (this.items.length == 0) return 0;
      let start = _.get(this.items[0], `credit_scores.${key}`, 0);
      let end = _.get(
        this.items[this.items.length - 1],
        `credit_scores.${key}`,
        0
      );
      return start - end;
    },
    async fetchScores() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          this.loading = true;
          let customerId = this.$route.params.id;
          let commonQuery = query(
            colCreditScores,
            // orderBy("created_at", "desc"),
            where("customer", "==", customerId)
          );
          let smartcreditQuery = query(
            colCreditReports,
            orderBy("created_at", "desc"),
            where("customer", "==", customerId)
          );
          let smartcredit = (await getDocs(smartcreditQuery)).docs.map((i) => ({
            ...i.data(),
            id: i.id,
          }));
          this.commonScores = (await getDocs(commonQuery)).docs.map((i) => ({
            ...i.data(),
            id: i.id,
          }));
          this.smartcredit = smartcredit.filter(
            (i) => Object.keys(i.credit_scores || {}).length == 3
          );
          this.loading = false;
          resolve();
        } catch (error) {
          console.log("Error while fetching the profile scores");
          reject(error);
          this.handleError(error);
        }
      });
    },
  },
  mounted() {
    this.fetchScores();
  },
};
</script>

<style lang="scss">
.scores-table {
  .v-data-table-header th {
    background: $input-bg !important;
  }
  &--footer {
    border-top: 1px solid #0000001f !important;
  }
  .w-20 {
    width: 25%;
  }
  .w-40 {
    width: 25%;
  }
}
</style>
