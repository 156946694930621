<template>
  <img
    src="@/assets/images/transunion.png"
    style="object-fit: contain"
    :width="width"
  />
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 100,
    },
  },
};
</script>

<style></style>
