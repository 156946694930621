<template>
  <form-wrapper ref="form" @submit="$emit('submit')">
    <v-card class="pa-2">
      <v-card-title class="pb-5 pt-3">
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          color="red"
          @click="$emit('close')"
          :disabled="loading"
          small
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text class="pb-0">
        <slot></slot>
      </v-card-text>
      <v-card-actions class="mr-2 pb-4">
        <v-spacer></v-spacer>
        <slot name="footer">
          <v-btn
            text
            :disabled="loading"
            color="primary"
            @click="$emit('close')"
            >Cancel</v-btn
          >
          <secondary-button
            width="90px"
            :loading="loading"
            type="submit"
            v-if="isSubmit"
            >Submit</secondary-button
          >
        </slot>
      </v-card-actions>
    </v-card>
  </form-wrapper>
</template>

<script>
import SecondaryButton from "./buttons/SecondaryButton.vue";
import FormWrapper from "./form/FormWrapper.vue";
export default {
  components: { FormWrapper, SecondaryButton },
  props: {
    isSubmit: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {},
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style></style>
