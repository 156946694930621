<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <template v-if="isEdit">
          <v-btn icon v-if="isReadOnly"> <v-icon>mdi-eye</v-icon></v-btn>
          <edit-icon-button v-else></edit-icon-button>
        </template>
        <add-button v-else> Add New </add-button>
      </span>
    </template>
    <dialog-card
      :title="title"
      @submit="handleSubmit"
      ref="dialog"
      @close="handleClose"
      :submitText="'Add'"
      :is-submit="!isReadOnly"
      :loading="loading"
      v-if="dialog"
    >
      <please-wait v-if="isLoading" />
      <v-row class="mt-0" v-else>
        <v-col cols="12" class="py-0">
          <select-field
            v-model="formData.payment_method"
            :items="clientPaymentTypes"
            :rules="'required'"
            :readonly="isReadOnly"
            label="Payment Method"
            @input="updateFields()"
          ></select-field>
        </v-col>
        <template v-if="formData.payment_method === 'Credit Card'">
          <v-col cols="12" class="py-0">
            <input-field
              :readonly="isReadOnly"
              v-model="formData.card_number"
              rules="required|cc|restrictDiscover"
              @keydown="onCCInput"
              label="Card Number"
              type="number"
            ></input-field>
          </v-col>
          <v-col cols="6" class="py-0">
            <date-field
              :readonly="isReadOnly"
              v-model="formData.exp_date"
              :rules="'required'"
              :min="new Date().toISOString().substr(0, 10)"
              type="month"
              label="Exp Date"
            ></date-field>
          </v-col>
          <v-col cols="6" class="py-0">
            <input-field
              :readonly="isReadOnly"
              v-model="formData.cvv"
              rules="required"
              label="Security Code"
              type="number"
            ></input-field>
          </v-col>
          <v-col
            cols="6"
            class="py-0"
            v-if="['MR', 'PPD'].indexOf(currentClient.client_type) == -1"
          >
            <select-field
              :readonly="isReadOnly"
              v-model="formData.cardknox_type"
              rules="required"
              :items="['MR', 'PPD']"
              label="Cardknox Type"
            ></select-field>
          </v-col>
        </template>
        <template v-if="formData.payment_method === 'Apple Pay'">
          <v-col cols="12" class="py-0">
            <input-field
              :readonly="isReadOnly"
              v-model="formData.email"
              :rules="'required|email'"
              label="Email"
            ></input-field>
          </v-col>
        </template>
        <template
          v-if="
            formData.payment_method === 'Cash App' ||
            formData.payment_method === 'Venmo'
          "
        >
          <v-col cols="12" class="py-0">
            <input-field
              v-model="formData.username"
              :rules="'required'"
              label="Username"
            ></input-field>
          </v-col>
        </template>
        <template v-if="formData.payment_method === 'Zelle'">
          <v-col cols="12" class="py-0">
            <phone-field
              v-model="formData.phone_number"
              :rules="'required'"
              label="Phone Number"
            ></phone-field>
          </v-col>
        </template>
      </v-row>
    </dialog-card>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/ui/DialogCard.vue";
import SelectField from "@/components/ui/form/SelectField.vue";
import InputField from "@/components/ui/form/InputField.vue";
import AddButton from "@/components/ui/buttons/AddButton.vue";
import { clientPaymentTypes } from "@/assets/constants/client.constants";
import PhoneField from "@/components/ui/form/PhoneField.vue";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/assets/utils/firebase.utils";
import EditIconButton from "@/components/ui/buttons/EditIconButton.vue";
import { initForm } from "@/assets/utils/common.utils";
import DateField from "@/components/ui/form/DateField.vue";
import PleaseWait from "@/components/ui/PleaseWait.vue";

export default {
  props: {
    isEdit: Boolean,
    paymentMethod: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    DialogCard,
    SelectField,
    InputField,
    AddButton,
    PhoneField,
    EditIconButton,
    DateField,
    PleaseWait,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isLoading: false,
      formData: {
        payment_method: "Credit Card",
        name: "",
        email: "",
        username: "",
        phone_number: "",
        card_number: "",
        cvv: "",
        exp_date: "",
        cardknox_type: "",
      },
    };
  },
  computed: {
    clientPaymentTypes() {
      return clientPaymentTypes.filter((i) => i.value == "Credit Card");
    },
    title() {
      if (this.isReadOnly) return "Preview Payment Method";
      return this.isEdit ? "Edit Payment Method" : "Add Payment Method";
    },
    isReadOnly() {
      return this.isEdit && this.paymentMethod.payment_method == "Credit Card";
    },
  },
  watch: {
    async dialog(val) {
      this.$nextTick(async () => {
        if (this.$refs.dialog) {
          this.$refs.dialog.reset();
        }
        if (val) {
          if (this.paymentMethod.payment_method == "Credit Card") {
            try {
              this.isLoading = true;
              let addMethod = httpsCallable(
                functions,
                "manageClientPaymentMethod"
              );
              let res = await addMethod({
                type: "decrypt-pm",
                customer: this.$route.params.id,
                id: this.paymentMethod.id,
              });
              initForm(this.formData, res.data);
              this.isLoading = false;
            } catch (error) {
              this.handleError(error);
            }
          } else {
            this.formData.cardknox_type = this.currentClient.client_type;
            initForm(this.formData, this.paymentMethod || {});
          }
        }
      });
    },
  },
  methods: {
    onCCInput(e) {
      let number = (this.formData.card_number || "").toString();
      if (e.keyCode == 54 && number.length == 0) {
        e.preventDefault();
        return false;
      }
    },
    async handleSubmit() {
      try {
        this.loading = true;
        let addMethod = httpsCallable(functions, "manageClientPaymentMethod");
        await addMethod({
          ...this.formData,
          type: "upsert",
          customer: this.$route.params.id,
          id: this.paymentMethod.id,
        });
        this.handleClose();
        this.$emit("onPaymentMethod");
        this.loading = false;
      } catch (error) {
        this.handleError(error);
      }
    },

    handleClose() {
      this.dialog = false;
      this.formData.payment_method = "";
      this.updateFields();
    },
    updateFields() {
      this.formData.name = "";
      this.formData.email = "";
      this.formData.username = "";
      this.formData.phone_number = "";
      this.formData.card_number = "";
      this.formData.exp_date = "";
      this.formData.cvv = "";
    },
  },
};
</script>
<style lang="scss">
.ifield {
  width: 100%;
  width: 100%;
  height: 45px;
  border: 0px;
  input {
    border: 1px solid black;
    border-radius: 5px;
    font-size: 14px;
    padding: 3px;
    width: 350px;
    height: 30px;
    margin-bottom: 15px;
  }
}
</style>
