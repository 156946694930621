<template>
  <layout :title="title" :loading="isLoading">
    <form-layout>
      <form-wrapper @submit="submit()">
        <v-row class="form-row mt-1">
          <v-col cols="12" md="6" class="pb-0">
            <input-field
              label="Template Name"
              rules="required"
              v-model="form.template_name"
            ></input-field>
          </v-col>
          <v-col cols="12" md="6"> </v-col>
          <v-col cols="12" md="6" class="pb-0">
            <input-field
              label="To ( Name of Recipient )"
              v-model="form.to"
            ></input-field>
          </v-col>
          <v-col cols="12">
            <template-editor
              label="Cover Letter Template"
              v-model="form.cover_letter"
              minHeight="350px"
            ></template-editor>
          </v-col>
        </v-row>
        <div class="d-flex">
          <v-spacer></v-spacer>
          <close-button :to="{ name: 'fax-templates' }" :disabled="loading"
            >Close</close-button
          >
          <secondary-button :loading="loading" type="submit" class="ml-md-4"
            >Submit</secondary-button
          >
        </div>
      </form-wrapper>
    </form-layout>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import FormWrapper from "@/components/ui/form/FormWrapper.vue";
import FormLayout from "@/components/ui/FormLayout.vue";
import InputField from "@/components/ui/form/InputField.vue";
import CloseButton from "@/components/ui/buttons/CloseButton.vue";
import SecondaryButton from "@/components/ui/buttons/SecondaryButton.vue";
import TemplateEditor from "@/components/ui/TemplateEditor.vue";
import { getDate, initForm } from "@/assets/utils/common.utils";
import {
  addDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { colFaxTemplates } from "@/assets/utils/firebase.utils";
export default {
  components: {
    Layout,
    FormWrapper,
    FormLayout,
    InputField,
    CloseButton,
    SecondaryButton,
    TemplateEditor,
  },
  data() {
    return {
      loading: false,
      isLoading: true,
      form: {
        template_name: "",
        crc_status: "",
        to: "",
        cover_letter: "",
      },
    };
  },
  computed: {
    isUpdate() {
      return this.$route.name == "update-fax-template";
    },
    title() {
      return this.isUpdate ? "Edit Fax Template" : "Add New Fax Template";
    },
    templateId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        let template = {
          updated_by: vm.userEmail,
          ...vm.form,
          updated_at: getDate(),
        };
        let { templateId } = vm;
        let { template_name, crc_status } = vm.form;
        let template_name_lower = template_name.trim().toString().toLowerCase();
        let crc_status_lower = crc_status.trim().toString().toLowerCase();
        template = { ...template, template_name_lower, crc_status_lower };
        let q = query(
          colFaxTemplates,
          where("template_name_lower", "==", template_name_lower),
          limit(1)
        );
        let snap = (await getDocs(q)).docs;
        if (snap.length != 0 && templateId != snap[0].id) {
          vm.loading = false;
          vm.notifyError(
            "Another template exists with the same template name. Please enter a unique template name."
          );
          return;
        }
        if (crc_status_lower) {
          let crcQuery = query(
            colFaxTemplates,
            where("crc_status_lower", "==", crc_status_lower),
            limit(1)
          );
          let crcSnap = (await getDocs(crcQuery)).docs;
          if (crcSnap.length != 0 && templateId != crcSnap[0].id) {
            vm.loading = false;
            vm.notifyError(
              "This CRC Status is already assigned to a template. Please enter another CRC Status."
            );
            return;
          }
        }

        if (templateId) {
          await updateDoc(doc(colFaxTemplates, templateId), template);
        } else {
          await addDoc(colFaxTemplates, { ...template, created_at: getDate() });
        }
        vm.loading = false;
        vm.$router.replace({ name: "fax-templates" });
      } catch (error) {
        vm.loading = false;
        vm.notifyError(error.message);
      }
    },
    async initTemplate() {
      const vm = this;
      if (!vm.templateId) {
        vm.isLoading = false;
        return;
      }
      try {
        let template = (
          await getDoc(doc(colFaxTemplates, vm.templateId))
        ).data();
        if (template == null) {
          vm.$router.replace({ name: "fax-templates" });
          vm.notifyError("Fax Template not found");
          return;
        }
        initForm(vm.form, template);
        vm.isLoading = false;
      } catch (error) {
        vm.$router.replace({ name: "fax-templates" });
        vm.notifyError(error.message);
      }
    },
  },
  mounted() {
    this.initTemplate();
  },
};
</script>

<style></style>
