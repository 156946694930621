<template>
  <layout :title="title" :loading="isLoading">
    <v-tabs class="tab-outlined" hide-slider v-model="tab">
      <v-tab class="text-none">Weekly</v-tab>
      <v-tab class="text-none">Daily</v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="tab">
      <v-tab-item :transition="false">
        <affiliate-weekly-commissions :affiliate="item" />
      </v-tab-item>
      <v-tab-item :transition="false">
        <affiliate-daily-commissions :affiliate="item" />
      </v-tab-item>
    </v-tabs-items>
  </layout>
</template>
  
  <script>
import Layout from "@/components/ui/Layout.vue";
import { doc, getDoc } from "firebase/firestore";
import { colAffiliates } from "@/assets/utils/firebase.utils";
import AffiliateWeeklyCommissions from "@/components/affiliates/AffiliateWeeklyCommissions.vue";
import AffiliateDailyCommissions from "@/components/affiliates/AffiliateDailyCommissions.vue";
export default {
  components: {
    Layout,
    AffiliateWeeklyCommissions,
    AffiliateDailyCommissions,
  },
  data() {
    return {
      isLoading: true,
      tab: 0,
      item: {},
    };
  },
  computed: {
    affiliateId() {
      return this.$route.params.id;
    },
    title() {
      return `Commission for ${this.item.first_name || ""} ${
        this.item.last_name || ""
      }`;
    },
  },
  methods: {
    async init() {
      const vm = this;
      try {
        vm.isLoading = true;
        let affiliate = (
          await getDoc(doc(colAffiliates, vm.affiliateId))
        ).data();
        if (affiliate == null) {
          vm.notifyError("Affiliate not found");
          vm.$router.replace({ name: "affiliates" });
          return;
        }
        vm.item = { ...affiliate, id: vm.affiliateId };
        vm.isLoading = false;
      } catch (error) {
        vm.handleError(error.message);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
  
  <style>
</style>