export const headerTabs = [
  { path: "clients", name: "Clients", permission: "customers" },
  {
    path: "schedule",
    name: "Schedule",
    permission: "schedule",
  },
  {
    path: "transaction-history",
    name: "Transaction History",
    permission: "transaction-log",
  },
  { path: "library", name: "Library", permission: "library" },
  {
    path: "affiliates",
    name: "Affiliates",
    permission: "affiliates",
  },
  {
    path: "invoices",
    name: "Invoices",
    permission: "invoices",
  },
  {
    path: "contracts",
    name: "Contracts",
    permission: "contracts",
  },
  {
    path: "dispute-letters",
    name: "Dispute Letters",
    permission: "send-faxes",
  },
  { path: "my-company", name: "My Company", permission: "my-company" },
];

export const myCompanyTabs = [
  {
    title: "My Company Profile",
    icon: "mdi-domain",
    path: "my-company-profile",
    permission: "my-company-profile",
  },
  {
    title: "Running Totals",
    icon: "mdi-currency-usd",
    path: "running-totals",
    permission: "running-totals",
  },
  {
    title: "My Team Members",
    icon: "mdi-account-group",
    path: "team-members",
    permission: "team-members",
  },
  {
    title: "Roles & Permissions",
    icon: "mdi-security",
    path: "roles-permissions",
    child: ["add-role", "update-role"],
    permission: "roles-permissions",
  },
  {
    title: "Client Status Types",
    icon: "mdi-map-marker",
    path: "client-status-types",
    permission: "client-status-types",
  },
  {
    title: "Affiliate Payment Methods",
    icon: "mdi-credit-card-outline",
    path: "affiliate-payment-methods",
    permission: "payment-methods",
  },
  {
    title: "Client Payment Methods",
    icon: "mdi-credit-card-outline",
    path: "client-payment-methods",
    permission: "payment-methods",
  },
  {
    title: "Automated Notifications",
    icon: "mdi-email-open-outline",
    disabled: true,
    path: "automated-notifications",
  },
  {
    title: "Fax Schedules",
    icon: "mdi-printer",
    path: "fax-schedules",
    permission: "fax-schedules",
  },
  {
    title: "Fax Templates",
    icon: "mdi-printer",
    path: "fax-templates",
    permission: "fax-templates",
  },
  {
    title: "Fax Recipients",
    icon: "mdi-printer",
    path: "fax-recipients",
    permission: "fax-recipients",
  },
  {
    title: "Client Agreements",
    icon: "mdi-file-sign",
    path: "client-agreements",
    permission: "client-agreements",
  },
  {
    title: "Digital Signature Records",
    icon: "mdi-signature-freehand",
    path: "digital-signature-records",
    permission: "client-agreements",
  },
];

export const clientTabs = [
  { path: "client-profile", name: "Dashboard", permission: "" },
  { path: "import-audit", name: "Import Audit", permission: "" },
  { path: "credit-report", name: "Credit Report", permission: "" },
  { path: "generate-letters", name: "Generate Letters ( MR)", permission: "" },
  { path: "send-letters", name: "Send Letters (PPD)", permission: "" },
  { path: "dispute-info", name: "Dispute Info", permission: "" },
  { path: "client-dispute-letters", name: "Dispute Letters", permission: "" },
  { path: "referral-payout", name: "Referral Payout", permission: "" },
  { path: "contract-history", name: "Contract History", permission: "" },
  {
    path: "client-transaction-history",
    name: "Transaction History",
    permission: "",
  },
];
