<template>
  <layout title="Digital Signature Records">
    <dynamic-table-view
      :list="list"
      :loading="loading"
      :headers="headers"
      @onPageChange="fetchAgreements"
      :server-items-length="total"
      ref="table"
    >
      <div slot="left" class="d-flex">
        <search-field
          v-model="search"
          @clear="
            search = '';
            applySearchFilter();
          "
          @onEnter="applySearchFilter()"
        />
        <primary-button class="ml-3" @click="applySearchFilter()"
          >Search</primary-button
        >
      </div>

      <template v-slot:item.status="{ item }">
        <client-status :type="item.client.customer_type"> </client-status>
      </template>
      <template v-slot:item.action="{ item }">
        <client-agreement-preview-dialog :agreement="item" :isIcon="true" />
      </template>
    </dynamic-table-view>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import { colClientAgreements, colClients } from "@/assets/utils/firebase.utils";
import {
  documentId,
  getCountFromServer,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { mapDocs } from "@/assets/utils/doc.utils";
import DynamicTableView from "@/components/ui/DynamicTableView.vue";
import moment from "moment-timezone";
import { formatDate } from "@/assets/utils/common.utils";
import _ from "lodash";
import ClientStatus from "@/components/clients/ClientStatus.vue";
import ClientAgreementPreviewDialog from "@/components/clients/ClientAgreementPreviewDialog.vue";
import SearchField from "@/components/ui/form/SearchField.vue";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
export default {
  components: {
    Layout,
    DynamicTableView,
    ClientStatus,
    ClientAgreementPreviewDialog,
    SearchField,
    PrimaryButton,
  },
  data() {
    return {
      list: [],
      dialog: false,
      loading: false,
      total: 50,
      options: {},
      agreement: {},
      search: " ",
    };
  },
  computed: {
    headers() {
      return [
        { text: "Name", value: "customer_name", sortable: true },
        { text: "Email", value: "client.email", sortable: false },
        { text: "IP Address", value: "ip_address", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Agreement Date", value: "timestamp", sortable: false },
        { text: "Action", value: "action", width: "100px", sortable: false },
      ];
    },
  },
  methods: {
    applySearchFilter() {
      let options = this.$refs.table.options;
      this.$refs.table.options = { ...options, page: 1 };
    },
    formatAddress(client) {
      let address = [];
      let { street, state, postal_code, city } = client;
      if (street) address.push(street);
      if (city) address.push(city);
      if (state) address.push(state);
      if (postal_code) address.push(postal_code);
      return address.toString().split(",").join(", ");
    },
    query(options) {
      let queryList = [];
      let sortBy = options.sortBy[0];
      let sortDesc = options.sortDesc[0];
      if (sortBy) {
        queryList.push(orderBy("customer_name", sortDesc ? "desc" : "asc"));
      } else {
        queryList.push(orderBy("customer_name", "asc"));
      }
      if (this.search?.trim()) {
        queryList.push(where("_search", "array-contains", this.search.trim()));
      }
      queryList.push(where("digital_signature", "!=", ""));

      return {
        countQuery: query(colClientAgreements, ...queryList),
        pageQuery: query(
          colClientAgreements,
          ...[...queryList, ...this.getPaginationQuery(options)]
        ),
      };
    },
    async fetchAgreements(options) {
      const vm = this;
      try {
        if (vm.loading) return;
        vm.loading = true;
        vm.options = options;
        vm.list = [];
        let page = options.page;
        let { countQuery, pageQuery } = vm.query(options);
        if (page == 1) {
          vm.total = (await getCountFromServer(countQuery)).data().count;
        }
        let docs = (await getDocs(pageQuery)).docs;
        vm.firstVisible = docs[0];
        vm.lastVisible = docs[docs.length - 1];
        let list = mapDocs(docs);
        let customers = await this.fetchCustomers(list);
        vm.list = list.map((i) => {
          let client = customers.find((c) => c.id == i.customer_id) || {};
          return {
            ...i,
            client,
            timestamp: formatDate(i.agreement_signed_on),
            signedAt: moment(i.agreement_signed_on).format("MMM DD, YYYY"),
          };
        });
        vm.currentPage = page;
        vm.loading = false;
      } catch (error) {
        console.log(error);
        vm.handleError(error);
      }
    },
    fetchCustomers(list) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          let clientIds = list.map((i) => i.customer_id);
          let customers = [];
          for (let items of _.chunk(clientIds, 100)) {
            let promises = [];
            for (let ids of _.chunk(items, 10)) {
              let listQuery = query(colClients, where(documentId(), "in", ids));
              promises.push(getDocs(listQuery));
            }
            let result = await Promise.all(promises);
            result.map((i) => {
              customers = customers.concat(
                mapDocs(i.docs.filter((i) => i.data() != null))
              );
            });
          }
          resolve(customers);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
</script>

<style>
.status-dot {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: inline-block;
}
</style>
