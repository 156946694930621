<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on }">
      <a v-on="selected.length == 0 ? null : on" class="mr-3">
        <error-button :disabled="selected.length == 0"
          >Delete/Deactivate</error-button
        >
      </a>
    </template>
    <dialog-card
      title="Deactivate/Delete Selected Clients"
      @close="dialog = false"
      :loading="isDeactivateLoading || isDeleteLoading"
    >
      <p class="black--text">
        <b>Warning!</b> <br />
        Are you sure you want to delete all the selected clients? <br />
        <br />
        Deleting the Clients will erase all their information, documents, notes
        and messages permanently. <br />
        <br />Another option is to deactivate these clients and keep the work
        history.
      </p>
      <div class="d-flex" slot="footer">
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="grey"
          height="40px"
          class="mr-3"
          @click="deactivate()"
          :loading="isDeactivateLoading"
          :disabled="isDeleteLoading"
          >Deactivate</v-btn
        >
        <error-button
          :loading="isDeleteLoading"
          @click="remove()"
          :disabled="isDeactivateLoading"
          >Delete Permanently</error-button
        >
      </div>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { doc, writeBatch } from "firebase/firestore";
import ErrorButton from "../ui/buttons/ErrorButton.vue";
import DialogCard from "../ui/DialogCard.vue";
import { colClients, db } from "@/assets/utils/firebase.utils";
import { getDate } from "@/assets/utils/common.utils";
export default {
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ErrorButton,
    DialogCard,
  },
  data() {
    return {
      dialog: false,
      isDeactivateLoading: false,
      isDeleteLoading: false,
    };
  },
  methods: {
    async deactivate() {
      const vm = this;
      try {
        vm.isDeactivateLoading = true;
        const batch = writeBatch(db);
        let updateDoc = {
          updated_at: getDate(),
          customer_type: "inactive",
          updated_by: vm.userEmail,
        };
        vm.selected.forEach((i) =>
          batch.update(doc(colClients, i.id), updateDoc)
        );
        await batch.commit();
        vm.$emit("onUpdate");
        vm.isDeactivateLoading = false;
        vm.dialog = false;
        vm.notifySuccess(
          "All the selected clients have been Deactivated successfully."
        );
      } catch (error) {
        vm.isDeactivateLoading = false;
        vm.handleError(error);
      }
    },
    async remove() {
      const vm = this;
      try {
        vm.isDeleteLoading = true;
        const batch = writeBatch(db);
        vm.selected.forEach((i) => batch.delete(doc(colClients, i.id)));
        await batch.commit();
        vm.$emit("onUpdate");
        vm.isDeleteLoading = false;
        vm.dialog = false;
        vm.notifySuccess(
          "All the selected clients have been deleted successfully."
        );
      } catch (error) {
        vm.isDeleteLoading = false;
        vm.handleError(error);
      }
    },
  },
};
</script>

<style></style>
