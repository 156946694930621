<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on }">
      <a v-on="selected.length == 0 ? null : on">
        <primary-button :disabled="selected.length == 0"
          >Change Status</primary-button
        >
      </a>
    </template>
    <dialog-card
      title="Change Status for selected clients"
      @submit="submit()"
      @close="dialog = false"
      :loading="isLoading"
    >
      <select-field
        rules="required"
        v-model="status"
        label="Status"
        :items="clientStatusTypes"
      >
        <template v-slot:item="{ item, on, attrs }">
          <v-list-item v-on="on" v-bind="attrs">
            <client-status :type="item.value"></client-status>
          </v-list-item>
        </template>
        <template v-slot:selection="{ item }">
          <client-status :type="item.value"></client-status>
        </template>
      </select-field>
      <div class="d-flex" slot="footer">
        <v-spacer></v-spacer>
        <close-button @click="dialog = false" :disabled="isLoading"
          >Cancel</close-button
        >
        <secondary-button type="submit" :loading="isLoading"
          >Update Status</secondary-button
        >
      </div>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { getDate } from "@/assets/utils/common.utils";
import CloseButton from "../ui/buttons/CloseButton.vue";
import PrimaryButton from "../ui/buttons/PrimaryButton.vue";
import SecondaryButton from "../ui/buttons/SecondaryButton.vue";
import DialogCard from "../ui/DialogCard.vue";
import SelectField from "../ui/form/SelectField.vue";
import ClientStatus from "./ClientStatus.vue";
import { doc, writeBatch } from "firebase/firestore";
import { colClients, db } from "@/assets/utils/firebase.utils";
export default {
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      status: "",
    };
  },
  components: {
    PrimaryButton,
    DialogCard,
    CloseButton,
    SecondaryButton,
    SelectField,
    ClientStatus,
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.isLoading = true;
        const batch = writeBatch(db);
        let updateDoc = {
          updated_at: getDate(),
          customer_type: vm.status,
          updated_by: vm.userEmail,
        };
        vm.selected.forEach((i) =>
          batch.update(doc(colClients, i.id), updateDoc)
        );
        await batch.commit();
        vm.$emit("onUpdate");
        vm.isLoading = false;
        vm.status = "";
        vm.dialog = false;
        vm.notifySuccess(
          "Status has been updated for all the selected clients successfully."
        );
      } catch (error) {
        vm.isLoading = false;
        vm.handleError(error);
      }
    },
  },
};
</script>
