<template>
  <layout title="My Schedule">
    <v-tabs v-model="tab">
      <v-tab class="text-none">Task & Event View</v-tab>
      <v-tab class="text-none">Day View</v-tab>
      <v-tab class="text-none">Week View</v-tab>
      <v-tab class="text-none">Month View</v-tab>
    </v-tabs>
    <div v-if="tab != 0">
      <div class="d-flex align-center py-3">
        <v-btn fab text color="primary" @click="prev">
          <v-icon size="30px"> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn fab text color="primary" @click="next">
          <v-icon size="30px"> mdi-chevron-right </v-icon>
        </v-btn>
        <div class="title px-3">
          {{ title }}
        </div>
        <v-btn
          outlined
          class="mr-4"
          color="success"
          :disabled="focus == ''"
          @click="setToday"
        >
          View Today
        </v-btn>
        <v-spacer></v-spacer>
        <select-field
          inline-label="Team Members"
          hide-details
          style="flex: 0 0 220px"
          :items="users"
          :dense="false"
          v-model="teamMember"
          class="mr-3"
        ></select-field>
        <add-schedule-event
          @onEventUpsert="fetchSchedules()"
          v-if="hasEdit('schedule')"
        />
      </div>
      <v-card outlined flat tile>
        <calendar
          :view="type"
          ref="calendar"
          :week="week"
          :usageStatistics="false"
          style="height: 800px"
          :events="filteredEvent"
          @selectDateTime="onDayClick"
          @clickEvent="showEvent"
          @clickMoreEventsBtn="onMoreClick"
        />
      </v-card>
    </div>
    <schedule-task-and-events v-if="tab == 0" />
    <preview-schedule-event
      :event="activeEvent"
      @onEventUpsert="fetchSchedules()"
      @onDelete="fetchSchedules()"
      ref="preview"
    />
    <add-schedule-event
      :show-button="false"
      @onEventUpsert="fetchSchedules()"
      ref="calenderEvent"
      :is-calender="true"
    />
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import SelectField from "@/components/ui/form/SelectField.vue";
import moment from "moment-timezone";
import AddScheduleEvent from "@/components/schedule/AddScheduleEvent.vue";
import { getDocs, query, where } from "firebase/firestore";
import { auth, colTeamSchedules } from "@/assets/utils/firebase.utils";
import { mapDocs } from "@/assets/utils/doc.utils";
import PreviewScheduleEvent from "@/components/schedule/PreviewScheduleEvent.vue";
import Calendar from "@toast-ui/vue-calendar";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import ScheduleTaskAndEvents from "@/components/schedule/ScheduleTaskAndEvents.vue";
import { computed } from "vue";

export default {
  components: {
    Layout,
    SelectField,
    AddScheduleEvent,
    PreviewScheduleEvent,
    Calendar,
    ScheduleTaskAndEvents,
  },
  data() {
    return {
      activeEvent: null,
      tab: 0,
      focus: "",
      events: [],
      schedules: [],
      teamMember: auth.currentUser?.uid || "",
      week: {
        taskView: ["task"],
        narrowWeekend: true,
        showTimezoneCollapseButton: true,
      },
    };
  },
  provide() {
    return {
      users: computed(() => this.users),
    };
  },
  computed: {
    filteredEvent() {
      if (!this.teamMember) return this.events;
      return this.events.filter((i) => i.member == this.teamMember);
    },
    users() {
      let members = [...this.$store.getters.members].map((i) => ({
        ...i,
        value: i.id,
      }));
      members.unshift({ name: "All Team Members", value: "" });
      return members;
    },

    title() {
      let date = this.focus || new Date();
      if (this.tab == 1) {
        let start = moment(date).startOf("week").format("MMM D");
        let end = moment(date).endOf("week").format("MMM D, YYYY");
        return `${start} - ${end}`;
      }
      if (this.tab == 2) {
        return moment(date).format("MMM YYYY");
      }
      return moment(date).format("MMM D, YYYY");
    },
    type() {
      switch (this.tab) {
        case 1:
          return "week";
        case 2:
          return "month";
        default:
          return "day";
      }
    },
    calendarInstance() {
      return this.$refs.calendar.getInstance();
    },
  },
  methods: {
    onDayClick(e) {
      let data = {
        date: moment(e.start).format("YYYY-MM-DD"),
        is_all_date: false,
        from_time: moment(e.start, "HH:mm").format("HH:mm"),
        to_time: moment(e.end, "HH:mm").format("HH:mm"),
      };
      if (e.isAllday) {
        data = {
          ...data,
          from_time: `00:00`,
          to_time: `00:00`,
        };
      }
      if (this.hasEdit("schedule")) {
        this.$refs.calenderEvent.open(data);
      }
      this.calendarInstance.clearGridSelections();
    },
    setToday() {
      this.calendarInstance.today();
      this.focus = new Date();
      this.fetchSchedules();
    },
    prev() {
      this.calendarInstance.prev();
      this.focus = this.calendarInstance.getDate().toDate();
      this.fetchSchedules();
    },
    next() {
      this.calendarInstance.next();
      this.focus = this.calendarInstance.getDate().toDate();
      this.fetchSchedules();
    },
    showEvent({ event }) {
      let item = this.schedules.find((i) => i.id == event.id);
      if (item) {
        this.activeEvent = item;
        this.$refs.preview.open();
      }
    },
    onMoreClick(e) {
      let dom = document.querySelector(".toastui-calendar-see-more-container");
      let top = e.target.offsetTop - 300;
      if (dom) {
        dom.style.top = top + "px";
      }
    },
    async fetchSchedules() {
      try {
        let month = moment(this.focus || new Date()).format("MM-YYYY");
        let scheduleQuery = query(
          colTeamSchedules,
          where("months", "array-contains", month)
        );
        let schedules = mapDocs((await getDocs(scheduleQuery)).docs);
        let events = [];
        for (let schedule of schedules) {
          events = events.concat(this.formatEvent(schedule));
        }
        this.schedules = schedules;
        this.events = events;
      } catch (error) {
        this.handleError(error);
      }
    },
    formatEvent(i) {
      let list = [];
      let start = moment(i.from_date).startOf("day");
      let end = moment(i.to_date).startOf("day");
      if (!i.is_all_date) {
        start = moment(`${start.format("YYYY-MM-DD")}T${i.from_time}`);
        end = moment(`${end.format("YYYY-MM-DD")}T${i.to_time}`);
      }
      if (i.type == "task") {
        end = start;
      }
      let category = "time";
      if (i.is_all_date) category = "allday";
      if (i.type == "task") category = "task";
      list.push({
        event: i,
        id: i.id,
        title: i.subject,
        category,
        member: i.member,
        backgroundColor: i.color,
        color: "white",
        isReadOnly: true,
        start: start.toDate(),
        end: end.toDate(),
      });

      return list;
    },
  },
  mounted() {
    this.fetchSchedules();
  },
};
</script>

<style lang="scss">
.schedule-calendar {
  min-height: calc(100vh - 300px);
}
.toastui-calendar-week {
  border-top: 0px !important;
}
.toastui-calendar-weekday-exceed-in-week {
  border: 0px !important;
}
</style>
