<template>
  <v-dialog v-model="dialog" max-width="1000px" scrollable>
    <v-card class="pa-4">
      <div class="d-flex">
        <div class="title">View Account</div>
        <v-spacer></v-spacer>
        <v-btn icon color="red" @click="dialog = false">
          <v-icon>mdi-close</v-icon></v-btn
        >
      </div>
      <v-card-text class="px-0">
        <v-row>
          <v-col cols="3"></v-col>
          <v-col cols="9">
            <v-row>
              <v-col>
                <equifax-img></equifax-img>
              </v-col>
              <v-col>
                <experian-img></experian-img>
              </v-col>
              <v-col>
                <transunion-img></transunion-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-for="(item, i) in accountItems" :key="i">
          <v-col cols="3" class="font-weight-bold black--text py-0 pt-2">
            {{ item.label }}
          </v-col>
          <v-col cols="9" class="py-0">
            <v-row>
              <v-col
                v-for="acc of ['equifax', 'experian', 'transunion']"
                :key="acc"
              >
                <input-field
                  v-model="account[acc][item.key]"
                  readonly
                ></input-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <close-button @click="dialog = false"></close-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDateOnly } from "@/assets/utils/common.utils";
import EquifaxImg from "../EquifaxImg.vue";
import InputField from "@/components/ui/form/InputField.vue";
import CloseButton from "@/components/ui/buttons/CloseButton.vue";
import ExperianImg from "../ExperianImg.vue";
import TransunionImg from "../TransunionImg.vue";
export default {
  components: {
    EquifaxImg,
    InputField,
    CloseButton,
    ExperianImg,
    TransunionImg,
  },
  data() {
    return {
      dialog: false,
      account: {
        equifax: {},
        experian: {},
        transunion: {},
      },
    };
  },
  computed: {
    accountItems() {
      let doc = {
        account_name: "Creditor/Furnisher",
        date_opened: "Date Opened",
        high_balance: "High Balance",
        credit_limit: "Credit Limit / Original Loan",
        account_number: "Account #",
        past_due_amount: "Past Due Amount",
        account_type: "Account Type",
        balance_owned: "Blance Owed",
      };

      return Object.keys(doc).map((key) => ({
        key,
        label: doc[key],
      }));
    },
  },
  methods: {
    init(item) {
      console.log(item);
      const vm = this;
      let equifax = item.list.find((a) => a.bureau == "Equifax") || {};
      let transunion = item.list.find((a) => a.bureau == "TransUnion") || {};
      let experian = item.list.find((a) => a.bureau == "Experian") || {};
      let doc = { equifax, transunion, experian };
      Object.keys(vm.account).forEach((key) => {
        let account = doc[key];
        vm.accountItems.forEach((i) => {
          vm.account[key][i.key] = account[i.key] || "";
        });
        if (vm.account[key].date_opened) {
          vm.account[key].date_opened = formatDateOnly(
            vm.account[key].date_opened
          );
        }
      });
      vm.dialog = true;
    },
  },
};
</script>

<style></style>
