<template>
  <v-dialog v-model="dialog" max-width="1000px" persistent scrollable>
    <template v-slot:activator="{ on }">
      <a v-on="on">
        {{ formatPrice(value) }}
      </a>
    </template>
    <dialog-card :title="title" @close="dialog = false">
      <v-row>
        <v-col cols="3">
          <label class="body-small">Affiliate</label>
          <div class="body-small--medium black--text">
            {{ transaction.name }}
          </div>
        </v-col>
        <v-col cols="3">
          <label class="body-small">Week</label>
          <div class="body-small--medium black--text">
            {{ transaction.weekName }}
          </div>
        </v-col>
        <v-col cols="3" v-if="isEarned">
          <label class="body-small">Total Transaction Amount</label>
          <div class="body-small--medium black--text">
            {{ formatPrice(transaction.total || 0) }}
          </div>
        </v-col>
        <v-col cols="3">
          <label class="body-small">{{ title }}</label>
          <div class="body-small--medium black--text">
            {{ formatPrice(value) }}
          </div>
        </v-col>
      </v-row>
      <div class="d-flex mt-5" v-if="isEarned && hasEdit('affiliates')">
        <input-field
          type="number"
          label="Commision%"
          v-model="commission_rate"
        />
        <secondary-button
          :loading="isLoading"
          class="mt-7 ml-3"
          @click="applyCommission()"
          >Apply To Selection</secondary-button
        >
      </div>

      <static-table-view
        :list="list"
        :headers="headers"
        :show-select="isEarned"
        v-model="selected"
      >
        <template v-slot:item.amount="{ value }">
          {{ formatPrice(value || 0) }}</template
        >
        <template v-slot:item.commission="{ value }">
          {{ formatPrice(value) }}</template
        >
        <template v-slot:item.commission_rate="{ value }">
          {{ value }}%</template
        >
        <template v-slot:item.paid_at="{ value }">
          {{ formatDate(value) }}</template
        >
      </static-table-view>
      <div slot="footer" class="d-flex">
        <close-button @click="dialog = false">Close</close-button>
      </div>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { getHeaders } from "@/assets/utils/common.utils";
import transactionMixin from "@/mixins/transaction.mixin";
import CloseButton from "../ui/buttons/CloseButton.vue";
import SecondaryButton from "../ui/buttons/SecondaryButton.vue";
import DialogCard from "../ui/DialogCard.vue";
import InputField from "../ui/form/InputField.vue";
import StaticTableView from "../ui/StaticTableView.vue";
export default {
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    transaction: {
      type: Object,
      default: () => ({}),
    },
    affiliate: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: "",
    },
  },
  components: {
    DialogCard,
    CloseButton,
    InputField,
    SecondaryButton,
    StaticTableView,
  },
  data() {
    return {
      dialog: false,
      selected: [],
      isLoading: false,
      commission_rate: 0,
    };
  },
  mixins: [transactionMixin],
  computed: {
    list() {
      let list = this.transaction.history;
      switch (this.type) {
        case "earned":
          return list;
        case "paid":
          return list.filter((i) => i.payout == true);
        case "owed":
          return list.filter((i) => i.payout == false);
        default:
          return [];
      }
    },
    isEarned() {
      return this.type == "earned";
    },
    title() {
      switch (this.type) {
        case "earned":
          return "Total Commission Earned";
        case "paid":
          return "Total Commission Paid";
        case "owed":
          return "Total Commission Owed";
        default:
          return "";
      }
    },
    headers() {
      let doc = {
        client_name: "Client",
        created_at: "Date",
        amount: "Transaction Amount",
        commission: "Comission Amount",
        commission_rate: "Commission %",
      };
      if (this.type == "paid") {
        doc.paid_at = "Payout Date";
        doc.payout_payment_method = "Payment Method";
      }
      return getHeaders(doc);
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.commission_rate = (this.affiliate.commission_rate || 0) * 100;
      }
    },
  },
  methods: {
    afterApplyCommission() {
      this.$emit("onUpdate");
    },
  },
};
</script>

<style></style>
