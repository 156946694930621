<template>
  <v-dialog v-model="dialog" max-width="1000px" persistent scrollable>
    <template v-slot:activator="{ on }">
      <a v-on="on">
        <primary-button small class="mr-1" height="35px">View</primary-button>
      </a>
    </template>
    <dialog-card :title="title" @close="dialog = false">
      <v-row class="mb-5">
        <v-col cols="3">
          <label class="body-small">Affiliate</label>
          <div class="body-small--medium black--text">{{ item.name }}</div>
        </v-col>
        <v-col cols="3">
          <label class="body-small">Week</label>
          <div class="body-small--medium black--text">{{ item.weekName }}</div>
        </v-col>
        <v-col cols="3">
          <label class="body-small">Commission Owed</label>
          <div class="body-small--medium black--text">
            {{ formatPrice(item.owed) }}
          </div>
        </v-col>
      </v-row>
      <static-table-view
        :list="list"
        :headers="headers"
        show-select
        v-model="selected"
      >
        <div class="flex-1" slot="left">
          <affiliate-weekly-payout
            :selected="selected"
            :disabled="selected.length == 0"
            @onPayout="$emit('onPayout')"
            type="button"
            :item="item"
          />
        </div>
        <template v-slot:item.amount="{ value }">
          {{ formatPrice(value || 0) }}</template
        >
        <template v-slot:item.commission="{ value }">
          {{ formatPrice(value) }}</template
        >
        <template v-slot:item.commission_rate="{ value }">
          {{ value }}%</template
        >
        <template v-slot:item.paid_at="{ value }">
          {{ formatDate(value) }}</template
        >
      </static-table-view>
      <div slot="footer" class="d-flex">
        <close-button @click="dialog = false">Close</close-button>
      </div>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { getHeaders } from "@/assets/utils/common.utils";
import CloseButton from "../ui/buttons/CloseButton.vue";
import DialogCard from "../ui/DialogCard.vue";
import StaticTableView from "../ui/StaticTableView.vue";
import moment from "moment-timezone";
import PrimaryButton from "../ui/buttons/PrimaryButton.vue";
import AffiliateWeeklyPayout from "./AffiliateWeeklyPayout.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    DialogCard,
    CloseButton,
    StaticTableView,
    PrimaryButton,
    AffiliateWeeklyPayout,
  },
  data() {
    return {
      dialog: false,
      selected: [],
    };
  },
  computed: {
    affiliateId() {
      return this.$route.params.id;
    },
    list() {
      let list = this.item.history;
      return list.map((i) => ({
        ...i,
        isSelectable: i.payout == false,
      }));
    },
    title() {
      let title = `The following commission for the week of ${moment(
        this.item.startWeek
      ).format("MMM DD YYYY")}`;
      if (this.item.payout == "Paid") return `${title} already paid`;
      return `${title}  are ready to be paid`;
    },
    headers() {
      let doc = {
        client_name: "Client",
        created_at: "Date",
        amount: "Transaction Amount",
        commission: "Comission Amount",
        commission_rate: "Commission %",
      };
      return getHeaders(doc);
    },
  },
};
</script>

<style></style>
