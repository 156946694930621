<template>
  <div class="d-flex flex-column" :class="align">
    <v-icon :color="color" v-if="icon">
      {{ icon }}
    </v-icon>
    <span :class="{ 'primary--text': icon != '' }">
      {{ status }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: "",
    },
    align: {
      type: String,
      default: "align-center",
    },
  },
  computed: {
    icon() {
      switch (this.status) {
        case "Positive":
          return "mdi-check-circle";
        case "Updated":
          return "mdi-check-circle";
        case "Deleted":
          return "mdi-check-circle";
        case "Negative":
          return "mdi-close-circle";
        case "Unspecified":
          return "mdi-close-circle";
        case "In Dispute":
          return "mdi-clock";

        default:
          return "";
      }
    },
    color() {
      switch (this.status) {
        case "Positive":
          return "success";
        case "Updated":
          return "success";
        case "Deleted":
          return "success";
        case "Negative":
          return "error";
        case "Unspecified":
          return "cyan";
        case "In Dispute":
          return "warning";
        default:
          return "";
      }
    },
  },
};
</script>

<style>
</style>