<template>
  <layout :title="`Send Letters (${customer.name})`" :loading="isLoading">
    <v-stepper v-model="step" vertical flat>
      <v-stepper-step :complete="step > 1" step="1" class="title">
        Select Accounts
      </v-stepper-step>
      <v-stepper-content step="1">
        <send-letter-account-step
          :value="form"
          @next="step = 2"
          v-if="step == 1"
        />
      </v-stepper-content>
      <v-stepper-step :complete="step > 2" step="2" class="title">
        Select Documents
      </v-stepper-step>
      <v-stepper-content step="2">
        <send-letter-document-step
          v-if="step == 2"
          :value="form"
          @input="onInput"
          @next="step = 3"
          @back="step = 1"
        />
      </v-stepper-content>
      <v-stepper-step step="3" class="title"> Review & Send </v-stepper-step>
      <v-stepper-content step="3">
        <send-letter-review-step
          v-if="step == 3"
          :value="form"
          @back="step = 2"
          @input="onInput"
        />
      </v-stepper-content> </v-stepper
  ></layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import { mapDocs, transformCustomer } from "@/assets/utils/doc.utils";
import {
  addDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  colClients,
  colCreditReports,
  colCustomerDocuments,
  colFaxes,
  colFaxRecipients,
  colFaxSchedules,
  colFaxTemplates,
  colSettings,
} from "@/assets/utils/firebase.utils";
import { computed } from "vue";
import SendLetterAccountStep from "@/components/clients/sendLetters/SendLetterAccountStep.vue";
import SendLetterDocumentStep from "@/components/clients/sendLetters/SendLetterDocumentStep.vue";
import SendLetterReviewStep from "@/components/clients/sendLetters/SendLetterReviewStep.vue";
import {
  dlFileIDs,
  formatDateOnly,
  formatFax,
  formatPhoneNumber,
  getDate,
  getRandom,
  getSearchArray,
  initForm,
  isDLFile,
} from "@/assets/utils/common.utils";
export default {
  components: {
    Layout,
    SendLetterAccountStep,
    SendLetterDocumentStep,
    SendLetterReviewStep,
  },
  data() {
    return {
      step: 1,
      isLoading: true,
      templates: [],
      form: {
        recipients: [],
        accounts: [],
        to: "",
        template: "",
        from_number: "",
        cover_letter: "",
        files: [],
        fax_title: "",
        cover_letter_message: "",
        schedule: null,
        mailing_address: "",
        priority: false,
        return_address: {},
      },
      report: {},
      recipients: [],
      schedules: [],
      customer: {},
      faxId: null,
      fax: {},
      faxSettings: {},
      prevRoute: null,
      customerDocuments: [],
    };
  },
  provide() {
    return {
      templates: computed(() => this.templates),
      schedules: computed(() => this.schedules),
      recipients: computed(() => this.recipients),
      customer: computed(() => this.customer),
      clientId: computed(() => this.clientId),
      report: computed(() => this.report),
      handleStepOneSubmit: this.handleStepOneSubmit,
      faxId: computed(() => this.faxId),
      fax: computed(() => this.fax),
      faxSettings: computed(() => this.faxSettings),
      prevRoute: computed(() => this.prevRoute),
      customerDocuments: computed(() => this.customerDocuments),
    };
  },
  computed: {
    clientId() {
      if (this.fax.customer) return this.fax.customer;
      return this.$route.params.id;
    },
  },
  methods: {
    handleStepOneSubmit(val) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          initForm(this.form, val);
          this.initCoverLetter();
          if (!this.faxId) {
            this.form.fax_title = "UNSPECIFIED";
            this.form.recipients = this.recipients;
          }
          let data = {
            ...this.form,
            updated_at: getDate(),
            customer_name: this.customer.name,
            _search: getSearchArray(this.form.fax_title),
            last_step: this.step,
          };
          if (this.faxId) {
            await updateDoc(doc(colFaxes, this.faxId), data);
          } else {
            let type = this.$route.params.type;
            if (this.faxSettings.defaultAddress) {
              data.mailing_address = this.faxSettings.defaultAddress || "";
              this.form.mailing_address = data.mailing_address;
            }
            data = {
              ...data,
              type: type ? Number(type) : 1,
              created_at: getDate(),
              status: "DRAFT",
              customer: this.clientId,
              sent_at: getDate(),
            };
            let snap = await addDoc(colFaxes, data);
            this.faxId = snap.id;
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    initCoverLetter() {
      let template = this.templates.find((i) => i.value == this.form.template);
      if (template) {
        this.form.cover_letter = template.cover_letter;
        this.form.to = template.to;
        let account_numbers = [];
        let accounts = this.form.accounts || [];
        accounts.forEach((i) => {
          account_numbers.push(`${i.name} ${i.account_number}`);
        });
        account_numbers = account_numbers
          .toString()
          .split(",")
          .join("<br/><br/>");
        let message = formatFax({
          message: this.form.cover_letter,
          fields: { ...this.customer, account_numbers },
        });
        this.form.cover_letter_message = message;
      }
    },
    onInput(val) {
      initForm(this.form, val);
    },
    async initCustomerFromNumber() {
      let files = [
        ...(this.customer.file_uploads || []),
        ...this.customerDocuments,
      ];
      let dlFiles = files.find((i) => isDLFile(i));
      if (dlFiles) {
        this.form.files.unshift(dlFiles);
      }
      if (this.customer.efax_number) {
        this.form.from_number = this.customer.efax_number;
      } else {
        let efax_number = formatPhoneNumber(getRandom(10));
        this.form.from_number = efax_number;
        updateDoc(doc(colClients, this.clientId), {
          efax_number,
        });
      }
    },
    async init() {
      try {
        this.isLoading = true;

        if (this.faxId) {
          let fax = (await getDoc(doc(colFaxes, this.faxId))).data();
          if (fax == null) {
            this.$router.replace("/dispute-letters");
            return;
          }
          this.fax = fax;
          initForm(this.form, this.fax);
          let customer = (await getDoc(doc(colClients, this.clientId))).data();
          if (customer == null) {
            this.notifyError("Customer not found");
            this.$router.replace("/dispute-letters");
            return;
          }
          this.customer = transformCustomer(customer);
        }
        let documentQuery = query(
          colCustomerDocuments,
          where("customer", "==", this.clientId),
          where("document_id", "in", dlFileIDs)
        );
        let customerDocuments = mapDocs((await getDocs(documentQuery)).docs);
        this.customerDocuments = customerDocuments.map((i) => {
          return {
            fileId: i.name,
            name: i.file_name,
            uid: i.uid || "",
            uploaded_at: i.created_date,
            url: i.url,
          };
        });
        if (this.$route.name == "send-letters") {
          this.customer = this.currentClient;
          this.initCustomerFromNumber();
        }
        this.templates = mapDocs(
          (await getDocs(colFaxTemplates, orderBy("template_name", "asc"))).docs
        );
        this.schedules = (
          await getDocs(colFaxSchedules, orderBy("schedule_name", "asc"))
        ).docs.map((item) => ({
          name: item.data().schedule_name,
          value: item.id,
        }));
        this.schedules.unshift({
          name: "N/A",
          value: "",
        });
        this.recipients = (await getDocs(colFaxRecipients)).docs.map(
          (item) => item.data().recipient
        );
        this.faxSettings = (await getDoc(doc(colSettings, "fax"))).data() || {};

        let reportQuery = query(
          colCreditReports,
          orderBy("created_at", "desc"),
          where("customer", "==", this.clientId),
          limit(1)
        );
        let reports = (await getDocs(reportQuery)).docs;

        if (reports.length > 0) {
          let data = reports[0].data();
          this.report = {
            ...data,
            id: reports[0].id,
            date: formatDateOnly(data.date),
          };
        }
        this.isLoading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
  mounted() {
    this.init();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  beforeMount() {
    if (this.$route.name == "edit-send-letter") {
      this.faxId = this.$route.params.id;
    }
  },
};
</script>

<style></style>
