<template>
  <v-dialog v-model="dialog" persistent max-width="1000px" scrollable>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text small color="primary"
        ><v-icon left small>mdi-tune</v-icon> Customize List</v-btn
      >
    </template>
    <dialog-card
      title="Customize List"
      :isSubmit="false"
      @close="dialog = false"
    >
      <div class="d-flex align-center">
        <div class="subtitle-2 black--text">Document Type</div>
        <v-spacer></v-spacer>
        <add-button
          small
          @click="addItem()"
          v-if="hasEdit('customers')"
          :disbaled="loading"
          >Add New</add-button
        >
      </div>
      <form-wrapper @submit="submit()">
        <v-list dense class="py-0">
          <draggable
            v-model="documentList"
            draggable=".document-item"
            @change="onPositionUpdate"
          >
            <div
              v-for="(item, i) in documentList"
              :key="i"
              :class="selectIndex != null ? '' : 'document-item'"
            >
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon>mdi-drag-horizontal</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <input-field
                      v-model="name"
                      v-if="selectIndex == i"
                      hide-details
                      rules="required"
                    />
                    <template v-else>
                      {{ item.name }}
                    </template>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <div class="d-flex align-center">
                    <edit-icon-button
                      @click="editItem(item, i)"
                      v-if="
                        hasDelete('customers') &&
                        selectIndex == null &&
                        item.is_readonly != true
                      "
                    />
                    <template v-if="selectIndex == i">
                      <v-btn
                        depressed
                        outlined
                        type="submit"
                        :loading="loading"
                        v-if="selectIndex == i"
                        color="success"
                        >Save</v-btn
                      >
                      <v-btn
                        depressed
                        outlined
                        @click="selectIndex = null"
                        class="ml-3"
                        :disabled="loading"
                        color="red"
                        >Cancel</v-btn
                      >
                    </template>

                    <delete-icon-button
                      :disabled="loading"
                      class="ml-3"
                      @click="deleteItem(item)"
                      v-if="hasDelete('customers') && item.is_readonly != true"
                    />
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
            </div>
          </draggable>
        </v-list>
      </form-wrapper>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import DialogCard from "../../ui/DialogCard.vue";
import draggable from "vuedraggable";
import {
  addDoc,
  deleteDoc,
  doc,
  getCountFromServer,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  colCustomerDocuments,
  colDocuments,
  db,
} from "@/assets/utils/firebase.utils";
import AddButton from "../../ui/buttons/AddButton.vue";
import { getDate } from "@/assets/utils/common.utils";
import InputField from "../../ui/form/InputField.vue";
import EditIconButton from "../../ui/buttons/EditIconButton.vue";
import DeleteIconButton from "../../ui/buttons/DeleteIconButton.vue";
import FormWrapper from "../../ui/form/FormWrapper.vue";
export default {
  components: {
    DialogCard,
    draggable,
    AddButton,
    InputField,
    EditIconButton,
    DeleteIconButton,
    FormWrapper,
  },
  data() {
    return {
      dialog: false,
      documentList: [],
      selectIndex: null,
      name: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["documents"]),
  },
  watch: {
    dialog() {
      this.documentList = JSON.parse(JSON.stringify(this.documents));
    },
    documents() {
      this.documentList = JSON.parse(JSON.stringify(this.documents));
    },
  },
  methods: {
    editItem(item, index) {
      this.selectIndex = index;
      this.name = item.name;
    },
    addItem() {
      if (this.loading) return;
      if (this.selectIndex != null) {
        return;
      }
      this.name = "";
      this.documentList.unshift({
        name: "",
        created_at: getDate(),
        created_by: this.userEmail,
      });
      this.selectIndex = 0;
    },
    deleteItem(item) {
      if (!item.id) {
        this.splice(this.documentList, 0, 1);
        return;
      }
      this.$confirm.show({
        message: `Are you sure, Do you want to remove "${item.name}"?`,
        onConfirm: async () => {
          try {
            let documentQuery = query(
              colCustomerDocuments,
              where("document_id", "==", item.id)
            );
            let count = (await getCountFromServer(documentQuery)).data().count;
            if (count != 0) {
              this.notifyError(
                `This document type cannot be deleted since there are documents uploaded against this document type.`
              );
              return;
            }
            console.log(item.id);
            await deleteDoc(doc(colDocuments, item.id));
          } catch (error) {
            this.handleError(error);
          }
        },
      });
    },
    onPositionUpdate() {
      let batch = writeBatch(db);
      this.documentList.forEach((item, index) => {
        if (item) {
          batch.update(doc(colDocuments, item.id), {
            position: index,
          });
        }
      });
      batch.commit();
    },
    async submit() {
      try {
        this.loading = true;
        let item = this.documentList[this.selectIndex];
        let name = this.name.trim().toLowerCase();
        let documentQuery = query(
          colDocuments,
          where("name_lw", "==", name),
          limit(1)
        );
        let snap = (await getDocs(documentQuery)).docs;
        if (snap.length > 0 && (!item.id || item.id != snap[0].id)) {
          this.loading = false;
          this.notifyError("This Document Type already exists.");
          return;
        }
        let data = {
          name: this.name,
          name_lw: name,
          updated_at: getDate(),
          updated_by: this.userEmail,
        };
        if (item.id) {
          await updateDoc(doc(colDocuments, item.id), data);
        } else {
          await addDoc(colDocuments, {
            ...data,
            created_at: getDate(),
            position: this.documentList.length,
          });
        }
        this.loading = false;
        this.selectIndex = null;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
  mounted() {
    this.documentList = JSON.parse(JSON.stringify(this.documents));
  },
};
</script>

<style>
.document-item {
  cursor: move;
}
</style>
