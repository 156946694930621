<template>
  <layout title="Client Status Types">
    <add-client-status-type
      @onUpdate="init()"
      slot="add"
      v-if="hasEdit('client-status-types')"
    />
    <static-table-view :list="list" :loading="loading" :headers="headers">
      <template v-slot:item.color="{ value }">
        <span class="status-dot" :style="{ background: value }"></span>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex">
          <add-client-status-type
            @onUpdate="init()"
            v-if="hasEdit('client-status-types')"
            :item="item"
          />
          <delete-icon-button
            v-if="hasDelete('client-status-types')"
            @click="remove(item)"
          />
        </div>
      </template>
    </static-table-view>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import StaticTableView from "@/components/ui/StaticTableView.vue";
import {
  colClients,
  colClientStatusTypes,
} from "@/assets/utils/firebase.utils";
import {
  deleteDoc,
  doc,
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import DeleteIconButton from "@/components/ui/buttons/DeleteIconButton.vue";
import AddClientStatusType from "@/components/mycompany/AddClientStatusType.vue";
import { mapDocs } from "@/assets/utils/doc.utils";
export default {
  components: {
    Layout,
    StaticTableView,
    DeleteIconButton,
    AddClientStatusType,
  },
  data() {
    return {
      list: [],
      loading: false,
    };
  },
  computed: {
    headers() {
      return [
        { text: "Status Name", value: "name" },
        { text: "Status Color", value: "color" },
        { text: "Total in Status", value: "total" },
        { text: "Action", value: "action", width: "100px" },
      ];
    },
  },
  methods: {
    async remove({ id, name_lw }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure you want to Delete this Status?",
        onConfirm: () => {
          // eslint-disable-next-line no-async-promise-executor
          return new Promise(async (resolve, reject) => {
            try {
              let q = query(
                colClients,
                where("customer_type", "==", name_lw),
                limit(1)
              );
              let docs = (await getDocs(q)).docs;
              if (docs.length > 0) {
                reject({
                  message:
                    "This Status cannot be deleted since it's assigned to the client(s).",
                });
                return;
              }
              await deleteDoc(doc(colClientStatusTypes, id));
              vm.notifySuccess("Status has been deleted successfully.");
              vm.init();
              resolve();
            } catch (error) {
              reject(error);
            }
          });
        },
      });
    },
    async init() {
      const vm = this;
      try {
        vm.list = [];
        vm.loading = true;
        let q = query(colClientStatusTypes, orderBy("name", "asc"));
        let list = mapDocs((await getDocs(q)).docs);
        let promises = list.map((i) =>
          getCountFromServer(
            query(colClients, where("customer_type", "==", i.name_lw))
          ).then((data) => {
            return { count: data.data().count, status: i.name_lw };
          })
        );
        let counts = await Promise.all(promises);
        vm.list = list.map((i) => {
          let item = counts.find((c) => c.status == i.name_lw);
          return { ...i, total: item ? item.count : 0 };
        });
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.status-dot {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: inline-block;
}
</style>
