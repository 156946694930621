import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
console.log(Vuetify.config);
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#589EE4", // #E53935
        secondary: "#00A650", // #FFCDD2
        accent: "#000000", // #3F51B5
      },
    },
  },
  breakpoint: {
    thresholds: {
      lg: 1280,
      xl: 1366,
    },
    scrollBarWidth: 24,
  },
});
