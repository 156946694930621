<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-title>Delete</v-list-item-title>
      </v-list-item>
    </template>
    <dialog-card
      title="Deactivate/Delete"
      @close="dialog = false"
      :loading="isDeactivateLoading || isDeleteLoading"
    >
      <p class="black--text">
        <b> Warning!</b> <br />
        Are you sure you want to delete this client?
        <br /><br />
        Deleting a Client will erase all their information, documents, notes and
        messages permanently.
        <br /><br />
        Another option is to deactivate this client and keep the work history.
      </p>

      <div class="d-flex" slot="footer">
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="grey"
          height="40px"
          class="mr-3"
          @click="deactivate()"
          :loading="isDeactivateLoading"
          :disabled="isDeleteLoading"
          >Deactivate</v-btn
        >
        <error-button
          :loading="isDeleteLoading"
          @click="remove()"
          :disabled="isDeactivateLoading"
          >Delete Permanently</error-button
        >
      </div>
    </dialog-card>
  </v-dialog>
</template>
<script>
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import DeleteIconButton from "../ui/buttons/DeleteIconButton.vue";
import ErrorButton from "../ui/buttons/ErrorButton.vue";
import DialogCard from "../ui/DialogCard.vue";
import { colClients } from "@/assets/utils/firebase.utils";
import { getDate } from "@/assets/utils/common.utils";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ErrorButton,
    DialogCard,
    DeleteIconButton,
  },
  data() {
    return {
      dialog: false,
      isDeactivateLoading: false,
      isDeleteLoading: false,
    };
  },
  methods: {
    async deactivate() {
      const vm = this;
      try {
        vm.isDeactivateLoading = true;
        await updateDoc(doc(colClients, vm.item.id), {
          updated_at: getDate(),
          customer_type: "inactive",
          updated_by: vm.userEmail,
        });
        vm.$emit("onUpdate");
        vm.isDeactivateLoading = false;
        vm.dialog = false;
        vm.notifySuccess("The client has been Deactivated successfully.");
      } catch (error) {
        vm.isDeactivateLoading = false;
        vm.handleError(error);
      }
    },
    async remove() {
      const vm = this;
      try {
        vm.isDeleteLoading = true;
        await deleteDoc(doc(colClients, vm.item.id));
        vm.$emit("onUpdate");
        vm.isDeleteLoading = false;
        vm.dialog = false;
        vm.notifySuccess("The client has been deleted successfully.");
      } catch (error) {
        vm.isDeleteLoading = false;
        vm.handleError(error);
      }
    },
  },
};
</script>

<style></style>
