<template>
  <div class="py-3">
    <div class="d-flex">
      <div class="title flex-grow-1">Tasks</div>
      <add-schedule-event
        type="task"
        v-if="hasEdit('customers')"
        @onEventUpsert="fetchEvents()"
      />
    </div>
    <v-card outlined tile height="400px" class="overflow-y-auto mt-3 pa-3">
      <v-progress-linear indeterminate v-if="loading" />
      <v-list>
        <div
          v-for="eventType in ['Overdue', 'Today', 'Upcoming']"
          :key="eventType"
        >
          <div v-if="getEvents(eventType).length" class="mb-3">
            <v-chip dark :color="getClipColor(eventType)" class="mb-3">{{
              eventType
            }}</v-chip>
            <v-divider />
            <div v-for="(item, i) in getEvents(eventType)" :key="i">
              <v-list-item>
                <v-list-item-action v-if="hasEdit('customers')">
                  <v-progress-circular
                    indeterminate
                    v-if="selectedTaskId == item.id && loading"
                  />

                  <v-simple-checkbox
                    hide-details
                    v-else
                    color="primary"
                    class="mt-0 pt-0"
                    :disabled="loading"
                    @click="updateCompeleteStatus(item)"
                    :value="item.is_completed"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.subject }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <div class="d-flex align-center">
                    <div class="mr-1">
                      {{ item.due_date }}
                    </div>
                    <team-task-history :task="item" />
                    <add-schedule-event
                      type="task"
                      @onEventUpsert="fetchEvents()"
                      :isEdit="true"
                      :showButton="true"
                      :item="item"
                    />
                    <delete-icon-button
                      @click="remove(item)"
                      v-if="hasDelete('schedule')"
                    />
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-divider />
            </div>
          </div>
        </div>
      </v-list>
    </v-card>
    <v-dialog v-model="dialog" max-width="1000px">
      <template v-slot:activator="{ on }">
        <div class="text-right">
          <v-btn
            v-on="on"
            text
            color="primary"
            class="text-none text-decoration-underline mt-3"
          >
            View Completed Team Tasks
          </v-btn>
        </div>
      </template>
      <dialog-card title="Completed Team Tasks" @close="dialog = false">
        <v-progress-linear v-if="loading" indeterminate />
        <v-list>
          <div v-for="(item, i) in completedTasks" :key="i">
            <v-list-item>
              <v-list-item-action v-if="hasEdit('customers')">
                <v-progress-circular
                  indeterminate
                  v-if="selectedTaskId == item.id && loading"
                />
                <v-simple-checkbox
                  hide-details
                  v-else
                  color="primary"
                  :disabled="loading"
                  class="mt-0 pt-0"
                  @click="updateCompeleteStatus(item)"
                  :value="item.is_completed"
                />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="text-decoration-line-through">
                  {{ item.subject }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <div class="d-flex align-center">
                  <div class="mr-1">
                    {{ item.due_date }}
                  </div>
                  <team-task-history :task="item" />
                  <add-schedule-event
                    type="task"
                    :isEdit="true"
                    :showButton="true"
                    :item="item"
                    @onEventUpsert="fetchEvents()"
                  />
                  <delete-icon-button
                    @click="removeTask(item)"
                    v-if="hasDelete('schedule')"
                  />
                </div>
              </v-list-item-action>
            </v-list-item>
            <v-divider />
          </div>
        </v-list>
        <div slot="footer"></div>
      </dialog-card>
    </v-dialog>
  </div>
</template>

<script>
import AddScheduleEvent from "@/components/schedule/AddScheduleEvent.vue";
import {
  doc,
  getDocs,
  orderBy,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { colTeamSchedules, db } from "@/assets/utils/firebase.utils";
import { mapDocs } from "@/assets/utils/doc.utils";
import moment from "moment-timezone";
import { getDate } from "@/assets/utils/common.utils";
import DialogCard from "@/components/ui/DialogCard.vue";
import DeleteIconButton from "@/components/ui/buttons/DeleteIconButton.vue";
import TeamTaskHistory from "./TeamTaskHistory.vue";
import scheduleMixin from "@/mixins/schedule.mixin";
export default {
  data() {
    return {
      loading: true,
      events: [],
      dialog: false,
      selectedTaskId: null,
    };
  },
  components: {
    AddScheduleEvent,
    DialogCard,
    DeleteIconButton,
    TeamTaskHistory,
  },
  computed: {
    completedTasks() {
      let list = this.events.filter((i) => i.is_completed == true);
      return [...list].reverse();
    },
  },
  mixins: [scheduleMixin],
  methods: {
    getClipColor(type) {
      switch (type) {
        case "Overdue":
          return "error";
        case "Upcoming":
          return "warning";
        default:
          return "success";
      }
    },
    getEvents(type) {
      let events = this.events.filter((i) => i.is_completed == false);
      switch (type) {
        case "Overdue":
          return events.filter((i) =>
            moment(i.from_date).isBefore(moment(), "date")
          );
        case "Today":
          return events.filter((i) =>
            moment(i.from_date).isSame(moment(), "date")
          );
        case "Upcoming":
          return events.filter((i) =>
            moment(i.from_date).isAfter(moment(), "date")
          );
        default:
          return [];
      }
    },
    remove(item) {
      this.removeTask(item).then(() => {
        this.fetchEvents();
      });
    },
    async fetchEvents() {
      try {
        this.loading = true;
        let eventQuery = query(
          colTeamSchedules,
          orderBy("from_date", "asc"),
          where("client", "==", this.$route.params.id)
        );
        this.events = mapDocs((await getDocs(eventQuery)).docs).map((i) => {
          i.due_date = moment(`${i.from_date}T${i.from_time}`).format(
            "MM/DD/YYYY hh:mm A"
          );
          return i;
        });
        this.loading = false;
        this.selectedTaskId = "";
      } catch (error) {
        this.handleError(error);
      }
    },
    async updateCompeleteStatus({ id, is_completed }) {
      try {
        this.selectedTaskId = id;
        this.loading = true;
        let history = {
          created_at: getDate(),
          uid: this.uid,
          name: `${this.user.first_name} ${this.user.last_name}`,
          action: !is_completed ? "Marked Completed" : "Marked Incompleted",
        };
        let batch = writeBatch(db);
        batch.set(
          doc(colTeamSchedules, id, "history", new Date().getTime().toString()),
          history
        );
        batch.update(doc(colTeamSchedules, id), {
          is_completed: !is_completed,
          updated_at: getDate(),
          updated_by: this.userEmail,
        });
        await batch.commit();
        this.$store.dispatch("fetchUserTaskCount");
        this.fetchEvents();
      } catch (error) {
        this.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchEvents();
  },
};
</script>

<style></style>
