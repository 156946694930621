<template>
  <div class="form-layout">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.form-layout {
  max-width: 800px;
}
</style>
