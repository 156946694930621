<template>
  <v-dialog v-model="dialog" max-width="300px" persistent>
    <v-card>
      <v-card-title>Confirm</v-card-title>
      <v-card-text v-html="message"></v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="error" :disabled="loading" @click="cancel">
          No
        </v-btn>
        <v-btn text color="green" :loading="loading" @click="confirm">
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ConfirmModal from "./index";
export default {
  data() {
    return {
      message: "",
      dialog: false,
      onConfirm: {},
      loading: false,
      onCancel: {},
    };
  },
  methods: {
    async confirm() {
      const vm = this;
      if (typeof vm.onConfirm === "function") {
        try {
          vm.loading = true;
          await vm.onConfirm(vm);
          vm.loading = false;
        } catch (error) {
          vm.handleError(error);
        }
      }
      vm.dialog = false;
    },
    cancel() {
      const vm = this;
      if (typeof vm.onCancel === "function") {
        vm.onCancel();
      }
      vm.dialog = false;
    },
    show(params) {
      const vm = this;
      Object.keys(params).forEach((key) => {
        vm[key] = params[key];
      });
      vm.dialog = true;
    },
  },
  beforeMount() {
    ConfirmModal.EventBus.$on("show", (params) => {
      this.show(params);
    });
  },
};
</script>

<style></style>
