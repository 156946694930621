<template>
  <layout :title="`Dispute Info (${currentClient.name})`">
    <v-row v-if="report.id">
      <v-col
        cols="8"
        class="pr-8"
        style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
      >
        <v-card outlined>
          <v-data-table
            class="affiliate-table"
            :headers="headers"
            :footer-props="{
              'items-per-page-options': [10, 20, 50, 100, -1],
            }"
            :items-per-page="50"
            :multi-sort="false"
            :items="items"
          >
            <template v-slot:item.date_filed="{ value }">
              {{ value ? formatDateOnly(value) : "" }}
            </template>
            <template v-slot:item.status="{ item }">
              <div class="d-flex">
                <div
                  style="width: 20px; height: 20px; border-radius: 20px"
                  :class="getBg(item)"
                  class="font-weight-bold mr-1 d-flex align-center justify-center white--text"
                >
                  {{ item.status }}
                </div>
              </div>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn icon @click="onRowClick(item)">
                <v-icon>mdi-eye</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card>
        <credit-report-account-view
          ref="accountView"
        ></credit-report-account-view>
      </v-col>
      <v-col cols="4" class="pl-8">
        <form-wrapper @submit="submit()">
          <v-row>
            <v-col cols="6" class="py-0">
              <input-field
                v-model="customer.first_name"
                :readonly="true"
                label="First Name"
              ></input-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <input-field
                v-model="customer.middle_name"
                :readonly="true"
                label="Middle Name"
              ></input-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <input-field
                v-model="customer.last_name"
                :readonly="true"
                label="Last Name"
              ></input-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <input-field
                v-model="dob"
                :readonly="true"
                label="Birth Date"
              ></input-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <input-field
                v-model="email"
                :readonly="true"
                label="Email"
              ></input-field>
            </v-col>
          </v-row>
          <div class="title py-3">Previous Address</div>
          <v-row class="mt-4">
            <v-col cols="8" class="py-0">
              <input-field
                v-model="previous_address.street"
                label="Street"
              ></input-field>
            </v-col>
            <v-col cols="4" class="py-0">
              <input-field
                v-model="previous_address.city"
                label="City"
              ></input-field>
            </v-col>
            <v-col cols="8" class="py-0">
              <input-field
                v-model="previous_address.state"
                label="State"
              ></input-field>
            </v-col>
            <v-col cols="4" class="py-0">
              <input-field
                v-model="previous_address.postal_code"
                label="Postal Code"
              ></input-field>
            </v-col>
          </v-row>
          <div class="title py-3">Current Address</div>
          <v-row class="mt-4">
            <v-col cols="8" class="py-0">
              <input-field
                v-model="customer.street"
                :readonly="true"
                label="Street"
              ></input-field>
            </v-col>
            <v-col cols="4" class="py-0">
              <input-field
                v-model="customer.city"
                :readonly="true"
                label="City"
              ></input-field>
            </v-col>
            <v-col cols="8" class="py-0">
              <input-field
                v-model="customer.state"
                :readonly="true"
                label="State"
              ></input-field>
            </v-col>
            <v-col cols="4" class="py-0">
              <input-field
                v-model="customer.postal_code"
                :readonly="true"
                label="Postal Code"
              ></input-field>
            </v-col>
          </v-row>
          <div class="d-flex" v-if="hasEdit('customers')">
            <v-spacer></v-spacer>
            <primary-button :loading="loading" type="submit"
              >Save</primary-button
            >
          </div>
        </form-wrapper>
      </v-col>
    </v-row>
  </layout>
</template>

<script>
import { formatDateOnly, getDate } from "@/assets/utils/common.utils";
import Layout from "@/components/ui/Layout.vue";
import moment from "moment";
import {
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { colCreditReports } from "@/assets/utils/firebase.utils";
import InputField from "@/components/ui/form/InputField.vue";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
import FormWrapper from "@/components/ui/form/FormWrapper.vue";
import CreditReportAccountView from "@/components/clients/creditReport/CreditReportAccountView.vue";
export default {
  components: {
    Layout,
    InputField,
    PrimaryButton,
    FormWrapper,
    CreditReportAccountView,
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      report: {},
      previous_address: {
        street: "",
        city: "",
        state: "",
        postal_code: "",
      },
    };
  },
  computed: {
    customer() {
      return this.currentClient;
    },
    email() {
      let { email } = this.customer;
      let dob = this.dob;
      let year = "";
      if (dob) {
        year = moment(dob, "MM/DD/YYYY").format("YYYY");
      }
      if (!email) return "";
      return email.split("@").join(year + "@");
    },
    headers() {
      let doc = {
        name: "Account Name",
        status: "Status",
        number: "Account Number",
        bureau: "Bureau",
        date_filed: "Date Filed",
        action: "",
      };
      let widths = { action: "50px" };
      return Object.keys(doc).map((value) => ({
        text: doc[value],
        value,
        width: widths[value],
      }));
    },
    dob() {
      let { birth_date } = this.customer;
      return birth_date ? formatDateOnly(birth_date) : "";
    },
    items() {
      let accounts = this.report.accounts || [];
      accounts = accounts
        .map((item) => {
          let statusList = ["Negative", "In Dispute", "Unspecified", "Deleted"];
          let list = item.list.filter(
            (item) => statusList.indexOf(item.status) != -1
          );
          let status = list[0] ? list[0].status[0] : "";
          if (status == "D") status = "R";
          if (status == "I") status = "D";
          if (status == "U") status = "I";
          let date_filed = list[0] ? list[0].date_filed : "";
          let bureau = "";
          if (item.type == "inquiry") bureau = list[0] ? list[0].bureau : "";
          return {
            ...item,
            list,
            number: list[0] ? list[0].account_number : "",
            bureau,
            date_filed,
            status,
          };
        })
        .filter((i) => i.list.length != 0);
      let enquires = accounts.filter((i) => i.type == "inquiry");
      let notEnquires = accounts.filter((i) => i.type != "inquiry");
      return [...notEnquires, ...enquires];
    },
  },
  methods: {
    getBg({ status }) {
      switch (status) {
        case "I":
          return "primary";
        case "N":
          return "error";
        case "R":
          return "success";
        case "D":
          return "warning";
        default:
          return "";
      }
    },

    async fetchReport() {
      const vm = this;
      try {
        vm.isLoading = true;
        let id = vm.$route.params.id;
        let reportQuery = query(
          colCreditReports,
          orderBy("created_at", "desc"),
          where("customer", "==", id),
          limit(1)
        );
        let reports = (await getDocs(reportQuery)).docs;
        if (reports.length > 0) {
          vm.report = {
            ...reports[0].data(),
            id: reports[0].id,
            date: formatDateOnly(reports[0].data().date),
          };
          if (vm.report.previous_address) {
            vm.previous_address = vm.report.previous_address;
          }
        }
        vm.isLoading = false;
      } catch (error) {
        vm.isLoading = false;
        vm.notifyError(error.message);
      }
    },
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        await updateDoc(doc(colCreditReports, vm.report.id), {
          previous_address: { ...vm.previous_address },
          updated_at: getDate(),
        });
        vm.loading = false;
      } catch (error) {
        vm.loading = false;
        vm.notifyError(error.message);
      }
    },
    onRowClick(item) {
      this.$refs.accountView.init(item);
    },
  },
  mounted() {
    this.fetchReport();
  },
};
</script>

<style></style>
