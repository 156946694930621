<template>
  <layout title="Automated Notifications"></layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
export default {
  components: { Layout },
};
</script>

<style>
</style>