<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    scrollable
    v-if="hasEdit('customers')"
  >
    <template v-slot:activator="{ on }">
      <v-btn height="40px" color="primary" depressed v-on="on"
        >Payment Methods</v-btn
      >
    </template>
    <dialog-card
      title="Payment Methods"
      @close="dialog = false"
      :is-submit="false"
    >
      <div class="d-flex mb-3" v-if="hasEdit('customers')">
        <v-spacer></v-spacer>
        <add-client-payment-method-dialog
          @onPaymentMethod="fetchPaymentMethods"
        />
      </div>
      <please-wait v-if="isLoading" />
      <template v-else>
        <v-card outlined v-for="(item, i) in list" :key="i" class="mb-3">
          <v-list-item>
            <v-list-item-action>
              <v-img width="40px" v-if="item.logo" :src="item.logo" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div class="d-flex">
                <add-client-payment-method-dialog
                  :payment-method="item"
                  :isEdit="true"
                  @onPaymentMethod="fetchPaymentMethods"
                  v-if="hasEdit('customers')"
                />

                <delete-icon-button
                  @click="removePayment(item)"
                  v-if="hasDelete('customers')"
                />
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/ui/DialogCard.vue";
import DeleteIconButton from "@/components/ui/buttons/DeleteIconButton.vue";
import AddClientPaymentMethodDialog from "./AddClientPaymentMethodDialog.vue";
import PleaseWait from "@/components/ui/PleaseWait.vue";
import { collection, getDocs } from "firebase/firestore";
import { colClients, functions } from "@/assets/utils/firebase.utils";
import { mapDocs } from "@/assets/utils/doc.utils";
import { clientPaymentTypes } from "@/assets/constants/client.constants";
import { httpsCallable } from "firebase/functions";

export default {
  components: {
    DialogCard,
    DeleteIconButton,
    AddClientPaymentMethodDialog,
    PleaseWait,
  },
  data() {
    return {
      dialog: false,
      isLoading: true,
      list: [],
    };
  },
  methods: {
    removePayment({ id }) {
      this.$confirm.show({
        message: `Are you sure, Do you want to remove this?`,
        onConfirm: async () => {
          let addMethod = httpsCallable(functions, "manageClientPaymentMethod");
          await addMethod({
            type: "delete-pm",
            customer: this.$route.params.id,
            id: id,
          });
          this.fetchPaymentMethods();
        },
      });
    },
    async fetchPaymentMethods() {
      try {
        this.isLoading = true;
        let col = collection(
          colClients,
          this.$route.params.id,
          "payment_methods"
        );
        this.list = mapDocs((await getDocs(col)).docs).map((i) => {
          let paymentMethod = clientPaymentTypes.find(
            (p) => p.value == i.payment_method
          );
          if (paymentMethod) {
            i.logo = paymentMethod.logo;
            i.title = i[paymentMethod.title];
          }
          return i;
        });
        this.isLoading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchPaymentMethods();
  },
};
</script>
