<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <v-btn x-small icon>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </span>
    </template>
    <dialog-card
      title="Update Schedule"
      :loading="loading"
      @close="dialog = false"
      @submit="submit"
    >
      <select-field
        :items="faxSchedules"
        item-text="schedule_name"
        label="Schedule this letter"
        v-model="schedule"
      ></select-field>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { httpsCallable } from "firebase/functions";
import DialogCard from "../ui/DialogCard.vue";
import { functions } from "@/assets/utils/firebase.utils";
import SelectField from "../ui/form/SelectField.vue";
export default {
  components: { DialogCard, SelectField },
  props: {
    item: Object,
  },
  data() {
    return {
      dialog: false,
      schedule: "",
      loading: false,
    };
  },
  watch: {
    dialog(val) {
      if (val) this.$emit("close");
    },
  },
  inject: ["faxSchedules"],
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        let doc = {
          ids: [this.item.id],
          scheduleId: this.schedule,
        };

        let updateFaxSchedule = httpsCallable(functions, "updateFaxSchedule");
        let { status, message } = (await updateFaxSchedule(doc)).data;
        if (!status) {
          vm.notifyError(message);
        }
        vm.dialog = false;
        vm.loading = false;
        vm.$emit("onScheduleChange");
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.schedule = this.item.schedule || "";
  },
};
</script>

<style></style>
