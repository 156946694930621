<template>
  <div class="text-center">
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LoaderModal from "./index";
export default {
  data() {
    return {
      loader: false,
    };
  },
  beforeMount() {
    LoaderModal.EventBus.$on("show", () => {
      this.loader = true;
    });
    LoaderModal.EventBus.$on("hide", () => {
      this.loader = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  height: 70px;
}
.v-card__text {
  padding-top: 17px !important;
}
</style>
