<template>
  <layout :title="title" :loading="isLoading">
    <form-layout>
      <form-wrapper @submit="submit()">
        <v-row>
          <v-col cols="12" md="6" class="pb-0">
            <input-field
              label="Schedule Name"
              rules="required"
              v-model="form.schedule_name"
            ></input-field>
          </v-col>
          <v-col cols="12" md="6" class="pb-0 pt-0"></v-col>
          <v-col cols="12" md="6" class="pb-0 pt-2">
            <label>Frequency</label>
            <v-row no-gutters class="mt-1">
              <v-col cols="6" class="pr-2 d-flex pb-0">
                <span class="mt-2 mr-2"> Every </span>
                <input-field
                  rules="required"
                  type="number"
                  v-model="form.frequency"
                ></input-field>
              </v-col>
              <v-col cols="6" class="pl-2 pb-0">
                <select-field
                  rules="required"
                  :items="frequencyUnits"
                  v-model="form.frequency_unit"
                ></select-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" class="pb-0 pt-2">
            <select-field
              label="Start Day"
              rules="required"
              :items="weekDays"
              v-if="form.frequency_unit == 'Weeks'"
              v-model="form.start_day"
            >
            </select-field>
            <date-field
              label="Start Date"
              rules="required"
              v-if="form.frequency_unit == 'Months'"
              v-model="form.start_date"
            >
            </date-field>
          </v-col>
          <v-col cols="12" md="6" class="pb-0">
            <v-row no-gutters>
              <v-col cols="6" class="pr-2 pb-0">
                <select-field
                  rules="required"
                  label="Start Time"
                  :items="startTimes"
                  v-model="form.start_time"
                ></select-field>
              </v-col>
              <v-col cols="6" class="pl-2 pb-0">
                <select-field
                  rules="required"
                  class="mt-7"
                  :items="['AM', 'PM']"
                  v-model="form.start_time_unit"
                ></select-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" class="pb-0">
            <select-field
              label="Time Window (hours)"
              rules="required"
              :items="timeWindows"
              outlined
              dense
              v-model="form.time_window"
            >
            </select-field>
          </v-col>
          <v-col cols="6" class="pb-0">
            <input-field
              label="Alert Days"
              v-model="form.alert_days"
              type="number"
            ></input-field>
          </v-col>
          <v-col cols="6" class="py-0"></v-col>
          <v-col cols="8" class="pb-0">
            <v-checkbox
              class="mt-0 pt-0"
              dense
              v-model="form.is_autoturn_on"
              label="Automatic Turn On - Select Date and Time"
            />
            <v-row class="ml-4" v-if="form.is_autoturn_on">
              <v-col cols="6" class="py-0">
                <date-field
                  label="Date"
                  v-model="form.autoturn_on.date"
                  rules="required"
                />
              </v-col>
              <v-col cols="6" class="py-0">
                <label class="body-small">Time</label>
                <v-row class="pt-1">
                  <v-col cols="6" class="pr-0">
                    <select-field
                      v-model="form.autoturn_on.hour"
                      :items="hours"
                      rules="required"
                    />
                  </v-col>
                  <v-col cols="6">
                    <select-field
                      v-model="form.autoturn_on.am"
                      :items="['AM', 'PM']"
                      rules="required"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4" class="py-0"></v-col>
          <v-col cols="8" class="pb-0">
            <v-checkbox
              class="mt-0 pt-0"
              dense
              v-model="form.is_autoturn_off"
              label="Automatic Turn Off - Select Date and Time"
            />
            <v-row class="ml-4" v-if="form.is_autoturn_off">
              <v-col cols="6" class="py-0">
                <date-field
                  label="Date"
                  v-model="form.autoturn_off.date"
                  rules="required"
                />
              </v-col>
              <v-col cols="6" class="py-0">
                <label class="body-small">Time</label>
                <v-row class="pt-1">
                  <v-col cols="6" class="pr-0">
                    <select-field
                      v-model="form.autoturn_off.hour"
                      :items="hours"
                      rules="required"
                    />
                  </v-col>
                  <v-col cols="6">
                    <select-field
                      v-model="form.autoturn_off.am"
                      :items="['AM', 'PM']"
                      rules="required"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div class="d-flex">
          <v-spacer></v-spacer>
          <close-button :to="{ name: 'fax-schedules' }" :disabled="loading"
            >Close</close-button
          >
          <secondary-button :loading="loading" type="submit" class="ml-md-4"
            >Submit</secondary-button
          >
        </div>
      </form-wrapper>
    </form-layout>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import InputField from "@/components/ui/form/InputField.vue";
import SelectField from "@/components/ui/form/SelectField.vue";
import CloseButton from "@/components/ui/buttons/CloseButton.vue";
import SecondaryButton from "@/components/ui/buttons/SecondaryButton.vue";
import FormWrapper from "@/components/ui/form/FormWrapper.vue";
import FormLayout from "@/components/ui/FormLayout.vue";
import DateField from "@/components/ui/form/DateField.vue";
import { getDate, initForm } from "@/assets/utils/common.utils";
import { colFaxSchedules, functions } from "@/assets/utils/firebase.utils";
import {
  addDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
export default {
  components: {
    Layout,
    InputField,
    SelectField,
    CloseButton,
    SecondaryButton,
    FormWrapper,
    FormLayout,
    DateField,
  },
  data() {
    return {
      loading: false,
      isLoading: false,
      form: {
        frequency: "",
        frequency_unit: "",
        schedule_name: "",
        start_day: "Monday",
        start_time: "",
        start_date: "",
        start_time_unit: "",
        time_window: "",
        alert_days: 0,
        is_autoturn_off: false,
        autoturn_off: {
          hour: "",
          am: "AM",
        },
        is_autoturn_on: false,
        autoturn_on: {
          hour: "",
          am: "AM",
        },
      },

      frequencyUnits: ["Days", "Weeks", "Months"],
      weekDays: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
    };
  },
  computed: {
    hours() {
      let list = [];
      for (let i = 1; i < 13; i++) {
        if (i < 10) {
          list.push(`0${i}`);
        } else {
          list.push(i.toString());
        }
      }
      return list;
    },
    startTimes() {
      let list = [];
      for (let i = 0; i < 13; i++) {
        list.push(i.toString());
      }
      return list;
    },
    timeWindows() {
      let list = [];
      for (let i = 1; i < 25; i++) {
        list.push(i);
      }
      return list;
    },
    scheduleId() {
      return this.$route.params.id;
    },

    isUpdate() {
      return this.$route.name == "update-fax-schedule";
    },
    title() {
      return this.isUpdate ? "Edit Fax Schedule" : "Add New Fax Schedule";
    },
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        let schedule = {
          updated_by: vm.userEmail,
          ...vm.form,
          updated_at: getDate(),
        };
        let { scheduleId } = vm;
        let { schedule_name } = vm.form;
        if (schedule.frequency_unit != "Weeks") schedule.start_day = "";
        if (schedule.frequency_unit != "Months") schedule.start_date = "";
        let schedule_name_lower = schedule_name.trim().toString().toLowerCase();
        schedule = { ...schedule, schedule_name_lower };
        let q = query(
          colFaxSchedules,
          where("schedule_name_lower", "==", schedule_name_lower),
          limit(1)
        );
        let snap = (await getDocs(q)).docs;
        if (snap.length != 0 && scheduleId != snap[0].id) {
          vm.loading = false;
          vm.notifyError(
            "Another schedule exists with the same schedule name. Please enter a unique schedule name."
          );
          return;
        }
        if (scheduleId) {
          await updateDoc(doc(colFaxSchedules, scheduleId), schedule);
        } else {
          schedule.status = "Running";
          schedule.status_updated_at = getDate();
          schedule.created_at = getDate();
          await addDoc(colFaxSchedules, schedule);
        }
        if (!scheduleId) {
          const onScheduleUpdate = httpsCallable(
            functions,
            "onScheduleUpdateAction"
          );
          let { status, message } = (await onScheduleUpdate({})).data;
          if (!status) {
            vm.notifyError(message);
          }
        }
        vm.loading = false;
        vm.$router.push({ name: "fax-schedules" });
      } catch (error) {
        vm.loading = false;
        vm.notifyError(error.message);
      }
    },
    async initSchedule() {
      const vm = this;
      if (!vm.scheduleId) {
        vm.isLoading = false;
        return;
      }
      try {
        vm.isLoading = true;
        let ref = doc(colFaxSchedules, vm.scheduleId);
        let schedule = (await getDoc(ref)).data();
        if (schedule == null) {
          vm.notifyError("Fax Schedule not found");
          vm.$router.push({ name: "fax-schedules" });
          return;
        }
        initForm(vm.form, schedule);
        vm.isLoading = false;
      } catch (error) {
        vm.notifyError(error.message);
      }
    },
  },
  mounted() {
    this.initSchedule();
  },
};
</script>

<style></style>
