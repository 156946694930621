<template>
  <v-dialog v-model="dialog" max-width="600px" persistent scrollable>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on"> <v-icon>mdi-history</v-icon> </v-btn>
    </template>
    <dialog-card title="Task History" @close="dialog = false" :isSubmit="false">
      <static-table-view
        :headers="headers"
        :list="items"
        :loading="loading"
        :isTop="false"
      >
      </static-table-view>
      <div slot="footer"></div>
    </dialog-card>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/ui/DialogCard.vue";
import StaticTableView from "@/components/ui/StaticTableView.vue";
import { getHeaders } from "@/assets/utils/common.utils";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { colTeamSchedules } from "@/assets/utils/firebase.utils";
import { mapDocs } from "@/assets/utils/doc.utils";
export default {
  components: { DialogCard, StaticTableView },
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      dialog: false,
    };
  },
  computed: {
    headers() {
      let doc = {
        name: "Name",
        action: "Action",
        created_at: "Created At",
      };
      return getHeaders(doc);
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.fetchHistory();
      }
    },
  },
  methods: {
    async fetchHistory() {
      try {
        this.loading = true;
        let historyCol = collection(colTeamSchedules, this.task.id, "history");
        let historyQuery = query(historyCol, orderBy("created_at", "desc"));
        this.items = mapDocs((await getDocs(historyQuery)).docs);
        this.loading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>

<style></style>
