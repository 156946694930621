<template>
  <v-container
    class="mt-8"
    :style="{ width: $root.isMobile ? '100%' : '600px' }"
  >
    <please-wait v-if="isLoading"> </please-wait>
    <template v-else>
      <div v-html="text"></div>
      <v-dialog v-model="dialog" max-width="500px" persistent>
        <template v-slot:activator="{ on }">
          <v-btn
            class="primary mt-5"
            tile
            :loading="loading"
            v-if="isPayout && canPayout"
            depressed
            v-on="on"
            >Mark as Paid</v-btn
          >
        </template>
        <dialog-card
          title="Confirm"
          @close="dialog = false"
          @submit="submit"
          :loading="loading"
        >
          <select-field
            label="Payment Method"
            :items="paymentMethods"
            v-model="payment_method"
            rules="required"
          ></select-field>
          <b class="black--text body-medium"
            >Are you Sure you want to proceed?</b
          >
          <div class="d-flex" slot="footer">
            <v-spacer></v-spacer>
            <close-button @click="dialog = false" :disabled="loading"
              >No</close-button
            >
            <secondary-button
              type="submit"
              :loading="loading"
              :disabled="loading"
              >Yes</secondary-button
            >
          </div>
        </dialog-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
import moment from "moment";
import PleaseWait from "@/components/ui/PleaseWait.vue";
import {
  colAffiliates,
  colCommissions,
  functions,
} from "@/assets/utils/firebase.utils";
import { getDocs, query, where } from "firebase/firestore";
import { mapDocs } from "@/assets/utils/doc.utils";
import { httpsCallable } from "firebase/functions";
import SelectField from "@/components/ui/form/SelectField.vue";
import DialogCard from "@/components/ui/DialogCard.vue";
import SecondaryButton from "@/components/ui/buttons/SecondaryButton.vue";
import CloseButton from "@/components/ui/buttons/CloseButton.vue";
export default {
  components: {
    PleaseWait,
    SelectField,
    DialogCard,
    SecondaryButton,
    CloseButton,
  },
  data() {
    return {
      text: "",
      isPayout: false,
      loading: false,
      isLoading: true,
      payment_method: "",
      dialog: false,
    };
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        let payoutAction = httpsCallable(functions, "payoutAction");
        let data = {
          weekDate: vm.$route.query.date,
          payment_method: vm.payment_method,
          type: "weekly-payout",
        };
        await payoutAction(data);
        vm.loading = false;
        vm.dialog = false;
        vm.init();
      } catch (error) {
        vm.notifyError(error.message);
        vm.loading = false;
      }
    },

    getList(query) {
      return new Promise((resolve) => {
        let list = [];
        query.get().then((snap) => {
          snap.docs.forEach((item) => {
            let doc = item.data();
            doc.id = item.id;
            list.push(doc);
          });
          resolve(list);
        });
      });
    },
    getCommission({ id, commission_rate }, transactionList, customerList) {
      return new Promise((resolve) => {
        let total = 0;
        let commission_paid = 0;
        let commission = 0;
        let paid = 0;
        if (isNaN(commission_rate)) commission_rate = 0;
        if (commission_rate >= 1) {
          return resolve({ commission });
        }
        transactionList.forEach((item) => {
          let { customer_id, amount, payout } = item;
          customerList.forEach((cus) => {
            let { affiliate_id } = cus;
            let isCustomer = cus.id && cus.id === customer_id;
            let isAffiliate = affiliate_id && affiliate_id === id;
            if (isCustomer && isAffiliate) {
              amount = Number(amount);
              if (isNaN(amount)) amount = 0;
              if (payout === false) {
                total = Number(total) + amount;
              } else {
                paid = Number(paid) + amount;
              }
            }
          });
        });
        commission = total * commission_rate;
        commission = commission.toFixed(2);
        commission_paid = paid * commission_rate;
        commission_paid = commission_paid.toFixed(2);
        return resolve({ commission, commission_paid });
      });
    },
    async init() {
      const vm = this;
      try {
        vm.isLoading = true;
        let affiliates = mapDocs((await getDocs(colAffiliates)).docs);
        let weekDate = vm.$route.query.date;
        let startDate = moment(weekDate)
          .subtract("days", 7)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss");
        let endDate = moment(weekDate)
          .subtract("days", 1)
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss");
        console.log(startDate, endDate);
        let month = moment(startDate).format("MMM DD YYYY");
        let commissionQuery = query(
          colCommissions,
          where("created_at", ">=", startDate),
          where("created_at", "<=", endDate)
        );
        let transactions = mapDocs((await getDocs(commissionQuery)).docs);
        let message = "";
        let paid = "";
        for (let item of affiliates) {
          let commission = 0;
          let commission_paid = 0;
          transactions
            .filter((i) => i.affiliate_id == item.id)
            .forEach((i) => {
              console.log(i.payout);
              if (i.payout == false) {
                commission += parseFloat(i.commission);
              } else {
                commission_paid += parseFloat(i.commission);
              }
            });

          let { first_name, last_name } = item;
          if (commission > 0) {
            message += `<tr><td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${first_name} ${last_name}</td><td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">$${commission.toFixed(
              2
            )}</td>`;
          }
          if (commission_paid > 0) {
            paid += `<tr><td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${first_name} ${last_name}</td><td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">$${commission_paid.toFixed(
              2
            )}</td>`;
          }
        }
        if (message !== "") {
          vm.text = `<p>The following commissions for the week of ${month} are ready to be paid:</p><br/>
                <table style="border-collapse: collapse;width:100%">
                <tr>
                    <th style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Affiliate Name</th>
                    <th style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Commission Amount</th>
                </tr>
                ${message}</table>
                <br/>
                `;
          vm.isPayout = true;
        } else if (paid !== "") {
          vm.text = `<p>The following commissions for the week of ${month} were already paid:</p><br/>
                <table style="border-collapse: collapse; width: 100%">
                <tr>
                    <th style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Affiliate Name</th>
                    <th style="border: 1px solid #dddddd;text-align: left;padding: 8px;">Commission Amount</th>
                </tr>
                ${paid}</table>
                `;
        }
        vm.isLoading = false;
      } catch (error) {
        vm.isLoading = false;
        vm.notifyError(error.message);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
