<template>
  <v-tabs
    active-class="white accent--text"
    background-color="transparent"
    class="app-nav"
    v-model="tab"
    centered
    hide-slider
  >
    <v-tab
      class="text-none"
      @click="$router.push({ name: tab.path })"
      v-for="(tab, key) in tabs"
      :disabled="isDisabled(tab)"
      :key="key"
      >{{ tab.name }}</v-tab
    >
  </v-tabs>
</template>
<script>
import { headerTabs, myCompanyTabs } from "@/assets/constants/nav.constants";
export default {
  data() {
    return {
      tab: 0,
      whitelist: {
        affiliates: [
          "affiliate-transaction",
          "add-affiliate",
          "update-affiliate",
        ],
        "transaction-history": ["weeklypayout"],
        "dispute-letters": ["copy-dispute-letter", "edit-send-letter"],
      },
    };
  },
  computed: {
    tabs() {
      return headerTabs;
    },
  },
  watch: {
    $route() {
      this.initNavbar();
    },
  },
  methods: {
    isDisabled({ permission }) {
      if (permission == "my-company") {
        let tab = myCompanyTabs.find((i) => this.hasRead(i.permission));
        return tab == null;
      }
      if (permission == "") return false;
      return !this.hasPermission(permission, "view");
    },
    initNavbar() {
      let name = this.$route?.matched[0]?.name || this.$route.name;
      Object.keys(this.whitelist).forEach((key) => {
        if (this.whitelist[key].indexOf(name) != -1) {
          name = key;
        }
      });
      this.tab = this.tabs.findIndex((i) => i.path == name);
    },
  },
  mounted() {
    this.initNavbar();
  },
};
</script>

<style></style>
