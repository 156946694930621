<template>
  <div>
    <form-wrapper>
      <v-row>
        <v-col cols="4" class="d-flex align-end">
          <input-field
            hide-details
            v-model="form.search"
            class="flex-1"
            @input="onSearch"
            clearable
            :label="isAdvance ? 'Search by Reference No' : ''"
            placeholder="Search by Reference No"
          />
        </v-col>
        <template v-if="isAdvance">
          <v-col cols="2">
            <date-field hide-details v-model="form.from" label="Added From" />
          </v-col>
          <v-col cols="2">
            <date-field hide-details v-model="form.to" label="Added To" />
          </v-col>
          <v-col cols="4"> </v-col>
          <v-col cols="3">
            <autocomplete
              v-model="form.affiliate_id"
              placeholder="All"
              clearable
              :items="affiliates"
              hide-details
              label="Affiliate Name"
            />
          </v-col>
          <v-col cols="3">
            <select-field
              v-model="form.payment_method"
              placeholder="All"
              clearable
              :items="paymentMethods"
              hide-details
              label="Payment Method"
            />
          </v-col>
          <v-col cols="6" class="d-flex align-end mb-3">
            <select-field
              hide-details
              placeholder="All"
              label="Transaction Type"
              clearable
              v-model="form.client_type"
              :items="clientTypes"
              style="max-width: 120px"
              class="mr-4"
            />
            <primary-button @click="applyFilter()">Search</primary-button>
            <text-button @click="isAdvance = false">Basic Search</text-button>
            <text-button @click="resetFilter()"> Reset Filter</text-button>
          </v-col>
        </template>
      </v-row>
      <v-btn
        text
        v-if="!isAdvance"
        @click="isAdvance = true"
        class="primary--text px-0 mt-2"
        >Advanced Search</v-btn
      >
    </form-wrapper>
  </div>
</template>

<script>
import { clientTypes } from "@/assets/constants/client.constants";
import TextButton from "../ui/buttons/TextButton.vue";
import Autocomplete from "../ui/form/Autocomplete.vue";
import DateField from "../ui/form/DateField.vue";
import FormWrapper from "../ui/form/FormWrapper.vue";
import InputField from "../ui/form/InputField.vue";
import SelectField from "../ui/form/SelectField.vue";
import PrimaryButton from "../ui/buttons/PrimaryButton.vue";
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isAdvance: false,
      clientTypes,
      form: {},
    };
  },
  components: {
    FormWrapper,
    InputField,
    SelectField,
    DateField,
    TextButton,
    Autocomplete,
    PrimaryButton,
  },
  methods: {
    resetFilter() {
      this.form = {};
      this.$emit("input", {});
    },
    onSearch() {
      if (this.isAdvance) return;
      this.$emit("input", { search: this.form.search });
    },
    applyFilter() {
      let form = { ...this.form };
      this.$emit("input", form);
    },
  },
};
</script>

<style></style>
