import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../pages/account/Login.vue";
import ForgotPassword from "../pages/account/ForgotPassword.vue";
import TransactionHistory from "@/pages/transactions/TransactionHistory.vue";
import { isAuth, isAuthLogin } from "@/assets/utils/auth.utils";
import store from "@/store";
import DisputeLetters from "@/pages/disputeLetters/DisputeLetters.vue";
import clientRoutes from "./modules/client.routes";
import affiliateRoutes from "./modules/affiliate.routes";
import myCompanyRoutes from "./modules/my.company.routes";
import SendLetters from "@/pages/clients/SendLetters.vue";
import CopyDisputeLetter from "@/pages/clients/CopyDisputeLetter.vue";
import Schedule from "@/pages/Schedule.vue";
import WeeklyPayout from "@/pages/transactions/WeeklyPayout.vue";

Vue.use(VueRouter);

const routes = [
  ...clientRoutes,
  ...myCompanyRoutes,
  ...affiliateRoutes,
  {
    name: "schedule",
    path: "/schedule",
    component: Schedule,
    beforeEnter: isAuth,
    meta: {
      permission: "view",
      way: "schedule",
    },
  },
  {
    name: "transaction-history",
    path: "/transaction-history",
    component: TransactionHistory,
    beforeEnter: isAuth,
    meta: {
      permission: "view",
      way: "transaction-log",
    },
  },
  {
    name: "weeklypayout",
    path: "/weeklypayout",
    component: WeeklyPayout,
    beforeEnter: isAuth,
    meta: {
      permission: "view",
      way: "transaction-log",
    },
  },
  {
    name: "dispute-letters",
    path: "/dispute-letters",
    component: DisputeLetters,
    beforeEnter: isAuth,
    meta: {
      permission: "view",
      way: "send-faxes",
    },
  },
  {
    path: "/copy-dispute-letter/:id",
    name: "copy-dispute-letter",
    component: CopyDisputeLetter,
    beforeEnter: isAuth,
    meta: {
      permission: "edit",
      way: "send-faxes",
    },
  },
  {
    path: "/edit-send-letter/:id",
    name: "edit-send-letter",
    beforeEnter: isAuth,
    component: SendLetters,
    meta: {
      permission: "edit",
      way: "send-faxes",
    },
  },
  {
    name: "login",
    path: "/login",
    component: Login,
    beforeEnter: isAuthLogin,
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: ForgotPassword,
    beforeEnter: isAuthLogin,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  let user = store.state.user.user;
  if (Object.keys(user).length == 0) {
    document.getElementById("loader-view").style.display = "flex";
  }
  next();
});
router.afterEach(() => {
  document.getElementById("loader-view").style.display = "none";
});

export default router;
