<template>
  <form-wrapper @submit="submit()">
    <v-card outlined class="mb-7">
      <v-data-table
        :headers="headers"
        :items="items"
        @click:row="selectAccount"
        :items-per-page="-1"
        :loading="isLoading"
        class="affiliate-table"
        hide-default-footer
      >
        <template v-slot:header.equifax="">
          <equifax-img></equifax-img>
        </template>
        <template v-slot:header.experian="">
          <experian-img></experian-img>
        </template>
        <template v-slot:header.transunion="">
          <transunion-img></transunion-img>
        </template>
        <template v-slot:item.name="{ value, item }">
          <v-checkbox
            @change="onAccountChange"
            @click.stop=""
            :value="getAccountValue(item)"
            v-model="accounts"
            :label="value"
          />
        </template>
        <template v-slot:item.account="{ value }">
          <span v-html="value"></span>
        </template>
        <template v-slot:item.equifax="{ value }">
          <credit-report-status :status="value"></credit-report-status>
        </template>
        <template v-slot:item.transunion="{ value }">
          <credit-report-status :status="value"></credit-report-status>
        </template>
        <template v-slot:item.experian="{ value }">
          <credit-report-status :status="value"></credit-report-status>
        </template>
      </v-data-table>
    </v-card>
    <div style="max-width: 300px">
      <select-field
        dense
        label="Cover Letter Template"
        :items="filteredTemplates"
        rules="required"
        item-text="template_name"
        clearable
        v-model="form.template"
      ></select-field>
    </div>
    <primary-button
      type="submit"
      v-if="hasEdit('send-faxes')"
      :loading="loading"
      >Continue</primary-button
    >
  </form-wrapper>
</template>

<script>
import SelectField from "@/components/ui/form/SelectField.vue";
import CreditReportStatus from "../creditReport/CreditReportStatus.vue";
import EquifaxImg from "../EquifaxImg.vue";
import ExperianImg from "../ExperianImg.vue";
import TransunionImg from "../TransunionImg.vue";
import { formatDateOnly, sortUnspecified } from "@/assets/utils/common.utils";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
import FormWrapper from "@/components/ui/form/FormWrapper.vue";
export default {
  components: {
    EquifaxImg,
    ExperianImg,
    TransunionImg,
    CreditReportStatus,
    SelectField,
    PrimaryButton,
    FormWrapper,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      accounts: [],
      form: {
        accounts: [],
        template: "",
      },
    };
  },
  inject: ["templates", "report", "handleStepOneSubmit", "customer"],
  computed: {
    filteredTemplates() {
      return [...this.templates].filter((item) => {
        if (this.accounts.length == 1)
          return item.template_name_lower.indexOf("single") != -1;
        if (this.accounts.length > 1)
          return item.template_name_lower.indexOf("multiple") != -1;
        return false;
      });
    },
    items() {
      if (!this.report.accounts) return [];
      let list = [...this.report.accounts]
        .map((i) => {
          let statusList = ["Negative", "In Dispute", "Unspecified", "Deleted"];
          let equifax =
            sortUnspecified(i.list).find(
              (a) => a.bureau == "Equifax" && statusList.indexOf(a.status) != -1
            ) || {};
          let transunion =
            i.list.find(
              (a) =>
                a.bureau == "TransUnion" && statusList.indexOf(a.status) != -1
            ) || {};
          let experian =
            i.list.find(
              (a) =>
                a.bureau == "Experian" && statusList.indexOf(a.status) != -1
            ) || {};

          let account = `
          Equifax: ${this.getAccountNumber(equifax, i.type) || ""}<br/>
          Experian: ${this.getAccountNumber(experian, i.type) || ""}<br/>
          Transunion: ${this.getAccountNumber(transunion, i.type) || ""}<br/>
          `;
          let account_number = "";
          let date_filed = "";
          let bureaus = {};
          if (equifax.account_name) {
            if (equifax.account_number) account_number = equifax.account_number;
            if (equifax.date_filed) {
              date_filed = formatDateOnly(equifax.date_filed);
            }
            bureaus["Equifax"] = {
              account: equifax.account_number || "",
              name: equifax.account_name,
              status: equifax.status,
            };
          }
          if (experian.account_name) {
            if (experian.account_number) {
              account_number = experian.account_number;
            }
            if (experian.date_filed) {
              date_filed = formatDateOnly(experian.date_filed);
            }
            bureaus["Experian"] = {
              account: experian.account_number || "",
              name: experian.account_name,
              status: experian.status,
            };
          }
          if (transunion.account_name) {
            if (transunion.account_number) {
              account_number = transunion.account_number;
            }
            if (transunion.date_filed) {
              date_filed = formatDateOnly(transunion.date_filed);
            }
            bureaus["TransUnion"] = {
              account: transunion.account_number || "",
              name: transunion.account_name,
              status: transunion.status,
            };
          }
          if (!equifax.status && !experian.status && !transunion.status) {
            return null;
          }

          return {
            ...i,
            account,
            account_number,
            date_filed,
            bureaus,
            equifax: equifax.status || "",
            experian: experian.status || "",
            transunion: transunion.status || "",
          };
        })
        .filter((item) => item != null);
      let inquiryItems = list.filter((i) => i.type == "inquiry");
      let nonInquiryItems = list.filter((i) => i.type != "inquiry");
      return [...inquiryItems, ...nonInquiryItems];
    },
    headers() {
      let doc = {
        name: "Creditor/Furnisher",
        account: "Account Number",
        equifax: "equifax",
        experian: "experian",
        transunion: "transunion",
      };
      return Object.keys(doc).map((value) => ({
        value,
        text: doc[value],
        class: "py-3",
        cellClass: "py-3",
      }));
    },
  },
  methods: {
    getAccountValue({ account_number, name, account_id }) {
      return `${account_number} ${name} ${account_id || ""}`;
    },
    getAccountNumber({ account_number, date_filed }, type) {
      if (type == "inquiry") {
        if (date_filed) return formatDateOnly(date_filed);
        return "";
      }
      return account_number || "";
    },

    selectAccount(item) {
      let val = this.getAccountValue(item);
      let index = this.accounts.indexOf(val);
      if (index == -1) {
        this.accounts.push(val);
      } else {
        this.accounts.splice(index, 1);
      }
      this.onAccountChange();
    },
    onAccountChange(isReport) {
      this.form.accounts = this.items
        .filter(
          (item) => this.accounts.indexOf(this.getAccountValue(item)) != -1
        )
        .map((item) => ({
          name: item.name,
          account_id: item.account_id || "",
          bureaus: item.bureaus,
          account_number: item.account_number,
        }));
      if (!isReport) {
        this.form.template = "";
      }
    },
    async initReport() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        const vm = this;
        try {
          vm.isLoading = true;
          let return_address = {};
          if (vm.report.previous_address) {
            return_address = vm.getReturnAddress(vm.report.previous_address);
          }
          return_address = vm.getReturnAddress(vm.customer);
          if (return_address.street) vm.$emit("input", { return_address });
          vm.accounts = vm.accounts.filter((item) => {
            let a = vm.items.find((i) => {
              return vm.getAccountValue(i) === item;
            });
            return a != null;
          });
          if (vm.accounts.length == 0) {
            this.form.template = "";
          }
          vm.onAccountChange(true);
          vm.isLoading = false;
          resolve();
        } catch (error) {
          vm.isLoading = false;
          vm.$notifyError(error.message);
          resolve();
        }
      });
    },
    getReturnAddress(address) {
      let return_address = "";
      try {
        let { street, postal_code, state, city } = address;
        const add5 = (str) => {
          let list = str.toString().split(" ");
          if (typeof parseInt(list[0]) == "number") {
            let s = list[0];
            let lastChar = (Number(s.charAt(s.length - 1)) + 5).toString();
            s = setCharAt(
              s,
              s.length - 1,
              lastChar.toString().charAt(lastChar.length - 1)
            );
            if (s.length > 2) {
              let secondLastChar = (
                Number(s.charAt(s.length - 2)) + 5
              ).toString();
              s = setCharAt(
                s,
                s.length - 2,
                secondLastChar.toString().charAt(secondLastChar.length - 1)
              );
            }
            list[0] = s;
            return list.join(" ");
          } else {
            return str;
          }
          function setCharAt(str, index, chr) {
            if (index > str.length - 1) return str;
            return str.substring(0, index) + chr + str.substring(index + 1);
          }
        };
        street = add5(street);
        postal_code = add5(postal_code);
        return {
          street,
          postal_code,
          city,
          state,
        };
      } catch (error) {
        console.log("error while processsing the address", error);
      }
      return return_address;
    },
    init() {
      Object.keys(this.form).forEach((key) => {
        if (this.value[key] != undefined) {
          this.form[key] = this.value[key];
        }
      });
      this.accounts = this.form.accounts.map((i) => this.getAccountValue(i));
      this.initReport();
    },
    async submit() {
      if (this.form.accounts.length == 0) {
        return this.notifyError("Please select at least one account.");
      }
      let whiteListedAccount = [];
      this.form.accounts.forEach((a) => {
        let account = Object.keys(a.bureaus)
          .map((k) => a.bureaus[k])
          .find((i) => ["Deleted", "Unspecified"].indexOf(i.status) != -1);
        if (account == null) whiteListedAccount.push(a);
      });
      if (whiteListedAccount.length > 5) {
        return this.notifyError("Maximum 5 accounts can be selected.");
      }
      try {
        this.loading = true;
        await this.handleStepOneSubmit(this.form);
        this.loading = false;
        this.$emit("next");
      } catch (error) {
        this.handleError(error);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
