<template>
  <select-field
    v-model="input"
    v-bind="$attrs"
    @input="$emit('input', input)"
    :items="clientStatusTypes"
  >
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item v-on="on" v-bind="attrs">
        <client-status :type="item.value"></client-status>
      </v-list-item>
    </template>
    <template v-slot:selection="{ item }">
      <client-status :type="item.value"></client-status>
    </template>
  </select-field>
</template>
<script>
import SelectField from "../ui/form/SelectField.vue";
import ClientStatus from "./ClientStatus.vue";
export default {
  components: { SelectField, ClientStatus },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      input: "",
    };
  },
  mounted() {
    this.input = this.value || "";
  },
};
</script>

<style>
</style>