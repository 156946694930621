<template>
  <div class="my-3">
    <!-- <v-chip dark v-if="type" :color="chipColor">{{ type }}</v-chip> -->
    <v-data-table
      :loading="loading"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="50"
      :must-sort="true"
      :footer-props="{
        'items-per-page-options': [10, 20, 50, 100, 500],
        'show-first-last-page': false,
      }"
      class="mt-4"
      :headers="headers"
      :items="items"
    >
      <template v-slot:item.subject="{ item, value }">
        <div class="d-flex align-center">
          <div v-if="selectedTaskId == item.id && loading" width="30px">
            <v-progress-circular indeterminate />
          </div>
          <v-simple-checkbox
            hide-details
            color="primary"
            :disabled="loading"
            class="mt-0 pt-0"
            @click="onChange(!item.is_completed, item)"
            :value="item.is_completed"
            v-else-if="item.type == 'task'"
          />
          <v-icon v-else>mdi-clock-outline</v-icon>
          <div class="ml-3">
            {{ value }}
          </div>
        </div>
      </template>
      <template v-slot:item.client_name="{ item, value }">
        <router-link
          v-if="item.client"
          :to="{ name: 'client-profile', params: { id: item.client } }"
          >{{ value }}</router-link
        >
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex">
          <add-schedule-event
            v-if="hasEdit('schedule')"
            @onEventUpsert="fetchEvents()"
            :type="item.type"
            :item="item"
            :isEdit="true"
          />
          <delete-icon-button
            @click="deleteEvent(item)"
            v-if="hasDelete('schedule')"
          />
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from "lodash";
import {
  and,
  doc,
  getCountFromServer,
  getDocs,
  or,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { colTeamSchedules } from "@/assets/utils/firebase.utils";
import { mapDocs } from "@/assets/utils/doc.utils";
import moment from "moment-timezone";
import DeleteIconButton from "../ui/buttons/DeleteIconButton.vue";
import AddScheduleEvent from "./AddScheduleEvent.vue";
import { getDate, getTaskCustomers } from "@/assets/utils/common.utils";
import scheduleMixin from "@/mixins/schedule.mixin";
export default {
  components: { DeleteIconButton, AddScheduleEvent },
  props: {
    type: String,
    scheduleType: String,
    completedTasks: Boolean,
    member: String,
  },
  data() {
    return {
      items: [],
      options: {},
      loading: true,
      total: 20,
      selectedTaskId: null,
      customers: [],
    };
  },
  computed: {
    chipColor() {
      switch (this.type) {
        case "Overdue":
          return "error";
        case "Upcoming":
          return "warning";
        default:
          return "success";
      }
    },
    headers() {
      let headers = {
        subject: "Task/Event",
        client_name: "Client",
        member_name: "Team Member",
        due_date: "Due Date",
        action: "Action",
      };
      if (!this.hasEdit("schedule") && !this.hasDelete("schedule")) {
        delete headers.action;
      }
      let width = {
        due_date: "130px",
        action: "50px",
      };
      return Object.keys(headers).map((value) => ({
        value,
        sortable: value == "due_date",
        text: headers[value],
        width: width[value] || "200px",
      }));
    },
    querys() {
      let queryList = [];
      let { sortBy, sortDesc } = this.options;
      if (sortBy[0] && sortDesc[0] == true) {
        queryList.push(orderBy("from_date", "desc"));
      } else {
        queryList.push(orderBy("from_date", "asc"));
      }
      let date = moment().format("YYYY-MM-DD");
      if (this.type == "Overdue") {
        queryList.push(where("from_date", "<", date));
        queryList.push(where("type", "!=", "event"));
      }
      if (this.type == "Today") {
        queryList.push(where("from_date", "==", date));
      }
      if (this.type == "Upcoming") {
        queryList.push(where("from_date", ">", date));
      }
      if (this.completedTasks) {
        if (this.scheduleType == "") {
          queryList.push(
            or(
              where("is_completed", "==", true),
              and(
                where("type", "==", "event"),
                where("from_date", "<", moment().format("YYYY-MM-DD"))
              )
            )
          );
        }
        if (this.scheduleType == "task") {
          queryList.push(where("is_completed", "==", true));
          queryList.push(where("type", "==", "task"));
        }
        if (this.scheduleType == "event") {
          queryList.push(
            where("from_date", "<", moment().format("YYYY-MM-DD"))
          );
          queryList.push(where("type", "==", "event"));
        }
      } else {
        queryList.push(where("is_completed", "==", false));
      }
      if (this.member) {
        queryList.push(where("member", "==", this.member));
      }
      return {
        countQuery: query(colTeamSchedules, ...queryList),
        pageQuery: query(
          colTeamSchedules,
          ...[...queryList, ...this.getPaginationQuery(this.options)]
        ),
      };
    },
  },
  inject: {
    users: {
      from: "users",
      default: () => [],
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchEvents();
      },
      deep: true,
    },
    member() {
      this.reset();
    },
    scheduleType() {
      this.reset();
    },
  },
  mixins: [scheduleMixin],
  methods: {
    deleteEvent(event) {
      this.removeTask(event).then(() => {
        this.fetchEvents();
      });
    },
    reset() {
      this.options = { ...this.options, page: 1 };
    },
    async onChange(e, item) {
      if (!this.hasEdit("schedule")) return;
      try {
        this.loading = true;
        this.selectedTaskId = item.id;
        await updateDoc(doc(colTeamSchedules, item.id), {
          is_completed: e,
          updated_at: getDate(),
          updated_by: this.userEmail,
        });
        this.options = { ...this.options, page: 1 };
        this.$store.dispatch("fetchUserTaskCount");
      } catch (error) {
        this.handleError(error);
      }
      console.log(e);
    },
    async fetchEvents() {
      try {
        this.loading = true;
        let { countQuery, pageQuery } = this.querys;
        if (this.options.page == 1) {
          this.total = (await getCountFromServer(countQuery)).data().count;
        }
        let docs = (await getDocs(pageQuery)).docs;
        this.customers = await getTaskCustomers(docs, this.customers);
        this.firstVisible = docs[0];
        this.lastVisible = docs[docs.length - 1];
        this.currentPage = this.options.page;
        this.items = mapDocs(docs).map((i) => {
          i.due_date = moment(`${i.from_date}T${i.from_time}`).format(
            "MM/DD/YYYY hh:mm A"
          );
          if (this.type == "Overdue") {
            let days = moment().diff(moment(i.from_date), "days");
            i.due_date = `${days} Days Overdue`;
          }
          if (this.type == "Today") {
            i.due_date = "Today";
          }
          let customer = this.customers.find((c) => c.id == i.client);
          if (customer && i.client) {
            i.client_name = `${customer.first_name} ${customer.last_name}`;
          }
          let member = this.users.find((u) => u.id == i.member);
          if (member) i.member_name = member.name;
          return i;
        });
        this.selectedTaskId = "";
        this.loading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>

<style></style>
