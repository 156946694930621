<template>
  <ValidationProvider :rules="rules" v-slot="{ errors }" mode="passive">
    <div>
      <label class="body-small" v-if="label">{{ label }}</label>
      <v-autocomplete
        :class="label ? 'mt-1' : ''"
        v-model="input"
        :items="items"
        :dense="dense"
        item-value="value"
        :item-text="itemText"
        @blur="$emit('blur')"
        append-icon="mdi-chevron-down"
        outlined
        @input="$emit('input', input)"
        v-bind="$attrs"
        :error-messages="errors"
      >
        <!-- <template v-slot:selection="{ item, index }">
        <template v-if="isObject(item)">
          <span v-if="index === 0">{{ item.name }}</span>
          <span
            v-if="index === 1"
            class="grey--text caption autocomplete-selection"
          >
            (+{{ value.length - 1 }} others)
          </span>
        </template>
        <template v-else>
          <span v-if="index === 0">{{ item }}</span>
          <span
            v-if="index === 1"
            class="grey--text caption autocomplete-selection"
          >
            (+{{ value.length - 1 }} others)
          </span>
        </template>
      </template> -->
      </v-autocomplete>
    </div>
  </ValidationProvider>
</template>

<script>
import { getRules, inputDense } from "../../../assets/utils/common.utils.js";
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    itemText: {
      type: String,
      default: "name",
    },
    value: {},
    items: {
      type: Array,
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: inputDense,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: String,
      default: "",
    },
    customRules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      input: "",
    };
  },
  computed: {
    getRules() {
      let { customRules, rules } = this;
      if (customRules.length > 0) return customRules;
      return getRules(rules);
    },
  },
  watch: {
    value(val) {
      this.input = val;
    },
  },
  methods: {
    isObject(item) {
      return item instanceof Object;
    },
  },
  mounted() {
    this.input = this.value;
  },
};
</script>

<style>
.autocomplete-selection {
  white-space: nowrap;
  overflow: hidden;
}
</style>
