<template>
  <ValidationProvider :rules="rules" v-slot="{ errors }" mode="passive">
    <div>
      <label class="body-small" v-if="label">{{ label }}</label>
      <v-text-field
        v-model="input"
        :dense="dense"
        ref="input"
        :class="label ? 'mt-1' : ''"
        v-bind="$attrs"
        :value="input"
        @keydown.enter.prevent="$emit('enter')"
        outlined
        class="color-picker"
        :error-messages="errors"
        @input="$emit('input', input)"
      >
        <template v-slot:append>
          <v-menu
            v-model="menu"
            top
            nudge-bottom="105"
            nudge-left="16"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on }">
              <div :style="swatchStyle" v-on="on" />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker
                  v-model="input"
                  @input="$emit('input', input)"
                  flat
                />
              </v-card-text>
            </v-card>
          </v-menu>
        </template>
      </v-text-field>
    </div>
  </ValidationProvider>
</template>

<script>
import { getRules, inputDense } from "../../../assets/utils/common.utils.js";
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    help: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: inputDense,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: String,
      default: "",
    },
    customRules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      input: "#FF0000FF",
      menu: false,
    };
  },
  computed: {
    swatchStyle() {
      const { input, menu } = this;
      return {
        backgroundColor: input,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    getRules() {
      let { customRules, rules } = this;
      if (customRules.length > 0) return customRules;
      return getRules(rules);
    },
  },
  watch: {
    value(val) {
      this.input = val;
    },
  },
  mounted() {
    this.input = this.value;
  },
};
</script>
