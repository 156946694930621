<template>
  <div class="mt-3" style="height: 165px">
    <v-divider />
    <div class="title pt-3">Progress</div>
    <v-row class="py-5" v-if="loading">
      <v-col v-for="i in 5" :key="i">
        <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
      </v-col>
    </v-row>
    <template v-else>
      <v-stepper alt-labels flat class="client-progress">
        <v-stepper-header class="px-0">
          <v-stepper-step
            step="1"
            :complete="true"
            class="text-caption text-center"
          >
            Contract <br />Sent
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            step="2"
            :complete="true"
            class="text-caption text-center"
          >
            Contract<br />Signed
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            step="3"
            :complete="isPhotoIDUpload"
            class="text-caption text-center"
          >
            Photo<br />
            ID</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step
            step="4"
            :complete="isSSUpload"
            class="text-caption text-center"
            >Social <br />
            Security<br />
            Document
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            step="5"
            :complete="isReportImported"
            class="text-caption text-center"
            >Report<br />
            Imported</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step
            step="6"
            :complete="isFaxSent"
            class="text-caption text-center"
          >
            Letters<br />
            Sent</v-stepper-step
          >
        </v-stepper-header>
      </v-stepper>
    </template>
  </div>
</template>

<script>
import { getCountFromServer, getDocs, query, where } from "firebase/firestore";
import {
  colCreditReports,
  colCustomerDocuments,
  colFaxes,
} from "@/assets/utils/firebase.utils";
import { mapDocs } from "@/assets/utils/doc.utils";
export default {
  data() {
    return {
      loading: true,
      isReportImported: false,
      isFaxSent: false,
      isPhotoIDUpload: false,
      isSSUpload: false,
    };
  },
  methods: {
    async fetchProgress() {
      try {
        this.loading = true;
        let customerId = this.$route.params.id;
        let reportQuery = query(
          colCreditReports,
          where("customer", "==", customerId)
        );
        let faxQuery = query(
          colFaxes,
          where("customer", "==", customerId),
          where("status", "==", "COMPLETED")
        );
        let reportCount = (await getCountFromServer(reportQuery)).data().count;
        if (this.hasRead("send-faxes")) {
          let faxCount = (await getCountFromServer(faxQuery)).data().count;
          this.isFaxSent = faxCount > 0;
        }
        let documentQuery = query(
          colCustomerDocuments,
          where("customer", "==", customerId),
          where("document_id", "in", ["photo-id-copy", "social-security-card"])
        );
        let documents = mapDocs((await getDocs(documentQuery)).docs);
        this.isSSUpload =
          documents.find((i) => i.document_id == "social-security-card") !=
          null;
        this.isPhotoIDUpload =
          documents.find((i) => i.document_id == "photo-id-copy") != null;
        this.isReportImported = reportCount > 0;
        this.loading = false;
      } catch (error) {
        console.log("Error while fetching progress");
        this.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchProgress();
  },
};
</script>

<style lang="scss">
.client-progress {
  .v-stepper__label {
    text-align: center;
    font-weight: 500;
  }
}
</style>
