import Confirm from "./ConfirmAlert";

const ConfirmModal = {
  install(Vue) {
    this.EventBus = new Vue();
    Vue.component("ConfirmAlert", Confirm);
    Vue.prototype.$confirm = {
      show(params) {
        ConfirmModal.EventBus.$emit("show", params);
      }
    };
  }
};

export default ConfirmModal;
