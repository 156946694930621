import { isAuth } from "@/assets/utils/auth.utils";
import MyCompany from "@/pages/mycompany/MyCompany.vue";
import MyCompanyProfile from "@/pages/mycompany/MyCompanyProfile.vue";
import RunningTotals from "@/pages/mycompany/RunningTotals.vue";
import MyTeamMembers from "@/pages/mycompany/MyTeamMembers.vue";
import RolesPermissions from "@/pages/mycompany/RolesPermissions.vue";
import AddRole from "@/pages/mycompany/AddRole.vue";
import ClientStatusTypes from "@/pages/mycompany/ClientStatusTypes.vue";
import AffiliatePaymentMethods from "@/pages/mycompany/AffiliatePaymentMethods.vue";
import ClientPaymentMethods from "@/pages/mycompany/ClientPaymentMethods.vue";
import AutomatedNotifications from "@/pages/mycompany/AutomatedNotifications.vue";
import FaxSchedules from "@/pages/mycompany/FaxSchedules.vue";
import AddFaxSchedule from "@/pages/mycompany/AddFaxSchedule.vue";
import FaxTemplates from "@/pages/mycompany/FaxTemplates.vue";
import AddFaxTemplate from "@/pages/mycompany/AddFaxTemplate.vue";
import FaxRecipients from "@/pages/mycompany/FaxRecipients.vue";
import ClientAgreements from "@/pages/mycompany/ClientAgreements.vue";
import DigitalSignatureRecords from "@/pages/mycompany/DigitalSignatureRecords.vue";

export default [
  {
    name: "my-company",
    path: "/my-company",
    component: MyCompany,
    beforeEnter: isAuth,
    redirect: "/my-company/profile",
    children: [
      {
        path: "profile",
        name: "my-company-profile",
        component: MyCompanyProfile,
        beforeEnter: isAuth,
        meta: {
          permission: "view",
          way: "my-company-profile",
        },
      },
      {
        path: "running-totals",
        name: "running-totals",
        component: RunningTotals,
        beforeEnter: isAuth,
        meta: {
          permission: "view",
          way: "running-totals",
        },
      },
      {
        path: "team-members",
        name: "team-members",
        component: MyTeamMembers,
        beforeEnter: isAuth,
        meta: {
          permission: "view",
          way: "team-members",
        },
      },
      {
        path: "roles-permissions",
        name: "roles-permissions",
        component: RolesPermissions,
        beforeEnter: isAuth,
        meta: {
          permission: "view",
          way: "roles-permissions",
        },
      },
      {
        path: "add-role",
        name: "add-role",
        component: AddRole,
        beforeEnter: isAuth,
        meta: {
          permission: "edit",
          way: "roles-permissions",
        },
      },
      {
        path: "update-role/:id",
        name: "update-role",
        component: AddRole,
        beforeEnter: isAuth,
        meta: {
          permission: "view",
          way: "roles-permissions",
        },
      },
      {
        path: "client-status-types",
        name: "client-status-types",
        component: ClientStatusTypes,
        beforeEnter: isAuth,
        meta: {
          permission: "view",
          way: "client-status-types",
        },
      },
      {
        path: "affiliate-payment-methods",
        name: "affiliate-payment-methods",
        component: AffiliatePaymentMethods,
        beforeEnter: isAuth,
        meta: {
          permission: "view",
          way: "payment-methods",
        },
      },
      {
        path: "client-payment-methods",
        name: "client-payment-methods",
        component: ClientPaymentMethods,
        beforeEnter: isAuth,
        meta: {
          permission: "view",
          way: "payment-methods",
        },
      },
      {
        path: "automated-notifications",
        name: "automated-notifications",
        component: AutomatedNotifications,
        beforeEnter: isAuth,
        meta: {
          permission: "view",
          way: "automated-notifications",
        },
      },
      {
        path: "fax-schedules",
        name: "fax-schedules",
        component: FaxSchedules,
        beforeEnter: isAuth,
        meta: {
          permission: "view",
          way: "fax-schedules",
        },
      },
      {
        path: "add-fax-schedule",
        name: "add-fax-schedule",
        component: AddFaxSchedule,
        beforeEnter: isAuth,
        meta: {
          permission: "edit",
          way: "fax-schedules",
        },
      },
      {
        path: "update-fax-schedule/:id",
        name: "update-fax-schedule",
        component: AddFaxSchedule,
        beforeEnter: isAuth,
        meta: {
          permission: "edit",
          way: "fax-schedules",
        },
      },
      {
        path: "fax-templates",
        name: "fax-templates",
        component: FaxTemplates,
        beforeEnter: isAuth,
        meta: {
          permission: "view",
          way: "fax-templates",
        },
      },
      {
        path: "add-fax-template",
        name: "add-fax-template",
        component: AddFaxTemplate,
        beforeEnter: isAuth,
        meta: {
          permission: "edit",
          way: "fax-templates",
        },
      },
      {
        path: "update-fax-template/:id",
        name: "update-fax-template",
        component: AddFaxTemplate,
        beforeEnter: isAuth,
        meta: {
          permission: "edit",
          way: "fax-templates",
        },
      },
      {
        path: "fax-recipients",
        name: "fax-recipients",
        beforeEnter: isAuth,
        component: FaxRecipients,
        meta: {
          permission: "view",
          way: "fax-recipients",
        },
      },
      {
        path: "client-agreements",
        name: "client-agreements",
        beforeEnter: isAuth,
        component: ClientAgreements,
        meta: {
          permission: "view",
          way: "client-agreements",
        },
      },
      {
        path: "digital-signature-records",
        name: "digital-signature-records",
        beforeEnter: isAuth,
        component: DigitalSignatureRecords,
        meta: {
          permission: "view",
          way: "client-agreements",
        },
      },
    ],
  },
];
