<template>
  <div>
    <v-card
      class="file-dropper mb-3 d-flex"
      @drop="onDrop"
      outlined
      max-width="1000px"
      v-if="isSendFaxAdmin"
      @dragover="dragover"
      @dragleave="dragleave"
    >
      <div
        class="d-flex flex-column align-center justify-center file-dropper-item"
        style="width: 100%"
      >
        <img src="@/assets/images/file-upload.png" width="80px" alt="" />
        <v-btn text @click="$refs.file.click()" color="primary" class="my-1"
          >Upload File</v-btn
        >
        <div class="subtitle-2 grey--text">Drag & Drop files here</div>
        <input
          type="file"
          ref="file"
          multiple
          style="display: none"
          @change="fileChange"
        />
      </div>
    </v-card>
    <v-row style="max-width: 1000px">
      <v-col>
        <div class="subtitle-1 font-weight-bold">
          Accounts to Add to the Letter
        </div>
        <v-list>
          <v-list-item-group v-model="files" multiple @change="addSelected()">
            <template v-for="(item, i) in fileList">
              <v-list-item
                :key="i"
                v-if="!isSelected(item)"
                :value="item"
                :disabled="isSelectDisabled"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ formatName(item.name) }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      color="deep-purple accent-4"
                    ></v-checkbox>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col>
        <div class="subtitle-1 font-weight-bold">Documents you've selected</div>
        <div class="caption grey--text mb-3">
          Drag and drop documents to reorder them.
        </div>
        <draggable draggable=".file-item" v-model="form.files" @input="onInput">
          <div
            v-for="(item, i) in form.files"
            class="file-item"
            :key="i"
            style="max-width: 400px"
          >
            <div
              class="d-flex pa-2 align-center justify-space-between"
              v-if="item.deleted != true"
            >
              <div>
                <v-icon class="mr-2">mdi-drag</v-icon>
                <v-icon>mdi-file</v-icon>
                <v-btn
                  text
                  @click="openFile(item)"
                  :disabled="isDisabled(item)"
                  class="subtitle-2 text-truncate pa-0 primary--text text-none"
                >
                  {{ formatName(item.name) }}
                </v-btn>
              </div>
              <delete-icon-button
                @click="removeFile({ item, index: i })"
                small
              ></delete-icon-button>
            </div>
            <v-divider v-if="item.deleted != true"></v-divider>
          </div>
        </draggable>
      </v-col>
    </v-row>
    <div>
      <primary-button
        type="submit"
        v-if="hasEdit('send-faxes')"
        :loading="loading"
        @click="submit()"
        >Continue</primary-button
      >
      <v-btn
        outlined
        height="40px"
        color="red"
        class="ml-3"
        :disabled="loading"
        @click="$emit('back')"
        >Back</v-btn
      >
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import DeleteIconButton from "@/components/ui/buttons/DeleteIconButton.vue";
import {
  findMimeType,
  getDate,
  getSearchArray,
  isDLFile,
} from "@/assets/utils/common.utils";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import { colFaxes, storage } from "@/assets/utils/firebase.utils";
import { doc, updateDoc } from "firebase/firestore";
import { Buffer } from "buffer";
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { draggable, DeleteIconButton, PrimaryButton },
  inject: ["customer", "faxId"],
  data() {
    return {
      form: {
        files: [],
      },
      loading: false,
      newFiles: [],
      files: [],
    };
  },
  computed: {
    isSelectDisabled() {
      return this.form.files.filter((i) => i.deleted != true).length > 1;
    },
    fileList() {
      let files = this.customer.file_uploads || [];
      return [...files, ...this.newFiles];
    },
  },
  methods: {
    isDisabled({ name }) {
      if (this.isSendFaxAdmin) return false;
      return !isDLFile({ name });
    },
    addSelected() {
      const vm = this;
      vm.files.forEach((item) => {
        let index = vm.form.files.findIndex((i) => item.fileId == i.fileId);
        if (index != -1) {
          let item = { ...vm.form.files[index] };
          delete item.deleted;
          vm.$set(vm.form.files, index, item);
        } else {
          vm.form.files.push(item);
        }
      });
      vm.files = [];
    },
    isSelected(item) {
      return (
        this.form.files.find(
          (i) => i.fileId == item.fileId && i.deleted != true
        ) != null
      );
    },
    formatName(name) {
      name = name.split(".")[0];
      if (name.length > 50) {
        return name.substr(0, 50) + "...";
      }
      return name;
    },
    removeFile({ item, index }) {
      if (item.url instanceof File) {
        this.form.files.splice(index, 1);
      } else {
        this.$set(this.form.files, index, { ...item, deleted: true });
      }
    },
    onInput() {
      // this.$emit("input", this.form);
    },
    dragover(event) {
      event.preventDefault();
      event.currentTarget.classList.add("primary");
      event.currentTarget.classList.add("lighten-4");
    },
    dragleave(event) {
      event.currentTarget.classList.remove("primary");
      event.currentTarget.classList.remove("lighten-4");
    },
    onDrop(event) {
      event.preventDefault();

      let files = this.mapFiles(event.dataTransfer.files);
      this.newFiles = [...this.newFiles, ...files];

      this.dragleave(event);
    },
    mapFiles(files) {
      return Array.from(files).map((url, i) => {
        const parts = url.name.split(".");
        let ext = parts[parts.length - 1];
        let fileId = `${new Date().getTime() + i}.${ext}`;
        return {
          url,
          fileId,
          name: url.name,
        };
      });
    },
    fileChange(e) {
      let files = this.mapFiles(e.target.files);
      this.newFiles = [...this.newFiles, ...files];
    },
    openFile({ url }) {
      if (url instanceof File == false) {
        window.open(url, "_blank");
      }
    },
    init() {
      Object.keys(this.form).forEach((key) => {
        if (this.value[key] != undefined) {
          this.form[key] = this.value[key];
        }
      });
      this.form.files = [...this.form.files].map((i) => {
        if (isDLFile(i)) return i;
        return { ...i, deleted: true };
      });
    },
    getFileFromUrl({ url, type, name }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const axios = require("axios");
          let response = await axios.get(url, { responseType: "arraybuffer" });
          let file = Buffer.from(response.data, "binary").toString("base64");
          type = type || findMimeType(name);
          file = `data:${type};base64,${file}`;
          resolve(file);
        } catch (error) {
          reject(error);
        }
      });
    },
    async submit() {
      try {
        this.loading = true;
        let list = [];
        for (let file of this.form.files) {
          if (
            (file.url instanceof File ||
              file.url.indexOf(`${this.faxId}`) == -1) &&
            file.deleted != true
          ) {
            let fileId = file.fileId || new Date().getTime();
            if (file.url instanceof File) {
              const parts = file.url.name.split(".");
              let ext = parts[parts.length - 1];
              fileId = `${new Date().getTime()}.${ext}`;
            }
            let storageRef = ref(storage, `faxes/${this.faxId}/${fileId}`);
            let fileItem = file.url;
            if (file.url instanceof File == false) {
              let base64 = await this.getFileFromUrl(file);
              await uploadString(storageRef, base64, "data_url");
            } else {
              file.type = file.url.type;
              await uploadBytes(storageRef, fileItem);
            }
            file.url = await getDownloadURL(storageRef);
            file.fileId = fileId;
            list.push(file);
          } else if (file.deleted == true) {
            console.log("deletign the file");
            try {
              let storageRef = ref(
                storage,
                `faxes/${this.faxId}/${file.fileId}`
              );
              await deleteObject(storageRef);
            } catch (error) {
              console.log(error);
            }
          } else {
            list.push(file);
          }
        }
        let files = list.map((item) => {
          delete item.deleted;
          return item;
        });
        let _ = require("lodash");
        let fileName = _.get(files, "[1].name", "");
        let data = {
          files,
          last_step: 2,
          updated_at: getDate(),
        };
        if (fileName) {
          data.fax_title = `${fileName.split(".")[0]}`;
          data._search = getSearchArray(data.fax_title);
        }
        await updateDoc(doc(colFaxes, this.faxId), data);
        this.$emit("input", {
          files,
          fax_title: data.fax_title,
        });
        this.$emit("next");
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.handleError(error);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
};
</script>

<style lang="scss">
.file-upload-rules {
  .v-input__slot {
    display: none !important;
  }
}
.file-dropper {
  width: 100%;
  &-item {
    height: 220px;
  }
}
.file-item {
  cursor: move;
}
</style>
