<template>
  <v-dialog v-model="dialog" width="600px">
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <edit-icon-button></edit-icon-button>
      </span>
    </template>
    <dialog-card
      :loading="loading"
      title="Update Transaction"
      @submit="submit()"
      @close="dialog = false"
    >
      <v-row>
        <v-col cols="6" v-for="(label, key) in details" :key="key">
          <label class="body-small">{{ label }}</label>
          <div class="body-small--medium black--text">
            {{ formatText(key) }}
          </div>
        </v-col>
      </v-row>
      <client-autocomplete
        label="Customer"
        class="mt-5"
        v-model="customer"
        rules="required"
      ></client-autocomplete>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { formatDate } from "@/assets/utils/common.utils";
import EditIconButton from "../ui/buttons/EditIconButton.vue";
import DialogCard from "../ui/DialogCard.vue";
import ClientAutocomplete from "../clients/ClientAutocomplete.vue";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/assets/utils/firebase.utils";
export default {
  components: {
    DialogCard,
    EditIconButton,
    ClientAutocomplete,
  },
  props: {
    customers: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      customer: "",
      loading: false,
      details: {
        created_at: "Date",
        reference_no: "Reference No",
        amount: "Amount",
        type: "Type",
        payment_method: "Payment Method",
        name: "Customer Name",
        email: "Customer Email",
        affiliate: "Affiliate Name",
      },
    };
  },
  watch: {
    dialog() {
      this.customer = this.item.customer_id || "";
    },
  },
  methods: {
    formatText(key) {
      if (key == "created_at") return formatDate(this.item[key]);
      return this.item[key];
    },
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        let updateTransaction = httpsCallable(functions, "updateTransaction");
        let doc = { customerId: vm.customer, transactionId: vm.item.id };
        let { status, message } = (await updateTransaction(doc)).data;
        vm.loading = false;
        if (status) {
          vm.dialog = false;
          vm.$emit("onUpdate");
        } else {
          vm.notifyError(message);
        }
      } catch (error) {
        console.log(error);
        vm.loading = false;
        vm.handleError(error);
      }
    },
  },
  beforeMount() {
    this.customer = this.item.customer_id || "";
  },
};
</script>

<style></style>
