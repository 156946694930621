<template>
  <ValidationProvider :rules="rules" v-slot="{ errors }" mode="passive">
    <vc-date-picker
      v-model="date"
      mode="time"
      @input="update()"
      inputTime="HH:mm"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <v-text-field
          :value="inputValue"
          :label="inlineLabel"
          outlined
          dense
          readonly
          :error-messages="errors"
          append-icon="mdi-clock-outline"
          v-bind="$attrs"
          v-on="inputEvents"
        ></v-text-field>
      </template>
    </vc-date-picker>
  </ValidationProvider>
</template>

<script>
import moment from "moment";
import { getRules, inputDense } from "../../../assets/utils/common.utils.js";
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: inputDense,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: String,
      default: "",
    },
    help: String,
    customRules: {
      type: Array,
      default: () => [],
    },
    inlineLabel: String,
  },
  data: () => ({
    date: "",
    menu: false,
    modal: false,
  }),
  computed: {
    formated() {
      let { date } = this;
      if (date) return moment(date, "HH:mm").format("hh:mm A");
      return "";
    },
    getRules() {
      let { customRules, rules } = this;
      if (customRules.length > 0) return customRules;
      return getRules(rules);
    },
  },
  watch: {
    value() {
      this.initDate();
    },
  },
  mounted() {
    this.initDate();
  },
  methods: {
    initDate() {
      let date = moment(this.value, "HH:mm");
      if (date.isValid()) {
        this.date = date.toDate();
      }
    },
    update() {
      const vm = this;
      let date = moment(vm.date).format("HH:mm");
      vm.$emit("input", date);
    },
  },
};
</script>
