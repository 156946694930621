<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <ValidationProvider :rules="rules" v-slot="{ errors }" mode="passive">
        <label class="body-small" v-if="label">{{ label }}</label>
        <v-text-field
          v-model="formated"
          @keypress="onInput"
          :class="label ? 'mt-1' : ''"
          :hide-details="hideDetails"
          :label="inlineLabel"
          :disabled="readonly"
          :placeholder="format"
          :dense="dense"
          append-icon="mdi-calendar"
          outlined
          :error-messages="errors"
          @blur="validateDate()"
          errors
          @click:append="on.click"
        />
      </ValidationProvider>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable
      :type="type"
      :min="min"
      :disabled="readonly"
      :max="max"
      @input="
        menu = false;
        $emit('input', date);
      "
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
import { getRules, inputDense } from "../../../assets/utils/common.utils.js";
export default {
  props: {
    readonly: Boolean,
    label: {
      type: String,
      default: "",
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: inputDense,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: String,
      default: "",
    },
    help: String,
    customRules: {
      type: Array,
      default: () => [],
    },
    inlineLabel: String,
    type: {
      type: String,
      default: "date",
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    input: "",
    modal: false,
    formated: "",
  }),
  computed: {
    getRules() {
      let { customRules, rules } = this;
      if (customRules.length > 0) return customRules;
      return getRules(rules);
    },
    format() {
      return this.type == "date" ? "MM/DD/YYYY" : "MM/YYYY";
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.date = val;
        this.formatDate();
      },
    },
  },
  beforeMount() {
    this.date = this.value;
    this.input = this.date;
    this.formatDate();
  },
  methods: {
    formatDate() {
      console.log("formatting...", this.date);

      if (this.date) {
        this.formated = moment(this.date).format(this.format);
      } else {
        this.formated = "";
      }
    },
    update() {
      const vm = this;
      vm.$refs.menu.save(vm.date);
      let val = vm.date;
      vm.input = vm.date;
      vm.$emit("input", val);
      this.formatDate();
    },
    validateDate() {
      const date = moment(this.formated, this.format);
      if (
        !date.isValid() ||
        (this.min && date.isBefore(this.min)) ||
        (this.max && date.isAfter(this.max))
      ) {
        this.date = "";
      } else {
        this.date = date.format(this.type == "date" ? "YYYY-MM-DD" : "YYYY-MM");
      }
      this.$emit("input", this.date);
      this.formatDate();
    },
    onInput(e) {
      if (e.keyCode < 47 || e.keyCode > 57) {
        e.preventDefault();
      }
      let length = this.type == "date" ? 10 : 9;
      if (this.formated.length == length) {
        e.preventDefault();
      }
      if (this.formated.length) if (!this.formated) this.formated = "";
      var len = this.formated.length;

      if (len !== 1 || len !== 3) {
        if (e.keyCode == 47) {
          e.preventDefault();
        }
      }

      // If they don't add the slash, do it for them...
      if (len === 2) {
        this.formated += "/";
      }
      if (this.type == "date") {
        if (len === 5) {
          this.formated += "/";
        }
      }
      let split = this.formated.split("/");
      if (split[0] != undefined && Number(split[0]) > 12) {
        split[0] = 12;
      }
      if (this.type == "date") {
        if (split[1] != undefined && Number(split[1]) > 31) {
          split[1] = 31;
        }
        if (split[2] != undefined && Number(split[2]) > 9999) {
          split[2] = split[2].substr(0, 3);
        }
      } else {
        if (split[1] != undefined && Number(split[1]) > 9999) {
          split[1] = split[1].substr(0, 3);
        }
      }
      this.formated = split.join("/");
    },
  },
};
</script>
