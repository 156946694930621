<template>
  <layout>
    <template slot="title">
      <div class="d-flex align-center text-h4">
        Fax Schedules
        <fax-schedule-settings />
      </div>
    </template>
    <add-button slot="add" to="add-fax-schedule" v-if="hasEdit('fax-schedules')"
      >Add New</add-button
    >
    <static-table-view :headers="headers" :list="list" :loading="loading">
      <template v-slot:item.status="{ value }">
        <div class="d-flex align-center">
          <span class="dot mr-2" :class="statusStyle(value)"></span>
          {{ value }}
        </div>
      </template>
      <template v-slot:item.updated_date="{ item }">
        {{ item.updated_at }}</template
      >

      <template v-slot:item.time_window="{ item }">{{
        formatTimeWindow(item)
      }}</template>
      <template v-slot:item.frequency="{ item }">{{
        formatFrequency(item)
      }}</template>
      <template v-slot:item.start_time="{ item }">{{
        formatStartTime(item)
      }}</template>
      <template v-slot:item.updated_by="{ value }">
        {{ getUpdatedBy(value) }}
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex align-center">
          <update-fax-schedule-status
            v-if="hasEdit('fax-schedules')"
            @onUpdate="init()"
            :item="item"
          />
          <edit-icon-button
            class="ml-2"
            v-if="hasEdit('fax-schedules')"
            :to="{ name: 'update-fax-schedule', params: { id: item.id } }"
          />
          <delete-icon-button
            v-if="hasDelete('fax-schedules')"
            @click="remove(item)"
          />
        </div>
      </template>
    </static-table-view>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import StaticTableView from "@/components/ui/StaticTableView.vue";
import moment from "moment-timezone";
import {
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { colFaxes, colFaxSchedules } from "@/assets/utils/firebase.utils";
import { mapDocs } from "@/assets/utils/doc.utils";
import EditIconButton from "@/components/ui/buttons/EditIconButton.vue";
import DeleteIconButton from "@/components/ui/buttons/DeleteIconButton.vue";
import UpdateFaxScheduleStatus from "@/components/mycompany/UpdateFaxScheduleStatus.vue";
import AddButton from "@/components/ui/buttons/AddButton.vue";
import FaxScheduleSettings from "@/components/mycompany/FaxScheduleSettings.vue";
export default {
  components: {
    Layout,
    StaticTableView,
    EditIconButton,
    DeleteIconButton,
    UpdateFaxScheduleStatus,
    AddButton,
    FaxScheduleSettings,
  },
  data() {
    return {
      list: [],
      loading: false,
    };
  },
  computed: {
    headers() {
      let doc = {
        schedule_name: "Name",
        frequency: "Frequency",
        start_day: "Start Day",
        start_time: "Start Time",
        time_window: "Time Window",
        updated_date: "Updated On",
        updated_by: "Updated By",
        status: "Status",
        action: "Actions",
      };
      return Object.keys(doc).map((value) => ({ text: doc[value], value }));
    },
  },
  methods: {
    remove({ id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure you want to delete this Fax Schedule?",
        onConfirm: async () => {
          // eslint-disable-next-line no-async-promise-executor
          return new Promise(async (resolve, reject) => {
            try {
              let q = query(colFaxes, where("schedule", "==", id), limit(1));
              let snap = await getDocs(q);
              if (snap.size != 0) {
                reject({
                  message:
                    "This schedule cannot be deleted since it is assigned to the faxes.",
                });
                return;
              }
              await deleteDoc(doc(colFaxSchedules, id));
              resolve();
              vm.init();
            } catch (error) {
              reject(error);
            }
          });
        },
      });
    },
    statusStyle(value) {
      if (value == "Running") return "running";
      return "paused";
    },
    formatFrequency({ frequency, frequency_unit }) {
      if (frequency == 1) return `Every ${frequency_unit.split("s").join("")}`;
      return `Every ${frequency} ${frequency_unit}`;
    },
    formatStartTime({ start_time, start_time_unit }) {
      if (start_time_unit == "PM") start_time = Number(start_time) + 12;
      return moment().hour(start_time).minute(0).format("hh:mm A");
    },
    formatTimeWindow({ time_window }) {
      if (time_window == 1) return `1 Hours`;
      return `${time_window} Hours`;
    },
    async init() {
      const vm = this;
      try {
        vm.list = [];
        vm.loading = true;
        let q = query(colFaxSchedules, orderBy("schedule_name", "asc"));
        vm.list = mapDocs((await getDocs(q)).docs);
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
