<template>
  <v-chip
    v-if="isChip"
    outlined
    small
    style="text-transform: capitalize"
    :color="getClientStatusColor(type).background"
  >
    {{ type }}
  </v-chip>
  <div class="client-status" v-else>
    <span class="dot" :style="getClientStatusColor(type)"></span>
    {{ type }}
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: () => "",
    },
  },
  computed: {
    isChip() {
      return this.$route.name == "client-profile";
    },
  },
};
</script>

<style lang="scss">
.client-status {
  display: flex !important;
  text-transform: capitalize !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  align-items: center !important;
  color: black;
  .dot {
    margin-right: 8px !important;
  }
}
</style>
