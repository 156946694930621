<template>
  <layout title="Client Payment Methods">
    <add-payment-method
      @onUpdate="init()"
      slot="add"
      v-if="hasEdit('payment-methods')"
    />
    <static-table-view :list="list" :loading="loading" :headers="headers">
      <template v-slot:item.color="{ value }">
        <span class="status-dot" :style="{ background: value }"></span>
      </template>
      <template v-slot:item.updated_by="{ value }">
        {{ getUpdatedBy(value) }}
      </template>
      <template v-slot:item.updated_date="{ item }">
        {{ item.updated_at }}</template
      >

      <template v-slot:item.action="{ item }">
        <div class="d-flex">
          <add-payment-method
            @onUpdate="init()"
            :item="item"
            v-if="hasEdit('payment-methods')"
          />
          <delete-icon-button
            @click="remove(item)"
            v-if="hasDelete('payment-methods')"
          />
        </div>
      </template>
    </static-table-view>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import StaticTableView from "@/components/ui/StaticTableView.vue";
import { colClientPaymentMethods } from "@/assets/utils/firebase.utils";
import { deleteDoc, doc, getDocs, orderBy, query } from "firebase/firestore";
import DeleteIconButton from "@/components/ui/buttons/DeleteIconButton.vue";
import { mapDocs } from "@/assets/utils/doc.utils";
import AddPaymentMethod from "@/components/mycompany/AddPaymentMethod.vue";
export default {
  components: {
    Layout,
    StaticTableView,
    DeleteIconButton,
    AddPaymentMethod,
  },
  data() {
    return { list: [], loading: false };
  },
  computed: {
    headers() {
      return [
        { text: "Payment Method", value: "name" },
        { text: "Update On", value: "updated_date", width: "200px" },
        { text: "Update By", value: "updated_by", width: "200px" },
        { text: "Action", value: "action", width: "100px" },
      ];
    },
  },
  methods: {
    async remove({ id }) {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure you want to Delete this Payment Method?",
        onConfirm: () => {
          // eslint-disable-next-line no-async-promise-executor
          return new Promise(async (resolve, reject) => {
            try {
              await deleteDoc(doc(colClientPaymentMethods, id));
              vm.notifySuccess("Payment Method has been deleted successfully.");
              vm.init();
              resolve();
            } catch (error) {
              reject(error);
            }
          });
        },
      });
    },
    async init() {
      const vm = this;
      try {
        vm.list = [];
        vm.loading = true;
        let q = query(colClientPaymentMethods, orderBy("name", "asc"));
        vm.list = mapDocs((await getDocs(q)).docs);
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.status-dot {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: inline-block;
}
</style>
