<template>
  <v-dialog v-model="dialog" max-width="1000px" persistent scrollable>
    <template v-slot:activator="{ on }">
      <a v-on="on">
        {{ value }}
      </a>
    </template>
    <dialog-card :title="title" @close="dialog = false">
      <v-row>
        <v-col cols="3">
          <label class="body-small">Affiliate</label>
          <div class="body-small--medium black--text">{{ affiliate.name }}</div>
        </v-col>
        <v-col cols="3" v-if="isEarned">
          <label class="body-small">Total Transaction Amount</label>
          <div class="body-small--medium black--text">
            {{ formatPrice(affiliate.transaction_amount || 0) }}
          </div>
        </v-col>
        <v-col cols="3">
          <label class="body-small">{{ title }}</label>
          <div class="body-small--medium black--text">
            {{ commission }}
          </div>
        </v-col>
      </v-row>
      <div class="d-flex mt-5" v-if="isEarned && hasEdit('affiliates')">
        <input-field
          type="number"
          v-model="commission_rate"
          label="Commision%"
        ></input-field>
        <secondary-button
          :loading="isLoading"
          class="mt-7 ml-3"
          @click="applyCommission()"
          >Apply To Selection</secondary-button
        >
      </div>
      <v-row>
        <v-col cols="3">
          <date-field v-model="form.from" label="From Date" />
        </v-col>
        <v-col cols="3">
          <date-field v-model="form.to" label="To Date" />
        </v-col>
        <v-col cols="3">
          <client-autocomplete
            v-model="form.client"
            label="Client"
            placeholder="All Clients"
          />
        </v-col>
        <v-col cols="3" class="pt-10 d-flex">
          <v-spacer></v-spacer>
          <close-button width="100px" @click="resetFilter()"
            >Reset</close-button
          >
          <primary-button width="100px" @click="fetchTransactions(options)"
            >Apply</primary-button
          >
        </v-col>
      </v-row>
      <dynamic-table-view
        :list="list"
        :loading="loading"
        v-model="selected"
        :show-select="isEarned"
        ref="table"
        :server-items-length="total"
        @onPageChange="fetchTransactions"
        :headers="headers"
      >
        <template v-slot:item.amount="{ value }">
          {{ formatPrice(value || 0) }}</template
        >
        <template v-slot:item.paid_at="{ value }">
          {{ formatDate(value) }}</template
        >
        <template v-slot:item.commission="{ value }">
          {{ formatPrice(value) }}</template
        >
        <template v-slot:item.commission_rate="{ value }">
          {{ value }}%</template
        >
      </dynamic-table-view>
      <div slot="footer" class="d-flex">
        <close-button @click="dialog = false">Close</close-button>
      </div>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { getHeaders, getStartEndDate } from "@/assets/utils/common.utils";
import ClientAutocomplete from "../clients/ClientAutocomplete.vue";
import CloseButton from "../ui/buttons/CloseButton.vue";
import PrimaryButton from "../ui/buttons/PrimaryButton.vue";
import SecondaryButton from "../ui/buttons/SecondaryButton.vue";
import DialogCard from "../ui/DialogCard.vue";
import DynamicTableView from "../ui/DynamicTableView.vue";
import DateField from "../ui/form/DateField.vue";
import InputField from "../ui/form/InputField.vue";
import {
  getCountFromServer,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { colCommissions } from "@/assets/utils/firebase.utils";
import { fetchTransactionClients, mapDocs } from "@/assets/utils/doc.utils";
import transactionMixin from "@/mixins/transaction.mixin";
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    affiliate: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: "",
    },
  },
  components: {
    DialogCard,
    CloseButton,
    InputField,
    SecondaryButton,
    DateField,
    ClientAutocomplete,
    PrimaryButton,
    DynamicTableView,
  },
  mixins: [transactionMixin],
  data() {
    return {
      dialog: false,
      total: 50,
      selected: [],
      isLoading: false,
      loading: false,
      commission_rate: 0,
      list: [],
      form: {
        from: "",
        to: "",
        client: "",
      },
    };
  },
  computed: {
    isEarned() {
      return this.type == "earned";
    },
    commission() {
      let { commission_owed, commission } = this.affiliate;
      commission = commission || 0;
      commission_owed = commission_owed || 0;
      let earned = commission + commission_owed;
      switch (this.type) {
        case "earned":
          return this.formatPrice(earned);
        case "paid":
          return this.formatPrice(commission);
        case "owed":
          return this.formatPrice(commission_owed);
        default:
          return "";
      }
    },
    title() {
      switch (this.type) {
        case "earned":
          return "Total Commission Earned";
        case "paid":
          return "Total Commission Paid";
        case "owed":
          return "Total Commission Owed";
        default:
          return "";
      }
    },
    headers() {
      let doc = {
        client_name: "Client",
        created_at: "Date",
        amount: "Transaction Amount",
        commission: "Comission Amount",
        commission_rate: "Commission %",
      };
      let sortable = ["created_at"];

      if (this.type == "paid") {
        doc.paid_at = "Payout Date";
        doc.payout_payment_method = "Payment Method";
      }
      return getHeaders(doc).map((i) => {
        i.sortable = sortable.indexOf(i.value) != -1;
        return i;
      });
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.commission_rate = (this.affiliate.commission_rate || 0) * 100;
      }
    },
  },

  methods: {
    resetFilter() {
      this.form = { from: "", to: "", client: "" };
      this.fetchTransactions(this.options);
    },
    query(options) {
      let { sortDesc, sortBy } = options;
      let { client } = this.form;
      let queryList = [];
      let updatedAtSortIndex = sortBy.indexOf("created_at");
      if (updatedAtSortIndex != -1 && sortDesc[updatedAtSortIndex] == false) {
        queryList.push(orderBy("created_at", "asc"));
      } else {
        queryList.push(orderBy("created_at", "desc"));
      }
      let { start, end } = getStartEndDate(this.form);
      if (start && end) {
        queryList.push(where("created_at", ">=", start));
        queryList.push(where("created_at", "<=", end));
      }
      if (client) queryList.push(where("customer_id", "==", client));
      queryList.push(where("affiliate_id", "==", this.affiliate.id));
      if (this.type == "owed") queryList.push(where("payout", "==", false));
      if (this.type == "paid") queryList.push(where("payout", "==", true));
      return {
        countQuery: query(colCommissions, ...queryList),
        pageQuery: query(
          colCommissions,
          ...[...queryList, ...this.getPaginationQuery(options)]
        ),
      };
    },
    async fetchTransactions(options) {
      const vm = this;
      try {
        vm.loading = true;
        vm.options = options;
        vm.selected = [];
        vm.$refs.table.selected = [];
        vm.list = [];
        let page = options.page;
        let { countQuery, pageQuery } = vm.query(options);
        if (page == 1) {
          vm.total = (await getCountFromServer(countQuery)).data().count;
        }
        let docs = (await getDocs(pageQuery)).docs;
        vm.firstVisible = docs[0];
        vm.lastVisible = docs[docs.length - 1];
        vm.list = await fetchTransactionClients(mapDocs(docs));
        vm.currentPage = page;
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
    afterApplyCommission() {
      this.fetchTransactions(this.options);
    },
  },
};
</script>

<style></style>
