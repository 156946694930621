<template>
  <v-menu v-model="menu" open-on-hover>
    <template v-slot:activator="{ on }">
      <v-btn
        class="ml-1"
        v-on="on"
        icon
        x-small
        v-if="item.affiliate_id_2 || item.affiliate_id_3"
      >
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>
    </template>
    <v-list class="my-0 py-0" dense>
      <v-list-item v-if="item.affiliate_id_2">
        <v-list-item-title>{{
          getAffiliateName(item.affiliate_id_2)
        }}</v-list-item-title>
      </v-list-item>
      <v-divider v-if="item.affiliate_id_3"></v-divider>
      <v-list-item v-if="item.affiliate_id_3">
        <v-list-item-title>{{
          getAffiliateName(item.affiliate_id_3)
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      menu: false,
    };
  },
};
</script>

<style>
</style>