<template>
  <v-dialog v-model="dialog" :max-width="isCalender ? '400px' : '650px'">
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <template v-if="showButton">
          <edit-icon-button v-if="isEdit"></edit-icon-button>
          <add-button height="54px" v-else>
            {{ isTask ? "Add Task" : "Add New Event" }}</add-button
          >
        </template>
      </span>
    </template>
    <dialog-card
      :title="title"
      @close="dialog = false"
      @submit="submit()"
      :loading="loading"
    >
      <template v-if="isCalender">
        <input-field v-model="form.subject" inline-label="Add Title" />
        <div class="d-flex align-center body-3 black--text">
          <v-icon>mdi-clock-outline</v-icon>
          {{ dateRange }}
          <v-spacer></v-spacer>
          {{ timeRange }}
        </div>
      </template>
      <template v-else>
        <select-field
          :items="eventTypes"
          v-model="form.event_type"
          :inline-label="isTask ? 'Task Type' : 'Event Type'"
        />
        <input-field
          v-if="form.event_type == 'Other'"
          v-model="form.other_event_type"
          inline-label="Other Type"
        />
        <div class="d-flex">
          <event-color-picker v-model="form.color" />
          <div class="flex-grow-1 ml-3">
            <input-field
              inline-label="Subject"
              v-model="form.subject"
              name="subject"
              rules="required"
            />
          </div>
        </div>
        <v-row>
          <v-col cols="3" class="pb-0">
            <date-field
              :inline-label="isTask ? 'Due Date' : 'From Date'"
              v-model="form.from_date"
              rules="required"
            />
          </v-col>
          <v-col cols="3" v-if="!form.is_all_date" class="pb-0">
            <time-field
              inline-label="Time"
              v-model="form.from_time"
              rules="required"
            />
          </v-col>
          <v-col cols="3" class="pb-0">
            <date-field
              v-if="!isTask"
              inline-label="To Date"
              v-model="form.to_date"
              rules="required"
            />
          </v-col>
          <v-col cols="3" v-if="!form.is_all_date" class="pb-0">
            <time-field
              v-if="!isTask"
              inline-label="Time"
              v-model="form.to_time"
              rules="required"
            />
          </v-col>
        </v-row>
        <v-checkbox
          v-if="!isTask"
          class="mb-3 mt-0"
          hide-details
          label="All day event"
          v-model="form.is_all_date"
        />
        <client-autocomplete
          :hide-details="false"
          v-if="!isClientProfile"
          :disabled="isEdit && item && item.client != ''"
          inline-label="Client"
          v-model="form.client"
          clearable
        />
        <select-field
          v-model="form.member"
          :items="users"
          inline-label="Team Member"
          clearable
        />
        <input-field
          v-if="!isTask"
          inline-label="Location"
          v-model="form.location"
        />
        <textarea-field
          v-model="form.remark"
          :inline-label="isTask ? 'Notes' : 'Remark'"
        />
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { getDate, initForm } from "@/assets/utils/common.utils";
import ClientAutocomplete from "../clients/ClientAutocomplete.vue";
import AddButton from "../ui/buttons/AddButton.vue";
import DialogCard from "../ui/DialogCard.vue";
import DateField from "../ui/form/DateField.vue";
import InputField from "../ui/form/InputField.vue";
import SelectField from "../ui/form/SelectField.vue";
import TextareaField from "../ui/form/TextareaField.vue";
import TimeField from "../ui/form/TimeField.vue";
import EventColorPicker from "./EventColorPicker.vue";
import moment from "moment-timezone";
import { doc, getDoc, writeBatch } from "firebase/firestore";
import {
  colClients,
  colTeamSchedules,
  db,
} from "@/assets/utils/firebase.utils";
import EditIconButton from "../ui/buttons/EditIconButton.vue";

export default {
  components: {
    AddButton,
    DialogCard,
    SelectField,
    InputField,
    EventColorPicker,
    DateField,
    TimeField,
    ClientAutocomplete,
    TextareaField,
    EditIconButton,
  },
  props: {
    showButton: {
      type: Boolean,
      default: true,
    },
    isEdit: Boolean,
    item: Object,
    isCalender: Boolean,
    type: {
      type: String,
      default: "event",
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      form: {},
    };
  },
  computed: {
    isClientProfile() {
      return this.$route.name == "client-profile";
    },
    dateRange() {
      let { from_date, to_date } = this.form;
      let from = moment(from_date).format("ddd,MMM DD,YYYY");
      let to = moment(to_date).format("ddd,MMM DD,YYYY");
      if (!this.form.is_all_date) return from;
      return `${from} - ${to}`;
    },
    timeRange() {
      if (this.form.is_all_date) return "";
      let from = moment(this.form.from_time, "HH:mm").format("hh:mm A");
      let to = moment(this.form.to_time, "HH:mm").format("hh:mm A");
      return `${from} - ${to}`;
    },
    isTask() {
      return this.type == "task";
    },
    title() {
      if (this.isTask) {
        return this.isEdit ? "Edit Task" : "Add Task";
      }
      return this.isEdit ? "Edit Event" : "Create New Event";
    },
    eventTypes() {
      return [
        "Choose",
        "General",
        "Billing",
        "Send Invoice",
        "Follow Up",
        "Appointment",
        "Other",
      ].map((name) => {
        let value = name;
        if (name == "Choose") value = "";
        return { name, value };
      });
    },
    users() {
      let users = this.$store.getters.members;
      return [...users]
        .map((i) => ({ ...i, value: i.id }))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  watch: {
    dialog() {
      if (this.item) {
        initForm(this.form, this.item);
      }
    },
  },
  methods: {
    open({ is_all_date, date, from_time, to_time }) {
      this.reset();
      this.form.is_all_date = is_all_date == true;
      this.form.from_date = date;
      this.form.to_date = date;
      this.form.from_time = from_time;
      this.form.to_time = to_time;
      this.dialog = true;
    },
    reset() {
      this.form = {
        event_type: "",
        other_event_type: "",
        color: "#336abc",
        subject: "",
        from_date: moment().format("YYYY-MM-DD"),
        from_time: "12:20",
        to_date: moment().format("YYYY-MM-DD"),
        to_time: "",
        client: "",
        member: this.uid,
        is_all_date: false,
        remark: "",
      };
      if (this.isClientProfile) {
        this.form.client = this.$route.params.id;
        if (!this.isEdit) {
          this.form.event_type = "General";
        }
      }
      this.form.from_time = moment()
        .startOf("day")
        .startOf("hour")
        .format("HH:mm");
      this.form.to_time = moment()
        .startOf("day")
        .add(1, "hour")
        .startOf("hour")
        .format("HH:mm");
    },
    async submit() {
      try {
        let start = moment(this.form.from_date);
        if (this.isTask) {
          this.form.to_date = this.form.from_date;
        }
        let end = moment(this.form.to_date);
        let months = [];
        while (start <= end) {
          months.push(start.format("MM-YYYY"));
          start = start.clone().add(1, "month");
        }
        if (months.length > 12) {
          this.notifyError("The date range cannot exceed 12 months.");
          return;
        }
        this.loading = true;
        let client_name = "";
        if (this.form.client) {
          let client = (await getDoc(doc(colClients, this.form.client))).data();
          if (client) {
            client_name = `${client.first_name} ${client.last_name}`;
          }
        }
        if (this.type != "task") {
          await this.validateDate();
        }
        let data = {
          ...this.form,
          months,
          client_name,
          updated_at: getDate(),
          updated_by: this.userEmail,
          uid: this.uid,
        };
        let batch = writeBatch(db);
        let scheduleId = this.item ? this.item.id : doc(colTeamSchedules).id;
        let history = {
          created_at: getDate(),
          uid: this.uid,
          name: `${this.user.first_name} ${this.user.last_name}`,
          action: this.isEdit ? "Update" : "Create",
        };
        if (this.isEdit) {
          if (!data.client_name) {
            delete data.client_name;
          }
          batch.update(doc(colTeamSchedules, scheduleId), data);
        } else {
          batch.set(doc(colTeamSchedules, scheduleId), {
            ...data,
            type: this.type,
            is_completed: false,
            created_at: getDate(),
          });
        }
        batch.set(
          doc(
            colTeamSchedules,
            scheduleId,
            "history",
            new Date().getTime().toString()
          ),
          history
        );
        await batch.commit();
        this.$store.dispatch("fetchUserTaskCount");
        this.loading = false;
        this.reset();
        this.dialog = false;
        this.$emit("onEventUpsert");
      } catch (error) {
        this.handleError(error);
      }
    },
    validateDate() {
      return new Promise((resolve, reject) => {
        let fromDate = moment(this.form.from_date).startOf("day");
        let toDate = moment(this.form.to_date).startOf("day");
        if (!this.form.is_all_date) {
          fromDate = moment(
            `${fromDate.format("YYYY-MM-DD")}T${this.form.from_time}`
          );
          toDate = moment(
            `${toDate.format("YYYY-MM-DD")}T${this.form.to_time}`
          );
        }
        if (fromDate.isAfter(toDate)) {
          reject({
            message:
              "The end time must be later than the start time. Please choose again.",
          });
        } else {
          resolve();
        }
      });
    },
  },
  beforeMount() {
    this.reset();
  },
};
</script>

<style></style>
