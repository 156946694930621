<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <template v-slot:activator="{ on }">
      <span v-on="selected.length != 0 ? on : null" class="mr-3">
        <primary-button :disabled="selected.length == 0"
          >Sent Date</primary-button
        >
      </span>
    </template>
    <dialog-card
      title="Update Sent Date"
      :loading="loading"
      @close="dialog = false"
      @submit="submit"
    >
      <date-field label="Sent Date" rules="required" v-model="date" />
    </dialog-card>
  </v-dialog>
</template>

<script>
import { doc, writeBatch } from "firebase/firestore";
import PrimaryButton from "../ui/buttons/PrimaryButton.vue";
import DialogCard from "../ui/DialogCard.vue";
import DateField from "../ui/form/DateField.vue";
import { colFaxes, db } from "@/assets/utils/firebase.utils";
export default {
  components: { DialogCard, PrimaryButton, DateField },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      date: "",
      loading: false,
    };
  },
  watch: {
    dialog(val) {
      if (val) this.$emit("close");
    },
  },
  computed: {
    selected() {
      return [...this.items].filter((i) => i.status != "DRAFT");
    },
  },
  methods: {
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        let batch = writeBatch(db);
        this.selected.forEach((i) => {
          batch.update(doc(colFaxes, i.id), {
            created_at: `${vm.date}T00:00:00`,
          });
        });
        await batch.commit();
        vm.dialog = false;
        vm.loading = false;
        vm.date = "";
        vm.$emit("fetchFaxes");
      } catch (error) {
        console.log(error);
        vm.loading = false;
        vm.notifyError(error.message);
      }
    },
  },
};
</script>

<style></style>
